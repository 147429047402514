<template>
  <BaseSimpleLayout class="puchase-create">
    <template #title>仕入登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="createFormRef"
        :model="createForm"
        :rules="createFormRules"
        size="large"
        label-width="120px"
      >
        <el-form-item label="拠点" prop="depotId">
          <el-select
            v-model="createForm.depotId"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="仕入日" prop="purchaseDate">
          <el-date-picker
            v-model="createForm.purchaseDate"
            type="date"
            placeholder="選択"
            format="YYYY-MM-DD"
            value-format="x"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="仕入先" prop="supplierId">
          <el-select
            v-model="createForm.supplierId"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="supplier in supplierList"
              :key="supplier.id"
              :label="`${String(supplier.id).padStart(2, '0')}: ${
                supplier.name
              }`"
              :value="supplier.id"
            />
          </el-select>
        </el-form-item>
        <el-button
          type="success"
          class="add-item-button"
          size="large"
          :disabled="isConfirm"
          @click="dialogFormVisible = true"
        >
          単品追加
        </el-button>
        <el-table
          :data="createForm.items"
          stripe
          border
          empty-text="単品情報が存在しません"
        >
          <el-table-column
            prop="partCode"
            label="品番"
            width="120px"
            align="center"
          />
          <el-table-column
            property="itemName"
            label="品名"
            align="center"
            width="280px"
          />
          <el-table-column
            property="lotQty"
            label="入数"
            align="center"
            width="80px"
          />
          <el-table-column
            property="purchaseQty"
            label="仕入数"
            align="center"
            width="80px"
          />
          <el-table-column
            property="cost"
            label="単価"
            align="center"
            width="100px"
          >
            <template #default="scope">
              {{ scope.row.cost.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column
            property="price"
            label="金額"
            align="center"
            width="120px"
          >
            <template #default="scope">
              {{ scope.row.price.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column property="note" label="備考" align="center" />
          <el-table-column label="操作" width="90px" align="center">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                class="button-delete"
                :disabled="isConfirm"
                @click="deleteItem(scope.$index)"
              >
                削除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="itemErrorVisible" class="item-input-details-error-text">
          単品登録は必須です。
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button type="info" size="large" @click="router.push('/purchase')">
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(createFormRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
      <el-dialog v-model="dialogFormVisible" title="発注単品" width="750px">
        <el-form
          ref="dialogFormRef"
          :model="dialogForm"
          :rules="dialogFormRules"
          size="large"
          label-width="120px"
        >
          <el-form-item prop="partCode" label="品番">
            <el-input v-model="dialogForm.partCode" @change="changePartCode" />
          </el-form-item>
          <el-form-item prop="itemName" label="品名" class="item-name">
            <el-input v-model="dialogForm.itemName" :disabled="true" />
          </el-form-item>
          <el-form-item prop="lotQty" label="入数">
            <el-input v-model="dialogForm.lotQty" :disabled="true" />
          </el-form-item>
          <el-form-item prop="cost" label="単価">
            <el-input-number
              v-model="dialogForm.cost"
              placeholder=""
              :min="0"
              :precision="2"
              :controls="false"
            />
          </el-form-item>
          <el-form-item prop="purchaseQty" label="仕入数">
            <el-input-number
              v-model="dialogForm.purchaseQty"
              placeholder=""
              :min="1"
              :precision="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item prop="note" label="備考" class="note">
            <el-input v-model="dialogForm.note" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="addItem(dialogFormRef)"
          >
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';
  import { PurchaseCreateForm, PurchaseCreateItemForm } from '@/types/purchase';
  import { DepotsDataState } from '@/types/master/depot';
  import { SupplierDataState } from '@/types/master/supplier';
  import { ItemsDataState } from '@/types/master/item';
  import { ElLoading } from 'element-plus';
  import _ from 'lodash';

  const emptyDialogFormValue: PurchaseCreateItemForm = {
    partCode: '',
    itemName: '',
    lotQty: null,
    cost: null,
    purchaseQty: null,
    note: '',
    id: null,
    price: null
  };

  const store = useStore();
  const createFormRef = ref<FormInstance>();
  const dialogFormRef = ref<FormInstance>();
  const dialogFormVisible = ref(false);
  const itemErrorVisible = ref(false);
  const createForm = ref<PurchaseCreateForm>({
    depotId: null,
    purchaseDate: Date.now(),
    supplierId: null,
    items: []
  });
  const dialogForm = ref<PurchaseCreateItemForm>(
    _.cloneDeep(emptyDialogFormValue)
  );
  const existsItemValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      itemList.value.find((item) => item.partCode === dialogForm.value.partCode)
    ) {
      callback();
    } else {
      callback(new Error('該当する単品がありません。'));
    }
  };
  const createFormRules = ref<FormRules>({
    depotId: [{ required: true, message: '拠点は必須です。' }],
    purchaseDate: [{ required: true, message: '仕入日は必須です。' }],
    supplierId: [{ required: true, message: '仕入先は必須です。' }]
  });
  const dialogFormRules = ref<FormRules>({
    partCode: [
      { required: true, message: '品番は必須です。' },
      { validator: existsItemValidator, trigger: 'blur' }
    ],
    cost: [{ required: true, message: '単価は必須です。' }],
    purchaseQty: [{ required: true, message: '仕入数は必須です。' }]
  });
  const depotList = ref<DepotsDataState[]>([]);
  const supplierList = ref<SupplierDataState[]>([]);
  const itemList = ref<ItemsDataState[]>([]);
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('suppliersMaster/fetch'),
      store.dispatch('itemsMaster/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;
    supplierList.value = store.state.suppliersMaster.listData;
    itemList.value = store.state.itemsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    if (!createForm.value.items.length) {
      itemErrorVisible.value = true;
      return false;
    }
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = async () => {
    const submitData = {
      depotId: createForm.value.depotId,
      purchaseDate: createForm.value.purchaseDate,
      supplierId: createForm.value.supplierId,
      items: createForm.value.items.map((item) => {
        return {
          id: item.id,
          cost: item.cost,
          lotQty: item.lotQty,
          purchaseQty: item.purchaseQty,
          note: item.note
        };
      })
    };
    await store.dispatch('purchase/submit', submitData);
  };
  const addItem = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      calcPrice();
      createForm.value.items.push(dialogForm.value);
      dialogForm.value = _.cloneDeep(emptyDialogFormValue);
      dialogFormVisible.value = false;
      itemErrorVisible.value = false;
    });
  };
  const deleteItem = (index: number) => {
    createForm.value.items.splice(index, 1);
  };
  const changePartCode = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    dialogForm.value.itemName = '';
    dialogForm.value.lotQty = null;
    dialogForm.value.cost = null;
    dialogForm.value.id = null;
    dialogForm.value.price = null;
    const item = itemList.value.find(
      (item) => item.partCode === dialogForm.value.partCode
    );
    if (item) {
      await store.dispatch('itemMaster/fetch', item.id);
      const itemDetail = store.state.itemMaster.detailData;
      if (itemDetail) {
        dialogForm.value.partCode = itemDetail.partCode;
        dialogForm.value.itemName = itemDetail.name;
        dialogForm.value.lotQty = itemDetail.lotQty;
        if (itemDetail.lotQty === 1) {
          dialogForm.value.cost = itemDetail.baseCost;
        } else if (itemDetail.lotQty > 1) {
          dialogForm.value.cost = itemDetail.caseCost;
        }
        dialogForm.value.id = itemDetail.id;
      }
    }
    loadingInstance.close();
  };
  const calcPrice = () => {
    dialogForm.value.price = null;
    if (dialogForm.value.cost == null || dialogForm.value.purchaseQty == null)
      return;
    dialogForm.value.price =
      dialogForm.value.cost * dialogForm.value.purchaseQty;
  };
  initialize();
</script>
<style lang="scss">
  .puchase-create {
    form {
      width: 1200px;
      display: inline-block;
      margin: auto;
    }

    .el-input-number {
      .el-input {
        float: left;
        width: 300px;
      }

      .el-input__inner {
        text-align: left;
      }
    }

    .el-form-item {
      font-weight: 600 !important;
      width: 300px;
    }

    .el-select {
      width: 300px;
      float: left;
    }

    .el-button {
      font-weight: 600;
    }

    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }

    .el-table {
      margin: auto;
    }

    td {
      div {
        font-weight: 600;
      }
    }

    .el-dialog {
      .item-name {
        width: 650px;
      }

      .note {
        width: 650px;
      }
    }

    .add-item-button {
      margin-bottom: 20px;
    }

    .item-input-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: 0px;
    }

    .button-area {
      margin: 10px 0;
    }
  }
</style>
