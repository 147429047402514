<template>
  <BaseSimpleLayout class="sale-detail">
    <template #title>売上詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/sale')">
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/sale/input/accept')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        size="large"
        type="danger"
        @click="displayDialog = true"
      >
        {{ acceptId ? '取り下げ' : '削除' }}
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="sale-detail-table-area">
        <div class="sale-detail-table-line">
          <BaseDataTable
            :table-data="leftData"
            :label-width="170"
            table-size="mini"
          />
        </div>
        <div class="sale-detail-table-line">
          <BaseDataTable
            :table-data="rightData"
            :label-width="125"
            table-size="mini"
          />
        </div>
      </div>
      <div class="sale-detail-details-area">
        <div class="table-title">製品情報</div>
        <el-table
          :data="productData"
          stripe
          border
          class="accept-create-product-details-table"
          empty-text="製品が存在しません"
          size="small"
        >
          <el-table-column
            prop="productName"
            label="名称"
            align="center"
            class-name="unit-master-product-details-name"
          />
          <el-table-column
            prop="productPrice"
            label="価格"
            width="80px"
            align="center"
            class-name="unit-master-product-details-name"
          />
          <el-table-column
            prop="productType"
            label="種別"
            width="120px"
            align="center"
            class-name="unit-master-product-details-qty"
          >
            <template #default="scope">
              <span>{{ productType[scope.row.productType] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="productQty"
            label="個数"
            width="60px"
            align="center"
            class-name="unit-master-product-details-qty"
          />
        </el-table>
      </div>
      <el-dialog v-model="displayDialog" title="確認" width="400px">
        <span>
          <b>
            売上情報を{{
              acceptId ? '取り下げ' : '削除し'
            }}ますがよろしいですか？
          </b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteData">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { SaleProductsState } from '@/types/sale';
  import { deviceSaleType } from '@/libs/sale';
  import { DetailTableData } from '@/types/common';
  import { productType } from '@/libs/product';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { formatDate } from '@/libs/dateFormat';

  const props = defineProps({
    saleId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const productData = ref<SaleProductsState[]>([]);
  const acceptId = ref<string | null>(null);
  const displayDialog = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch'),
      store.dispatch('supportShopsMaster/fetch'),
      store.dispatch('sale/fetchDetail', props.saleId)
    ]);
    acceptId.value = store.state.sale.detailData.acceptId;
    const detailData = store.state.sale.detailData;
    if (!detailData) return router.push('/sale');
    leftData.value = [
      {
        label: '売上日',
        value: detailData.saleDate
          ? formatDate(Number(detailData.saleDate))
          : '-'
      },
      {
        label: '受注ID',
        value: detailData.acceptId ?? '-'
      },
      {
        label: '受注区分',
        value: detailData.type === 1 ? '通常' : '返品'
      },
      {
        label: '売上種別',
        value: deviceSaleType[detailData.saleDeviceType]
      },
      {
        label: '顧客管理番号',
        value: detailData.customerCode ?? '-'
      },
      {
        label: 'ファイナンス管理番号',
        value: detailData.financeCode ?? '-'
      },
      {
        label: '車台番号',
        value: detailData.vin ?? '-'
      },
      {
        label: '取付店種別',
        value: detailData.mountType ?? '-'
      },
      {
        label: '備考',
        value: detailData.note
      }
    ];
    rightData.value = [
      {
        label: 'ファイナンス',
        value: store.state.financesMaster.replaceData[detailData.financeId]
      },
      {
        label: '販売店グループ',
        value:
          store.state.companyGroupsMaster.replaceData[detailData.companyGroupId]
            .name
      },
      {
        label: '販売店企業',
        value: detailData.companyId
          ? store.state.companiesMaster.replaceData[detailData.companyId].name
          : '-'
      },
      {
        label: '販売店',
        value: detailData.shopId
          ? store.state.shopsMaster.replaceData[detailData.shopId].name
          : '-'
      },
      {
        label: 'サポート店',
        value: detailData.supportShopId
          ? store.state.supportShopsMaster.replaceData[detailData.supportShopId]
          : '-'
      },
      {
        label: 'その他発送先',
        value: detailData.otherShipName ?? '-'
      }
    ];
    productData.value = detailData.details;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const deleteData = () => {
    if (acceptId.value) {
      return store.dispatch('sale/cancel');
    }
    store.dispatch('sale/delete');
  };
  initialize();
</script>
<style lang="scss">
  .sale-detail {
    .sale-detail-table-area {
      display: inline-block;
      margin-top: 20px;
    }
    .sale-detail-table-line {
      width: 500px;
      margin-left: 10px;
      float: left;
    }
    .sale-detail-details-area {
      width: 750px;
      margin: auto;
      .el-table {
        font-size: 14px;
        font-weight: 600;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
    .table-title {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
</style>
