<template>
  <div v-if="loadingFlag" class="inventory-check-list">
    <div class="sheets">
      <span
        >この画面では単品マスタ管理の在庫管理の項目が、「管理する」となっているもののみが表示されます。</span
      >
      <div class="button-area">
        <el-button
          size="large"
          type="info"
          @click="router.push('/inventory/detail')"
          >戻る</el-button
        >
        <el-button size="large" type="primary" @click="handlePrint"
          >印刷</el-button
        >
      </div>
      <!-- テーブルデータをチャンクに分割してそれぞれに対してテーブルを表示 -->
      <div
        class="sheet"
        v-for="(chunk, index) in chunkedTableData"
        :key="index"
      >
        <h2>
          棚卸チェックリスト：{{ today }} ({{ index + 1 }}/{{
            chunkedTableData.length
          }})
        </h2>
        <el-table
          :data="chunk"
          stripe
          border
          :default-sort="{ prop: 'partCode', order: 'ascending' }"
        >
          <el-table-column label="品番" prop="partCode" width="158px" />
          <el-table-column
            label="品名"
            prop="itemName"
            width="630px"
            align="left"
          />
          <el-table-column
            label="在庫数"
            prop="logicalStockQty"
            width="100px"
            align="left"
          />
          <el-table-column
            label="棚卸数"
            prop="inventoryQty"
            width="120px"
            align="left"
            class-name="last"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue';
  import router from '@/router';
  import { useStore } from 'vuex';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<
    {
      itemId: number;
      partCode: string;
      itemName: string;
      logicalStockQty: number;
      inventoryQty: number;
    }[]
  >([]);
  const loadingFlag = ref(false);
  const today = formatDate(
    Number(store.state.inventories.detailListData[0].inventoryDate)
  );
  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('itemsMaster/fetch');
    tableData.value = store.state.inventories.printListData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const handlePrint = () => {
    window.print();
  };

  const chunkedTableData = computed(() => {
    const chunkSize = 30;
    const chunks = [];
    for (let i = 0; i < tableData.value.length; i += chunkSize) {
      chunks.push(tableData.value.slice(i, i + chunkSize));
    }
    return chunks;
  });

  initialize();
</script>
<style lang="scss">
  .inventory-check-list {
    .sheets {
      .button-area {
        text-align: center;
        margin: 20px 10px;

        .el-button {
          font-weight: 600;
        }
      }

      .el-table {
        width: 989px;
        margin: auto;

        thead {
          color: #606266;
        }

        .cell {
          white-space: pre-wrap !important;
          font-weight: 600;
          font-size: 20px;
        }
      }

      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }

      .el-table--border {
        border-right: 1px solid #606266;
        border-bottom: 1px solid #606266;
      }

      .el-table__row {
        font-weight: 600;
      }

      .el-table td,
      .el-table th.is-leaf {
        border-color: #606266;
      }

      .el-table--border,
      .el-table--group {
        border-color: #606266;
      }

      .last {
        border-right: none;
      }
    }

    @media print {
      .sheets > :not(.sheet) {
        display: none;
      }

      .sheet {
        width: 275mm;
        margin: 10mm;
        margin-top: 30mm;
        margin-left: auto;
        margin-right: auto;
        page-break-before: always;
      }

      .el-table {
        width: 989px;
        margin: auto;
        border: 1px solid #606266;

        .el-table__header {
          font-size: 18px;
        }

        .el-table__row {
          font-size: 14px;
        }
      }
    }

    /* for preview */
    @media screen {
      .sheet {
        width: 275mm;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
</style>
