/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  OrderState,
  OrderDetailDataState,
  OrderPutDataState,
  OrderApproveDataState
} from '@/types/order';
import _ from 'lodash';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null
  },
  mutations: {
    setDetail(state: OrderState, payload: OrderDetailDataState) {
      state.detailData = {
        ...payload,
        price: payload.cost * payload.orderQty
      };
    }
  },
  actions: {
    async fetch(context: any, orderId: any) {
      await axios
        .get(getBaseUrl() + '/orders/' + orderId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '発注情報が存在しません。');

            return router.push('/order');
          }
          if (e.response.status < 500) {
            error(e.response.status);

            return router.push('/order');
          }
          router.push('/error/server');
        });
    },
    async put(context: any, payload: OrderPutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const putData = _.omit(payload, ['id']);
      await axios
        .put(getBaseUrl() + '/orders/' + payload.id, putData, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          loadingInstance.close();
          success('success', '更新に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '発注情報が存在しません。');
            return router.push('/order');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async approve(context: any, payload: OrderApproveDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/orders/approve/' + payload.orderId,
          {
            purchaseQty: payload.purchaseQty
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('success', '仕入計上に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '発注情報が存在しません。');
            return router.push('/order');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, orderId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/orders/' + orderId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '発注情報の削除に成功しました。');
          router.push('/order');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '発注情報が存在しません。');
            return router.push('/order');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
