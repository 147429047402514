/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { ShopState, ShopDetailData, ShopPutData } from '@/types/master/shop';

import { ElLoading } from 'element-plus';
import _ from 'lodash';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: ShopState, payload: ShopDetailData) {
      state.detailData = payload;
    },
    setPut(state: ShopState, payload: ShopPutData) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, shopMasterId: any) {
      await axios
        .get(getBaseUrl() + '/master/shops/' + shopMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店情報が存在しません。');
            router.push('/master/shop');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/shop');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, shopMasterId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/master/shops/' + shopMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '販売店情報の削除に成功しました。');
          router.push('/master/shop');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店情報が存在しません。');
            return router.push('/master/shop');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: ShopPutData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const argument = _.cloneDeep(context.state.putData);
      delete argument.id;
      await axios
        .put(
          getBaseUrl() + '/master/shops/' + payload.id,
          {
            companyId: payload.companyId,
            shopCode: payload.shopCode,
            name: payload.name,
            zip: payload.zip,
            prefecture: payload.prefecture,
            city: payload.city,
            address: payload.address,
            addressSub: payload.addressSub,
            tel: payload.tel,
            fax: payload.fax,
            email: payload.email,
            staffName: payload.staffName
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '販売店情報の変更に成功しました。');
          router.push('/master/shop');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店情報が存在しません。');
            return router.push('/master/shop');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
