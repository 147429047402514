<template>
  <BaseSimpleLayout class="order-list">
    <template #title>発注一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button type="success" size="large" @click="createClick">
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form :inline="true" :model="filterForm">
        <el-form-item label="拠点">
          <el-select
            v-model="filterForm.depotId"
            clearable
            placeholder=""
            @change="filterData"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="発注日">
          <span class="index">開始:</span>
          <el-date-picker
            v-model="filterForm.orderStartDate"
            clearable
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
          <span class="index">終了:</span>
          <el-date-picker
            v-model="filterForm.orderEndDate"
            clearable
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
        </el-form-item>
        <el-form-item label="完納">
          <el-select
            v-model="filterForm.isDone"
            clearable
            placeholder=""
            @change="filterData"
          >
            <el-option
              v-for="doneState in doneStateList"
              :key="doneState.value"
              :label="doneState.filterName"
              :value="doneState.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="フィルタ">
          <el-input
            v-model="filterForm.filterText"
            placeholder="検索"
            style="width: 200px"
            @input="filterData"
          />
        </el-form-item>
        <el-form-item label="件数">
          <el-input-number
            class="form-count"
            clearable
            v-model="filterForm.count"
            :min="1"
            :max="100000"
            :controls="false"
            :precision="0"
            @change="filterData"
          />
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        :default-sort="{ prop: 'id', order: 'descending' }"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column property="id" label="ID" align="center" width="60" />
        <el-table-column
          property="depotName"
          label="拠点"
          align="center"
          width="60"
        />
        <el-table-column
          property="estimateNo"
          label="見積もりNo"
          align="center"
          width="130"
          sortable
        />
        <el-table-column
          property="orderDate"
          label="発注日"
          align="center"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.orderDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="supplierName"
          label="仕入先名"
          align="center"
          width="120"
          sortable
        />
        <el-table-column
          property="partCode"
          label="品番"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="itemName"
          label="品名"
          align="center"
          width="250"
        />
        <el-table-column
          property="lotQty"
          label="入数"
          align="center"
          width="70"
        />
        <el-table-column
          property="orderQty"
          label="発注数"
          align="center"
          width="70"
        />
        <el-table-column property="cost" label="単価" align="center" width="80">
          <template #default="scope">
            {{
              scope.row.cost.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="price"
          label="金額"
          align="center"
          width="100"
        >
          <template #default="scope">
            {{ scope.row.price.toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column
          property="deliveryDate"
          label="納品予定日"
          align="center"
          width="100"
        >
          <template #default="scope">
            {{ convertDate(scope.row.deliveryDate) }}
          </template>
        </el-table-column>
        <el-table-column property="note" label="備考" align="center" />
        <el-table-column
          property="purchaseQty"
          label="仕入数"
          align="center"
          width="70"
        />
        <el-table-column
          property="isDone"
          label="完納"
          align="center"
          width="60"
        >
          <template #default="scope">
            {{
              doneStateList.find(
                (doneState) => doneState.value == scope.row.isDone
              )?.fieldName ?? '-'
            }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { OrdersDataState, OrdersFilterForm } from '@/types/order';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';
  import { DepotsDataState } from '@/types/master/depot';

  const doneStateList = [
    { value: false, filterName: '未', fieldName: '' },
    { value: true, filterName: '済', fieldName: '済' }
  ];
  const store = useStore();
  const filterForm = ref<OrdersFilterForm>({
    depotId: null,
    orderStartDate: null,
    orderEndDate: null,
    isDone: null,
    filterText: '',
    count: 100
  });
  const depotList = ref<DepotsDataState[]>([]);
  const tableData = ref<OrdersDataState[]>([]);
  const loadingFlag = ref(false);
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('orders/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;
    tableData.value = store.state.orders.listData;
    filterData();
    tableHeight.value = window.innerHeight - 190;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const filterData = () => {
    let count = 0;
    tableData.value = store.state.orders.listData.filter(
      (data: OrdersDataState) => {
        if (count >= filterForm.value.count) return false;
        if (
          filterForm.value.depotId &&
          filterForm.value.depotId !== data.depotId
        ) {
          return false;
        }
        if (
          filterForm.value.orderStartDate &&
          filterForm.value.orderStartDate > data.orderDate
        ) {
          return false;
        }
        if (
          filterForm.value.orderEndDate &&
          filterForm.value.orderEndDate < data.orderDate
        ) {
          return false;
        }
        if (
          (filterForm.value.isDone === true ||
            filterForm.value.isDone === false) &&
          filterForm.value.isDone !== data.isDone
        ) {
          return false;
        }
        if (
          filterForm.value.filterText &&
          !data.estimateNo.includes(filterForm.value.filterText) &&
          !data.supplierName.includes(filterForm.value.filterText) &&
          !data.partCode.includes(filterForm.value.filterText) &&
          !data.itemName.includes(filterForm.value.filterText) &&
          !data.note.includes(filterForm.value.filterText)
        ) {
          return false;
        }

        ++count;
        return true;
      }
    );
  };
  const rowClick = (row: OrdersDataState) => {
    router.push('/order/' + row.id);
  };
  const createClick = async () => {
    router.push('/order/create');
  };
  initialize();
</script>
<style lang="scss">
  .order-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    td {
      cursor: pointer;
    }
    .el-button {
      font-weight: 600;
    }
    .el-form {
      width: 100%;
    }
    .el-form--inline .el-form-item {
      margin-right: 15px;
    }
    .el-form-item {
      text-align: left;
      margin: 5px 0;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-table {
      margin: 10px auto 0;
      width: 1500px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
  }
</style>
