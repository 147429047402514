<template>
  <BaseSimpleLayout class="receive-list">
    <template #title>入荷一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="shipAdminRole"
        type="success"
        size="large"
        @click="router.push('/receive/create')"
      >
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form :inline="true" :model="filterForm">
        <el-form-item label="入荷日">
          <span>開始:</span>
          <el-date-picker
            v-model="filterForm.receiveStartDate"
            type="date"
            placeholder="日付を選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterReceive"
          />
          <span>終了:</span>
          <el-date-picker
            v-model="filterForm.receiveEndDate"
            type="date"
            placeholder="日付を選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterReceive"
          />
        </el-form-item>
        <el-form-item label="フィルター">
          <el-input
            v-model="filterForm.filter"
            style="width: 180px"
            clearable
            placeholder="フィルター"
            @input="filterReceive"
          />
        </el-form-item>
        <el-form-item label="ステータス">
          <el-checkbox-group
            v-model="filterForm.status"
            @change="filterReceive"
          >
            <el-checkbox label="仮登録" />
            <el-checkbox label="本登録" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="件数">
          <el-input-number
            class="form-count"
            v-model="filterForm.count"
            :min="1"
            :max="100000"
            :controls="false"
            :precision="0"
            @change="filterReceive"
          />
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        border
        :max-height="tableHeight"
        :row-class-name="tableRowClassName"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column
          property="id"
          label="ID"
          align="center"
          width="75"
          sortable
        />
        <el-table-column
          property="receiveDate"
          label="入荷日"
          align="center"
          width="105"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.receiveDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="type"
          label="入荷種別"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ receiveType[scope.row.receiveType] }}
          </template>
        </el-table-column>
        <el-table-column
          property="shipSourceName"
          label="発送元"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ scope.row.shipSourceName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="serialCode"
          label="シリアル番号"
          width="160"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column
          property="deviceStatus"
          label="ステータス"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.deviceStatus
                ? deviceStatus[scope.row.deviceStatus]
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="orderCompanyName"
          label="確定端末"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ scope.row.orderCompanyName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="handlingType"
          label="取扱"
          align="center"
          width="80"
          sortable
        >
          <template #default="scope">
            {{ handlingType[scope.row.handlingType] }}
          </template>
        </el-table-column>
        <el-table-column
          property="customerCode"
          label="顧客管理番号"
          width="225"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ scope.row.customerCode ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="updatedAt"
          label="更新日"
          align="center"
          width="105"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.updatedAt) }}
          </template>
        </el-table-column>
        <el-table-column
          property="status"
          label=""
          align="center"
          width="70"
          sortable
        >
          <template #default="scope">
            {{ receiveStatus[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import {
    ReceiveState,
    ReceiveListDataElement,
    ReceiveFilterForm
  } from '@/types/receive';
  import { formatDate } from '@/libs/dateFormat';
  import { receiveStatus, receiveType } from '@/libs/receive';
  import { handlingType } from '@/libs/device';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { deviceStatus } from '@/libs/device';

  const store = useStore();
  const tableData = ref<ReceiveState[]>([]);
  const filterForm = ref<ReceiveFilterForm>({
    receiveStartDate: null,
    receiveEndDate: null,
    filter: null,
    status: ['仮登録'],
    count: 100
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(550);
  const shipAdminRole = ref<boolean>(rolePermission(['SHIPADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.dispatch('receive/setReceiveId', null);
    store.dispatch('receive/setEditData', null);
    await store.dispatch('receive/fetch');
    if (store.state.receive.filterData) {
      filterForm.value = store.state.receive.filterData;
    }
    filterReceive();
    tableHeight.value = window.innerHeight - 165;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const filterReceive = () => {
    let count = 0;
    store.dispatch('receive/setFilterData', filterForm.value);
    tableData.value = store.state.receive.listData.filter(
      (data: ReceiveListDataElement) => {
        if (count >= filterForm.value.count) return false;
        if (
          filterForm.value.receiveStartDate &&
          filterForm.value.receiveStartDate > data.receiveDate
        )
          return false;
        if (
          filterForm.value.receiveEndDate &&
          filterForm.value.receiveEndDate < data.receiveDate
        )
          return false;
        const status = data.status ? receiveStatus[data.status] : '-';
        if (!filterForm.value.status.includes(status)) return false;
        if (!filterForm.value.filter) {
          count += 1;
          return true;
        }
        const receiveTypeData = data.receiveType
          ? receiveType[data.receiveType]
          : '-';
        const serialCode = data.serialCode ?? '-';
        const shipSourceName = data.shipSourceName ?? '-';
        const orderCompanyName = data.orderCompanyName ?? '-';
        const handlingTypeData = data.handlingType
          ? handlingType[data.handlingType]
          : '-';
        const customerCode = data.customerCode ?? '-';
        const deviceStatusData = data.deviceStatus
          ? deviceStatus[data.deviceStatus]
          : '-';
        if (
          receiveTypeData.includes(filterForm.value.filter) ||
          serialCode.includes(filterForm.value.filter) ||
          shipSourceName.includes(filterForm.value.filter) ||
          orderCompanyName.includes(filterForm.value.filter) ||
          handlingTypeData.includes(filterForm.value.filter) ||
          customerCode.includes(filterForm.value.filter) ||
          deviceStatusData.includes(filterForm.value.filter)
        ) {
          count += 1;
          return true;
        }
        return false;
      }
    );
  };
  const rowClick = (item: ReceiveListDataElement) => {
    router.push('/receive/' + item.id);
  };
  const tableRowClassName = ({
    row,
    rowIndex
  }: {
    row: ReceiveListDataElement;
    rowIndex: number;
  }) => {
    console.error(row.id);
    if ((row.deviceStatus < 4 || row.customerCode) && row.status === 1) {
      return 'danger-row';
    }
    if (rowIndex % 2 !== 0) {
      return 'light-row';
    }
    return '';
  };

  initialize();
</script>
<style lang="scss">
  .receive-list {
    .main-container {
      width: 99%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    td {
      cursor: pointer;
    }
    .el-button {
      font-weight: 600;
    }
    .el-form {
      width: 100%;
    }
    .el-form-item {
      text-align: left;
      margin: 5px 0;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .el-checkbox {
      font-weight: 600;
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-table {
      margin: 10px auto;
      thead th {
        background: #f5f7fa;
      }
      .danger-row {
        --el-table-tr-bg-color: var(--el-color-danger-light-9);
      }
      .light-row {
        --el-table-tr-bg-color: var(--el-fill-color-lighter);
      }
    }
  }
</style>
