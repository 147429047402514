<template>
  <el-table
    :data="tableData"
    :show-header="false"
    border
    :size="tableSize"
    class="base-data-table"
  >
    <el-table-column
      prop="label"
      align="left"
      class-name="label-column"
      :width="labelWidth"
    />
    <el-table-column prop="value" />
  </el-table>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { DetailTableData } from '@/types/common';

  export default defineComponent({
    name: 'BaseDataTable',
    props: {
      tableData: {
        type: Array as PropType<DetailTableData[]>,
        default: () => []
      },
      labelWidth: {
        type: Number,
        default: 150
      },
      tableSize: {
        type: String,
        default: 'default'
      }
    }
  });
</script>
<style lang="scss">
  .base-data-table.el-table {
    --el-table-row-hover-background-color: #ffffff;
    --el-table-row-hover-bg-color: #ffffff;
    .cell {
      white-space: pre-wrap !important;
      font-weight: 600;
      font-size: 14px;
    }
    td.label-column {
      background-color: #f5f7fa !important;
    }
  }
</style>
