/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  StockState,
  StocksDataState,
  OrderPointData,
  StockFilterData
} from '@/types/stock';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    filterData: null
  },
  mutations: {
    setList(state: StockState, payload: StocksDataState[]) {
      state.listData = payload;
    },
    setFilter(state: StockState, payload: StockFilterData) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any, status?: string[]) {
      const query = status
        ? `?${status.map((s) => `status[]=${s}`).join('&')}`
        : '';
      await axios
        .get(`${getBaseUrl()}/stocks${query}`, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: StocksDataState, b: StocksDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async putOrderPoint(context: any, payload: OrderPointData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/stocks/orderpoint',
          {
            depotId: payload.depotId,
            itemId: payload.itemId,
            orderPoint: payload.orderPoint
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '発注点を更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の在庫情報が存在しません。');
            router.push('/stock');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/stock');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: StockFilterData) {
      context.commit('setFilter', payload);
    }
  }
};
