<template>
  <BaseSimpleLayout class="bulk-detail">
    <template #title>バルク受注詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable :table-data="detailTableData" :label-width="140" />
      <el-button size="large" type="info" @click="router.push('/bulk')">
        戻る
      </el-button>
      <el-button
        v-if="status === 2 && orderRole"
        type="success"
        size="large"
        @click="router.push('/bulk/input')"
      >
        編集
      </el-button>
      <el-button
        v-if="status === 2 && orderRole"
        type="primary"
        size="large"
        @click="displayDialog = true"
      >
        出荷依頼
      </el-button>
      <el-button
        v-if="status === 2 && orderRole"
        type="danger"
        size="large"
        @click="displayDeleteDialog = true"
      >
        削除
      </el-button>
      <el-dialog v-model="displayDialog" title="出荷依頼" width="500px">
        <span>
          <b>この受注情報で出荷依頼しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="shipRequest">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayDeleteDialog"
        title="バルク受注情報削除"
        width="400px"
      >
        <span>
          <b>この受注情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeleteDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteBulkAccept">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { BulkDetailData } from '@/types/bulk';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    bulkId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  let detailData: BulkDetailData | null = null;
  const status = ref(0);
  const detailTableData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const displayDialog = ref(false);
  const displayDeleteDialog = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('bulk/fetchDetail', props.bulkId);
    detailData = store.state.bulk.detailData;
    if (!detailData) {
      return router.push('/bulk');
    }
    detailTableData.value = [
      {
        label: 'ステータス',
        value: detailData.status === 2 ? '受注確定' : '出荷依頼'
      },
      {
        label: '受注日',
        value: formatDate(Number(detailData.acceptDate))
      },
      {
        label: 'ファイナンス',
        value: detailData.financeName
      },
      {
        label: 'MCCSver',
        value: detailData.deviceType
      },
      {
        label: '製品名',
        value: detailData.productName
      },
      {
        label: '製品単価',
        value: detailData.productPrice
      },
      {
        label: '個数',
        value: detailData.productQty
      },
      {
        label: '東京専用備考',
        value: detailData.note
      },
      {
        label: '発送時連携用備考',
        value: detailData.shipNote
      }
    ];
    status.value = detailData.status;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const shipRequest = async () => {
    await store.dispatch('bulk/request', props.bulkId);
  };

  const deleteBulkAccept = async () => {
    await store.dispatch('bulk/delete', props.bulkId);
  };
  initialize();
</script>
<style lang="scss">
  .bulk-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
    }
  }
</style>
