<template>
  <div>
    <BaseSimpleLayout class="master-carinfo-detail">
      <template #title>車両情報詳細</template>
      <template v-if="loadingFlag" #main>
        <BaseDataTable
          :table-data="detailTableData"
          table-size="medium"
          :label-width="130"
        />
        <el-button
          class="unit-master-finance-detail-button-list"
          type="info"
          size="large"
          @click="router.push('/master/carinfo')"
        >
          戻る
        </el-button>
      </template>
    </BaseSimpleLayout>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { DetailTableData } from '@/types/common';
  import { ElLoading } from 'element-plus';

  const props = defineProps({
    carInfoId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const loadingFlag = ref(false);
  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('carInfoMaster/fetchDetail', props.carInfoId),
      store.dispatch('carMakersMaster/fetch')
    ]);
    const detailData = store.state.carInfoMaster.detailData;
    const carMakerNameData = store.state.carMakersMaster.replaceData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id.toString()
      },
      {
        label: 'メーカーコード',
        value: detailData.makerCode
      },
      {
        label: 'メーカー',
        value: carMakerNameData[detailData.carMakerId]
      },
      {
        label: '型式指定番号',
        value: detailData.modelCode
      },
      {
        label: '車種',
        value: detailData.modelCode
      },
      {
        label: '仕様',
        value: detailData.oem ?? '-'
      },
      {
        label: '型式',
        value: detailData.model ?? '-'
      },
      {
        label: '年式',
        value: detailData.modelYear ?? '-'
      },
      {
        label: 'グレード',
        value: detailData.grade ?? '-'
      },
      {
        label: 'モデル区分',
        value: detailData.modelType ?? '-'
      },
      {
        label: '終了',
        value: detailData.endSales ?? '-'
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .master-carinfo-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
