/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  OrdersState,
  OrdersDataState,
  OrdersSubmitDataState
} from '@/types/order';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: []
  },
  mutations: {
    setOrders(state: OrdersState, payload: OrdersDataState[]) {
      state.listData = payload.map((item) => {
        return {
          ...item,
          price: item.cost * item.orderQty
        };
      });
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/orders', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setOrders', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);

            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: OrdersSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/orders', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '発注情報の登録に成功しました。');
          router.push('/order');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
