/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';
import { UnrecordDetailData, UnrecordSubmitData } from '@/types/unrecord';
import { acceptType, shipSelectType } from '@/libs/accept';
import { managementStatus } from '@/libs/management';
import { formatDate } from '@/libs/dateFormat';

export const saleType: NumberData = {
  1: '通常',
  2: 'バルク',
  3: '先行売上',
  4: '確定端末'
};

export const deviceSaleType: NumberData = {
  1: '通常',
  2: 'バルク',
  3: '先行売上',
  4: '先行受注端末（受領済）',
  5: '流用端末',
  6: 'リビルド端末'
};

export function createUnrecordLeftTableData(data: UnrecordDetailData) {
  return [
    {
      label: '受注区分',
      value: acceptType[data.type]
    },
    {
      label: '売上種別',
      value: data.saleDeviceType ? deviceSaleType[data.saleDeviceType] : '-'
    },
    {
      label: '顧客管理番号',
      value: data.customerCode ? data.customerCode : '-'
    },
    {
      label: 'ファイナンス管理番号',
      value: data.financeCode ? data.financeCode : '-'
    },
    {
      label: '受注ID',
      value: data.acceptId ? data.acceptId : '-'
    },
    {
      label: '受注日',
      value: formatDate(Number(data.acceptDate))
    },
    {
      label: '先受注企業',
      value: data.orderCompanyName ?? '-'
    },
    {
      label: 'ステータス',
      value: data.status ? managementStatus[data.status] : '-'
    },
    {
      label: '備考',
      value: data.note ?? ''
    }
  ];
}

export function createUnrecordRightTableData(data: UnrecordDetailData) {
  return [
    {
      label: 'ファイナンス',
      value: data.financeName ? data.financeName : '-'
    },
    {
      label: '販売店グループ',
      value: data.companyGroupName ? data.companyGroupName : '-'
    },
    {
      label: '販売店企業',
      value: data.companyName ? data.companyName : '-'
    },
    {
      label: '販売店',
      value: data.shopName ? data.shopName : '-'
    },
    {
      label: 'サポート店',
      value: data.supportShopName ? data.supportShopName : '-'
    },
    {
      label: 'その他発送先',
      value: data.otherShipName ? data.otherShipName : '-'
    },
    {
      label: '出荷先種別',
      value: data.shipSelectType ? shipSelectType[data.shipSelectType] : '-'
    },
    {
      label: '取付店種別',
      value: data.mountType ? data.mountType : '-'
    },
    {
      label: '出荷日',
      value: data.shipDate ? formatDate(Number(data.shipDate)) : '-'
    },
    {
      label: '取付日',
      value: data.mountDate ? formatDate(Number(data.mountDate)) : '-'
    }
  ];
}

export function createConfirmTableData(data: UnrecordSubmitData) {
  return [
    {
      label: '売上日',
      value: formatDate(Number(data.saleDate))
    },
    {
      label: '受注区分',
      value: acceptType[data.type]
    },
    {
      label: '売上種別',
      value: data.saleDeviceType ? deviceSaleType[data.saleDeviceType] : '-'
    },
    {
      label: '顧客管理番号',
      value: data.customerCode ? data.customerCode : '-'
    },
    {
      label: '受注ID',
      value: data.acceptId ? data.acceptId : '-'
    },
    {
      label: '受注日',
      value: formatDate(Number(data.acceptDate))
    },
    {
      label: '備考',
      value: data.note
    }
  ];
}
