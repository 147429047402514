/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  CompanyGroupState,
  CompanyGroupDetailData,
  CompanyGroupPutData
} from '@/types/master/companyGroup';
import _ from 'lodash';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: CompanyGroupState, payload: CompanyGroupDetailData) {
      state.detailData = payload;
    },
    setPut(state: CompanyGroupState, payload: CompanyGroupPutData) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, companyGroupId: any) {
      await axios
        .get(getBaseUrl() + '/master/company-groups/' + companyGroupId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          delete setData.affiliatedCompanies;
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店グループ情報が存在しません。');
            router.push('/master/companygroup');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/companygroup');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, companyGroupId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/master/company-groups/' + companyGroupId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '販売店グループ情報の削除に成功しました。');
          router.push('/master/companygroup');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店グループ情報が存在しません。');
            return router.push('/master/companygroup');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: CompanyGroupPutData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/company-groups/' + payload.id,
          {
            name: payload.name,
            fcCode: payload.fcCode,
            deviceTradePrice: payload.deviceTradePrice,
            deviceAttachedPrice: payload.deviceAttachedPrice,
            deviceDettachedPrice: payload.deviceDettachedPrice,
            incurredPriceType: payload.incurredPriceType,
            monthlyPrice: payload.monthlyPrice,
            unusedPrice: payload.unusedPrice,
            harnessSalesPrice: payload.harnessSalesPrice,
            customerNextNumber: payload.customerNextNumber,
            companyGroupType: payload.companyGroupType
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '販売店グループ情報の変更に成功しました。');
          router.push('/master/companygroup');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店グループ情報が存在しません。');
            return router.push('/master/companygroup');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
