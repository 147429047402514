<template>
  <BaseSimpleLayout class="company-master-detail">
    <template #title>販売店企業詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <BaseCompanyList :table-data="companyGroupList" />
      <el-button
        class="unit-master-support-shop-detail-button-list"
        type="info"
        size="large"
        @click="router.push('/master/company')"
      >
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        class="unit-master-support-shop-detail-button-edit"
        size="large"
        @click="router.push('/master/company/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        type="danger"
        class="unit-master-support-shop-detail-button-delete"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="30%">
        <span><b>この販売店企業情報を削除しますか。</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import BaseCompanyList from '@/components/BaseCompanyList.vue';
  import { DetailTableData, CompanyListData } from '@/types/common';
  import { CompanyGroupList } from '@/types/master/company';
  import { groupTypeData } from '@/libs/companyGroup';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const props = defineProps({
    companyId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const companyGroupList = ref<CompanyListData[]>([]);
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const deleteItem = () => {
    store.dispatch('companyMaster/delete', props.companyId);
  };
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('companyMaster/fetch', props.companyId),
      store.dispatch('companyGroupsMaster/fetch')
    ]);
    const detailData = store.state.companyMaster.detailData;
    companyGroupList.value = detailData.companyGroupList.map(
      (data: CompanyGroupList) => {
        return {
          id: data.id,
          name: data.name,
          type: groupTypeData[data.companyGroupType]
        };
      }
    );
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: '種別',
        value: detailData.isAdvanceOrder ? '先行受注' : '通常'
      },
      {
        label: '販売店企業名',
        value: detailData.name
      },
      {
        label: '郵便番号',
        value: detailData.zip
      },
      {
        label: '都道府県',
        value: detailData.prefecture
      },
      {
        label: '市区町村',
        value: detailData.city
      },
      {
        label: '住所',
        value: detailData.address
      },
      {
        label: '住所サブ',
        value: detailData.addressSub
      },
      {
        label: '電話番号',
        value: detailData.tel
      },
      {
        label: 'FAX',
        value: detailData.fax
      },
      {
        label: 'メールアドレス',
        value: detailData.email
      },
      {
        label: '担当者',
        value: detailData.staffName
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .company-master-detail {
    .el-button {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .el-table {
      width: 500px;
      margin: auto auto 30px;
    }
  }
</style>
