<template>
  <BaseSimpleLayout class="move-create">
    <template #title>移動登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="createFormRef"
        :model="createForm"
        :rules="createFormRules"
        size="large"
        label-width="120px"
      >
        <el-form-item label="移動日" prop="moveDate">
          <el-date-picker
            v-model="createForm.moveDate"
            type="date"
            placeholder="選択"
            format="YYYY-MM-DD"
            value-format="x"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="出庫拠点" prop="outDepotId">
          <el-select
            v-model="createForm.outDepotId"
            clearable
            placeholder="(選択なし)"
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot?.id"
              :label="depot?.name"
              :value="depot?.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="入庫拠点" prop="inDepotId">
          <el-select
            v-model="createForm.inDepotId"
            clearable
            placeholder="(選択なし)"
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot?.id"
              :label="depot?.name"
              :value="depot?.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input v-model="createForm.note" :disabled="isConfirm" />
        </el-form-item>
        <el-button
          type="success"
          class="add-item-button"
          size="large"
          :disabled="isConfirm"
          @click="handleAddItem"
        >
          単品追加
        </el-button>
        <el-table
          :data="createForm.items"
          stripe
          border
          empty-text="単品情報が存在しません"
        >
          <el-table-column
            prop="partCode"
            label="品番"
            width="120px"
            align="center"
          />
          <el-table-column
            property="itemName"
            label="品名"
            align="center"
            width="280px"
          />
          <el-table-column
            property="moveQty"
            label="移動数"
            align="center"
            width="80px"
          />
          <el-table-column property="note" label="備考" align="center" />
          <el-table-column label="操作" width="90px" align="center">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                class="button-delete"
                :disabled="isConfirm"
                @click="deleteItem(scope.$index)"
              >
                削除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="itemErrorVisible" class="item-input-details-error-text">
          単品登録は必須です。
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button type="info" size="large" @click="router.push('/move')">
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(createFormRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
      <el-dialog v-model="dialogFormVisible" title="移動単品" width="750px">
        <el-form
          ref="dialogFormRef"
          :model="dialogForm"
          :rules="dialogFormRules"
          size="large"
          label-width="120px"
        >
          <el-form-item prop="partCode" label="品番">
            <el-input v-model="dialogForm.partCode" @change="changePartCode" />
          </el-form-item>
          <el-form-item prop="itemName" label="品名" class="item-name">
            <el-input v-model="dialogForm.itemName" :disabled="true" />
          </el-form-item>
          <el-form-item prop="moveQty" label="移動数">
            <el-input-number
              v-model="dialogForm.moveQty"
              placeholder="1"
              :min="1"
              :precision="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item prop="note" label="備考" class="note">
            <el-input v-model="dialogForm.note" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="addItem(dialogFormRef)"
          >
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';
  import { MoveCreateForm, MoveCreateItemForm } from '@/types/move';
  import { DepotsDataState } from '@/types/master/depot';
  import { ItemsDataState } from '@/types/master/item';
  import { ElLoading } from 'element-plus';
  import _ from 'lodash';

  const emptyDialogFormValue: MoveCreateItemForm = {
    partCode: '',
    itemName: '',
    moveQty: null,
    note: '',
    id: null
  };

  const store = useStore();
  const createFormRef = ref<FormInstance>();
  const dialogFormRef = ref<FormInstance>();
  const dialogFormVisible = ref(false);
  const itemErrorVisible = ref(false);

  const createForm = ref<MoveCreateForm>({
    moveDate: Date.now(),
    outDepotId: null,
    inDepotId: null,
    note: '',
    items: []
  });

  const dialogForm = ref<MoveCreateItemForm>(_.cloneDeep(emptyDialogFormValue));
  const existsItemValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      itemList.value.find((item) => item.partCode === dialogForm.value.partCode)
    ) {
      callback();
    } else {
      callback(new Error('該当する単品がありません。'));
    }
  };
  const setDepodValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      createForm.value.inDepotId != null ||
      createForm.value.outDepotId != null
    ) {
      if (createForm.value.inDepotId !== createForm.value.outDepotId) {
        callback();
      } else {
        callback(new Error('出庫拠点、入庫拠点が同じです。'));
      }
    } else {
      callback(new Error('出庫拠点、入庫拠点が設定されていません。'));
    }
  };
  const createFormRules = ref<FormRules>({
    moveDate: [{ required: true, message: '移動日は必須です。' }],
    outDepotId: [{ validator: setDepodValidator, trigger: 'blur' }],
    inDepotId: [{ validator: setDepodValidator, trigger: 'blur' }]
  });
  const dialogFormRules = ref<FormRules>({
    partCode: [
      { required: true, message: '品番は必須です。' },
      { validator: existsItemValidator, trigger: 'blur' }
    ],
    moveQty: [{ required: true, message: '移動数は必須です。' }]
  });
  const depotList = ref<(DepotsDataState | null)[]>([]);
  const itemList = ref<ItemsDataState[]>([]);
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('itemsMaster/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;
    depotList.value.unshift(null);
    itemList.value = store.state.itemsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    if (!createForm.value.items.length) {
      itemErrorVisible.value = true;
      return false;
    }
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = async () => {
    const submitData = {
      moveDate: createForm.value.moveDate,
      outDepotId: createForm.value.outDepotId,
      inDepotId: createForm.value.inDepotId,
      items: createForm.value.items.map((item) => {
        return {
          id: item.id,
          moveQty: item.moveQty,
          note: item.note
        };
      })
    };
    await store.dispatch('move/submit', submitData);
  };
  const addItem = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      createForm.value.items.push(dialogForm.value);
      dialogForm.value = _.cloneDeep(emptyDialogFormValue);
      dialogFormVisible.value = false;
      itemErrorVisible.value = false;
    });
  };
  const deleteItem = (index: number) => {
    createForm.value.items.splice(index, 1);
  };
  const handleAddItem = () => {
    dialogForm.value.note = createForm.value.note;
    dialogFormVisible.value = true;
  };
  const changePartCode = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    dialogForm.value.id = null;
    dialogForm.value.itemName = '';
    const item = itemList.value.find(
      (item) => item.partCode === dialogForm.value.partCode
    );
    if (item) {
      await store.dispatch('itemMaster/fetch', item.id);
      const itemDetail = store.state.itemMaster.detailData;
      if (itemDetail) {
        dialogForm.value.id = itemDetail.id;
        dialogForm.value.itemName = itemDetail.name;
      }
    }
    loadingInstance.close();
  };
  initialize();
</script>
<style lang="scss">
  .move-create {
    form {
      width: 900px;
      display: inline-block;
      margin: auto;
    }
    .el-input-number {
      .el-input {
        float: left;
        width: 380px;
      }
      .el-input__inner {
        text-align: left;
      }
    }
    .el-form-item {
      font-weight: 600 !important;
      width: 380px;
    }
    .el-select {
      width: 380px;
      float: left;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      margin: auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
    .el-dialog {
      .item-name {
        width: 650px;
      }
      .note {
        width: 650px;
      }
    }
    .add-item-button {
      margin-bottom: 20px;
    }
    .item-input-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: 0px;
    }
    .button-area {
      margin: 10px 0;
    }
  }
</style>
