<template>
  <div class="base-item-list">
    <div class="table-title">組付パッケージ</div>
    <el-table :data="tableData" stripe border>
      <el-table-column prop="id" label="ID" width="80px" align="center" />
      <el-table-column prop="name" label="名称" header-align="center" />
      <el-table-column prop="qty" label="個数" width="80px" align="center" />
    </el-table>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { ItemListData } from '@/types/common';

  export default defineComponent({
    name: 'BaseItemList',
    props: {
      tableData: {
        type: Array as PropType<ItemListData[]>,
        default: () => []
      }
    }
  });
</script>
<style lang="scss">
  .base-item-list {
    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    .el-table {
      width: 800px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        white-space: pre-wrap !important;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
</style>
