<template>
  <div>
    <BaseSimpleLayout class="management-edit-confirm">
      <template #title>顧客管理情報編集確認</template>
      <template v-if="loadingFlag" #main>
        <div class="management-edit-confirm-table-area">
          <div class="management-edit-confirm-table-line">
            <BaseDataTable :table-data="leftData" :label-width="140" />
          </div>
          <div class="management-edit-confirm-table-line">
            <BaseDataTable :table-data="rightData" :label-width="140" />
          </div>
        </div>
        <div class="management-edit-confirm-button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/management/edit')"
          >
            戻る
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
      </template>
    </BaseSimpleLayout>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { createLeftEditData, createRightEditData } from '@/libs/management';
  import { DetailTableData } from '@/types/common';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const putData = store.state.management.putData;
    if (!putData) return router.push('/management');
    const detailData = store.state.management.detailData;
    const finaceChanger = store.state.financesMaster.replaceData;
    leftData.value = createLeftEditData(detailData, putData);
    leftData.value[2].value = finaceChanger[putData.financeId];
    rightData.value = createRightEditData(putData);
    rightData.value[2].value = detailData.vin ?? '-';
    if (putData.reasonCancelId) {
      rightData.value[1].value =
        store.state.reasonCancel.replaceData[putData.reasonCancelId];
    }
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const submit = () => {
    store.dispatch('management/put');
  };
  initialize();
</script>
<style lang="scss">
  .management-edit-confirm {
    .management-edit-confirm-table-area {
      display: inline-block;
    }
    .management-edit-confirm-table-line {
      width: 450px;
      margin-left: 10px;
      float: left;
      .BaseDataTable {
        width: 450px;
      }
    }
    .management-edit-confirm-button-area {
      margin: 10px 0px 10px;
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
