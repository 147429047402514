<template>
  <BaseSimpleLayout class="stock-list">
    <template #title>在庫一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="success" @click="csvDownload">
        CSVダウンロード
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form :inline="true" :model="filterForm">
        <div class="filter-area">
          <span class="label">拠点</span>
          <el-select
            v-model="filterForm.depotName"
            filterable
            clearable
            placeholder="選択"
            @change="filterStock"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.name"
            />
          </el-select>
          <span class="label">フィルター</span>
          <el-input
            v-model="filterForm.filterText"
            placeholder="検索"
            @change="filterStock"
          />
        </div>
      </el-form>

      <div class="disp-count">
        最終棚卸日：<span style="font-weight: bold">{{
          LastInventoryDate
        }}</span>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="データが存在しません。"
      >
        <el-table-column
          prop="depotName"
          label="拠点"
          width="100"
          align="center"
          sortable
        />
        <el-table-column
          property="partCode"
          label="品番"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="itemName"
          label="品名"
          align="center"
          sortable
        />
        <el-table-column
          property="stockQty"
          label="棚卸在庫数"
          align="center"
          width="120"
          sortable
        />
        <el-table-column
          property="purchaseQty"
          label="仕入数"
          align="center"
          width="120"
          sortable
        />
        <el-table-column
          property="shipQty"
          label="出荷数"
          align="center"
          width="120"
          sortable
        />
        <el-table-column
          property="moveQty"
          label="移動数"
          align="center"
          width="120"
          sortable
        />
        <el-table-column
          property="logicalStockQty"
          label="現状在庫数"
          align="center"
          width="120"
          sortable
        >
          <template #default="{ row }">
            <span
              :style="{
                color: row.logicalStockQty < row.orderPoint ? 'red' : '#606266'
              }"
            >
              {{ row.logicalStockQty }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          property="orderPoint"
          label="発注点"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="orderPointSetting"
          label="設定"
          align="center"
          width="100"
          sortable
        >
          <template #default="scope">
            <el-button
              size="small"
              type="primary"
              @click="
                clickRegisterButton(
                  scope.row.depotId,
                  scope.row.depotName,
                  scope.row.itemId,
                  scope.row.itemName,
                  scope.row.partCode,
                  scope.row.orderPoint
                )
              "
            >
              発注点
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogVisible" title="発注点設定" width="550px">
        <el-form
          ref="formRef"
          :model="rowData"
          :rules="orderPointRules"
          size="large"
          label-width="170px"
        >
          <el-form-item label="拠点">
            <el-input v-model="rowData.depotName" disabled />
          </el-form-item>
          <el-form-item label="品番">
            <el-input v-model="rowData.partCode" disabled />
          </el-form-item>
          <el-form-item label="品名">
            <el-input v-model="rowData.itemName" disabled />
          </el-form-item>
          <el-form-item label="発注点" prop="orderPoint">
            <el-input-number
              v-model="rowData.orderPoint"
              placeholder=""
              :min="0"
              :precision="0"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="updateOrderPoint(formRef)"
          >
            更新
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ElLoading, FormInstance, FormRules } from 'element-plus';
  import {
    StocksDataState,
    StockFilterData,
    StockRowData
  } from '@/types/stock';
  import { csvFormat } from '@/libs/dateFormat';
  import { DepotsDataState } from '@/types/master/depot';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const tableData = ref<StocksDataState[]>([]);
  const tableHeight = ref(600);
  const filterForm = ref<StockFilterData>({
    depotName: null,
    filterText: null
  });
  const rowData = ref<StockRowData>({
    depotId: 0,
    depotName: '',
    itemId: 0,
    itemName: '',
    partCode: '',
    orderPoint: 0
  });
  const orderPointRules = ref<FormRules>({
    orderPoint: [
      {
        required: true,
        message: '発注点は必須です。'
      }
    ]
  });
  const loadingFlag = ref(false);
  const depotList = ref<DepotsDataState[]>([]);
  const dialogVisible = ref(false);
  let LastInventoryDate: string;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    if (store.state.stock.filterData) {
      filterForm.value = store.state.stock.filterData;
    }
    await Promise.all([store.dispatch('depotsMaster/fetch')]);
    await Promise.all([store.dispatch('stock/fetch')]);
    filterStock();
    tableHeight.value = window.innerHeight - 200;
    depotList.value = store.state.depotsMaster.listData;

    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterStock = () => {
    store.dispatch('stock/setFilterData', filterForm.value);
    tableData.value = store.state.stock.listData.filter(
      (data: StocksDataState) => {
        if (filterForm.value.depotName) {
          if (data.depotName !== filterForm.value.depotName) return false;
        }
        if (!filterTextData(data, 'filterText')) return false;
        return true;
      }
    );
  };

  const filterTextData = (
    data: StocksDataState,
    text: 'filterText'
  ): boolean => {
    if (!filterForm.value[text]) return true;
    const value = filterForm.value[text] ?? '';
    const itemName = data.itemName ?? '-';
    const partCode = data.partCode ?? '-';
    return itemName.includes(value) || partCode.includes(value);
  };

  const csvDownload = async () => {
    const filename = '在庫一覧' + csvFormat(Date.now());
    const header = ['拠点,品番,品名,数量,原単価,原価金額'];
    const baseData = store.state.stock.listData.map(
      (data: StocksDataState): string => {
        return Object.entries({
          depotName: data.depotName ? data.depotName : '-',
          partCode: data.partCode ? data.partCode : '-',
          itemName: data.itemName ? data.itemName : '-',
          logicalStockQty: data.logicalStockQty,
          baseCost: data.baseCost,
          logicalStockAmount: data.logicalStockQty * data.baseCost
        })
          .map(([, value]) => value)
          .join(',');
      }
    );
    const data = header.concat(baseData).join('\r\n');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(url);
  };

  const clickRegisterButton = (
    depotId: number,
    depotName: string,
    itemId: number,
    itemName: string,
    partCode: string,
    orderPoint: number
  ) => {
    rowData.value.depotId = depotId;
    rowData.value.depotName = depotName;
    rowData.value.itemId = itemId;
    rowData.value.itemName = itemName;
    rowData.value.partCode = partCode;
    rowData.value.orderPoint = orderPoint;
    dialogVisible.value = true;
  };

  const updateOrderPoint = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      dialogVisible.value = false;
      await store.dispatch('stock/putOrderPoint', {
        depotId: rowData.value.depotId,
        itemId: rowData.value.itemId,
        orderPoint: rowData.value.orderPoint
      });
      initialize();
    });
  };

  initialize();
</script>
<style lang="scss">
  .stock-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    .filter-area {
      margin: 10px 0px;

      .label {
        margin: 0 10px 0 20px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 250px;
      }
    }
    .el-form {
      width: 100%;
    }
    .el-form-item {
      text-align: left;
      margin-bottom: 10px;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .id-filter-area {
      label {
        width: 60px !important;
      }
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-checkbox {
      font-weight: 600;
    }
    .el-table {
      margin: 10px auto 0;
      width: 1200px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    @media print {
      .main-container {
        width: 100%;
      }
      .base-header {
        display: none;
      }
      .el-form {
        display: none;
      }
      .main {
        padding-top: 0;
      }
    }
    .disp-count {
      text-align: left;
    }
  }
</style>
