<template>
  <BaseSimpleLayout class="purchase-detail">
    <template #title>仕入詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/purchase')">
        一覧
      </el-button>
      <el-button
        v-if="displayButton"
        size="large"
        type="primary"
        @click="moveEdit()"
      >
        編集
      </el-button>
      <el-button
        v-if="displayButton"
        size="large"
        type="danger"
        @click="displayDeletePurchaseDialog = true"
      >
        削除
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <el-dialog
        v-model="displayDeletePurchaseDialog"
        class="delete-purchase-dialog"
        title="発注情報削除"
        width="500px"
      >
        <span>
          <b>この仕入情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeletePurchaseDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deletePurchase">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { formatDate } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    purchaseId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const canDeliveryCount = ref<number>(0);
  const displayButton = ref(false);
  const loadingFlag = ref(false);
  const displayDeletePurchaseDialog = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    displayDeletePurchaseDialog.value = false;

    await store.dispatch('purchase/fetchDetail', props.purchaseId);
    const detailData = store.state.purchase.detailData;
    detailTableData.value = [
      {
        label: '拠点',
        value: detailData.depotName
      },
      {
        label: '発注ID',
        value: detailData.orderId
      },
      {
        label: '仕入日',
        value: detailData.purchaseDate
          ? formatDate(Number(detailData.purchaseDate))
          : '-'
      },
      {
        label: '仕入先名',
        value: detailData.supplierName
      },
      {
        label: '品番',
        value: detailData.partCode
      },
      {
        label: '品名',
        value: detailData.itemName
      },
      {
        label: '入数',
        value: detailData.lotQty
      },
      {
        label: '仕入数',
        value: detailData.purchaseQty
      },
      {
        label: '単価',
        value: detailData.cost.toLocaleString()
      },
      {
        label: '金額',
        value: (detailData.purchaseQty * detailData.cost).toLocaleString()
      },
      {
        label: '備考',
        value: detailData.note
      },
      {
        label: '確定',
        value: detailData.isConfirm ? '確定済' : '-'
      }
    ];

    if (detailData.isConfirm) {
      displayButton.value = false;
    } else {
      displayButton.value = true;
    }
    canDeliveryCount.value = detailData.orderQty - detailData.purchaseQty;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const deletePurchase = async () => {
    store.dispatch('purchase/delete', props.purchaseId);
  };

  const moveEdit = async () => {
    store.commit('purchase/setPurchaseId', props.purchaseId);
    router.push(`/purchase/edit/`);
  };
  initialize();
</script>
<style lang="scss">
  .purchase-detail {
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }

    .el-table {
      width: 500px;
      margin: 40px auto;
    }

    td {
      div {
        font-weight: 600;
      }
    }

    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }

    .purchase-record-dialog {
      .el-form-item__content {
        text-align: left;
      }

      .el-input {
        width: 150px;
      }

      label {
        font-weight: 600;
      }
    }
  }
</style>
