<template>
  <BaseSimpleLayout class="master-item-list">
    <template #title>単品マスタ一覧</template>
    <template #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="createRole"
        class="unit-master-item-list-button-create"
        type="success"
        size="large"
        @click="router.push('/master/item/create')"
      >
        単品登録
      </el-button>
      <el-button size="large" type="success" @click="csvDownload">
        CSVダウンロード
      </el-button>
    </template>
    <template #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" width="550" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="model" label="型式" width="150" sortable />
        <el-table-column prop="partCode" label="品番" width="150" sortable />
        <el-table-column prop="category" label="カテゴリ" sortable>
          <template #default="scope">
            {{
              scope.row.categoryId
                ? categoryNameChanger[scope.row.categoryId]
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="supplier" label="仕入先" sortable>
          <template #default="scope">
            {{
              scope.row.categoryId
                ? supplierNameChanger[scope.row.supplierId]
                : '-'
            }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ItemsDataState, ItemsTableData } from '@/types/master/item';
  import { SupplierReplaceData } from '@/types/master/supplier';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { CategoryReplaceData } from '@/types/category';
  import { csvFormat } from '@/libs/dateFormat';

  const store = useStore();
  const tableData = ref<ItemsTableData[]>([]);
  const categoryNameChanger = ref<CategoryReplaceData>({});
  const supplierNameChanger = ref<SupplierReplaceData>({});
  const loadingFlag = ref(false);
  const createRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('itemsMaster/fetch'),
      store.dispatch('categoryMaster/fetch'),
      store.dispatch('suppliersMaster/fetch')
    ]);
    categoryNameChanger.value = store.state.categoryMaster.replaceData;
    supplierNameChanger.value = store.state.suppliersMaster.replaceData;
    tableData.value = store.state.itemsMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/item/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.itemsMaster.listData.filter(
      (item: ItemsDataState) => {
        return (
          (item.name && item.name.includes(searchText.value)) ||
          (item.partCode && item.partCode.includes(searchText.value)) ||
          (item.model && item.model.includes(searchText.value)) ||
          (item.supplierName && item.supplierName.includes(searchText.value))
        );
      }
    );
  };
  const csvDownload = async () => {
    const filename = '単品一覧' + csvFormat(Date.now());
    const header = [
      'ID,品番,品名,カテゴリー,分類,小分類,仕入先,原単価,ケース単価,入数,リードタイム,備考,在庫管理'
    ];
    const baseData = store.state.itemsMaster.listData.map(
      (data: ItemsDataState): string => {
        return Object.entries({
          Id: data.id,
          partCode: data.partCode ? data.partCode : '',
          name: data.name ? data.name : '',
          categoryName: data.categoryName ? data.categoryName : '',
          subCategoryName: data.subCategoryName ? data.subCategoryName : '',
          subSubCategoryName: data.subSubCategoryName
            ? data.subSubCategoryName
            : '',
          supplierName: data.supplierName ? data.supplierName : '',
          baseCost: data.baseCost,
          caseCost: data.caseCost,
          lotQty: data.lotQty,
          leadTimeDay: data.leadTimeDay,
          note: data.note,
          isStock: data.isStock
        })
          .map(([, value]) => value)
          .join(',');
      }
    );
    const data = header.concat(baseData).join('\r\n');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(url);
  };
  initialize();
</script>
<style lang="scss">
  .master-item-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px 0 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
