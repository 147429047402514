<template>
  <div>
    <BaseSimpleLayout class="activation-detail">
      <template #title>起動制御解除詳細</template>
      <template #main>
        <BaseDataTable :table-data="detailTableData" :label-width="140" />
        <el-button type="info" size="large" @click="router.push('/activation')">
          戻る
        </el-button>
        <el-button
          v-if="orderRole && reserveStatus"
          type="danger"
          size="large"
          @click="dialogVisible = true"
        >
          予約停止
        </el-button>
      </template>
    </BaseSimpleLayout>
    <el-dialog v-model="dialogVisible" title="確認" width="300px">
      <span><b>この予約を停止しますか。</b></span>
      <template #footer>
        <el-button size="large" @click="dialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="danger" @click="cancelReserve">
          停止
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { rolePermission } from '@/libs/auth';
  import { activationStatus } from '@/libs/activation';
  import { formatTimeStamp } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';

  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const dialogVisible = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const reserveStatus = ref(false);
  const activateId = ref(0);

  const initialize = async () => {
    const detailData = store.state.activation.detailData;
    const deviceData = store.state.activation.deviceData;
    if (!detailData) {
      router.push('/activate');
    }
    if (detailData.status == 1) {
      reserveStatus.value = true;
    }
    activateId.value = detailData.id;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: '顧客管理番号',
        value: detailData.customerCode
      },
      {
        label: 'ステータス',
        value: activationStatus[detailData.status]
      },
      {
        label: '予約日時',
        value: formatTimeStamp(detailData.reserveDate)
      },
      {
        label: '予約者',
        value: detailData.updateUserName
      },
      {
        label: 'VIN',
        value: deviceData.tags.VIN
      },
      {
        label: 'DeviceSerial No',
        value: deviceData.tags.deviceSerialNo
      },
      {
        label: 'Last Connection',
        value: formatTimeStamp(deviceData.detailData.lastCommunicatedAt)
      },
      {
        label: 'Current Status',
        value: deviceData.detailData.activation.currentStatus
      }
    ];
  };

  const cancelReserve = () => {
    store.dispatch('activation/cancel', activateId.value);
  };
  initialize();
</script>
<style lang="scss">
  .activation-detail {
    .el-table {
      width: 400px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
