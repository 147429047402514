/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  InventoryState,
  InventoryDataState,
  InventoryDetailsDataState,
  InventorySubmitDataState,
  InventoryPutDataState,
  InventoryDetailTableData,
  InventoryDetailDataState,
  InventoryFilterForm
} from '@/types/inventory';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setContent(state: InventoryState, payload: InventoryDataState[]) {
      state.listData = payload;
    },
    setDetailStatus(
      state: InventoryDetailDataState,
      payload: InventoryDataState
    ) {
      state.status = payload.status;
    },
    setDetail(state: InventoryState, payload: InventoryDetailsDataState[]) {
      state.detailListData = payload;
    },
    setPrintTable(state: InventoryState, payload: InventoryDetailTableData[]) {
      state.printListData = payload;
    },
    setFilter(state: InventoryState, payload: InventoryFilterForm) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/inventories', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setContent', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          error(e.response.status);
          router.push('/error/server');
        });
    },
    async submit(context: any, payload: InventorySubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/inventories/confirm',
          {
            depotId: payload.depotId,
            inventoryDate: payload.inventoryDate
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then((response) => {
          context.commit('setContent', response.data);
          loadingInstance.close();
          success('Success', '棚卸日の登録に成功しました。');
          router.push('/inventory');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          error(e.response.status);
          router.push('/error/server');
        });
    },
    async put(context: any, payload: InventoryPutDataState) {
      await axios
        .put(
          getBaseUrl() + '/inventories',
          {
            depotId: payload.depotId,
            inventoryDate: payload.inventoryDate,
            itemId: payload.itemId,
            inventoryQty: payload.inventoryQty
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          error(e.response.status);
          router.push('/error/server');
        });
    },
    async fetchDetail(context: any, payload: InventorySubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .get(
          `${getBaseUrl()}/inventories/details?depotId=${
            payload.depotId
          }&inventoryDate=${payload.inventoryDate}`,
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(async (response) => {
          await context.commit('setDetail', response.data);
          loadingInstance.close();
        })
        .catch((e) => {
          loadingInstance.close();
          if (e.response.status === 400) {
            error('400', '対象端末が存在しません。');
          } else if (e.response.status === 401) {
            handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async confirm(context: any, payload: InventoryDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          `${getBaseUrl()}/inventories/confirm`,
          {
            depotId: payload.depotId,
            inventoryDate: payload.inventoryDate
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(async () => {
          router.push('/inventory');
          success('Success', '棚卸を確定しました。');
        })
        .catch((e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: InventoryFilterForm) {
      context.commit('setFilter', payload);
    }
  }
};
