/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { MSPFState, CommandTmkeepaint } from '@/types/mspf';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    status: ''
  },
  mutations: {
    setCommand(state: MSPFState, payload: string) {
      state.status = payload;
    }
  },
  actions: {
    async tmkeepaint(context: any, payload: CommandTmkeepaint) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/mspf/tmkeepaint/' + payload.deviceId,
          {
            tmkeepaint: 60
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', 'tmk:60/tmk2:720コマンド送信に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のデバイスが存在しません');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
