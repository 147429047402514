<template>
  <BaseSimpleLayout class="company-group-master-list">
    <template #title>販売店グループ一覧</template>
    <template #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="orderRole"
        class="unit-master-support-company-list-button-create"
        type="success"
        size="large"
        @click="router.push('/master/companygroup/create')"
      >
        販売店グループ登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="販売店企業名" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="fcCode"
          label="FCコード"
          width="120"
          align="center"
          sortable
        />
        <el-table-column
          prop="companyGroupType"
          label="種別"
          :width="130"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ groupTypeData[scope.row.companyGroupType] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { CompanyGroupsDataState } from '@/types/master/companyGroup';
  import { groupTypeData } from '@/libs/companyGroup';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<CompanyGroupsDataState[]>([]);
  const tableHeight = ref(600);
  const searchText = ref('');
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('companyGroupsMaster/fetch');
    tableData.value = store.state.companyGroupsMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/companygroup/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.companyGroupsMaster.listData.filter(
      (item: CompanyGroupsDataState) => {
        return (
          item.name.includes(searchText.value) ||
          item.fcCode.includes(searchText.value) ||
          groupTypeData[item.companyGroupType].includes(searchText.value)
        );
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .company-group-master-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      width: 800px;
      margin: 10px auto 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
