<template>
  <BaseSimpleLayout class="move-list">
    <template #title>移動一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        type="success"
        size="large"
        @click="router.push('/move/create')"
      >
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form :inline="true" :model="filterForm">
        <el-form-item label="出荷拠点">
          <el-select
            v-model="filterForm.outDepotId"
            filterable
            clearable
            placeholder="選択"
            @change="filterData"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="入荷拠点">
          <el-select
            v-model="filterForm.inDepotId"
            filterable
            clearable
            placeholder="選択"
            @change="filterData"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="移動日">
          <span class="index">開始:</span>
          <el-date-picker
            v-model="filterForm.moveStartDate"
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
          <span class="index">終了:</span>
          <el-date-picker
            v-model="filterForm.moveEndDate"
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
        </el-form-item>
        <el-form-item label="フィルタ">
          <el-input
            v-model="filterForm.filterText"
            placeholder="検索"
            style="width: 200px"
            @input="filterData"
          />
        </el-form-item>
        <el-form-item label="件数">
          <el-input-number
            class="form-count"
            v-model="filterForm.count"
            :min="1"
            :max="100000"
            :controls="false"
            :precision="0"
            @change="filterData"
          />
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column
          property="id"
          label="ID"
          align="center"
          width="80"
          sortable
        />
        <el-table-column
          property="moveDate"
          label="移動日"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ formatDate(Number(scope.row.moveDate)) }}
          </template>
        </el-table-column>
        <el-table-column
          property="outDepotName"
          label="出荷拠点"
          align="center"
          width="110"
          sortable
        />
        <el-table-column
          property="inDepotName"
          label="入荷拠点"
          align="center"
          width="110"
          sortable
        />
        <el-table-column
          property="partCode"
          label="品番"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="itemName"
          label="品名"
          align="center"
          width="250"
          sortable
        />
        <el-table-column
          property="moveQty"
          label="移動数"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="isConfirm"
          label="確定"
          align="center"
          width="80"
          sortable
        >
          <template #default="scope">
            {{ getConfirmName(scope.row.isConfirm) }}
          </template>
        </el-table-column>
        <el-table-column property="note" label="備考" align="center" sortable />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { MoveDataState, MoveFilterForm } from '@/types/move';
  import { DepotsDataState } from '@/types/master/depot';
  import { formatDate } from '@/libs/dateFormat';
  import { getConfirmName } from '@/libs/move';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<MoveDataState[]>([]);
  const filterForm = ref<MoveFilterForm>({
    moveStartDate: null,
    moveEndDate: null,
    outDepotId: null,
    inDepotId: null,
    isConfirm: false,
    filterText: null,
    count: 100
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(600);

  const depotList = ref<DepotsDataState[]>([]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('move/fetch'),
      store.dispatch('depotsMaster/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;

    if (store.state.move.filterData) {
      filterForm.value = store.state.move.filterData;
    }

    filterData();

    tableHeight.value = window.innerHeight - 190;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterData = () => {
    store.dispatch('move/setFilterData', filterForm.value);
    let count = 0;
    tableData.value = store.state.move.listData.filter(
      (data: MoveDataState) => {
        if (count >= filterForm.value.count) return false;

        if (filterForm.value.moveStartDate) {
          if (filterForm.value.moveStartDate > data.moveDate) return false;
        }

        if (filterForm.value.moveEndDate) {
          if (filterForm.value.moveEndDate < data.moveDate) return false;
        }

        if (filterForm.value.outDepotId) {
          if (filterForm.value.outDepotId !== data.outDepotId) return false;
        }
        if (filterForm.value.inDepotId) {
          if (filterForm.value.inDepotId !== data.inDepotId) return false;
        }

        const filterText =
          filterForm.value.filterText &&
          filterForm.value.filterText.toLowerCase();

        if (filterText) {
          if (
            !data.partCode.toLowerCase().includes(filterText) &&
            !(data.itemName ?? '').toLowerCase().includes(filterText) &&
            !(data.note ?? '').toLowerCase().includes(filterText)
          )
            return false;
        }

        count += 1;
        return true;
      }
    );
  };
  const rowClick = (row: MoveDataState) => {
    router.push('/move/' + row.id);
  };
  initialize();
</script>
<style lang="scss">
  .move-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    td {
      cursor: pointer;
    }
    .el-button {
      font-weight: 600;
    }
    .el-form {
      width: 100%;
    }
    .el-form--inline .el-form-item {
      margin-right: 15px;
    }
    .el-form-item {
      text-align: left;
      margin: 5px 0;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .el-checkbox {
      font-weight: 600;
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-table {
      margin: 10px auto 0;
      width: 1200px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .disp-count {
      text-align: left;
    }
  }
</style>
