<template>
  <BaseSimpleLayout class="master-finace-list">
    <template #title>ファイナンス一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/finance/create')"
      >
        ファイナンス登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column
          prop="name"
          label="ファイナンス名称"
          width="550"
          sortable
        >
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="bcId"
          label="BCID"
          width="150"
          align="center"
          sortable
        />
        <el-table-column
          prop="financeCode"
          label="ファイナンスコード"
          width="300"
          align="center"
          sortable
        />
        <el-table-column
          prop="isBulk"
          label="バルク発注"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ scope.row.isBulk ? '有効' : '無効' }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { FinancesDataState } from '@/types/master/finance';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<FinancesDataState[]>([]);
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('financesMaster/fetch');
    tableData.value = store.state.financesMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/finance/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.financesMaster.listData.filter(
      (item: FinancesDataState) => {
        if (!searchText.value) return true;
        if (item.name.includes(searchText.value)) return true;
        if (item.bcId.toString().includes(searchText.value)) return true;
        if (item.financeCode.includes(searchText.value)) return true;
        const bulk = item.isBulk ? '有効' : '無効';
        if (bulk.includes(searchText.value)) return true;
        return false;
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .master-finace-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px 0 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
