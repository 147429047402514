<template>
  <BaseSimpleLayout class="shop-master-detail">
    <template #title>販売店詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable :table-data="detailTableData" :label-width="150" />
      <el-button
        class="unit-master-support-shop-detail-button-list"
        type="info"
        size="large"
        @click="router.push('/master/shop')"
      >
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/shop/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        type="danger"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="30%">
        <span><b>この販売店を削除しますか。</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    shopId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('shopMaster/fetch', props.shopId),
      store.dispatch('companiesMaster/fetch')
    ]);
    const detailData = store.state.shopMaster.detailData;
    const companyDataChanger = store.state.companiesMaster.replaceData;
    detailTableData.value = [
      {
        label: '販売店名',
        value: detailData.name
      },
      {
        label: '販売店コード',
        value: detailData.shopCode
      },
      {
        label: '販売店企業名',
        value: companyDataChanger[detailData.companyId].name
      },
      {
        label: '郵便番号',
        value: detailData.zip
      },
      {
        label: '都道府県',
        value: detailData.prefecture
      },
      {
        label: '市区町村',
        value: detailData.city
      },
      {
        label: '住所',
        value: detailData.address
      },
      {
        label: '住所サブ',
        value: detailData.addressSub
      },
      {
        label: '電話番号',
        value: detailData.tel
      },
      {
        label: 'FAX',
        value: detailData.fax
      },
      {
        label: 'メールアドレス',
        value: detailData.email
      },
      {
        label: '担当者',
        value: detailData.staffName
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const deleteItem = () => {
    store.dispatch('shopMaster/delete', props.shopId);
  };
  initialize();
</script>
<style lang="scss">
  .shop-master-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
