/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  ItemsState,
  ItemsDataState,
  ItemsSubmitDataState,
  ItemReplaceData
} from '@/types/master/item';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setItems(state: ItemsState, payload: ItemsDataState[]) {
      state.listData = payload;
    },
    setReplace(state: ItemsState, payload: ItemReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/items', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setItems', response.data);
          const replaceData: ItemReplaceData = {};
          response.data.forEach((data: ItemsDataState) => {
            replaceData[data.id] = {
              name: data.name,
              partCode: data.partCode
            };
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);

            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: ItemsSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/master/items', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '単品登録に成功しました。');
          router.push('/master/item');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
