<template>
  <BaseSimpleLayout class="advance-detail">
    <template #title>先行受注詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable :table-data="detailTableData" :label-width="130" />
      <el-button size="large" type="info" @click="router.push('/advance')">
        戻る
      </el-button>
      <el-button
        v-if="status === 1 && orderRole"
        type="success"
        size="large"
        @click="router.push('/advance/input')"
      >
        編集
      </el-button>
      <el-button
        v-if="status === 1 && orderRole"
        type="primary"
        size="large"
        @click="displayDialog = true"
      >
        受注確定
      </el-button>
      <el-button
        v-if="status === 1 && orderRole"
        type="danger"
        size="large"
        @click="displayDeleteDialog = true"
      >
        削除
      </el-button>
      <el-dialog v-model="displayDialog" title="受注確定" width="500px">
        <span>
          <b>この受注情報で受注確定しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="completeOrder">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayDeleteDialog"
        title="バルク受注情報削除"
        width="400px"
      >
        <span>
          <b>この受注情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeleteDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteOrder">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { AdvanceOrderDetailData } from '@/types/advanceOrder';
  import { formatDate } from '@/libs/dateFormat';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    advanceOrderId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  let detailData: AdvanceOrderDetailData | null = null;
  const status = ref(0);
  const detailTableData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const displayDialog = ref(false);
  const displayDeleteDialog = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('advanceOrder/fetchDetail', props.advanceOrderId);
    detailData = store.state.advanceOrder.detailData;
    if (!detailData) {
      return router.push('/advance');
    }
    detailTableData.value = [
      {
        label: 'ステータス',
        value: detailData.status === 1 ? '下書き' : '受注確定'
      },
      {
        label: '受注日',
        value: formatDate(Number(detailData.acceptDate))
      },
      {
        label: 'ファイナンス',
        value: detailData.financeName
      },
      {
        label: '販売店企業',
        value: detailData.companyName
      },
      {
        label: '製品名',
        value: detailData.productName
      },
      {
        label: '製品単価',
        value: detailData.productPrice
      },
      {
        label: '個数',
        value: detailData.productQty
      },
      {
        label: '備考',
        value: detailData.note
      }
    ];
    status.value = detailData.status;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const completeOrder = () => {
    store.dispatch('advanceOrder/complete', props.advanceOrderId);
  };

  const deleteOrder = () => {
    store.dispatch('advanceOrder/delete', props.advanceOrderId);
  };
  initialize();
</script>
<style lang="scss">
  .advance-detail {
    .el-table {
      width: 400px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
    }
  }
</style>
