<template>
  <BaseSimpleLayout class="receive-edit">
    <template #title>{{ !isConfirm ? '入荷編集' : '入荷編集確認' }}</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item label="シリアル番号" class="receive-edit-serial">
          <el-input v-model="detailData.serialCode" :disabled="true" />
        </el-form-item>
        <el-form-item
          label="入荷日"
          prop="receiveDate"
          class="receive-edit-receive-date"
        >
          <el-date-picker
            v-model="editForm.receiveDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
            :clearable="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          label="発送元"
          prop="shipSourceSelectType"
          @change="selectedSourceType"
        >
          <el-radio-group
            v-model="editForm.shipSourceSelectType"
            :disabled="isConfirm"
          >
            <el-radio :label="1">販売店</el-radio>
            <el-radio :label="2">サポート店</el-radio>
            <el-radio :label="3">GMS</el-radio>
            <el-radio :label="4">その他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="editForm.shipSourceSelectType > 2"
          label="発送元名"
          prop="shipSourceName"
          class="receive-edit-name"
        >
          <el-input v-model="editForm.shipSourceName" :disabled="isConfirm" />
        </el-form-item>
        <el-form-item
          v-if="editForm.shipSourceSelectType === 1"
          label="発送元名"
          prop="shopId"
          class="receive-edit-name"
        >
          <el-select
            v-model="editForm.shopId"
            filterable
            @change="selectedShop"
            :disabled="isConfirm"
          >
            <el-option
              v-for="shop in shopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="editForm.shipSourceSelectType === 2"
          label="発送元名"
          prop="supportShopId"
          :disabled="isConfirm"
          class="receive-edit-name"
        >
          <el-select
            v-model="editForm.supportShopId"
            filterable
            :disabled="isConfirm"
            @change="selectedShop"
          >
            <el-option
              v-for="shop in supportShopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="入荷種別" prop="receiveType">
          <el-select v-model="editForm.receiveType" :disabled="isConfirm">
            <el-option
              v-for="data in receiveTypeList"
              :key="data.label"
              :label="data.label"
              :value="data.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input
            :disabled="isConfirm"
            v-model="editForm.note"
            type="textarea"
          />
        </el-form-item>
        <div v-if="!isConfirm">
          <el-button
            type="info"
            size="large"
            @click="router.push('/receive/' + receiveId)"
          >
            戻る
          </el-button>
          <el-button type="primary" size="large" @click="editConfirm(formRef)">
            確認
          </el-button>
        </div>
        <div v-if="isConfirm">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="put">更新</el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ElLoading } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { receiveTypeList } from '@/libs/receive';
  import { ShopsDataState } from '@/types/master/shop';
  import { SupportShopsDataState } from '@/types/master/supportShop';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref();
  const rules = ref<FormRules>({
    receiveDate: [{ required: true, message: '入荷日は必須です。' }],
    shipSourceName: [{ required: true, message: '販売元名は必須です。' }],
    shopId: [{ required: true, message: '販売元名は必須です。' }],
    supportShopId: [{ required: true, message: '販売元名は必須です。' }]
  });
  const loadingFlag = ref(false);
  const shopList = ref<ShopsDataState[]>([]);
  const supportShopList = ref<SupportShopsDataState[]>([]);
  const isConfirm = ref(false);
  const detailData = ref();
  const receiveId = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    editForm.value = store.state.receive.editData;
    detailData.value = store.state.receive.detailData;
    receiveId.value = store.state.receive.receiveId;
    if (!editForm.value) return router.push('/receive');
    await Promise.all([
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('supportShopsMaster/fetch')
    ]);
    shopList.value = store.state.shopsMaster.listData;
    supportShopList.value = store.state.supportShopsMaster.listData;
    if (editForm.value.shipSourceSelectType === 1 && editForm.value.shopId)
      selectedShop(editForm.value.shopId);
    if (
      editForm.value.shipSourceSelectType === 2 &&
      editForm.value.supportShopId
    )
      selectedShop(editForm.value.supportShopId);
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const selectedSourceType = () => {
    if (editForm.value.shipSourceSelectType < 3)
      editForm.value.shipSourceName = null;
    editForm.value.shopId = null;
    editForm.value.supportShopId = null;
  };
  const selectedShop = (id: number) => {
    const shopType = editForm.value.shipSourceSelectType;
    if (shopType === 1) {
      editForm.value.shipSourceName =
        store.state.shopsMaster.replaceData[id].name;
    }
    if (shopType === 2) {
      editForm.value.shipSourceName =
        store.state.supportShopsMaster.replaceData[id];
    }
  };
  const editConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const put = () => {
    store.dispatch('receive/put', {
      receiveId: receiveId.value,
      editData: editForm.value
    });
  };
  initialize();
</script>
<style lang="scss">
  .receive-edit {
    form {
      width: 550px;
      margin: auto;
      .receive-edit-serial {
        .el-input {
          width: 197.5px;
        }
      }
      .receive-edit-receive-date {
        .el-input {
          width: 130px;
        }
      }
      .receive-edit-name {
        .el-input {
          width: 400px;
        }
      }
      .receive-edit-device {
        .el-input {
          width: 400px;
        }
      }
      .el-input.is-disabled {
        .el-input__inner {
          color: #606266;
        }
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #606266;
        }
      }
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
