<template>
  <BaseSimpleLayout class="master-sub-category-detail">
    <template #title>分類マスタ詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        class="sub-category-detail-table"
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <el-table
        :data="subSubCategories"
        stripe
        border
        style="width: 700px"
        empty-text="小分類が存在しません"
      >
        <el-table-column
          prop="code"
          label="小分類コード"
          width="120px"
          align="center"
        />
        <el-table-column prop="name" label="小分類名" />
      </el-table>
      <el-button
        type="info"
        size="large"
        @click="router.push('/master/subCategory')"
      >
        戻る
      </el-button>
      <el-button
        v-if="editRole"
        type="success"
        size="large"
        @click="router.push('/master/subCategory/edit')"
      >
        編集
      </el-button>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { SubSubCategory } from '@/types/master/subCategory';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    subCategoryMasterId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const subSubCategories = ref<SubSubCategory[]>([]);
  const loadingFlag = ref(false);
  const editRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('subCategoryMaster/fetch', props.subCategoryMasterId)
    ]);
    const detailData = store.state.subCategoryMaster.detailData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: '名前',
        value: detailData.name
      },
      {
        label: 'カテゴリー名称',
        value: detailData.categoryName
      }
    ];
    subSubCategories.value = detailData.subSubCategories;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .master-sub-category-detail {
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      width: 500px;
      margin: auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
    .sub-category-detail-table {
      margin-bottom: 20px;
    }
  }
</style>
