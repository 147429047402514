<template>
  <div>
    <BaseSimpleLayout class="company-group-detail">
      <template #title>販売店グループ詳細</template>
      <template v-if="loadingFlag" #main>
        <BaseDataTable
          :table-data="detailTableData"
          table-size="medium"
          :label-width="130"
        />
        <BaseCompanyList :table-data="companyList" :is-group="false" />
        <el-button
          class="unit-master-support-shop-detail-button-list"
          type="info"
          size="large"
          @click="router.push('/master/companygroup')"
        >
          戻る
        </el-button>
        <el-button
          v-if="orderRole"
          type="success"
          size="large"
          class="unit-master-support-shop-detail-button-edit"
          @click="router.push('/master/companygroup/edit')"
        >
          編集
        </el-button>
        <el-button
          v-if="orderRole"
          type="danger"
          size="large"
          class="unit-master-support-shop-detail-button-delete"
          @click="dialogVisible = true"
        >
          削除
        </el-button>
      </template>
    </BaseSimpleLayout>
    <el-dialog v-model="dialogVisible" title="確認" width="30%">
      <span><b>この販売店グループ情報を削除しますか。</b></span>
      <template #footer>
        <el-button size="large" @click="dialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="danger" @click="deleteItem">
          削除
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import BaseCompanyList from '@/components/BaseCompanyList.vue';
  import { DetailTableData, CompanyListData } from '@/types/common';
  import { incurredPrice, groupTypeData } from '@/libs/companyGroup';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { AffiliatedCompanyState } from '@/types/master/companyGroup';

  const props = defineProps({
    companyGroupId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const companyList = ref<CompanyListData[]>([]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('companyGroupMaster/fetch', props.companyGroupId)
    ]);
    const detailData = store.state.companyGroupMaster.detailData;
    const companyChanger = store.state.companiesMaster.replaceData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: 'グループ名',
        value: detailData.name
      },
      {
        label: 'FCコード',
        value: detailData.fcCode
      },
      {
        label: '種別',
        value: groupTypeData[detailData.companyGroupType]
      },
      {
        label: '次回顧客番号',
        value: detailData.customerNextNumber
      },
      {
        label: 'MCCS卸価格',
        value: detailData.deviceTradePrice
      },
      {
        label: '取付価格',
        value: detailData.deviceAttachedPrice
      },
      {
        label: '取外価格',
        value: detailData.deviceDettachedPrice
      },
      {
        label: '発生費用対象',
        value: incurredPrice[detailData.incurredPriceType]
      },
      {
        label: '月額費用',
        value: detailData.monthlyPrice
      },
      {
        label: '未使用費用',
        value: detailData.unusedPrice
      },
      {
        label: 'ハーネス価格',
        value: detailData.harnessSalesPrice
      }
    ];
    companyList.value = detailData.affiliatedCompanies.map(
      (data: AffiliatedCompanyState) => {
        return {
          id: data.id,
          name: data.name,
          type: companyChanger[data.id].isAdvanceOrder ? '先行' : '通常'
        };
      }
    );
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const deleteItem = () => {
    store.dispatch('companyGroupMaster/delete', props.companyGroupId);
  };
  initialize();
</script>
<style lang="scss">
  .company-group-detail {
    .el-button {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .el-table {
      width: 500px;
      margin: auto auto 30px;
    }
  }
</style>
