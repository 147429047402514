<template>
  <BaseSimpleLayout class="order-detail">
    <template #title>発注詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/order')">
        一覧
      </el-button>
      <el-button
        v-if="displayEditButton"
        size="large"
        type="primary"
        @click="router.push('/order/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="displayPurchaseRecordButton"
        size="large"
        type="primary"
        @click="displayPurchaseRecordDialog = true"
      >
        仕入計上
      </el-button>
      <el-button
        v-if="displayConfirmDeliveryButton"
        size="large"
        type="success"
        @click="displayConfirmDeliveryDialog = true"
      >
        完納
      </el-button>
      <el-button
        v-if="displayDeleteOrderButton"
        size="large"
        type="danger"
        @click="displayDeleteOrderDialog = true"
      >
        削除
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <el-dialog
        v-model="displayPurchaseRecordDialog"
        class="purchase-record-dialog"
        title="仕入計上"
        width="500px"
      >
        <el-form
          ref="acceptFormRef"
          :model="acceptForm"
          :rules="acceptRules"
          label-width="170px"
        >
          <el-form-item label="仕入数" prop="purchaseQty">
            <el-input-number
              v-model="acceptForm.purchaseQty"
              :precision="0"
              :min="1"
              :controls="false"
              @keydown.enter.prevent
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="displayPurchaseRecordDialog = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="recordPurchase(acceptFormRef)"
          >
            確定
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayConfirmDeliveryDialog"
        class="confirm-delivery-dialog"
        title="完納確認"
        width="500px"
      >
        <span>
          <b
            >当発注を納品済みにしてよろしいですか？<br />※仕入計上できなくなります</b
          >
        </span>
        <template #footer>
          <el-button size="large" @click="displayConfirmDeliveryDialog = false">
            いいえ
          </el-button>
          <el-button size="large" type="primary" @click="updateDeliveryIsDone">
            はい
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="displayDeleteOrderDialog"
        class="delete-order-dialog"
        title="発注情報削除"
        width="500px"
      >
        <span>
          <b>この発注情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeleteOrderDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteOrder">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { formatDate } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';

  const props = defineProps({
    orderId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const canPurchaseQty = ref<number>(0);
  const displayEditButton = ref(false);
  const displayPurchaseRecordButton = ref(false);
  const displayConfirmDeliveryButton = ref(false);
  const displayDeleteOrderButton = ref(false);
  const loadingFlag = ref(false);
  const displayPurchaseRecordDialog = ref(false);
  const displayConfirmDeliveryDialog = ref(false);
  const displayDeleteOrderDialog = ref(false);

  const acceptForm = ref({
    purchaseQty: 0
  });
  const overflowValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (acceptForm.value.purchaseQty <= canPurchaseQty.value) {
      callback();
    } else {
      callback(new Error('仕入数が発注数を超えています。'));
    }
  };
  const acceptRules = ref<FormRules>({
    purchaseQty: [
      { required: true, message: '仕入数は必須です。' },
      { validator: overflowValidator, trigger: 'blur' }
    ]
  });
  const acceptFormRef = ref<FormInstance>();

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });

    displayPurchaseRecordDialog.value = false;
    displayConfirmDeliveryDialog.value = false;
    displayDeleteOrderDialog.value = false;

    await store.dispatch('order/fetch', props.orderId);
    const detailData = store.state.order.detailData;
    detailTableData.value = [
      {
        label: '拠点',
        value: detailData.depotName
      },
      {
        label: '見積もりNo.',
        value: detailData.estimateNo
      },
      {
        label: '発注日',
        value: detailData.orderDate
          ? formatDate(Number(detailData.orderDate))
          : '-'
      },
      {
        label: '仕入先名',
        value: detailData.supplierName
      },
      {
        label: '品番',
        value: detailData.partCode
      },
      {
        label: '品名',
        value: detailData.itemName
      },
      {
        label: '入数',
        value: detailData.lotQty
      },
      {
        label: '発注数',
        value: detailData.orderQty
      },
      {
        label: '単価',
        value: detailData.cost.toLocaleString()
      },
      {
        label: '金額',
        value: detailData.price.toLocaleString()
      },
      {
        label: '納品予定日',
        value: detailData.deliveryDate
          ? formatDate(Number(detailData.deliveryDate))
          : '-'
      },
      {
        label: '備考',
        value: detailData.note
      },
      {
        label: '仕入数',
        value: detailData.purchaseQty
      },
      {
        label: '完納',
        value: detailData.isDone ? '済' : '未'
      }
    ];

    if (detailData.isDone) {
      displayEditButton.value = false;
      displayPurchaseRecordButton.value = false;
      displayConfirmDeliveryButton.value = false;
      displayDeleteOrderButton.value = false;
    } else {
      displayConfirmDeliveryButton.value = true;
      if (detailData.purchaseQty > 0) {
        displayEditButton.value = false;
        displayDeleteOrderButton.value = false;
      } else {
        displayEditButton.value = true;
        displayDeleteOrderButton.value = true;
      }
      if (detailData.purchaseQty >= detailData.orderQty) {
        displayPurchaseRecordButton.value = false;
      } else {
        displayPurchaseRecordButton.value = true;
      }
    }
    acceptForm.value.purchaseQty = detailData.orderQty - detailData.purchaseQty;
    canPurchaseQty.value = detailData.orderQty - detailData.purchaseQty;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const recordPurchase = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      await store.dispatch('order/approve', {
        orderId: props.orderId,
        purchaseQty: acceptForm.value.purchaseQty
      });
      initialize();
    });
  };
  const updateDeliveryIsDone = async () => {
    const detailData = store.state.order.detailData;
    await store.dispatch('order/put', {
      id: detailData.id,
      estimateNo: detailData.estimateNo,
      orderDate: detailData.orderDate,
      depotId: detailData.depotId,
      supplierId: detailData.supplierId,
      itemId: detailData.itemId,
      cost: detailData.cost,
      lotQty: detailData.lotQty,
      orderQty: detailData.orderQty,
      deliveryDate: detailData.deliveryDate,
      note: detailData.note,
      isDone: true
    });
    initialize();
  };
  const deleteOrder = async () => {
    await store.dispatch('order/delete', props.orderId);
  };
  initialize();
</script>
<style lang="scss">
  .order-detail {
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      width: 500px;
      margin: 40px auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
    .purchase-record-dialog {
      .el-form-item__content {
        text-align: left;
      }
      .el-input {
        width: 150px;
      }
      label {
        font-weight: 600;
      }
    }
  }
</style>
