<template>
  <div>
    <el-menu
      class="base-header"
      mode="horizontal"
      :background-color="backgroundColor"
      text-color="#f8f8ff"
      active-text-color="#ffd04b"
      :default-active="activeIndex()"
    >
      <h3 class="header-title unit-header-title">SIPS</h3>
      <el-sub-menu
        v-if="displayItem(['ORDERADMIN', 'ORDERING', 'VIEW', 'SHIPADMIN'])"
        index="1"
      >
        <template #title>受注管理</template>
        <el-menu-item
          v-if="displayItem()"
          index="accept"
          class="unit-header-item-accept"
          @click="routerPush('/accept')"
        >
          受注情報
        </el-menu-item>
        <el-menu-item
          v-if="displayItem()"
          index="bulk"
          class="unit-header-item-bulk"
          @click="routerPush('/bulk')"
        >
          バルク受注
        </el-menu-item>
        <el-menu-item
          v-if="displayItem()"
          index="advance"
          class="unit-header-item-bulk"
          @click="routerPush('/advance')"
        >
          先行受注
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item
        v-if="displayItem(['SHIPADMIN', 'ORDERADMIN'])"
        index="receive"
        class="unit-header-item-receive"
        @click="routerPush('/receive')"
      >
        入荷管理
      </el-menu-item>
      <el-menu-item
        v-if="
          displayItem([
            'ORDERING',
            'ORDERADMIN',
            'SHIPPING',
            'SHIPPING_PLUS',
            'SHIPADMIN',
            'VIEW'
          ])
        "
        index="ship"
        class="unit-header-item-ship"
        @click="routerPush('/ship')"
      >
        出荷管理
      </el-menu-item>
      <el-menu-item
        v-if="displayItem(['ORDERING', 'ORDERADMIN', 'SHIPADMIN', 'VIEW'])"
        index="management"
        class="unit-header-item-management"
        @click="routerPush('/management')"
      >
        総合管理
      </el-menu-item>
      <el-sub-menu
        v-if="displayItem(['ORDERING', 'ORDERADMIN', 'VIEW'])"
        index="2"
      >
        <template #title>請求管理</template>
        <el-menu-item
          v-if="displayItem()"
          index="unrecord"
          @click="routerPush('/unrecord')"
        >
          未計上受注
        </el-menu-item>
        <el-menu-item
          v-if="displayItem()"
          index="sale"
          @click="routerPush('/sale')"
        >
          売上一覧
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu
        v-if="
          displayItem([
            'ORDERING',
            'ORDERADMIN',
            'SHIPPING',
            'SHIPPING_PLUS',
            'SHIPADMIN',
            'VIEW'
          ])
        "
        index="3"
      >
        <template #title>在庫管理</template>
        <el-menu-item
          v-if="displayItem(['SHIPADMIN', 'ORDERADMIN'])"
          index="order"
          @click="routerPush('/order')"
        >
          発注
        </el-menu-item>
        <el-menu-item
          v-if="displayItem(['SHIPADMIN', 'ORDERADMIN'])"
          index="purchase"
          @click="routerPush('/purchase')"
        >
          仕入
        </el-menu-item>
        <el-menu-item
          v-if="displayItem(['SHIPADMIN', 'ORDERADMIN'])"
          index="move"
          @click="routerPush('/move')"
        >
          移動
        </el-menu-item>
        <el-menu-item
          v-if="
            displayItem([
              'SHIPPING',
              'SHIPPING_PLUS',
              'SHIPADMIN',
              'ORDERING',
              'ORDERADMIN'
            ])
          "
          index="inventory"
          @click="routerPush('/inventory/')"
        >
          棚卸
        </el-menu-item>
        <el-menu-item
          v-if="displayItem()"
          index="stock"
          @click="routerPush('/stock')"
        >
          在庫一覧表
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu
        v-if="
          displayItem([
            'ORDERING',
            'ORDERADMIN',
            'SHIPPING',
            'SHIPPING_PLUS',
            'SHIPADMIN',
            'VIEW'
          ])
        "
        index="4"
      >
        <template #title>端末管理</template>
        <el-menu-item index="device3" @click="routerPush('/device/3')">
          MCCSⅢ
        </el-menu-item>
        <el-menu-item index="device2" @click="routerPush('/device/2')">
          MCCSⅡ
        </el-menu-item>
      </el-sub-menu>
      <!-- <el-menu-item
        v-if="displayItem(['SHIPPING_PLUS', 'SHIPADMIN', 'ORDERADMIN'])"
        index="autobacs"
        class="unit-header-item-autobacs-device"
        @click="routerPush('/autobacs/device')"
      >
        AUTOBACS端末
      </el-menu-item> -->
      <el-sub-menu
        v-if="
          displayItem([
            'SHIPPING_PLUS',
            'SHIPADMIN',
            'ORDERADMIN',
            'VIEW',
            'TECH'
          ])
        "
        index="carSecurityDevice"
      >
        <template #title>カーセキュリティ</template>
        <el-menu-item
          v-if="displayItem(['SHIPPING_PLUS', 'SHIPADMIN'])"
          index="carSecurityDevice3"
          @click="routerPush('/carSecurity/device/3')"
        >
          MCCSⅢ 端末管理
        </el-menu-item>
        <el-menu-item
          v-if="displayItem(['SHIPPING_PLUS', 'SHIPADMIN'])"
          index="carSecurityDevice2"
          @click="routerPush('/carSecurity/device/2')"
        >
          MCCSⅡ 端末管理
        </el-menu-item>
        <el-menu-item
          v-if="displayItem(['ORDERADMIN', 'SHIPADMIN', 'TECH'])"
          index="carSecurityHarness"
          @click="routerPush('/carSecurityHarness')"
        >
          スマブロ適合表
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item
        v-if="displayItem(['ORDERING', 'ORDERADMIN', 'SHIPADMIN', 'TECH'])"
        index="master"
        class="unit-header-item-master"
        @click="routerPush('/master')"
      >
        マスタ管理
      </el-menu-item>
      <el-menu-item
        v-if="
          displayItem(['ORDERING', 'ORDERADMIN', 'SHIPADMIN', 'VIEW', 'TECH'])
        "
        index="harness"
        class="unit-header-item-master"
        @click="routerPush('/harness')"
      >
        ハーネス適合表
      </el-menu-item>
      <el-menu-item
        v-if="displayItem(['ORDERADMIN', 'SHIPADMIN'])"
        class="unit-header-item-user"
        index="user"
        @click="routerPush('/user')"
      >
        ユーザ管理
      </el-menu-item>
      <el-menu-item
        v-if="displayItem(['ORDERING', 'ORDERADMIN'])"
        class="unit-header-item-user"
        index="activation"
        @click="routerPush('/activation')"
      >
        起動制御解除
      </el-menu-item>
      <el-sub-menu v-if="displayItem()" index="5">
        <template #title>MSPF</template>
        <el-menu-item
          v-if="displayItem()"
          index="4-1"
          class="unit-header-item-bulk"
          @click="moveMSPF('operation')"
        >
          Operator
        </el-menu-item>
      </el-sub-menu>
    </el-menu>
    <el-dialog v-model="dialogVisible" title="確認" width="400px">
      <span><b>SIPS画面からログアウトします。</b></span>
      <template #footer>
        <el-button @click="dialogVisible = false">キャンセル</el-button>
        <el-button type="primary" @click="logout"> ログアウト </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import router from '@/router';
  import { useRoute } from 'vue-router';
  import { RoleText } from '@/libs/auth';
  import { rolePermission } from '@/libs/auth';
  import { signOut } from '@/libs/firebaseAuth';
  import { getEnv } from '@/libs/env';

  export default defineComponent({
    name: 'BaseHeader',
    setup() {
      const route = useRoute();
      const dialogVisible = false;

      const logout = async () => {
        await signOut();
        router.push('/login');
      };
      const displayItem = (roleList: RoleText[] | null = null) => {
        let permission = true;
        const isPublic = route.meta?.isPublic;

        if (roleList) permission = rolePermission(roleList);
        return !isPublic && permission;
      };
      const activeIndex = () => {
        return route.path.split('/')[1];
      };
      const routerPush = (path: string) => {
        if (route.path === path) return;
        router.push(path);
      };
      const moveMSPF = (path: string) => {
        window.open('https://ops.cloud-gms.com/' + path, '_blank');
      };
      const backgroundColor = getEnv() === 'staging' ? '#797979' : '#0148a0';
      return {
        dialogVisible,
        logout,
        displayItem,
        activeIndex,
        routerPush,
        moveMSPF,
        backgroundColor
      };
    }
  });
</script>
<style lang="scss">
  .base-header {
    z-index: 1000;

    .header-title {
      font-size: 30px;
      font-weight: 800;
      float: left;
      color: #ffffff;
      margin: 8px 10px;
      pointer-events: none;
    }

    li {
      font-weight: 600;
    }
  }

  .header-link-text {
    color: #ffffff;
  }

  .el-menu--horizontal {
    .el-sub-menu.header-menu-right {
      float: right;
    }
  }

  .el-menu.el-menu--horizontal {
    position: fixed;
    width: 100%;
  }
</style>
