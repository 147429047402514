/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';

export const productType: NumberData = {
  1: 'MCCSセット',
  2: '単品',
  3: '取付',
  4: '取外',
  5: '先行受注'
};

export const productTypeList: { val: number; label: string }[] = [
  { val: 1, label: 'MCCSセット' },
  { val: 2, label: '単品' },
  { val: 3, label: '取付' },
  { val: 4, label: '取外' },
  { val: 5, label: '先行受注' }
];

export const deviceTypeList: (number | null)[] = [null, 2, 3];
