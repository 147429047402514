<template>
  <BaseSimpleLayout class="master-support-shop-list">
    <template #title>サポート店一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/supportshop/create')"
      >
        サポート店登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" width="550" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="affiliationUnion"
          label="所属組合"
          align="center"
          sortable
        />
        <el-table-column
          prop="prefecture"
          label="都道府県"
          align="center"
          sortable
        />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { SupportShopsDataState } from '@/types/master/supportShop';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<SupportShopsDataState[]>([]);
  const loadingFlag = ref(false);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('supportShopsMaster/fetch');
    tableData.value = store.state.supportShopsMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/supportshop/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.supportShopsMaster.listData.filter(
      (item: SupportShopsDataState) => {
        return (
          item.name.includes(searchText.value) ||
          item.affiliationUnion.includes(searchText.value) ||
          item.prefecture.includes(searchText.value)
        );
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .master-support-shop-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px auto 0;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
