/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';

export const incurredPrice: NumberData = {
  0: '対象外',
  1: '取付',
  2: '取外',
  3: '両方'
};

export const incurredPriceSelect: { val: number; label: string }[] = [
  { val: 0, label: '対象外' },
  { val: 1, label: '取付' },
  { val: 2, label: '取外' },
  { val: 3, label: '両方' }
];

export const groupTypeData: NumberData = {
  1: 'FC',
  2: '自社'
};
