/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  FinanceState,
  FinanceDetailDataState,
  FinancePutDataState
} from '@/types/master/finance';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: FinanceState, payload: FinanceDetailDataState) {
      state.detailData = payload;
    },
    setPut(state: FinanceState, payload: FinancePutDataState) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, financeMasterId: any) {
      await axios
        .get(getBaseUrl() + '/master/finances/' + financeMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          context.commit('setPut', {
            id: response.data.id,
            name: response.data.name,
            bcId: response.data.bcId,
            financeCode: response.data.financeCode,
            isBulk: response.data.isBulk
          });
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(
              e.response.status,
              '対象のファイナンスマスタ情報が存在しません。'
            );
            return router.push('/master/finance');
          }
          if (e.response.status < 500) {
            error(e.response.status);
            return router.push('/master/finance');
          }

          router.push('/error/server');
        });
    },
    async delete(context: any, financeMasterId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/master/finances/' + financeMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', 'ファイナンスマスタの削除に成功しました。');
          router.push('/master/finance');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(
              e.response.status,
              '対象のファイナンスマスタ情報が存在しません。'
            );
            return router.push('/master/finance');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: FinancePutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/finances/' + payload.id,
          {
            name: payload.name,
            bcId: payload.bcId,
            financeCode: payload.financeCode,
            isBulk: payload.isBulk
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ファイナンスマスタ情報の変更に成功しました。');
          router.push('/master/finance');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(
              e.response.status,
              '対象のファイナンスマスタ情報が存在しません。'
            );
            return router.push('/master/finance');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setPutData(context: any, payload: FinancePutDataState) {
      context.commit('setPut', payload);
    }
  }
};
