<template>
  <BaseSimpleLayout class="item-master-create">
    <template #title>単品登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="140px"
      >
        <el-form-item
          class="unit-master-item-create-name"
          label="品名"
          prop="name"
        >
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-model"
          label="型式"
          prop="model"
        >
          <el-input
            v-model="createForm.model"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-part-code"
          label="品番"
          prop="partCode"
        >
          <el-input
            v-model="createForm.partCode"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-category"
          label="カテゴリー"
          prop="categoryId"
        >
          <el-select
            v-model="createForm.categoryId"
            placeholder=""
            :disabled="isConfirm"
            @change="setCategory(false)"
          >
            <el-option
              v-for="category in categoryList"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-sub-category"
          label="分類"
          prop="subCategoryId"
        >
          <el-select
            v-model="createForm.subCategoryId"
            placeholder=""
            :disabled="isConfirm || disableSubCategorySelect"
            @change="setSubCategory(false)"
          >
            <el-option
              v-for="subCategory in subCategoryList"
              :key="subCategory.id"
              :label="`${String(subCategory.id).padStart(2, '0')}: ${
                subCategory.name
              }`"
              :value="subCategory.id"
            />
          </el-select>
          <span>※ カテゴリー選択後選択可</span>
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-minor-sub-category"
          label="小分類"
          prop="subSubCategoryId"
        >
          <el-select
            v-model="createForm.subSubCategoryId"
            placeholder=""
            :disabled="isConfirm || disableSubSubCategorySelect"
          >
            <el-option
              v-for="subSubCategory in subSubCategoryList"
              :key="subSubCategory.id"
              :label="`${String(subSubCategory.id).padStart(2, '0')}: ${
                subSubCategory.name
              }`"
              :value="subSubCategory.id"
            />
          </el-select>
          <span>※ 分類選択後選択可</span>
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-base-cost base-cost"
          label="基準原価 (円)"
          prop="baseCost"
        >
          <el-input-number
            v-model="createForm.baseCost"
            :precision="2"
            :min="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-case-cost"
          label="ケース単価 (円)"
          prop="caseCost"
        >
          <el-input-number
            v-model="createForm.caseCost"
            :precision="2"
            :min="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-lot-qty"
          label="入数"
          prop="lotQty"
        >
          <el-input-number
            v-model="createForm.lotQty"
            :precision="0"
            :min="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-lead-time-day"
          label="リードタイム (日)"
          prop="leadTimeDay"
        >
          <el-input-number
            v-model="createForm.leadTimeDay"
            :precision="0"
            :min="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-supplier"
          label="仕入先"
          prop="supplierId"
        >
          <el-select
            v-model="createForm.supplierId"
            clearable
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="supplier in supplierList"
              :key="supplier.id"
              :label="`${String(supplier.id).padStart(2, '0')}: ${
                supplier.name
              }`"
              :value="supplier.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-note"
          label="備考"
          prop="note"
        >
          <el-input
            v-model="createForm.note"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-item-create-is-stock"
          label="在庫管理"
          prop="isStock"
        >
          <el-radio-group v-model="createForm.isStock" :disabled="isConfirm">
            <el-radio :label="true">管理する</el-radio>
            <el-radio :label="false">管理しない</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            class="unit-master-item-create-button-list"
            type="info"
            size="large"
            @click="router.push('/master/item')"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-item-create-button-confirm"
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { ItemsSubmitDataState } from '@/types/master/item';
  import { CategoryListState } from '@/types/category';
  import { SubCategoriesDataState } from '@/types/master/subCategory';
  import { SubSubCategoriesDataState } from '@/types/master/subSubCategory';
  import { SupplierDataState } from '@/types/master/supplier';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<ItemsSubmitDataState>({
    name: '',
    model: '',
    partCode: '',
    categoryId: null,
    baseCost: 0,
    isStock: true,
    caseCost: 0,
    lotQty: 1,
    leadTimeDay: 0,
    supplierId: null,
    subCategoryId: null,
    subSubCategoryId: null,
    note: ''
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: '品名は必須です。' }],
    categoryId: [{ required: true, message: 'カテゴリーは必須です。' }],
    partCode: [{ required: true, message: '品番は必須です。' }],
    baseCost: [{ required: true, message: '基準原価は必須です。' }],
    caseCost: [{ required: true, message: 'ケース単価は必須です。' }],
    lotQty: [{ required: true, message: '入数は必須です。' }],
    leadTimeDay: [{ required: true, message: 'リードタイムは必須です。' }]
  });
  const categoryList = ref<CategoryListState[]>([]);
  const subCategoryList = ref<SubCategoriesDataState[]>([]);
  const subSubCategoryList = ref<SubSubCategoriesDataState[]>([]);
  const supplierList = ref<SupplierDataState[]>([]);
  const loadingFlag = ref(false);
  const disableSubCategorySelect = ref(true);
  const disableSubSubCategorySelect = ref(true);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    if (!store.state.categoryMaster.listData.length) {
      await store.dispatch('categoryMaster/fetch');
    }
    if (!store.state.subCategoriesMaster.listData.length) {
      await store.dispatch('subCategoriesMaster/fetch');
    }
    if (!store.state.suppliersMaster.listData.length) {
      await store.dispatch('suppliersMaster/fetch');
    }
    categoryList.value = store.state.categoryMaster.listData;
    subCategoryList.value = store.state.subCategoriesMaster.listData;
    supplierList.value = store.state.suppliersMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
    if (createForm.value.categoryId) {
      await setCategory(true);
    }
    if (createForm.value.subCategoryId) {
      await setSubCategory(true);
    }
  };
  const setCategory = async (initial = false) => {
    subCategoryList.value = store.state.subCategoriesMaster.listData.filter(
      (subCategory: SubCategoriesDataState) =>
        subCategory.categoryId === createForm.value.categoryId
    );
    disableSubCategorySelect.value = false;
    if (!initial) {
      createForm.value.subCategoryId = null;
      createForm.value.subSubCategoryId = null;
    }
  };
  const setSubCategory = async (initial = false) => {
    await store.dispatch('subSubCategoriesMaster/fetch');
    subSubCategoryList.value =
      store.state.subSubCategoriesMaster.listData.filter(
        (subSubCategory: SubSubCategoriesDataState) =>
          subSubCategory.subCategoryId === createForm.value.subCategoryId
      );
    disableSubSubCategorySelect.value = false;
    if (!initial) {
      createForm.value.subSubCategoryId = null;
    }
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('itemsMaster/submit', createForm.value);
  };
  initialize();
</script>
<style lang="scss">
  .item-master-create {
    form {
      width: 650px;
      display: inline-block;
    }
    .el-input-number {
      .el-input {
        float: left;
        width: 130px;
      }
      .el-input__inner {
        text-align: left;
      }
    }
    .el-select {
      width: 330px;
      float: left;
    }
    .el-form-item {
      font-weight: 600 !important;

      span {
        color: #606266;
        font-weight: 100;
      }
    }
    .el-button {
      font-weight: 600;
    }
    .button-area {
      margin: 0px 0px 10px 0px;
    }
  }
</style>
