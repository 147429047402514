<template>
  <BaseSimpleLayout class="inventory-list">
    <template #title>棚卸一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="createRole && status === 1"
        size="large"
        type="success"
        @click="moveToCreate"
      >
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">拠点</span>
        <el-select
          v-model="filterForm.depotName"
          placeholder="検索"
          @change="filterData"
        >
          <el-option
            v-for="data in selectDepots"
            :key="data.id"
            :label="data.name"
            :value="data.name"
          />
        </el-select>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        @row-click="rowClick"
      >
        <el-table-column
          property="depot"
          label="拠点"
          align="center"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ scope.row.depotName }}
          </template>
        </el-table-column>

        <el-table-column
          property="inventoryDate"
          label="棚卸実施日"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ formatDate(Number(scope.row.inventoryDate)) }}
          </template>
        </el-table-column>

        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="140"
          sortable
        >
          <template #default="scope">
            {{ inventoryStatus[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          property="confirmUserName"
          label="確定者"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ scope.row.confirmUserName }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { formatDate } from '@/libs/dateFormat';
  import { InventoryDataState, InventoryFilterForm } from '@/types/inventory';
  import { DepotsDataState } from '@/types/master/depot';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { inventoryStatus } from '@/libs/inventory';

  const store = useStore();
  const tableData = ref<InventoryDataState[]>([]);
  const filterForm = ref<InventoryFilterForm>({
    depotName: null
  });
  const loadingFlag = ref(false);
  const createRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const selectDepots = ref<DepotsDataState[]>([
    {
      id: 0,
      name: '',
      zip: '',
      address: '',
      tel: '',
      fax: ''
    }
  ]);
  const status = ref<number>(0);
  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.state.activation.inputData = null;
    await store.dispatch('depots/fetch'),
      (selectDepots.value = store.state.depots.listData);

    await store.dispatch('inventories/fetch');
    tableData.value = store.state.inventories.listData;

    tableHeight.value = window.innerHeight - 160;
    filterForm.value = {
      depotName: '岐阜'
    };
    filterData();
    loadingFlag.value = true;
    loadingInstance.close();
  };

  const filterData = () => {
    store.dispatch('inventories/setFilterData', filterForm.value);
    if (store.state.inventories.listData.length === 0) {
      status.value = 1;
      return;
    }
    const filteredData = store.state.inventories.listData.filter(
      (data: InventoryDataState) => {
        if (!filterForm.value.depotName) return true;
        return data.depotName.includes(filterForm.value.depotName);
      }
    );
    tableData.value = filteredData;

    const latestData = filteredData.reduce(
      (latest: InventoryDataState, current: InventoryDataState) => {
        return new Date(latest.inventoryDate) < new Date(current.inventoryDate)
          ? current
          : latest;
      },
      filteredData[0]
    );

    if (latestData && latestData.status === 1) {
      status.value = 0;
    } else {
      status.value = 1;
    }
  };

  const rowClick = async (inventoryState: InventoryDataState) => {
    store.commit('inventories/setDetailStatus', inventoryState);
    await store.dispatch('inventories/fetchDetail', {
      depotId: inventoryState.depotId,
      inventoryDate: inventoryState.inventoryDate
    });
    router.push('/inventory/detail');
  };
  const moveToCreate = () => {
    router.push('/inventory/create');
  };
  initialize();
</script>
<style lang="scss">
  .inventory-list {
    .filter-area {
      margin: 20px 0px;

      .label {
        margin: 0 10px;
        font-weight: 600;
        color: #606266;
      }

      .index {
        margin: 0 5px;
      }

      .el-input {
        width: 200px;
      }
    }

    .el-table {
      width: 500px;
      margin: 10px auto;

      thead th {
        background: #f5f7fa;
      }
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
