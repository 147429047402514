<template>
  <BaseSimpleLayout class="finance-master-edit">
    <template #title>ファイナンス編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item label="ID" prop="id">
          <el-input v-model="editForm.id" :disabled="true" />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-edit-name"
          label="ファイナンス名称"
          prop="name"
        >
          <el-input
            v-model="editForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-edit-bc-id"
          label="BCID"
          prop="bcId"
        >
          <el-input
            v-model="editForm.bcId"
            autocomplete="off"
            :disabled="isConfirm"
            @input="idRestriction"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-edit-finance-code"
          label="ファイナンスコード"
          prop="financeCode"
        >
          <el-input
            v-model="editForm.financeCode"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-edit-is-bulk"
          label="バルク"
          prop="isBulk"
        >
          <el-radio-group v-model="editForm.isBulk" :disabled="isConfirm">
            <el-radio :label="true">有効</el-radio>
            <el-radio :label="false">無効</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="update()">
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            class="unit-master-finance-edit-button-detail"
            type="info"
            @click="router.push(`/master/finance/${editForm.id}`)"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-finance-edit-button-confirm"
            type="primary"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { FinancePutDataState } from '@/types/master/finance';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<FinancePutDataState>({
    id: 0,
    name: '',
    bcId: null,
    financeCode: '',
    isBulk: false
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'ファイナンス名称は必須です。' }],
    bcId: [{ required: true, message: 'BCIDは必須です。' }],
    financeCode: [{ required: true, message: 'ファイナンスコードは必須です。' }]
  });
  const isConfirm = ref(false);

  const initialize = async () => {
    if (!store.state.financeMaster.putData) {
      router.push('/master/finance');
    } else {
      editForm.value = store.state.financeMaster.putData;
    }
  };

  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = () => {
    store.dispatch('financeMaster/put', editForm.value);
  };
  const idRestriction = (text: string) => {
    text = text.replace(/[^\d]/g, '');
    if (!text) {
      editForm.value.bcId = null;
      return;
    }
    editForm.value.bcId = parseFloat(text);
  };
  initialize();
</script>
<style lang="scss">
  .finance-master-edit {
    form {
      width: 450px;
      margin: auto;
    }

    .el-select {
      width: 380px;
    }

    .el-form-item {
      font-weight: 600 !important;
    }
    .el-button {
      font-weight: 600;
    }
    .button-area {
      margin: 0px 0px 10px 0px;
    }
  }
</style>
