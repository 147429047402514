/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  ManagementState,
  ManagementListState,
  ManagementDetailData,
  ManagementPutData,
  ManagementReplaceData,
  ManagementFilterData,
  ManagementLinkDeviceData,
  ManagementCopyData
} from '@/types/management';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    putData: null,
    customerId: null,
    replaceData: null,
    filterData: null
  },
  mutations: {
    setList(state: ManagementState, payload: ManagementListState[]) {
      state.listData = payload;
    },
    setDetail(state: ManagementState, payload: ManagementDetailData) {
      state.detailData = payload;
    },
    setPut(state: ManagementState, payload: ManagementPutData) {
      state.putData = payload;
    },
    setCustomerId(state: ManagementState, payload: string) {
      state.customerId = payload;
    },
    setFilter(state: ManagementState, payload: ManagementFilterData) {
      state.filterData = payload;
    },
    setReplace(state: ManagementState, payload: ManagementReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/mgmt/customer', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: ManagementListState, b: ManagementListState) => {
              if (a.customerLedgerId > b.customerLedgerId) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
          const replaceData: ManagementReplaceData = {};
          response.data.forEach((data: ManagementListState) => {
            replaceData[data.customerLedgerId] = data.customerCode;
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, customerId: any) {
      await axios
        .get(getBaseUrl() + '/mgmt/customer/' + customerId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          context.commit('setCustomerId', customerId);
          const putData = {
            vin: response.data.vin,
            mountDate: response.data.mountDate,
            unMountDate: response.data.unMountDate,
            operationCheckDate: response.data.operationCheckDate,
            startBillingDate: response.data.startBillingDate,
            endBillingDate: response.data.endBillingDate,
            returnedCheckDate: response.data.returnedCheckDate,
            usedMonthlyFee: response.data.usedMonthlyFee,
            unUsedMonthlyFee: response.data.unUsedMonthlyFee,
            status: response.data.status,
            note: response.data.note,
            reasonCancelId: response.data.reasonCancelId,
            customerCode: response.data.customerCode,
            financeId: response.data.financeId,
            financeCode: response.data.financeCode
          };
          context.commit('setPut', putData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '顧客管理情報が存在しません。');
            router.push('/management');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/mgmt/customer/' + context.state.customerId,
          context.state.putData,
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '顧客管理情報の更新に成功しました。');
          router.push('/management');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '顧客管理情報が存在しません。');
            router.push('/management');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async copy(context: any, payload: ManagementCopyData) {
      await axios
        .post(
          getBaseUrl() + '/mgmt/customer/duplicate/' + payload.id,
          {
            customerCode: payload.customerCode,
            shopId: payload.shopId,
            note: payload.note
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '顧客情報の複製に成功しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の顧客情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async linkDevice(context: any, payload: ManagementLinkDeviceData) {
      await axios
        .post(
          getBaseUrl() + '/mgmt/customer/link/' + payload.id,
          {
            serialCode: payload.serial
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '端末のリンクに成功しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async unlinkDevice(context: any, customerId: string) {
      await axios
        .post(
          getBaseUrl() + '/mgmt/customer/unlink/' + customerId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '端末のリンク解除に成功しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setPutData(context: any, payload: ManagementPutData) {
      context.commit('setPut', payload);
    },
    async setCustomerIdData(context: any, payload: string) {
      context.commit('setCustomerId', payload);
    },
    async setFilterData(context: any, payload: ManagementFilterData) {
      context.commit('setFilter', payload);
    }
  }
};
