<template>
  <BaseSimpleLayout class="accept-list">
    <template #title>受注一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="moveToCreate"
      >
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-form :inline="true" :model="filterForm">
        <el-form-item label="受注日">
          <span class="index">開始:</span>
          <el-date-picker
            v-model="filterForm.acceptStartDate"
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
          <span class="index">終了:</span>
          <el-date-picker
            v-model="filterForm.acceptEndDate"
            type="date"
            placeholder="選択"
            style="width: 140px; margin: 0px 10px 0px 5px"
            format="YYYY-MM-DD"
            value-format="x"
            @change="filterData"
          />
        </el-form-item>
        <el-form-item label="ステータス">
          <el-select
            v-model="filterForm.status"
            multiple
            style="width: 370px"
            placeholder="選択"
            @change="filterData"
          >
            <el-option
              v-for="status in statusList"
              :key="status"
              :label="status"
              :value="status"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="フィルタ">
          <el-input
            v-model="filterForm.filterText"
            placeholder="検索"
            style="width: 200px"
            @input="filterData"
          />
        </el-form-item>
        <el-form-item label="件数">
          <el-input-number
            class="form-count"
            v-model="filterForm.count"
            :min="1"
            :max="100000"
            :controls="false"
            :precision="0"
            @change="filterData"
          />
        </el-form-item>
        <div class="disp-count">
          USS件数：<span style="font-weight: bold">{{ USSOrderCount }}</span>
          GMS件数：<span style="font-weight: bold">{{ orderCount }}</span>
        </div>
      </el-form>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column
          property="id"
          label="ID"
          align="center"
          width="80"
          sortable
        />
        <el-table-column
          property="type"
          label="種別"
          align="center"
          width="80"
          sortable
        >
          <template #default="scope">
            {{ acceptType[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column
          property="customerCode"
          label="顧客管理番号"
          align="center"
          width="240"
          sortable
        />
        <el-table-column
          property="financeCode"
          label="ファイナンス管理番号"
          align="center"
          width="190"
          sortable
        />
        <el-table-column
          property="vin"
          label="車台番号"
          align="center"
          width="150"
          sortable
        />
        <el-table-column
          property="orderCompanyName"
          label="確定端末"
          align="center"
          sortable
        />
        <el-table-column
          property="shipName"
          label="発送先名"
          align="center"
          width="180"
          sortable
        />
        <el-table-column
          property="shipPrefecture"
          label="発送先"
          align="center"
          width="100"
          sortable
        />
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ acceptStatus(scope.row.status, scope.row.isRemand) }}
          </template>
        </el-table-column>
        <el-table-column
          property="acceptDate"
          label="受注日"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ convertAcceptDate(scope.row.acceptDate) }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { AcceptDataState, AcceptFilterForm } from '@/types/accept';
  import { formatDate } from '@/libs/dateFormat';
  import { acceptStatus, defaultSubmitData, acceptType } from '@/libs/accept';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';

  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const shipAdminRole = ref<boolean>(rolePermission(['SHIPADMIN']));
  const status =
    shipAdminRole.value && !orderRole.value
      ? ['出荷依頼']
      : ['受注', '受注確定', '差し戻し'];
  const statusList = [
    '受注',
    '受注確定',
    '差し戻し',
    '出荷依頼',
    '出荷依頼確定'
  ];
  const store = useStore();
  const tableData = ref<AcceptDataState[]>([]);
  const filterForm = ref<AcceptFilterForm>({
    acceptStartDate: null,
    acceptEndDate: null,
    filterText: null,
    status: status,
    count: 100
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(600);
  let USSOrderCount: number;
  let orderCount: number;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.dispatch('management/setCustomerIdData', null);
    await store.dispatch('accept/fetch');
    store.dispatch('accept/setSubmitData', null);
    store.dispatch('accept/resetAcceptId');
    filterData();
    tableHeight.value = window.innerHeight - 190;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const convertAcceptDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const filterData = () => {
    let count = 0;
    USSOrderCount = 0;
    orderCount = 0;
    store.dispatch('accept/setFilterData', filterForm.value);
    tableData.value = store.state.accept.listData.filter(
      (data: AcceptDataState) => {
        const type = data.type ? acceptType[data.type] : '-';
        const orderCompanyName = data.orderCompanyName ?? '-';
        const shipName = data.shipName ?? '-';
        const customerCode = data.customerCode ?? '-';
        const financeCode = data.financeCode ?? '-';

        if (count >= filterForm.value.count) return false;
        if (
          filterForm.value.acceptStartDate &&
          filterForm.value.acceptStartDate > data.acceptDate
        )
          return false;
        if (
          filterForm.value.acceptEndDate &&
          filterForm.value.acceptEndDate < data.acceptDate
        )
          return false;
        const status = acceptStatus(data.status, data.isRemand);
        if (!filterForm.value.status.includes(status)) return false;
        if (!filterForm.value.filterText) {
          customerCode.substring(0, 3) === 'USS'
            ? ++USSOrderCount
            : ++orderCount;
          count += 1;
          return true;
        }
        if (
          type.includes(filterForm.value.filterText) ||
          orderCompanyName.includes(filterForm.value.filterText) ||
          shipName.includes(filterForm.value.filterText) ||
          (data.vin && data.vin.includes(filterForm.value.filterText)) ||
          customerCode.includes(filterForm.value.filterText) ||
          financeCode.includes(filterForm.value.filterText)
        ) {
          customerCode.substring(0, 3) === 'USS'
            ? ++USSOrderCount
            : ++orderCount;
          count += 1;
          return true;
        }
        return false;
      }
    );
  };
  const rowClick = (row: AcceptDataState) => {
    router.push('/accept/' + row.id);
  };
  const moveToCreate = async () => {
    await Promise.all([
      store.dispatch('accept/setSubmitData', defaultSubmitData),
      store.dispatch('accept/resetAcceptId')
    ]);
    router.push('/accept/create/basic');
  };
  initialize();
</script>
<style lang="scss">
  .accept-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    td {
      cursor: pointer;
    }
    .el-button {
      font-weight: 600;
    }
    .el-form {
      width: 100%;
    }
    .el-form--inline .el-form-item {
      margin-right: 15px;
    }
    .el-form-item {
      text-align: left;
      margin: 5px 0;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .el-checkbox {
      font-weight: 600;
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-table {
      margin: 10px auto 0;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .disp-count {
      text-align: left;
    }
  }
</style>
