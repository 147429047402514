<template>
  <div class="base-select-support-shop">
    <div class="filter-area">
      <el-form ref="filterForm" :model="filterForm">
        <el-form-item
          label="所属組合"
          class="affiliation-filter"
          label-width="75px"
        >
          <el-select
            v-model="filterForm.affiliationUnion"
            filterable
            clearable
            placeholder="選択"
            class="select-union"
            @change="filterShopList()"
          >
            <el-option
              v-for="item in affiliationUnionList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="都道府県"
          class="select-prefecture"
          label-width="75px"
        >
          <el-select
            v-model="filterForm.prefecture"
            filterable
            clearable
            placeholder="選択"
            @change="filterShopList()"
          >
            <el-option
              v-for="item in prefectures"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="店名" class="select-name" label-width="50px">
          <el-select
            v-model="filterForm.supportShopId"
            filterable
            clearable
            placeholder="選択"
            @change="filterShopList()"
          >
            <el-option
              v-for="item in supportShopList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      max-height="288"
      highlight-current-row
      empty-text="都道府県またはサポート店名を選択してください"
      @row-click="rowClick"
    >
      <el-table-column property="id" label="ID" width="150px" align="center" />
      <el-table-column
        property="name"
        label="サポート店名"
        header-align="center"
      />
      <el-table-column
        property="affiliationUnion"
        label="所属組合"
        width="150px"
        align="center"
      />
      <el-table-column
        property="prefecture"
        label="都道府県"
        width="150px"
        align="center"
      />
    </el-table>
    <div class="close-button-area">
      <el-button type="info" size="medium" @click="closeDialog">
        画面を閉じる
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
  import { ref, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import {
    SupportShopsDataState,
    SupportShopsFilterData
  } from '@/types/master/supportShop';
  import { prefectureList } from '@/libs/prefecture';

  export default defineComponent({
    name: 'BaseSelectShop',
    emits: ['emitting', 'close'],
    setup(props, { emit }) {
      const store = useStore();
      const tableData = ref<SupportShopsDataState[]>([]);
      const supportShopList = ref<SupportShopsDataState[]>([]);
      const affiliationUnionList = ref<string[]>([]);
      const filterForm = ref<SupportShopsFilterData>({
        supportShopId: null,
        prefecture: null,
        affiliationUnion: null
      });
      const prefectures = prefectureList;

      const initialize = async () => {
        supportShopList.value = store.state.supportShopsMaster.listData;
        tableData.value = [];
        store.state.supportShopsMaster.listData.forEach(
          (data: SupportShopsDataState) => {
            if (affiliationUnionList.value.indexOf(data.affiliationUnion) < 0) {
              affiliationUnionList.value.push(data.affiliationUnion);
            }
          }
        );
      };
      const filterShopList = () => {
        if (
          !filterForm.value.prefecture &&
          !filterForm.value.supportShopId &&
          !filterForm.value.affiliationUnion
        ) {
          tableData.value = [];
          return;
        }
        tableData.value = store.state.supportShopsMaster.listData.filter(
          (data: SupportShopsDataState) => {
            let prefectureResult = !filterForm.value.prefecture;
            let supportShopResult = !filterForm.value.supportShopId;
            let affiliationUnionResult = !filterForm.value.affiliationUnion;
            if (filterForm.value.prefecture) {
              prefectureResult =
                data.prefecture === filterForm.value.prefecture;
            }
            if (filterForm.value.supportShopId) {
              supportShopResult = data.id === filterForm.value.supportShopId;
            }
            if (filterForm.value.affiliationUnion) {
              affiliationUnionResult =
                data.affiliationUnion === filterForm.value.affiliationUnion;
            }
            return (
              prefectureResult && supportShopResult && affiliationUnionResult
            );
          }
        );
      };
      const rowClick = (val: SupportShopsDataState) => {
        emit('emitting', val.id);
      };
      const closeDialog = () => {
        emit('close');
      };
      initialize();
      return {
        tableData,
        supportShopList,
        affiliationUnionList,
        filterForm,
        prefectures,
        filterShopList,
        rowClick,
        closeDialog
      };
    }
  });
</script>
<style lang="scss">
  .base-select-support-shop {
    .filter-area {
      margin-bottom: 10px;

      .el-form {
        width: 1000px;
        display: flex;
      }

      .select-prefecture {
        width: 150px;
        float: left;
        .el-input {
          width: 125px;
        }
      }

      .el-select {
        float: initial;
      }

      .el-input {
        margin: 0px 10px;
        float: initial;
      }

      .select-union {
        float: left;
        .el-input {
          width: 200px;
        }
      }

      .affiliation-filter {
        width: 330px;
      }

      .select-prefecture {
        width: 250px;
      }

      .select-name {
        width: 300px;
      }
      .close-button-area {
        margin-top: 10px;
        text-align: right;
      }
    }
  }
</style>
