<template>
  <BaseSimpleLayout class="advance-order-input">
    <template #title>先行受注入力</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="inputForm"
        :rules="rules"
        size="large"
        label-width="180px"
      >
        <el-form-item
          label="受注日"
          prop="acceptDate"
          class="advance-order-input-accept-date"
        >
          <el-date-picker
            v-model="inputForm.acceptDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
          />
        </el-form-item>
        <el-form-item
          label="ファイナンス"
          prop="financeId"
          class="advance-order-input-select"
        >
          <el-select
            v-model="inputForm.financeId"
            placeholder="選択"
            filterable
          >
            <el-option
              v-for="finance in financeList"
              :key="finance.id"
              :label="finance.name"
              :value="finance.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="販売店企業"
          prop="companyId"
          class="advance-order-input-select"
        >
          <el-select
            v-model="inputForm.companyId"
            placeholder="選択"
            filterable
          >
            <el-option
              v-for="company in companyList"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="advance-order-input-select"
          label="製品名"
          prop="productId"
        >
          <el-select
            v-model="inputForm.productId"
            placeholder="選択"
            filterable
          >
            <el-option
              v-for="product in productList"
              :key="product.id"
              :label="product.name"
              :value="product.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="advance-order-input-select"
          label="製品単価"
          prop="productPrice"
        >
          <el-input-number
            v-model="inputForm.productPrice"
            placeholder=""
            :min="0"
            :precision="0"
            :controls="false"
          />
        </el-form-item>
        <el-form-item
          class="advance-order-input-select"
          label="個数"
          prop="productQty"
        >
          <el-input-number
            v-model="inputForm.productQty"
            placeholder=""
            :min="1"
            :precision="0"
            :step="1"
          />
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input v-model="inputForm.note" type="textarea" />
        </el-form-item>
        <div class="advance-order-input-button-area">
          <el-button type="info" @click="backPage()">戻る</el-button>
          <el-button type="primary" @click="moveNext(formRef)">確認</el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { AdvanceOrderInputData } from '@/types/advanceOrder';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompaniesDataState } from '@/types/master/company';
  import { ProductsDataState } from '@/types/master/product';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const inputForm = ref<AdvanceOrderInputData>({
    acceptDate: null,
    financeId: null,
    companyId: null,
    productId: null,
    productQty: null,
    productPrice: null,
    note: ''
  });
  const rules = ref<FormRules>({
    acceptDate: [{ required: true, message: '受注日は必須です。' }],
    financeId: [{ required: true, message: 'ファイナンスは必須です。' }],
    companyId: [{ required: true, message: '販売店は必須です。' }],
    productId: [{ required: true, message: '製品名は必須です。' }],
    productQty: [{ required: true, message: '製品数は必須です。' }],
    productPrice: [{ required: true, message: '製品単価は必須です。' }]
  });
  const productList = ref<ProductsDataState[]>([]);
  const financeList = ref<FinancesDataState[]>([]);
  const companyList = ref<CompaniesDataState[]>([]);
  const loadingFlag = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('productsMaster/fetch')
    ]);
    const inputData = store.state.advanceOrder.inputData;
    if (!inputData) {
      return router.push('/advance');
    }
    inputForm.value = {
      acceptDate: inputData.acceptDate,
      financeId: inputData.financeId,
      companyId: inputData.companyId,
      productId: inputData.productId,
      productQty: inputData.productQty,
      productPrice: inputData.productPrice,
      note: inputData.note
    };
    productList.value = store.state.productsMaster.listData.filter(
      (data: ProductsDataState) => {
        return data.productType === 5;
      }
    );
    financeList.value = store.state.financesMaster.listData;
    companyList.value = store.state.companiesMaster.listData.filter(
      (data: CompaniesDataState) => data.isAdvanceOrder
    );
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveNext = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      store.dispatch('advanceOrder/setInputData', inputForm.value);
      router.push('/advance/input/confirm');
    });
  };
  const backPage = () => {
    const advanceOrderId = store.state.advanceOrder.advanceOrderId;
    if (advanceOrderId) {
      router.push('/advance/' + advanceOrderId);
    } else {
      router.push('/advance');
    }
  };
  initialize();
</script>
<style lang="scss">
  .advance-order-input {
    form {
      width: 600px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }

    .advance-order-input-select {
      text-align: left;
      .el-select {
        width: 300px;
      }
    }

    .advance-order-input-button-area {
      margin: 0px 0px 15px 0px;
    }

    .advance-order-input-accept-date {
      .el-input {
        float: left;
      }
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
