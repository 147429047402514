import store from '@/store';

export function rolePermission(roleList: RoleText[] = []): boolean {
  const payload = roleList;
  payload.push('ALL');
  return store.getters['auth/permit'](payload);
}

export function matchPasswordRegex(password: string): boolean {
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9.?/\-_]{8,100}$/;
  return passwordRegex.test(password);
}

export enum Role {
  ORDERING = 1,
  SHIPPING = 2,
  SHIPPING_PLUS = 3,
  TECH = 4,
  VIEW = 5,
  SHIPADMIN = 6,
  ORDERADMIN = 7,
  ALL = 9
}

export type RoleText =
  | 'ORDERING'
  | 'SHIPPING'
  | 'SHIPPING_PLUS'
  | 'TECH'
  | 'VIEW'
  | 'SHIPADMIN'
  | 'ORDERADMIN'
  | 'ALL';
