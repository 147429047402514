/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  ProductState,
  ProductDetailDataState,
  ProductPutDataState
} from '@/types/master/product';
import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: ProductState, payload: ProductDetailDataState) {
      state.detailData = payload;
    },
    setPut(state: ProductState, payload: ProductPutDataState) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, productMasterId: any) {
      await axios
        .get(getBaseUrl() + '/master/products/' + productMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '製品情報が存在しません。');
            router.push('/master/product');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/product');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, productMasterId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/master/products/' + productMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '製品情報の削除に成功しました。');
          router.push('/master/product');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '製品情報が存在しません。');
            router.push('/master/product');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: ProductPutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/products/' + payload.id,
          {
            name: payload.name,
            productCode: payload.productCode,
            jan: payload.jan,
            price: payload.price,
            productType: payload.productType,
            deviceType: payload.deviceType,
            details: payload.details
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '製品情報の変更に成功しました。');
          router.push('/master/product');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '製品情報が存在しません。');
            return router.push('/master/product');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
