<template>
  <div v-if="loadingFlag" class="ship-check-list">
    <div class="sheets">
      <div class="sheet">
        <h2>出荷チェックリスト: {{ shipId }} ({{ today }})</h2>
        <el-table :data="tableData" stripe border>
          <el-table-column label="項目名" prop="label" width="186px" />
          <el-table-column label="データ" prop="value" width="560px" />
          <el-table-column
            label="確認A"
            prop="checkA"
            width="70px"
            align="left"
          />
          <el-table-column
            label="確認B"
            prop="checkB"
            width="70px"
            align="left"
            class-name="last"
          />
        </el-table>
      </div>
      <div class="button-area">
        <el-button
          size="large"
          type="info"
          @click="router.push('/ship/' + shipId)"
        >
          戻る
        </el-button>
        <el-button size="large" type="primary" @click="handlePrint">
          印刷
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import router from '@/router';
  import { useStore } from 'vuex';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';
  import { ignitionType } from '@/libs/accept';
  import { getPrintName } from '@/libs/ship';
  import { ShipDetailDataState } from '@/types/ship';
  import { deviceType, getHarnessByDeviceType } from '@/libs/device';

  const props = defineProps({
    shipId: String
  });
  const store = useStore();
  const tableData = ref<
    {
      label: string;
      value: string;
      checkA: string;
      checkB: string;
    }[]
  >([]);
  const loadingFlag = ref(false);
  const today = ref(formatDate(Date.now()));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('ship/fetchDetail', props.shipId),
      store.dispatch('itemsMaster/fetch'),
      store.dispatch('harnessesMaster/fetch')
    ]);
    const detailData = store.state.ship.detailData;
    if (!detailData) return router.push('/ship');
    let harnessName = '-';
    if (detailData.harnessId) {
      const item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        detailData.harnessId,
        detailData.deviceType
      );
      if (item?.itemName) {
        harnessName = item.itemName;
      }
    } else {
      harnessName = 'キーカット';
    }
    const MCCSver = detailData.deviceType;
    let deviceList = '';
    for (let i = 0; i < detailData.devices.length; i++) {
      deviceList = deviceList + detailData.devices[i].deviceSerialCode;
      if (i === 4) deviceList = deviceList + '\r\n';
      else if (i === detailData.devices.length - 1)
        deviceList = deviceList + '';
      else deviceList = deviceList + ',';
    }

    let packageList = '';
    detailData.details.forEach(
      (pack: ShipDetailDataState) =>
        (packageList = packageList + pack.listName + '\n')
    );

    // const packageList = detailData.details
    //   .filter(
    //     (pack: ShipDetailDataState) =>
    //       pack.itemHandlingType === detailData.deviceHandlingType
    //   )
    //   .map((pack: ShipDetailDataState): string => pack.listName + '\n');
    tableData.value = [
      {
        label: '顧客管理番号',
        value: addTableData(detailData.customerCode),
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'ファイナンス',
        value: addTableData(detailData.financeName),
        checkA: '',
        checkB: ''
      },
      {
        label: '確定端末',
        value: addTableData(detailData.orderCompanyName),
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'シリアル番号',
        value: addTableData(deviceList),
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'メーカー',
        value: addTableData(detailData.carMakerName),
        checkA: '',
        checkB: ''
      },
      {
        label: '車名',
        value: addTableData(detailData.carType),
        checkA: '',
        checkB: ''
      },
      {
        label: '型式',
        value: addTableData(detailData.carModel),
        checkA: '',
        checkB: ''
      },
      {
        label: '車台番号',
        value: addTableData(detailData.vin),
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'E/Gスタート方式',
        value: ignitionType[detailData.ignitionType],
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'MCCSver',
        value: deviceType[MCCSver],
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'パッケージ名称',
        value: packageList,
        checkA: '*',
        checkB: '*'
      },
      {
        label: 'ハーネス名称',
        value: harnessName,
        checkA: '*',
        checkB: '*'
      },
      {
        label: '印刷資料',
        value: getPrintName(harnessName, detailData),
        checkA: '*',
        checkB: '*'
      },
      {
        label: '発送先店名',
        value: detailData.shipName,
        checkA: '*',
        checkB: '*'
      },
      {
        label: '着指定日',
        value: detailData.arrivalDate
          ? formatDate(detailData.arrivalDate)
          : '-',
        checkA: '*',
        checkB: '*'
      },
      {
        label: '受注備考情報',
        value: addTableData(detailData.acceptNote),
        checkA: '',
        checkB: ''
      },
      {
        label: '出荷備考情報',
        value: addTableData(detailData.note),
        checkA: '',
        checkB: ''
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const handlePrint = () => {
    window.print();
  };
  const addTableData = (text: string): string => {
    return text ?? '-';
  };
  initialize();
</script>
<style lang="scss">
  .ship-check-list {
    .sheets {
      .button-area {
        text-align: center;
        margin: 20px 10px;

        .el-button {
          font-weight: 600;
        }
      }

      .el-table {
        width: 889px;
        margin: auto;
        thead {
          color: #606266;
        }
        .cell {
          white-space: pre-wrap !important;
          font-weight: 600;
          font-size: 20px;
        }
      }

      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }

      .el-table--border {
        border-right: 1px solid #606266;
        border-bottom: 1px solid #606266;
      }

      .el-table__row {
        font-weight: 600;
      }

      .el-table td,
      .el-table th.is-leaf {
        border-color: #606266;
      }

      .el-table--border,
      .el-table--group {
        border-color: #606266;
      }

      .last {
        border-right: none;
      }
    }
    @media print {
      .sheets > :not(.sheet) {
        display: none;
      }

      .sheet {
        width: 275mm;
        margin: 10mm;
        margin-top: 50mm;
      }

      .el-table {
        width: 839px;
        margin: auto;
        border: 1px solid #606266;
        .el-table__header {
          font-size: 20px;
        }
        .el-table__row {
          font-size: 18px;
        }
      }
    }

    /* for preview */
    @media screen {
      .sheet {
        width: 275mm;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
</style>
