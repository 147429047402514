<template>
  <BaseSimpleLayout class="supplier-master-detail">
    <template #title>仕入先詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable :table-data="detailTableData" :label-width="150" />
      <el-button
        class="unit-master-supplier-detail-button-list"
        type="info"
        size="large"
        @click="router.push('/master/supplier')"
      >
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/supplier/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        type="danger"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="30%">
        <span><b>この仕入先を削除しますか。</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData } from '@/types/common';
  import { SupplierDetailData } from '@/types/master/supplier';

  const props = defineProps({
    supplierId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('supplierMaster/fetch', props.supplierId);
    const detailData: SupplierDetailData =
      store.state.supplierMaster.detailData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: '仕入先名',
        value: detailData.name
      },
      {
        label: '郵便番号',
        value: detailData.post
      },
      {
        label: '住所',
        value: detailData.address
      },
      {
        label: '電話番号',
        value: detailData.tel
      },
      {
        label: 'FAX',
        value: detailData.fax
      },
      {
        label: '担当者',
        value: detailData.managerName
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const deleteItem = () => {
    store.dispatch('supplierMaster/delete', props.supplierId);
  };
  initialize();
</script>
<style lang="scss">
  .supplier-master-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
