/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  ReceiveState,
  ReceiveListDataElement,
  ReceiveDetailData,
  ReceiveSubmitData,
  ReceiveEditData,
  ReceiveFilterForm
} from '@/types/receive';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    editData: null,
    filterData: null,
    receiveId: null
  },
  mutations: {
    setList(state: ReceiveState, payload: ReceiveListDataElement[]) {
      state.listData = payload;
    },
    setDetail(state: ReceiveState, payload: ReceiveDetailData) {
      state.detailData = payload;
    },
    setFilter(state: ReceiveState, payload: ReceiveFilterForm) {
      state.filterData = payload;
    },
    setEdit(state: ReceiveState, payload: ReceiveEditData) {
      state.editData = payload;
    },
    setId(state: ReceiveState, payload: string) {
      state.receiveId = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/receive', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: ReceiveListDataElement, b: ReceiveListDataElement) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, id: string) {
      await axios
        .get(getBaseUrl() + '/receive/' + id, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          context.commit('setEdit', {
            receiveDate: response.data.receiveDate,
            shipSourceSelectType: response.data.shipSourceSelectType,
            shopId: response.data.shopId,
            supportShopId: response.data.supportShopId,
            shipSourceName: response.data.shipSourceName,
            orderCompanyId: response.data.orderCompanyId,
            subDeviceSerialCode: response.data.subDeviceSerialCode,
            note: response.data.note,
            status: response.data.status,
            accidentType: response.data.accidentType,
            receiveType: response.data.receiveType
          });
          context.commit('setId', id);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の入荷情報が存在しません。');
            router.push('/receive');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: ReceiveSubmitData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/receive', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '入荷登録に成功しました。');
          router.push('/receive');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status, e.response.data);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(
      context: any,
      payload: {
        receiveId: number;
        editData: ReceiveEditData;
      }
    ) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(getBaseUrl() + '/receive/' + payload.receiveId, payload.editData, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '入荷情報更新に成功しました。');
          router.push('/receive');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の入荷情報が存在しません。');
            router.push('/receive');
          } else if (e.response.status < 500) {
            error(e.response.status, e.response.data);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, receiveId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/receive/' + receiveId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '入荷情報の削除に成功しました。');
          router.push('/receive');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の入荷情報が存在しません。');
            router.push('/receive');
          } else if (e.response.status < 500) {
            error(e.response.status, e.response.data);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: ReceiveFilterForm) {
      context.commit('setFilter', payload);
    },
    async setEditData(context: any, payload: ReceiveEditData | null) {
      context.commit('setEdit', payload);
    },
    async setReceiveId(context: any, payload: string | null) {
      context.commit('setId', payload);
    }
  }
};
