<template>
  <BaseSimpleLayout class="accept-create-confirm">
    <template #title>受注入力確認</template>
    <template #button-area>
      <el-button
        size="large"
        type="info"
        @click="router.push('/accept/create/shipment')"
      >
        戻る
      </el-button>
      <el-button size="large" type="primary" @click="submit()">登録</el-button>
    </template>
    <template #main>
      <div class="accept-create-confirm-table-area">
        <div class="accept-create-confirm-table-line">
          <div class="table-title">基本情報</div>
          <BaseDataTable
            :table-data="basicData"
            table-size="small"
            :label-width="110"
          />
        </div>
        <div class="accept-create-confirm-table-line">
          <div class="table-title">発送製品情報</div>
          <el-table
            :data="productData"
            stripe
            border
            style="margin: 0px auto 10px"
            class="accept-create-confirm-product"
            empty-text="製品が存在しません"
            size="small"
          >
            <el-table-column prop="productName" label="製品名" align="center">
              <template #default="scope">
                <span>{{ productNameChanger[scope.row.productId].name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="productPrice"
              label="価格"
              width="70px"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="productQty"
              label="数"
              width="40px"
              align="center"
              class-name="unit-master-product-details-qty"
            />
          </el-table>
          <div v-if="displayCarInfo" class="table-title">取付車両情報</div>
          <BaseDataTable
            v-if="displayCarInfo"
            :table-data="carData"
            table-size="small"
            :label-width="125"
          />
        </div>
        <div class="accept-create-confirm-table-line">
          <div class="table-title">取付先/発送先情報</div>
          <BaseDataTable
            :table-data="shipmentData"
            table-size="small"
            :label-width="120"
          />
        </div>
        <div class="accept-create-confirm-table-line">
          <div class="table-title">その他/備考</div>
          <BaseDataTable
            :table-data="otherData"
            table-size="small"
            :label-width="95"
          />
        </div>
      </div>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createBasicTableData,
    createCarTableData,
    createShipmentTableData,
    depotName
  } from '@/libs/accept';
  import { AcceptDetailsElement, AcceptSubmitData } from '@/types/accept';
  import { DetailTableData } from '@/types/common';
  import { ProductReplaceData } from '@/types/master/product';
  import { formatDate } from '@/libs/dateFormat';
  import { getHarnessByDeviceType } from '@/libs/device';

  const store = useStore();
  const basicData = ref<DetailTableData[]>([]);
  const carData = ref<DetailTableData[]>([]);
  const productData = ref<AcceptDetailsElement[]>([]);
  const shipmentData = ref<DetailTableData[]>([]);
  const otherData = ref<DetailTableData[]>([]);
  const productNameChanger = ref<ProductReplaceData>({});
  const displayCarInfo = ref(false);

  const initialize = () => {
    const submitData = store.state.accept.submitData;
    if (!submitData) {
      return router.push('/accept');
    }
    const carMakerReplaceData = store.state.carMakersMaster.replaceData;
    const supportShopChanger = store.state.supportShopsMaster.replaceData;
    const companyChanger = store.state.companiesMaster.replaceData;
    const finaceChanger = store.state.financesMaster.replaceData;
    basicData.value = createBasicTableData(submitData);
    basicData.value[3].value = finaceChanger[submitData.financeId];
    basicData.value[12].value = submitData.orderCompanyId
      ? companyChanger[submitData.orderCompanyId].name
      : '-';
    setShopData(submitData);
    if (submitData.type === 1 && submitData.isShip) {
      carData.value = createCarTableData(submitData);
      carData.value[2].value = carMakerReplaceData
        ? carMakerReplaceData[submitData.carMakerId]
        : '';
      displayCarInfo.value = true;
    }
    setHarnessInfo(submitData);
    productData.value = submitData.details;
    productNameChanger.value = store.state.productsMaster.replaceData;
    shipmentData.value = createShipmentTableData(submitData);
    if (submitData.supportShopId) {
      shipmentData.value[1].value = supportShopChanger
        ? supportShopChanger[submitData.supportShopId]
        : '';
    } else {
      shipmentData.value[1].value = basicData.value[7].value;
    }
    otherData.value = [
      {
        label: '拠点',
        value: depotName[submitData.depotId]
      },
      {
        label: '着指定日',
        value: submitData.arrivalDate
          ? formatDate(Number(submitData.arrivalDate))
          : '-'
      },
      {
        label: '東京専用\r\n備考',
        value: submitData.note
      },
      {
        label: '発送時連携\r\n用備考',
        value: submitData.shipNote
      }
    ];
  };

  const setShopData = (submitData: AcceptSubmitData) => {
    let shopName = '';
    let companyName = '';
    let companyGroupName = '';
    if (submitData.shopId) {
      shopName = store.state.shopsMaster.replaceData[submitData.shopId].name;
    }
    if (submitData.companyId) {
      companyName =
        store.state.companiesMaster.replaceData[submitData.companyId].name;
    }
    if (submitData.companyGroupId) {
      companyGroupName =
        store.state.companyGroupsMaster.replaceData[submitData.companyGroupId]
          .name;
    }
    basicData.value[5].value = companyGroupName;
    basicData.value[6].value = companyName;
    basicData.value[7].value = shopName;
  };

  const setHarnessInfo = async (submitData: AcceptSubmitData) => {
    let partCode = '-';
    let itemName = '-';
    let item;
    if (!submitData.isHarnessCut && submitData.harnessId) {
      item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        submitData.harnessId,
        submitData.deviceType
      );
    }
    if (item?.itemId) {
      const itemReplaceData = store.state.itemsMaster.replaceData;
      partCode = itemReplaceData[item.itemId].partCode;
      itemName = item.itemName;
    }
    carData.value.push({
      label: 'ハーネス品番',
      value: partCode
    });
    carData.value.push({
      label: 'ハーネス名称',
      value: itemName
    });
  };

  const submit = () => {
    store.dispatch('accept/submit');
  };
  initialize();
</script>
<style lang="scss">
  .accept-create-confirm {
    .base-data-table {
      width: 330px;
      margin: 0px auto;
    }
    .main-container {
      width: 98%;
      margin-top: 5px;
    }
    .table-title {
      font-size: 20px;
    }
    .accept-create-confirm-table-area {
      margin: auto;
      width: 1410px;
      .el-table {
        .cell {
          font-size: 14px;
          font-weight: 600;
        }
        margin-bottom: 15px !important;
      }
    }
    .accept-create-confirm-table-line {
      width: 330px;
      margin-left: 10px;
      float: left;
    }
    .accept-create-confirm-button-area {
      clear: left;
      margin: 20px 0px;
    }
    .accept-create-confirm-product {
      .cell {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .el-button {
      font-weight: 600;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
  }
</style>
