/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error, authError } from '@/libs/notification';
import router from '@/router';
import store from '@/store';
import {
  ActivationState,
  ActivationDeviceData,
  ActivationListData,
  ActivationData
} from '@/types/activation';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { formatTimeStamp } from '@/libs/dateFormat';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    submitData: null,
    detailData: null,
    deviceData: null
  },
  mutations: {
    setList(state: ActivationState, payload: ActivationListData[]) {
      state.listData = payload;
    },
    setSubmit(state: ActivationState, payload: ActivationData) {
      state.submitData = payload;
    },
    setDetail(state: ActivationState, payload: ActivationListData) {
      state.detailData = payload;
    },
    setDevice(state: ActivationState, payload: ActivationDeviceData) {
      state.deviceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/activation', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            store.dispatch('auth/setAuthData', {
              token: '',
              expire: 0,
              role: 0
            });
            authError();
            router.push('/login');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDevice(context: any, customerCode: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/searchMCCS/',
          {
            identity: customerCode
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(async (response) => {
          await context.commit('setDevice', response.data);
          loadingInstance.close();
        })
        .catch((e) => {
          loadingInstance.close();
          if (e.response.status === 400) {
            error('400', '対象端末が存在しません。');
          } else if (e.response.status === 401) {
            handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const data = {
        customerCode: context.state.submitData.customerCode,
        reserveDate: formatTimeStamp(context.state.submitData.reserveDate)
      };
      await axios
        .post(getBaseUrl() + '/kintone', data, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '新規起動制御解除予約を登録しました。');
          router.push('/activation');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setSubmitData(context: any, data: ActivationListData) {
      context.commit('setSubmit', data);
    },
    async setDetailData(context: any, data: ActivationListData) {
      context.commit('setDetail', data);
    },
    async cancel(context: any, activationId: number) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/kintone/' + activationId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '起動制御解除の予約をキャンセルしました。');
          router.push('/activation');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
