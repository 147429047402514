<template>
  <BaseSimpleLayout class="accept-input-basic">
    <template #title>受注入力-基本情報</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="180px"
      >
        <el-form-item label="受注日" prop="acceptDate" class="accept-date">
          <el-date-picker
            v-model="createForm.acceptDate"
            type="date"
            placeholder="日付を選択"
            style="width: 150px"
            format="YYYY-MM-DD"
            value-format="x"
          />
        </el-form-item>
        <el-form-item
          class="basic-select"
          label="ファイナンス"
          prop="financeId"
        >
          <el-select
            v-model="createForm.financeId"
            filterable
            placeholder="選択"
          >
            <el-option
              v-for="finance in financeList"
              :key="finance.id"
              :label="finance.name"
              :value="finance.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="basic-input"
          label="ファイナンス管理番号"
          prop="financeCode"
        >
          <el-input v-model="createForm.financeCode" />
        </el-form-item>
        <el-form-item class="basic-select" label="FC" prop="companyGroupId">
          <el-select
            v-model="createForm.companyGroupId"
            filterable
            placeholder="選択"
            @change="setFC(false)"
          >
            <el-option
              v-for="fc in fcList"
              :key="fc.id"
              :label="fc.name"
              :value="fc.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="basic-select notice-input"
          label="販売店企業"
          prop="companyId"
        >
          <el-select
            v-model="createForm.companyId"
            filterable
            placeholder="選択"
            :disabled="disableCompanySelect"
            @change="setCompany(false)"
          >
            <el-option
              v-for="company in companyList"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            />
          </el-select>
          <span> ※ FC選択後選択可</span>
        </el-form-item>
        <el-form-item
          class="basic-select notice-input"
          label="販売店"
          prop="shopId"
        >
          <el-select
            v-model="createForm.shopId"
            filterable
            placeholder="選択"
            :disabled="disableShopSelect"
            @change="setShop"
          >
            <el-option
              v-for="shop in shopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
          <span> ※ 販売店企業選択後選択可 </span>
        </el-form-item>
        <el-form-item
          class="basic-input notice-input"
          label="販売店担当者"
          prop="shipStaffName"
        >
          <el-input v-model="createForm.shipStaffName" />
        </el-form-item>
        <el-form-item
          class="basic-input notice-input"
          label="顧客管理番号"
          prop="customerCode"
        >
          <el-input
            v-model="createForm.customerCode"
            clearable
            @change="createForm.customerCode = createForm.customerCode.trim()"
          />
          <span> ※ 空白の場合自動で設定されます。</span>
        </el-form-item>
        <el-form-item class="basic-radio" label="伝票区分" prop="type">
          <el-radio v-model="createForm.type" :label="1">通常</el-radio>
          <el-radio v-model="createForm.type" :label="2">返品</el-radio>
        </el-form-item>
        <el-form-item class="basic-radio" label="出荷作業" prop="type">
          <el-radio v-model="createForm.isShip" :label="true">有</el-radio>
          <el-radio v-model="createForm.isShip" :label="false">無</el-radio>
        </el-form-item>
        <el-form-item label="東京専用備考" prop="note">
          <el-input v-model="createForm.note" type="textarea" />
        </el-form-item>
        <el-form-item
          v-if="createForm.isShip"
          label="発送時連携用備考"
          prop="shipNote"
        >
          <el-input v-model="createForm.shipNote" type="textarea" />
        </el-form-item>
        <div class="button-area">
          <el-button type="info" size="large" @click="backPage()">
            戻る
          </el-button>
          <el-button type="primary" size="large" @click="moveNext(formRef)">
            次へ
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { AcceptSubmitBaseData } from '@/types/accept';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompaniesDataState } from '@/types/master/company';
  import {
    AffiliatedCompanyState,
    CompanyGroupsDataState
  } from '@/types/master/companyGroup';
  import { ShopsDataState } from '@/types/master/shop';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<AcceptSubmitBaseData>({
    acceptDate: null,
    shopId: null,
    shipStaffName: '',
    companyId: null,
    companyGroupId: null,
    financeId: null,
    financeCode: '',
    customerCode: '',
    type: 1,
    sfOrderCode: '',
    sfOrderDate: null,
    isShip: true,
    note: '',
    shipNote: ''
  });
  const rules = ref<FormRules>({
    acceptDate: [{ required: true, message: '受注日は必須です。' }],
    companyGroupId: [{ required: true, message: 'FCは必須です。' }],
    companyId: [{ required: true, message: '販売店企業は必須です。' }],
    shopId: [{ required: true, message: '販売店は必須です。' }],
    shipStaffName: [{ required: true, message: '販売店担当者は必須です。' }],
    financeId: [{ required: true, message: 'ファイナンスは必須です。' }]
  });
  const financeList = ref<FinancesDataState[]>([]);
  const fcList = ref<CompanyGroupsDataState[]>([]);
  const companyList = ref<CompaniesDataState[]>([]);
  const shopList = ref<ShopsDataState[]>([]);
  const loadingFlag = ref(false);
  const disableCompanySelect = ref(true);
  const disableShopSelect = ref(true);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.accept.submitData;
    if (!submitData) {
      return router.push('/accept');
    }
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch')
    ]);
    createForm.value = {
      acceptDate: submitData.acceptDate,
      shopId: submitData.shopId,
      shipStaffName: submitData.shipStaffName,
      companyId: submitData.companyId,
      companyGroupId: submitData.companyGroupId,
      financeId: submitData.financeId,
      financeCode: submitData.financeCode,
      customerCode: submitData.customerCode,
      type: submitData.type,
      sfOrderCode: submitData.sfOrderCode,
      sfOrderDate: submitData.sfOrderDate,
      isShip: submitData.isShip,
      shipNote: submitData.shipNote,
      note: submitData.note
    };
    fcList.value = store.state.companyGroupsMaster.listData;
    if (createForm.value.financeId && createForm.value.companyGroupId) {
      await setFC(true);
      setCompany(true);
    }
    financeList.value = store.state.financesMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveNext = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) return false;
      if (!createForm.value.isShip) {
        createForm.value.shipNote = '';
      }
      await store.dispatch('accept/setSubmitPartData', createForm.value);
      router.push('/accept/create/product');
    });
  };

  const setFC = async (initial = false) => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch(
      'companyGroupMaster/fetch',
      createForm.value.companyGroupId
    );
    companyList.value =
      store.state.companyGroupMaster.detailData.affiliatedCompanies.filter(
        (data: AffiliatedCompanyState) => {
          return !data.isAdvanceOrder;
        }
      );
    disableCompanySelect.value = false;
    if (!initial) {
      createForm.value.companyId = null;
      createForm.value.shopId = null;
    }
    loadingInstance.close();
  };

  const setCompany = async (initial = false) => {
    shopList.value = store.state.shopsMaster.listData.filter(
      (shop: ShopsDataState) => shop.companyId === createForm.value.companyId
    );
    disableShopSelect.value = false;
    if (!initial) {
      createForm.value.shopId = null;
    }
  };

  const setShop = () => {
    if (!createForm.value.shopId) return (createForm.value.shipStaffName = '');
    const replaceData = store.state.shopsMaster.replaceData;
    createForm.value.shipStaffName =
      replaceData[createForm.value.shopId].staffName;
  };

  const backPage = () => {
    const acceptId = store.state.accept.acceptId;
    if (acceptId) {
      router.push('/accept/' + acceptId);
    } else {
      router.push('/accept');
    }
  };
  initialize();
</script>
<style lang="scss">
  .accept-input-basic {
    form {
      width: 600px;
      margin: auto;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .basic-select {
      text-align: left;
    }

    .button-area {
      margin: 0px 0px 15px 0px;
    }

    .basic-select {
      .el-select {
        width: 300px;
      }
    }

    .basic-input {
      text-align: left;

      .el-input {
        width: 300px;
      }
    }

    .basic-input-disable {
      text-align: left;

      .el-input {
        width: 300px;
      }
    }

    .notice-input {
      width: 800px;

      span {
        color: #606266;
        font-weight: 100;
      }
    }

    .basic-radio {
      text-align: left;

      .el-form-item__label {
        margin-right: 20px;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        color: #606266;
      }
    }

    .accept-date {
      .el-input {
        float: left;
      }
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
