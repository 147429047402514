<template>
  <BaseSimpleLayout class="inventory-master-create">
    <template #title>棚卸開始</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item label="拠点" prop="depotId">
          <el-select v-model="createForm.depotId" placeholder="拠点を選択">
            <el-option
              v-for="depot in depots"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="unit-activation-create-reserve-date"
          label="棚卸日"
          prop="inventoryDate"
        >
          <el-date-picker
            v-model="createForm.inventoryDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
          />
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            開始
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/inventory')"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';
  import {
    InventorySubmitDataState,
    InventoryDataState
  } from '@/types/inventory';
  import type { DepotsDataState } from '@/types/master/depot';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<InventorySubmitDataState>({
    depotId: null,
    inventoryDate: Date.now()
  });
  const stateValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (store.state.inventories.listData.length === 0) {
      callback();
      return;
    }
    const filteredData = store.state.inventories.listData.filter(
      (data: InventoryDataState) => data.depotId === createForm.value.depotId
    );
    const latestData = filteredData.reduce(
      (latest: InventoryDataState, current: InventoryDataState) => {
        return new Date(latest.inventoryDate) < new Date(current.inventoryDate)
          ? current
          : latest;
      },
      filteredData[0]
    );
    if (latestData && latestData.status === 1) {
      callback(new Error('拠点は棚卸実施中です。'));
    } else {
      callback();
    }
  };
  const rules = ref<FormRules>({
    depotId: [
      { required: true, message: '在庫は必須です。' },
      { validator: stateValidator, trigger: 'blur' }
    ],
    inventoryDate: [{ required: true, message: '棚卸日は必須です。' }]
  });
  const isConfirm = ref(false);
  const depots = ref<DepotsDataState[]>([]);
  const loadingFlag = ref(false);
  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('depots/fetch');
    depots.value = store.state.depots.listData;
    createForm.value.depotId =
      depots.value.find(
        (depot) => depot.name === store.state.inventories.filterData.depotName
      )?.id ?? null;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = async () => {
    store.commit('inventories/setDetailStatus', 1);
    await store.dispatch('inventories/fetchDetail', {
      depotId: createForm.value.depotId,
      inventoryDate: createForm.value.inventoryDate
    });
    router.push('/inventory/detail');
  };
  initialize();
</script>
<style lang="scss">
  .inventory-master-create {
    form {
      width: 450px;
      margin: auto;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-button {
      font-weight: 600;
    }

    .button-area {
      margin: 0px 0px 10px 0px;
    }

    .status-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: 0px;
    }
  }
</style>
