<template>
  <BaseSimpleLayout class="master-shop-list">
    <template #title>販売店一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/shop/create')"
      >
        販売店マスタ登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" width="550" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="販売店企業"
          width="550"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.companyId
                ? companyDataChanger[scope.row.companyId].name
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="shopCode"
          label="販売店コード"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="prefecture"
          label="都道府県"
          align="center"
          sortable
        />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ShopsDataState } from '@/types/master/shop';
  import { CompanyReplaceData } from '@/types/master/company';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<ShopsDataState[]>([]);
  const companyDataChanger = ref<CompanyReplaceData>({});
  const loadingFlag = ref(false);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('companiesMaster/fetch')
    ]);
    companyDataChanger.value = store.state.companiesMaster.replaceData;
    tableData.value = store.state.shopsMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/shop/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.shopsMaster.listData.filter(
      (item: ShopsDataState) => {
        if (!searchText.value) return true;
        if (item.name.includes(searchText.value)) return true;
        if (item.prefecture.includes(searchText.value)) return true;
        if (item.shopCode.includes(searchText.value)) return true;
        const company = companyDataChanger.value[item.companyId].name;
        if (company.includes(searchText.value)) return true;
        else false;
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .master-shop-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px auto 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
