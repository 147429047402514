<template>
  <BaseSimpleLayout class="ship-detail">
    <template #title>出荷情報詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="status !== 3 && !isLinkedDevice && shipRole"
        type="danger"
        size="large"
        @click="displayRemandDialog"
      >
        差し戻し
      </el-button>
      <el-button
        v-if="displayRegisterButton()"
        type="success"
        size="large"
        @click="displayDeviceLinkDialog"
      >
        端末登録
      </el-button>
      <el-button
        v-if="status !== 1 && !isLinkedDevice && type === 3 && shipRole"
        type="success"
        size="large"
        @click="displayBulkLinkDialog"
      >
        端末一括登録
      </el-button>
      <el-button
        v-if="status === 2 && !reachLinkedDevice && type === 3 && shipRole"
        type="success"
        size="large"
        @click="displayDeviceLinkDialog"
      >
        個別端末登録
      </el-button>
      <el-button
        v-if="status === 2 && isLinkedDevice && type !== 2 && shipRole"
        type="warning"
        size="large"
        @click="displayUnLinkDialog"
      >
        リンク解除
      </el-button>
      <el-button
        v-if="status === 2 && isLinkedDevice && type === 3 && shipRole"
        type="warning"
        size="large"
        @click="deviceUnlinkDialog = true"
      >
        個別リンク解除
      </el-button>
      <el-button
        v-if="status === 1 && shipRole"
        type="primary"
        size="large"
        @click="displayReadyDialog"
      >
        準備開始
      </el-button>
      <el-button
        v-if="status === 2 && shipRole && !isLinkedDevice"
        type="warning"
        size="large"
        @click="displayUnreadyDialog"
      >
        準備キャンセル
      </el-button>
      <el-button
        v-if="displayShipComplete() && shipRole"
        type="danger"
        size="large"
        @click="router.push('/ship/check/' + shipId)"
      >
        チェックリスト
      </el-button>
      <el-button
        v-if="status === 2 && (isLinkedDevice || !mccsShip)"
        type="success"
        size="large"
        @click="router.push('/ship/label/' + shipId)"
      >
        ラベル印刷
      </el-button>
      <el-button size="large" @click="router.push('/ship/note/' + shipId)">
        納品書
      </el-button>
      <el-button
        v-if="displayShipComplete() && shipRole"
        type="primary"
        size="large"
        @click="shipCompleteDialog = true"
      >
        作業完了
      </el-button>
      <el-button size="large" type="info" @click="router.push('/ship')">
        戻る
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="ship-detail-table-area">
        <div class="ship-detail-table-line">
          <div class="table-title">受注情報</div>
          <BaseDataTable
            :table-data="acceptData"
            table-size="small"
            :label-width="110"
          />
          <div class="table-title">発送単品情報</div>
          <el-table
            :data="itemData"
            stripe
            border
            style="width: 500px; margin: 0px auto 30px"
            class="ship-detail-product"
            empty-text="製品が存在しません"
            size="small"
          >
            <el-table-column
              prop="name"
              label="製品名"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="qty"
              label="個数"
              width="55px"
              align="center"
              class-name="unit-master-product-details-qty"
            />
          </el-table>
        </div>
        <div v-if="type !== 2" class="ship-detail-table-line">
          <div v-if="type === 1" class="table-title">取付車両情報</div>
          <BaseDataTable
            v-if="type === 1"
            :table-data="carData"
            table-size="small"
            :label-width="120"
          />
          <div class="table-title">端末情報</div>
          <el-table
            v-if="type !== 2 && mccsShip"
            :data="deviceData"
            stripe
            border
            style="width: 500px; margin: 0px auto 30px"
            class="ship-detail-device"
            empty-text="端末が存在しません"
            size="small"
          >
            <el-table-column label="シリアル番号" align="center">
              <template #default="scope">
                <router-link
                  :to="
                    orderCompanyId === 459
                      ? `/carSecurity/device/detail/${scope.row.id}`
                      : `/device/${scope.row.id}/detail`
                  "
                >
                  {{ scope.row.deviceSerialCode }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column prop="simNumber" label="sim番号" align="center" />
            <el-table-column label="種類" align="center" width="50px">
              <template #default="scope">{{
                omitHandlingType[scope.row.handlingType]
              }}</template>
            </el-table-column>
          </el-table>
          <div v-else class="not-mccs-ship">端末出荷無し</div>
        </div>
        <div class="ship-detail-table-line">
          <div class="table-title">発送先情報</div>
          <BaseDataTable
            :table-data="shipmentData"
            table-size="small"
            :label-width="80"
          />
          <div class="table-title">
            出荷備考
            <el-button
              v-if="noteEditDisabled && shipRole"
              class="ship-detail-title-side-button"
              type="info"
              size="small"
              @click="noteEditDisabled = false"
            >
              編集
            </el-button>
            <el-button
              v-if="!noteEditDisabled"
              class="ship-detail-title-side-button"
              type="primary"
              size="small"
              @click="updateNote()"
            >
              更新
            </el-button>
          </div>
          <el-input
            v-model="shipNote"
            type="textarea"
            :rows="7"
            placeholder=""
            :disabled="noteEditDisabled"
          />
        </div>
        <div class="ship-detail-table-line">
          <div class="table-title">受注備考情報</div>
          <BaseDataTable
            :table-data="remarkData"
            table-size="small"
            :label-width="78"
          />
          <div class="table-title">
            送り状番号
            <el-button
              v-if="invoiceEditDisabled && shipRole"
              class="ship-detail-title-side-button"
              type="info"
              size="small"
              @click="invoiceEditDisabled = false"
            >
              編集
            </el-button>
            <el-button
              v-if="!invoiceEditDisabled"
              class="ship-detail-title-side-button"
              type="primary"
              size="small"
              @click="updateInvoice(invoiceFormRef)"
            >
              更新
            </el-button>
            <el-button
              v-if="!invoiceEditDisabled"
              class="ship-detail-title-side-button"
              type="info"
              size="small"
              @click="cancelInvoiceEdit()"
            >
              キャンセル
            </el-button>
          </div>
          <el-form
            ref="invoiceFormRef"
            :model="invoiceForm"
            :rules="invoiceRules"
            label-width="0px"
          >
            <el-form-item label="" prop="invoiceCode">
              <el-input
                v-model="invoiceForm.invoiceCode"
                :disabled="invoiceEditDisabled"
                clearable
              />
            </el-form-item>
          </el-form>
          <div class="table-title">出荷時入力情報</div>
          <BaseDataTable
            :table-data="shipInputData"
            table-size="small"
            :label-width="95"
          />
        </div>
      </div>
      <el-dialog v-model="isDisplayDialog" :title="dialogTitle" width="400px">
        <span>
          <b>{{ dialogMessage }}</b>
        </span>
        <template #footer>
          <el-button size="large" @click="isDisplayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="runOperate">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="shipCompleteDialog"
        title="作業完了登録"
        width="450px"
      >
        <el-form
          ref="completeFormRef"
          :model="completeForm"
          :rules="completeRules"
          label-width="170px"
        >
          <el-form-item label="出荷日" prop="shipDate">
            <el-date-picker
              v-model="completeForm.shipDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px"
              format="YYYY-MM-DD"
              value-format="x"
              :disabled="completeConfirmMode"
            />
          </el-form-item>
          <el-form-item label="着日" prop="receiptDate">
            <el-date-picker
              v-model="completeForm.receiptDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px"
              format="YYYY-MM-DD"
              value-format="x"
              :disabled="completeConfirmMode"
            />
          </el-form-item>
          <el-form-item label="送り状番号" prop="invoiceCode">
            <el-input
              v-model="completeForm.invoiceCode"
              style="width: 140px"
              :disabled="completeConfirmMode"
            />
          </el-form-item>
        </el-form>
        <template v-if="completeConfirmMode" #footer>
          <el-button size="large" @click="completeConfirmMode = false">
            戻る
          </el-button>
          <el-button size="large" type="primary" @click="submitComplete">
            登録
          </el-button>
        </template>
        <template v-else #footer>
          <el-button size="large" @click="shipCompleteDialog = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="completeShip(completeFormRef)"
          >
            確認
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="deviceLinkDialog"
        title="リンク端末登録"
        width="400px"
      >
        <el-form :model="deviceForm" label-width="150px">
          <el-form-item label="端末シリアル番号" prop="shipDate">
            <el-select v-model="deviceForm.id" filterable placeholder="選択">
              <el-option
                v-for="item in deviceList"
                :key="item.id"
                :label="item.serialCode"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="deviceLinkDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="linkDevice()">
            登録
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="deviceUnlinkDialog"
        title="リンク端末解除"
        width="400px"
      >
        <el-form :model="unlinkDeviceForm" label-width="150px">
          <el-form-item label="端末シリアル番号" prop="shipDate">
            <el-select
              v-model="unlinkDeviceForm.id"
              filterable
              placeholder="選択"
            >
              <el-option
                v-for="item in linkDeviceList"
                :key="item.id"
                :label="item.deviceSerialCode"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="deviceUnlinkDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="unlinkDevice()">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { error } from '@/libs/notification';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createAcceptTableData,
    createCarTableData,
    createShipmentTableData,
    createRemarkTableData,
    createShipInputTableData,
    getPrintName
  } from '@/libs/ship';
  import { rolePermission } from '@/libs/auth';
  import {
    ShipDetailDataState,
    ShipShipLinkDeviceDataState,
    ShipAcceptDetailState,
    ShipDetailData
  } from '@/types/ship';
  import { DevicesDataState } from '@/types/device';
  import { getHarnessByDeviceType } from '@/libs/device';
  import { DetailTableData } from '@/types/common';
  import { ElLoading } from 'element-plus';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';
  import { omitHandlingType } from '@/libs/device';

  const props = defineProps({
    shipId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const acceptData = ref<DetailTableData[]>([]);
  const carData = ref<DetailTableData[]>([]);
  const itemData = ref<ShipDetailDataState[]>([]);
  const shipmentData = ref<DetailTableData[]>([]);
  const deviceData = ref<ShipShipLinkDeviceDataState[]>([]);
  const remarkData = ref<DetailTableData[]>([]);
  const shipInputData = ref<DetailTableData[]>([]);
  const status = ref(0);
  const type = ref(1);
  const noteEditDisabled = ref(true);
  const invoiceEditDisabled = ref(true);
  const shipNote = ref('');
  const isLinkedDevice = ref(true);
  const reachLinkedDevice = ref(true);
  const isDisplayDialog = ref(false);
  const dialogMessage = ref('');
  const dialogTitle = ref('');
  let dialogOperate = '';
  const deviceLinkDialog = ref(false);
  const deviceUnlinkDialog = ref(false);
  const shipCompleteDialog = ref(false);

  const validateInvoice: FormItemRule['validator'] = (
    _rule,
    value,
    callback
  ) => {
    const pattern = /^[0-9]{4}-[0-9]{4}-[0-9]{4}$/;
    if (!value) return callback();
    if (pattern.test(value)) {
      callback();
    } else {
      callback(new Error('送り状番号形式で入力してください。'));
    }
  };

  const deviceForm = ref({
    id: 0
  });

  const unlinkDeviceForm = ref({
    id: 0
  });

  const invoiceForm = ref({
    invoiceCode: ''
  });
  const invoiceCode = ref('');
  const invoiceRules = ref<FormRules>({
    invoiceCode: [{ validator: validateInvoice, trigger: 'change' }]
  });
  const invoiceFormRef = ref<FormInstance>();

  const completeForm = ref({
    shipDate: 0,
    receiptDate: 0,
    invoiceCode: ''
  });
  const completeRules = ref<FormRules>({
    shipDate: [{ required: true, message: '出荷日は必須です。' }],
    receiptDate: [{ required: true, message: '着日は必須です。' }],
    invoiceCode: [
      { required: true, message: '送り状番号は必須です。' },
      { validator: validateInvoice, trigger: 'blur' }
    ]
  });
  const completeFormRef = ref<FormInstance>();

  const deviceList = ref<DevicesDataState[]>([]);
  const linkDeviceList = ref<ShipShipLinkDeviceDataState[]>([]);
  let acceptDetails: ShipAcceptDetailState[] = [];
  const loadingFlag = ref(false);
  const shipRole = ref<boolean>(
    rolePermission(['SHIPPING', 'SHIPPING_PLUS', 'SHIPADMIN'])
  );
  const mccsShip = ref(true);
  const completeConfirmMode = ref(false);
  const shipDetail = ref<ShipDetailData>();
  let orderCompanyId: number | null = null;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('ship/fetchDetail', props.shipId),
      store.dispatch('itemsMaster/fetch'),
      store.dispatch('harnessesMaster/fetch')
    ]);
    shipDetail.value = store.state.ship.detailData;
    const detailData = store.state.ship.detailData;
    if (!detailData) return router.push('/ship');
    status.value = detailData.status;
    acceptData.value = createAcceptTableData(detailData);
    acceptDetails = detailData.acceptDetails;
    deviceData.value = detailData.devices;
    itemData.value = detailData.details;
    carData.value = createCarTableData(detailData);
    shipmentData.value = createShipmentTableData(detailData);
    remarkData.value = createRemarkTableData(detailData);
    shipInputData.value = createShipInputTableData(detailData);
    orderCompanyId = detailData.orderCompanyId;
    if (acceptDetails) {
      const mccsData = acceptDetails.filter((detail: ShipAcceptDetailState) => {
        return detail.productType === 1;
      });
      if (mccsData.length === 0) {
        mccsShip.value = false;
      }
    }
    if (detailData.harnessId) {
      let partCode = '-';
      let itemName = '-';
      const item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        detailData.harnessId,
        detailData.deviceType
      );
      if (item?.itemId) {
        const itemReplaceData = store.state.itemsMaster.replaceData;
        partCode = itemReplaceData[item.itemId].partCode;
        itemName = item.itemName;
      }
      const printName = getPrintName(itemName, detailData);
      carData.value.push({
        label: 'ハーネス品番',
        value: partCode
      });
      carData.value.push({
        label: 'ハーネス名称',
        value: itemName
      });
      carData.value.push({
        label: '適合判定',
        value: detailData.compatibilityResultJudge === 2 ? '〇※' : ''
      });
      carData.value.push({
        label: '印刷資料',
        value: printName
      });
    }
    shipNote.value = detailData.note;
    invoiceCode.value = detailData.invoiceCode;
    completeForm.value.invoiceCode = detailData.invoiceCode;
    invoiceForm.value.invoiceCode = detailData.invoiceCode;
    type.value = detailData.type;
    isLinkedDevice.value = detailData.devices.length > 0;
    if (type.value === 3) {
      reachLinkedDevice.value =
        detailData.devices.length === itemData.value[0].qty;
    }
    const today = new Date();
    const tommorow = new Date().setDate(today.getDate() + 1);
    completeForm.value.shipDate = today.getTime();
    if (detailData.arrivalDate) {
      completeForm.value.receiptDate = detailData.arrivalDate;
    } else {
      completeForm.value.receiptDate = tommorow;
    }
    if (status.value === 2 && type.value === 3 && isLinkedDevice.value) {
      linkDeviceList.value = detailData.devices;
      unlinkDeviceForm.value.id = detailData.devices[0].id;
    }
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const displayRegisterButton = () => {
    return (
      status.value === 2 &&
      !isLinkedDevice.value &&
      type.value === 1 &&
      shipRole.value &&
      mccsShip.value
    );
  };
  const displayShipComplete = () => {
    if (status.value !== 2) return false;
    if (type.value === 1 && isLinkedDevice.value) return true;
    if (type.value === 1 && !mccsShip.value) return true;
    if (type.value === 3 && reachLinkedDevice.value) return true;
    return false;
  };
  const displayReadyDialog = () => {
    dialogOperate = 'preparation';
    dialogTitle.value = '作業準備';
    dialogMessage.value = 'ステータスを 準備中 にしますがよろしいですか？';
    isDisplayDialog.value = true;
  };
  const displayUnreadyDialog = () => {
    dialogOperate = 'unpreparation';
    dialogTitle.value = '準備キャンセル';
    dialogMessage.value = 'ステータスを 依頼 に戻しますがよろしいですか？';
    isDisplayDialog.value = true;
  };
  const displayUnLinkDialog = () => {
    dialogOperate = 'unLink';
    dialogTitle.value = 'リンク解除';
    dialogMessage.value = 'リンクされた端末を解除しますがよろしいですか？';
    isDisplayDialog.value = true;
  };
  const displayBulkLinkDialog = async () => {
    await createDeviceList();
    deviceList.value.sort((a: DevicesDataState, b: DevicesDataState) => {
      if (a.serialCode > b.serialCode) {
        return 1;
      } else {
        return -1;
      }
    });
    dialogOperate = 'bulkLink';
    dialogTitle.value = '端末一括登録';
    dialogMessage.value =
      '登録可能な端末を ' +
      deviceList.value[0].serialCode +
      ' から一括で登録しますがよろしいですか？';
    isDisplayDialog.value = true;
  };
  const displayRemandDialog = () => {
    dialogOperate = 'remand';
    dialogTitle.value = '出荷情報差し戻し';
    dialogMessage.value = '出荷情報を差し戻しますがよろしいですか？';
    isDisplayDialog.value = true;
  };
  const runOperate = async () => {
    isDisplayDialog.value = false;
    if (dialogOperate === 'preparation') {
      await store.dispatch('ship/preparation', props.shipId);
      initialize();
    }
    if (dialogOperate === 'unpreparation') {
      await store.dispatch('ship/unpreparation', props.shipId);
      initialize();
    }
    if (dialogOperate === 'unLink') {
      await store.dispatch('ship/unlinkDevice', props.shipId);
      initialize();
    }
    if (dialogOperate === 'bulkLink') {
      await createDeviceList();
      if (itemData.value[0].qty > deviceList.value.length) {
        error(
          'error',
          '端末数が不足しています。対象の確定端末を追加して再度実行してください。'
        );
      } else {
        const targetDevice = deviceList.value.slice(0, itemData.value[0].qty);
        const deviceIdList = targetDevice.map((data) => data.id);
        await store.dispatch('ship/linkDevice', {
          id: props.shipId,
          devices: deviceIdList
        });
        initialize();
      }
    }
    if (dialogOperate === 'remand') {
      await store.dispatch('ship/remand', props.shipId);
    }
  };
  const completeShip = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return;
      }
      completeConfirmMode.value = true;
    });
  };
  const submitComplete = async () => {
    await store.dispatch('ship/complete', {
      id: props.shipId,
      data: completeForm.value
    });
    shipCompleteDialog.value = false;
    completeConfirmMode.value = false;
    initialize();
    router.push('/ship');
  };
  const linkDevice = async () => {
    await store.dispatch('ship/linkDevice', {
      id: props.shipId,
      devices: [deviceForm.value.id]
    });
    deviceLinkDialog.value = false;
    initialize();
  };
  const unlinkDevice = async () => {
    await store.dispatch('device/unlink', unlinkDeviceForm.value.id);
    deviceUnlinkDialog.value = false;
    initialize();
  };
  const updateNote = async () => {
    await store.dispatch('ship/patchNote', {
      id: props.shipId,
      note: shipNote.value
    });
    initialize();
    noteEditDisabled.value = true;
  };
  const updateInvoice = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) return false;
      await store.dispatch('ship/patchInvoice', {
        id: props.shipId,
        invoiceCode: invoiceForm.value.invoiceCode
      });
      initialize();
      invoiceEditDisabled.value = true;
    });
  };
  const cancelInvoiceEdit = async () => {
    invoiceForm.value.invoiceCode = invoiceCode.value;
    invoiceEditDisabled.value = true;
  };
  const displayDeviceLinkDialog = async () => {
    await createDeviceList();
    if (deviceList.value.length < 1) {
      error(
        '404',
        '接続可能な端末が存在しません。端末管理画面で端末を追加してください。'
      );
    } else {
      deviceLinkDialog.value = true;
    }
  };
  const createDeviceList = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('devices/fetchReady');
    deviceList.value = store.state.devices.readyListData.filter(
      (data: DevicesDataState) => {
        return (
          data.orderCompanyId === orderCompanyId &&
          data.deviceType === (shipDetail.value?.deviceType ?? 2)
        );
      }
    );
    if (deviceList.value.length > 0)
      deviceForm.value.id = deviceList.value[0].id;
    loadingInstance.close();
  };
  initialize();
</script>
<style lang="scss">
  .ship-detail {
    .main-container {
      width: 98%;
      margin-top: 5px;
    }
    .table-title {
      font-size: 18px;
      font-weight: 600;
      margin: 5px 0;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .ship-detail-button-area {
      clear: left;
      margin: 20px 0px;
    }
    .ship-detail-product {
      .cell {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .ship-detail-table-area {
      margin: auto;
      width: 1400px;
      .ship-detail-table-line {
        width: 330px;
        margin-left: 10px;
        float: left;
        margin-top: 10px;
      }
      .el-table {
        .cell {
          font-size: 14px;
          font-weight: 600;
        }
        margin-bottom: 15px !important;
      }
      .BaseDataTable {
        width: 330px;
        margin: 0px auto;
      }
    }
    .ship-detail-title-side-button {
      vertical-align: bottom;
    }
    .el-button {
      font-weight: 600;
    }
    .not-mccs-ship {
      font-size: 16px;
      font-weight: 600;
      color: red;
    }
    .el-textarea.is-disabled {
      .el-textarea__inner {
        color: #606266;
        background-color: #fafafa;
      }
    }
    .el-input.is-disabled {
      textarea {
        cursor: default;
      }
    }
    .el-input.is-disabled {
      .el-input__inner {
        color: #606266;
        background-color: #fafafa;
      }
    }
    .el-textarea.is-disabled {
      textarea {
        cursor: default;
      }
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
  }
  .ship-detail-dialog {
    .el-form-item__content {
      text-align: left;
    }
    .el-input {
      width: 150px;
    }
    label {
      font-weight: 600;
    }
    .el-input.is-disabled .el-input__inner {
      color: #606266;
    }
  }
  .complete-dialog {
    .el-dialog {
      margin-left: 220px;
    }
  }
</style>
