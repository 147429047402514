<template>
  <BaseSimpleLayout class="unrecord-create-input">
    <template #title>売上計上登録</template>
    <template v-if="loadingFlag" #main>
      <el-button size="large" type="success" @click="dialogFormVisible = true">
        製品追加
      </el-button>
      <el-table
        :data="createForm.details"
        stripe
        border
        size="small"
        class="unrecord-create-input-details-table"
        empty-text="製品が存在しません"
      >
        <el-table-column prop="productName" label="名称" align="center">
          <template #default="scope">
            {{ productReplaceData[scope.row.productId].name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="productPrice"
          label="価格"
          width="90px"
          align="center"
          class-name="unit-master-product-details-name"
        />
        <el-table-column
          prop="productType"
          label="種別"
          width="120px"
          align="center"
          class-name="unit-master-product-details-qty"
        >
          <template #default="scope">
            <span>{{
              productType[productReplaceData[scope.row.productId].productType]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productQty"
          label="個数"
          width="70px"
          align="center"
        />
        <el-table-column label="操作" width="85px" align="center">
          <template #default="scope">
            <el-button type="danger" @click="deletePackage(scope.$index)">
              削除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="productErrorVisible"
        class="unrecord-create-input-details-error-text"
      >
        製品登録は必須です。
      </div>
      <el-form size="large" :model="createForm" label-width="100px">
        <el-form-item
          label="売上日"
          prop="saleDate"
          class="unrecord-input-accept-date"
        >
          <el-date-picker
            v-model="createForm.saleDate"
            type="date"
            placeholder="日付を選択"
            style="width: 140px"
            format="YYYY-MM-DD"
            value-format="x"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input v-model="createForm.note" type="textarea" />
        </el-form-item>
      </el-form>
      <div class="unrecord-create-input-button-area">
        <el-button type="info" size="large" @click="moveDetail">
          戻る
        </el-button>
        <el-button type="primary" size="large" @click="moveNext">
          確認
        </el-button>
      </div>
      <el-dialog v-model="dialogFormVisible" title="製品登録" width="500px">
        <el-form
          :model="productForm"
          class="unrecord-create-input-details-form"
          size="large"
          label-width="80px"
        >
          <el-form-item
            label="製品名称"
            class="unrecord-create-input-details-form-id"
          >
            <el-select
              v-model="productForm.productId"
              filterable
              placeholder="選択"
              class="unrecord-create-input-details-id"
              @change="setProductInfo()"
            >
              <el-option
                v-for="option in productList"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="製品種別">
            <el-input v-model="productForm.productType" disabled />
          </el-form-item>
          <el-form-item label="製品価格">
            <el-input v-model="productForm.productPrice" />
          </el-form-item>
          <el-form-item label="個数">
            <el-input-number
              v-model="productForm.productQty"
              :precision="0"
              :min="1"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="addProduct">
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { AddProductForm } from '@/types/accept';
  import { UnrecordFormData } from '@/types/unrecord';
  import {
    ProductsDataState,
    ProductReplaceData
  } from '@/types/master/product';
  import { ElLoading } from 'element-plus';
  import { productType } from '@/libs/product';

  const store = useStore();
  const dialogFormVisible = ref(false);
  const productErrorVisible = ref(false);
  const createForm = ref<UnrecordFormData>({
    saleDate: Date.now(),
    note: '',
    details: []
  });
  const productForm = ref<AddProductForm>({
    productId: 0,
    productType: '',
    productQty: 1,
    productPrice: 1
  });
  const productList = ref<ProductsDataState[]>([]);
  const productReplaceData = ref<ProductReplaceData>({});
  const loadingFlag = ref(false);
  let acceptId = 0;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.unrecord.submitData;
    if (!submitData) {
      return router.push('/unrecord');
    }
    await store.dispatch(
      'productsMaster/fetchSelectList',
      submitData.companyGroupId
    );
    acceptId = submitData.acceptId;
    createForm.value = {
      saleDate: submitData.moutDate ? submitData.moutDate : Date.now(),
      note: submitData.note,
      details: submitData.details
    };
    productList.value = store.state.productsMaster.listData;
    productForm.value.productId = productList.value[0]['id'];
    productReplaceData.value = store.state.productsMaster.replaceData;
    setProductInfo();
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const setProductInfo = () => {
    const productId = productForm.value.productId;
    if (!productId) return;
    const productData = productReplaceData.value[productId];
    if (!productData) return;
    productForm.value.productType = productType[productData.productType];
    productForm.value.productPrice = productData.price;
  };

  const addProduct = () => {
    createForm.value.details.push({
      productId: productForm.value.productId,
      productPrice: productForm.value.productPrice,
      productQty: productForm.value.productQty
    });
    dialogFormVisible.value = false;
    productForm.value = {
      productId: productList.value[0]['id'],
      productType: '',
      productPrice: 0,
      productQty: 1
    };
    setProductInfo();
    productErrorVisible.value = false;
  };

  const deletePackage = (index: number) => {
    createForm.value.details.splice(index, 1);
    if (createForm.value.details.length === 0) {
      productErrorVisible.value = true;
    }
  };

  const moveDetail = () => {
    router.push('/unrecord/' + acceptId);
  };

  const moveNext = async () => {
    if (!createForm.value.details.length) {
      productErrorVisible.value = true;
      return false;
    }
    await store.dispatch('unrecord/setUnrecordSubmitData', createForm.value);
    return router.push('/unrecord/create/confirm');
  };
  initialize();
</script>
<style lang="scss">
  .unrecord-create-input {
    form {
      width: 500px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }

    .unrecord-create-input-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 13px;
      line-height: 1;
      padding-top: 10px;
      margin: -33px 0px 10px 0px;
    }

    .unrecord-create-input-details-table {
      margin: 30px auto;
      width: 800px;
      div {
        font-size: 14px;
        font-weight: 600;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }

    .unrecord-create-input-details-form {
      width: 400px;

      .unrecord-create-input-details-form-id {
        .el-input {
          float: left;
          width: 320px;
        }
      }
      .el-input-number {
        float: left;
        .el-input {
          width: 80px;
        }
      }
      .el-input {
        float: left;
        width: 150px;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        color: #606266;
      }
    }

    .unrecord-create-input-button-area {
      margin: 30px 0px;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .unrecord-create-input-select {
      .el-select {
        width: 300px;
        float: left;
      }
      .el-input {
        width: 300px;
        float: left;
      }
    }
    .el-textarea {
      width: 500px;
    }
    .el-input {
      float: left;
    }
  }
</style>
