<template>
  <BaseSimpleLayout class="purchase-edit">
    <template #title>仕入訂正</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="140px"
      >
        <el-form-item label="拠点" prop="depotId">
          <el-select
            v-model="editForm.depotId"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot.id"
              :label="depot.name"
              :value="depot.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="仕入日" prop="purchaseDate">
          <el-date-picker
            v-model="editForm.purchaseDate"
            type="date"
            placeholder="選択"
            format="YYYY-MM-DD"
            value-format="x"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="仕入先" prop="supplierId">
          <el-select
            v-model="editForm.supplierId"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="supplier in supplierList"
              :key="supplier.id"
              :label="`${String(supplier.id).padStart(2, '0')}: ${
                supplier.name
              }`"
              :value="supplier.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="partCode" label="品番">
          <el-input
            v-model="editForm.partCode"
            :disabled="isConfirm"
            @change="changePartCode"
          />
        </el-form-item>
        <el-form-item prop="itemName" label="品名" class="item-name">
          <el-input v-model="editForm.itemName" :disabled="true" />
        </el-form-item>
        <el-form-item prop="lotQty" label="入数">
          <el-input v-model="editForm.lotQty" :disabled="true" />
        </el-form-item>
        <el-form-item prop="cost" label="単価">
          <el-input-number
            v-model="editForm.cost"
            placeholder=""
            :min="0"
            :precision="2"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item prop="purchaseQty" label="仕入数">
          <el-input-number
            v-model="editForm.purchaseQty"
            placeholder=""
            :min="1"
            :precision="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>

        <el-form-item prop="note" label="備考" class="note">
          <el-input v-model="editForm.note" :disabled="isConfirm" />
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="update()">
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push(`/purchase/${purchaseId}`)"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { error } from '@/libs/notification';
  import type { FormInstance, FormRules, FormItemRule } from 'element-plus';
  import { PurchaseEditForm } from '@/types/purchase';
  import { DepotsDataState } from '@/types/master/depot';
  import { SupplierDataState } from '@/types/master/supplier';
  import { ItemsDataState } from '@/types/master/item';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<PurchaseEditForm>({
    depotId: null,
    purchaseDate: null,
    supplierId: null,
    partCode: '',
    itemName: '',
    lotQty: null,
    cost: null,
    purchaseQty: null,
    note: ''
  });
  const existsItemValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      itemList.value.find((item) => item.partCode === editForm.value.partCode)
    ) {
      callback();
    } else {
      callback(new Error('該当する単品がありません。'));
    }
  };
  const rules = ref<FormRules>({
    depotId: [{ required: true, message: '拠点は必須です。' }],
    purchaseDate: [{ required: true, message: '仕入日は必須です。' }],
    supplierId: [{ required: true, message: '仕入先は必須です。' }],
    partCode: [
      { required: true, message: '品番は必須です。' },
      { validator: existsItemValidator, trigger: 'blur' }
    ],
    lotQty: [{ required: true, message: '入数は必須です。' }],
    cost: [{ required: true, message: '単価は必須です。' }],
    purchaseQty: [{ required: true, message: '仕入数は必須です。' }]
  });
  const depotList = ref<DepotsDataState[]>([]);
  const supplierList = ref<SupplierDataState[]>([]);
  const itemList = ref<ItemsDataState[]>([]);
  const itemId = ref<number>(0);
  let purchaseId: number;
  let orderId: number;
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    if (!store.state.purchase.detailData) {
      error('400', '発注一覧から選択してください。');
      router.push('/purchase');
    }
    editForm.value = {
      depotId: store.state.purchase.detailData.depotId,
      purchaseDate: store.state.purchase.detailData.purchaseDate
        ? Number(store.state.purchase.detailData.purchaseDate)
        : null,
      supplierId: store.state.purchase.detailData.supplierId,
      partCode: store.state.purchase.detailData.partCode,
      itemName: store.state.purchase.detailData.itemName,
      lotQty: store.state.purchase.detailData.lotQty,
      cost: store.state.purchase.detailData.cost,
      purchaseQty: store.state.purchase.detailData.purchaseQty,
      note: store.state.purchase.detailData.note
    };
    itemId.value = store.state.purchase.detailData.itemId;
    purchaseId = store.state.purchase.detailData.id;
    orderId = store.state.purchase.detailData.orderId;
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('suppliersMaster/fetch'),
      store.dispatch('itemsMaster/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;
    supplierList.value = store.state.suppliersMaster.listData;
    itemList.value = store.state.itemsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changePartCode = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    editForm.value.itemName = '';
    editForm.value.lotQty = null;
    editForm.value.cost = null;
    itemId.value = 0;
    const item = itemList.value.find(
      (item) => item.partCode === editForm.value.partCode
    );
    if (item) {
      await store.dispatch('itemMaster/fetch', item.id);
      const itemDetail = store.state.itemMaster.detailData;
      if (itemDetail) {
        editForm.value.partCode = itemDetail.partCode;
        editForm.value.itemName = itemDetail.name;
        editForm.value.lotQty = itemDetail.lotQty;
        if (itemDetail.lotQty === 1) {
          editForm.value.cost = itemDetail.baseCost;
        } else if (itemDetail.lotQty > 1) {
          editForm.value.cost = itemDetail.caseCost;
        }
        itemId.value = itemDetail.id;
      }
    }
    loadingInstance.close();
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = async () => {
    const putData = {
      purchaseId: purchaseId,
      orderId: orderId,
      depotId: editForm.value.depotId,
      purchaseDate: editForm.value.purchaseDate?.toString() ?? '',
      supplierId: editForm.value.supplierId,
      itemId: itemId.value,
      cost: editForm.value.cost,
      lotQty: editForm.value.lotQty,
      purchaseQty: editForm.value.purchaseQty,
      note: editForm.value.note
    };
    await store.dispatch('purchase/put', putData);
    router.push('/purchase');
  };
  initialize();
</script>
<style lang="scss">
  .purchase-edit {
    form {
      width: 650px;
      display: inline-block;
    }

    .el-input-number {
      .el-input {
        float: left;
        width: 130px;
      }

      .el-input__inner {
        text-align: left;
      }
    }

    .el-select {
      width: 330px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;

      span {
        color: #606266;
        font-weight: 100;
      }
    }

    .el-button {
      font-weight: 600;
    }

    .button-area {
      margin: 0px 0px 10px 0px;
    }

    .item-name {
      width: 650px;
    }

    .note {
      width: 650px;
    }
  }
</style>
