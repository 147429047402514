<template>
  <BaseSimpleLayout class="management-list">
    <template #title>総合管理表</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="orderRole || viewRole"
        type="success"
        size="large"
        @click="csvDownload"
      >
        CSVダウンロード
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">ファイナンス</span>
        <el-select
          v-model="filterForm.financeName"
          filterable
          clearable
          placeholder="選択"
          @change="filterManagementList"
        >
          <el-option
            v-for="finance in financeList"
            :key="finance.id"
            :label="finance.name"
            :value="finance.name"
          />
        </el-select>
        <span class="label">販売店グループ</span>
        <el-select
          v-model="filterForm.companyGroup"
          filterable
          clearable
          placeholder="選択"
          @change="filterManagementList"
        >
          <el-option
            v-for="companyGroup in companyGroupList"
            :key="companyGroup.id"
            :label="companyGroup.name"
            :value="companyGroup.name"
          />
        </el-select>
        <span class="label">フィルター</span>
        <el-input
          v-model="filterForm.filterText"
          placeholder="検索"
          @change="filterManagementList"
        />
        <span class="label">件数</span>
        <el-input-number
          class="form-count"
          v-model="filterForm.count"
          :min="1"
          :max="10000"
          :controls="false"
          :precision="0"
          @change="filterManagementList"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        size="small"
        :max-height="tableHeight"
        @row-click="rowClick"
      >
        <el-table-column
          prop="customerLedgerId"
          label="ID"
          width="70"
          align="center"
          sortable
        />
        <el-table-column
          property="deviceSerialCode"
          label="シリアル番号"
          align="customerCode"
          width="120"
          sortable
        />
        <el-table-column
          property="customerCode"
          label="顧客管理番号"
          align="customerCode"
          width="160"
          sortable
        />
        <el-table-column property="financeName" label="ファイナンス" sortable />
        <el-table-column
          property="financeCode"
          label="ファイナンス管理番号"
          width="180"
          sortable
        >
          <template #default="scope">
            {{ scope.row.financeCode ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="companyGroupName"
          label="販売店グループ"
          sortable
        />
        <el-table-column property="carType" label="車種" width="150" sortable>
          <template #default="scope">
            {{ scope.row.carType ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column property="vin" label="車台番号" width="150" sortable>
          <template #default="scope">
            {{ scope.row.vin ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="130"
          sortable
        >
          <template #default="scope">
            {{ managementStatus[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { managementStatus } from '@/libs/management';
  import { ElLoading } from 'element-plus';
  import { ManagementListState } from '@/types/management';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompanyGroupsDataState } from '@/types/master/companyGroup';
  import { shipSelectType } from '@/libs/accept';
  import { formatDate, csvFormat } from '@/libs/dateFormat';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<ManagementListState[]>([]);
  const tableHeight = ref(600);
  const filterForm = ref({
    filterText: null,
    financeName: null,
    companyGroup: null,
    count: 100
  });
  const loadingFlag = ref(false);
  const financeList = ref<FinancesDataState[]>([]);
  const companyGroupList = ref<CompanyGroupsDataState[]>([]);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const viewRole = ref<boolean>(rolePermission(['VIEW']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('management/fetch'),
      store.dispatch('financesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch'),
      store.dispatch('reasonCancel/fetch')
    ]);
    store.dispatch('management/setCustomerIdData', null);
    const filterData = store.state.management.filterData;
    if (filterData) {
      filterForm.value = filterData;
    }
    tableData.value = filterManagement();
    tableHeight.value = window.innerHeight - 170;
    financeList.value = store.state.financesMaster.listData;
    companyGroupList.value = store.state.companyGroupsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterManagementList = () => {
    store.dispatch('management/setFilterData', filterForm.value);
    tableData.value = filterManagement();
  };
  const filterManagement = (outPutCsv = false): ManagementListState[] => {
    let count = 0;
    store.dispatch('management/setFilterData', filterForm.value);
    return store.state.management.listData.filter(
      (data: ManagementListState) => {
        if (!outPutCsv && count >= filterForm.value.count) return false;
        if (filterForm.value.financeName) {
          if (data.financeName !== filterForm.value.financeName) return false;
        }
        if (filterForm.value.companyGroup) {
          if (data.companyGroupName !== filterForm.value.companyGroup)
            return false;
        }
        if (!filterForm.value.filterText) {
          count += 1;
          return true;
        }
        const deviceSerialCode = data.deviceSerialCode
          ? data.deviceSerialCode.toString()
          : '-';
        const financeCode = data.financeCode ?? '-';
        const customerCode = data.customerCode ?? '-';
        const carType = data.carType ?? '-';
        const vin = data.vin ?? '-';
        const status = managementStatus[data.status as number];
        if (
          deviceSerialCode.includes(filterForm.value.filterText) ||
          customerCode.includes(filterForm.value.filterText) ||
          financeCode.includes(filterForm.value.filterText) ||
          carType.includes(filterForm.value.filterText) ||
          vin.includes(filterForm.value.filterText) ||
          status.includes(filterForm.value.filterText)
        ) {
          count += 1;
          return true;
        }
        return false;
      }
    );
  };
  const rowClick = (item: ManagementListState) => {
    router.push('/management/' + item.customerLedgerId);
  };
  const convertDate = (date: number | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };
  const createNoteData = (note: string | null) => {
    if (!note) {
      return '';
    }
    const result = note.replace(/"/g, '""');
    return '"' + result + '"';
  };
  const csvDownload = () => {
    const cancelData = store.state.reasonCancel.replaceData;
    const filename = '顧客管理表' + csvFormat(Date.now()) + '.csv';
    const header = [
      '顧客管理ID,端末シリアル番号,SIM番号,顧客管理番号,ファイナンス,ファイナンス管理番号,販売店グループ,販売店企業,販売店,車種,型式,車台番号,ハーネス品番,ハーネス名称,出荷日,発送先種別,発送先名,取付日,解約日（取外日）,動作確認日,請求開始日,請求終了日,使用月額費,未使用月額費,最終請求日,返品確認日,ステータス,解約理由,登録者,更新者,登録日,更新日,取付店種別,取付店名,備考'
    ];
    const baseData = filterManagement(true).map(
      (item: ManagementListState): string => {
        return Object.entries({
          customerLedgerId: item.customerLedgerId,
          deviceSerialCode: item.deviceSerialCode
            ? item.deviceSerialCode.toString()
            : '-',
          simNumber: item.simNumber ?? '-',
          customerCode: item.customerCode,
          financeName: item.financeName,
          financeCode: item.financeCode ?? '-',
          companyGroupName: item.companyGroupName ?? '-',
          companyName: item.companyName ?? '-',
          shopName: item.shopName ?? '-',
          carType: item.carType ?? '-',
          carModel: item.carModel ?? '-',
          vin: item.vin ?? '-',
          harnessCode: item.harnessCode ?? '-',
          harnessName: item.harnessName ?? '-',
          shipDate: convertDate(item.shipDate),
          shipSelectType: item.shipSelectType
            ? shipSelectType[item.shipSelectType]
            : '-',
          shipName: item.shipName,
          mountDate: convertDate(item.mountDate),
          unMountDate: convertDate(item.unMountDate),
          operationCheckDate: convertDate(item.operationCheckDate),
          startBillingDate: convertDate(item.startBillingDate),
          endBillingDate: convertDate(item.endBillingDate),
          usedMonthlyFee: item.usedMonthlyFee,
          unUsedMonthlyFee: item.unUsedMonthlyFee,
          lastRecordDate: convertDate(item.lastRecordDate),
          returnedCheckDate: convertDate(item.returnedCheckDate),
          status: item.status ? managementStatus[Number(item.status)] : '-',
          reasonCancelId: item.reasonCancelId
            ? cancelData[item.reasonCancelId]
            : '-',
          registerUserName: item.registerUserName,
          updateUserName: item.updateUserName,
          createdAt: convertDate(item.createdAt),
          updatedAt: convertDate(item.updatedAt),
          installShopType: item.supportShopId ? 'サポート店' : '販売店',
          installShopName: item.supportShopName
            ? item.supportShopName
            : item.shopName,
          note: createNoteData(item.note)
        })
          .map(([, value]) => value)
          .join(',');
      }
    );
    const data = header.concat(baseData).join('\r\n');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(url);
  };
  initialize();
</script>
<style lang="scss">
  .management-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    td {
      cursor: pointer;
    }
    .filter-area {
      margin: 10px 0px;

      .label {
        margin: 0 10px 0 20px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 250px;
      }

      .form-count {
        width: 70px;
        .el-input {
          width: 70px;
        }
      }
    }
    .el-table {
      margin: 10px auto;
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        font-size: 13px;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
