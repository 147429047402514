/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  AdvanceOrderState,
  AdvanceOrderDataState,
  AdvanceOrderInputData,
  AdvanceOrderDetailData
} from '@/types/advanceOrder';

import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    inputData: null,
    advanceOrderId: ''
  },
  mutations: {
    setList(state: AdvanceOrderState, payload: AdvanceOrderDataState[]) {
      state.listData = payload;
    },
    setInput(state: AdvanceOrderState, payload: AdvanceOrderInputData) {
      state.inputData = payload;
    },
    setDetail(state: AdvanceOrderState, payload: AdvanceOrderDetailData) {
      state.detailData = payload;
    },
    setAdvanceOrderId(state: AdvanceOrderState, payload: string) {
      state.advanceOrderId = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/advance/accept', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, advanceOrderId: any) {
      await axios
        .get(getBaseUrl() + '/advance/accept/' + advanceOrderId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setAdvanceOrderId', advanceOrderId);
          context.commit('setDetail', response.data);
          const inputData = _.cloneDeep(response.data);
          delete inputData.id;
          delete inputData.status;
          delete inputData.productName;
          delete inputData.financeName;
          delete inputData.companyName;
          delete inputData.advanceOrderId;
          delete inputData.advanceOrderPrice;
          context.commit('setInput', inputData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の先行受注が存在しません。');
            router.push('/advance');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      if (context.state.advanceOrderId) {
        await axios
          .put(
            getBaseUrl() + '/advance/accept/' + context.state.advanceOrderId,
            context.state.inputData,
            {
              headers: { auth: await getAccessToken() }
            }
          )
          .then(() => {
            success('Success', '先行受注の更新に成功しました。');
            router.push('/advance');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status == 404) {
              error(e.response.status, '対象の先行受注が存在しません。');
              router.push('/advance');
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      } else {
        await axios
          .post(getBaseUrl() + '/advance/accept', context.state.inputData, {
            headers: { auth: await getAccessToken() }
          })
          .then(() => {
            success('Success', '先行受注の登録に成功しました。');
            router.push('/advance');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      }
    },
    async complete(context: any, advanceOrderId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/advance/accept/confirm/' + advanceOrderId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '先行受注確定処理に成功しました。');
          router.push('/advance');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の先行受注が存在しません。');
            router.push('/advance');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, advanceOrderId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/advance/accept/' + advanceOrderId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '先行受注情報の削除に成功しました。');
          router.push('/advance');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の先行受注が存在しません。');
            router.push('/advance');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    resetAdvanceOrderId(context: any) {
      context.commit('setAdvanceOrderId', '');
    },
    setInputData(context: any, inputData: any) {
      context.commit('setInput', inputData);
    }
  }
};
