/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { SimState, SimActivePayload } from '@/types/sim';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    status: ''
  },
  mutations: {
    setActive(state: SimState, payload: string) {
      state.status = payload;
    }
  },
  actions: {
    async fetchStatus(context: any, deviceId: string) {
      await axios
        .get(getBaseUrl() + '/sim/sb/' + deviceId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setActive', response.data.status);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のsim情報が存在しません。');
            router.push('/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async active(context: any, payload: SimActivePayload) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/sim/sb/' + payload.deviceId,
          {
            status: 1,
            isTimeKeepStart: payload.isTimeKeepStart
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', 'simの開通に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のsimが存在しません。');
            return router.push('/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async stop(context: any, deviceId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/sim/sb/' + deviceId,
          { status: 2 },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', 'simの休止に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のsimが存在しません。');
            router.push('/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
