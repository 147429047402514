<template>
  <BaseSimpleLayout class="move-detail">
    <template #title>移動詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/move')">
        一覧
      </el-button>
      <el-button
        v-if="displayEditButton"
        size="large"
        type="primary"
        @click="router.push('/move/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="displayDeleteMoveButton"
        size="large"
        type="danger"
        @click="displayDeleteMoveDialog = true"
      >
        削除
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <el-dialog
        v-model="displayDeleteMoveDialog"
        class="delete-move-dialog"
        title="移動情報削除"
        width="500px"
      >
        <span>
          <b>この移動情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDeleteMoveDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteMove">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { formatDate } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    moveId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const displayEditButton = ref(false);
  const displayDeleteMoveButton = ref(false);
  const loadingFlag = ref(false);
  const displayDeleteMoveDialog = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('move/fetchDetail', props.moveId);
    const detailData = store.state.move.detailData;
    detailTableData.value = [
      {
        label: '移動日',
        value: detailData.moveDate
          ? formatDate(Number(detailData.moveDate))
          : '-'
      },
      {
        label: '出庫拠点',
        value: detailData.outDepotName
      },
      {
        label: '入庫拠点',
        value: detailData.inDepotName
      },
      {
        label: '品番',
        value: detailData.partCode
      },
      {
        label: '品名',
        value: detailData.itemName
      },
      {
        label: '移動数',
        value: detailData.moveQty
      },
      {
        label: '備考',
        value: detailData.note
      },
      {
        label: '確定',
        value: detailData.isConfirm ? '済' : '-'
      }
    ];
    displayEditButton.value = !detailData.isConfirm;
    displayDeleteMoveButton.value = !detailData.isConfirm;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const deleteMove = async () => {
    store.dispatch('move/delete', props.moveId);
  };
  initialize();
</script>
<style lang="scss">
  .move-detail {
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      width: 500px;
      margin: 40px auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
    .purchase-record-dialog {
      .el-form-item__content {
        text-align: left;
      }
      .el-input {
        width: 150px;
      }
      label {
        font-weight: 600;
      }
    }
  }
</style>
