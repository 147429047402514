/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  SaleState,
  SaleListState,
  SaleCsvBaseState,
  SaleDetailData,
  SaleSubmitData,
  SaleAcceptFormData,
  SaleShipFormData,
  SaleProductsState,
  SaleFilterForm
} from '@/types/sale';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    csvData: [],
    detailData: null,
    submitData: null,
    filterData: null,
    saleId: null
  },
  mutations: {
    setList(state: SaleState, payload: SaleListState[]) {
      state.listData = payload;
    },
    setCsv(state: SaleState, payload: SaleCsvBaseState[]) {
      state.csvData = payload;
    },
    setDetail(state: SaleState, payload: SaleDetailData) {
      state.detailData = payload;
    },
    setSubmit(state: SaleState, payload: SaleSubmitData) {
      state.submitData = payload;
    },
    setAcceptSubmit(state: SaleState, payload: SaleAcceptFormData) {
      if (!state.submitData) return;
      state.submitData.saleDate = payload.saleDate;
      state.submitData.type = payload.type;
      state.submitData.saleType = payload.saleType;
      state.submitData.customerLedgerId = payload.customerLedgerId;
      state.submitData.financeId = payload.financeId;
      state.submitData.companyGroupId = payload.companyGroupId;
      state.submitData.companyId = payload.companyId;
      state.submitData.shopId = payload.shopId;
    },
    setShipSubmit(state: SaleState, payload: SaleShipFormData) {
      if (!state.submitData) return;
      state.submitData.shipSelectType = payload.shipSelectType;
      state.submitData.supportShopId = payload.supportShopId;
      state.submitData.otherShipName = payload.otherShipName;
      state.submitData.note = payload.note;
      state.submitData.details = payload.details;
    },
    setSaleId(state: SaleState, id: string | null) {
      state.saleId = id;
    },
    setFilter(state: SaleState, payload: SaleFilterForm) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/sales', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchCsv(context: any, yearMonth: number) {
      await axios
        .get(getBaseUrl() + '/sales/billing/' + yearMonth, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setCsv', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, id: number) {
      await axios
        .get(getBaseUrl() + '/sales/' + id, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const submitDetails = response.data.details.map(
            (data: SaleProductsState) => {
              return {
                productId: data.productId,
                productQty: data.productQty,
                productPrice: data.productPrice
              };
            }
          );
          context.commit('setSubmit', {
            saleDate: response.data.saleDate,
            type: response.data.type,
            saleType: response.data.saleType,
            customerLedgerId: response.data.customerLedgerId,
            financeId: response.data.financeId,
            companyGroupId: response.data.companyGroupId,
            companyId: response.data.companyId,
            shopId: response.data.shopId,
            shipSelectType: response.data.shipSelectType,
            supportShopId: response.data.supportShopId,
            note: response.data.note,
            otherShipName: response.data.otherShipName,
            details: submitDetails,
            saleDeviceType: response.data.saleDeviceType
          });
          context.commit('setSaleId', id);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の売上情報が存在しません。');
            router.push('/sale');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/accept');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/sales', context.state.submitData, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '新規売上登録に成功しました。');
          router.push('/sale');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/sales/' + context.state.saleId,
          context.state.submitData,
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '売上情報更新に成功しました。');
          router.push('/sale');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の売上情報が存在しません。');
            router.push('/sale');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/sales/' + context.state.saleId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '売上情報の削除に成功しました。');
          router.push('/sale');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の売上情報が存在しません。');
            router.push('/sale');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async cancel(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/sales/cancel/' + context.state.saleId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '売上情報の取り下げに成功しました。');
          router.push('/sale');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の売上情報が存在しません。');
            router.push('/sale');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async record(context: any, month: number) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/sales/monthly',
          {
            yearmonth: month
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'サービス利用計上に成功しました。');
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    resetSubmitData(context: any) {
      context.commit('setSubmit', {
        saleDate: Date.now(),
        type: 1,
        saleType: 1,
        customerLedgerId: null,
        financeId: null,
        companyGroupId: null,
        companyId: null,
        shopId: null,
        shipSelectType: 1,
        supportShopId: null,
        note: '',
        otherShipName: null,
        details: [],
        saleDeviceType: 1
      });
      context.commit('setSaleId', null);
    },
    setAcceptSubmitData(context: any, submitData: SaleAcceptFormData) {
      context.commit('setAcceptSubmit', submitData);
    },
    setShipSubmitData(context: any, submitData: SaleShipFormData) {
      context.commit('setShipSubmit', submitData);
    },
    setFilterData(context: any, filterData: SaleFilterForm) {
      context.commit('setFilter', filterData);
    }
  }
};
