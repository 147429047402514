<template>
  <div>
    <BaseSimpleLayout class="unrecord-create-confirm">
      <template #title>売上計上登録確認</template>
      <template v-if="loadingFlag" #button-area>
        <el-button
          size="large"
          type="info"
          @click="router.push('/unrecord/create/input')"
        >
          戻る
        </el-button>
        <el-button size="large" type="primary" @click="submit">登録</el-button>
      </template>
      <template v-if="loadingFlag" #main>
        <div class="unrecord-create-confirm-table-area">
          <div class="unrecord-create-confirm-table-line">
            <BaseDataTable
              :table-data="leftData"
              :label-width="125"
              table-size="mini"
            />
          </div>
          <div class="unrecord-create-confirm-table-line">
            <BaseDataTable
              :table-data="rightData"
              :label-width="125"
              table-size="mini"
            />
          </div>
        </div>
        <div class="unrecord-create-confirm-details-area">
          <div class="table-title">製品情報</div>
          <el-table
            :data="productData"
            stripe
            border
            class="accept-create-product-details-table"
            empty-text="製品が存在しません"
            size="small"
          >
            <el-table-column
              prop="productName"
              label="名称"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="productPrice"
              label="価格"
              width="80px"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="productType"
              label="種別"
              width="120px"
              align="center"
              class-name="unit-master-product-details-qty"
            >
              <template #default="scope">
                <span>{{ productType[scope.row.productType] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="productQty"
              label="個数"
              width="60px"
              align="center"
              class-name="unit-master-product-details-qty"
            />
          </el-table>
        </div>
      </template>
    </BaseSimpleLayout>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createConfirmTableData,
    createUnrecordRightTableData
  } from '@/libs/sale';
  import {
    UnrecordProductsState,
    UnrecordSubmitProductsState
  } from '@/types/unrecord';
  import { DetailTableData } from '@/types/common';
  import { productType } from '@/libs/product';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const productData = ref<UnrecordProductsState[][]>([]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.unrecord.submitData;
    const productReplaceData = store.state.productsMaster.replaceData;
    if (!submitData) return router.push('/unrecord');
    leftData.value = createConfirmTableData(submitData);
    rightData.value = createUnrecordRightTableData(submitData);
    leftData.value[3].value = submitData.customerLedgerId
      ? store.state.management.replaceData[submitData.customerLedgerId]
      : '-';
    rightData.value[0].value = submitData.financeId
      ? store.state.financesMaster.replaceData[submitData.financeId]
      : '-';
    rightData.value[1].value = submitData.companyGroupId
      ? store.state.companyGroupsMaster.replaceData[submitData.companyGroupId]
          .name
      : '-';
    rightData.value[2].value = submitData.companyId
      ? store.state.companiesMaster.replaceData[submitData.companyId].name
      : '-';
    rightData.value[3].value = submitData.shopId
      ? store.state.shopsMaster.replaceData[submitData.shopId].name
      : '-';
    if (submitData.supportShopId) {
      rightData.value[4].value = submitData.supportShopId
        ? store.state.supportShopsMaster.replaceData[submitData.supportShopId]
        : '-';
    }
    rightData.value.splice(7, 1);
    productData.value = submitData.details.map(
      (data: UnrecordSubmitProductsState) => {
        const product = productReplaceData[data.productId];
        return {
          productId: data.productId,
          productName: product.name,
          productType: product.productType,
          productQty: data.productQty,
          productPrice: data.productPrice
        };
      }
    );
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const submit = () => {
    store.dispatch('unrecord/submit');
  };
  initialize();
</script>
<style lang="scss">
  .unrecord-create-confirm {
    .unrecord-create-confirm-table-area {
      display: inline-block;
      margin-top: 20px;
    }
    .unrecord-create-confirm-table-line {
      width: 500px;
      margin-left: 10px;
      float: left;
    }
    .unrecord-create-confirm-details-area {
      width: 750px;
      margin: 20px auto;
      .table-title {
        font-size: 18px;
        font-weight: 600;
      }
      .el-table {
        font-size: 14px;
        font-weight: 600;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
    h5 {
      font-weight: 600;
    }
  }
</style>
