/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  PurchaseState,
  PurchaseDataState,
  PurchaseSubmitDataState,
  PurchasePutDataState
} from '@/types/purchase';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: []
  },
  mutations: {
    setList(state: PurchaseState, payload: PurchaseDataState[]) {
      state.listData = payload;
    },
    setDetail(state: PurchaseState, payload: PurchaseDataState) {
      state.detailData = payload;
    },
    setPurchaseId(state: PurchaseState, payload: number) {
      state.detailData.id = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/purchases', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: PurchaseSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/purchases', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '仕入情報の登録に成功しました。');
          router.push('/purchase');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, purchaseId: number) {
      await axios
        .get(getBaseUrl() + '/purchases/' + purchaseId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '仕入情報が存在しません。');

            return router.push('/purchase');
          }
          if (e.response.status < 500) {
            error(e.response.status);

            return router.push('/purchase');
          }
          router.push('/error/server');
        });
    },
    async put(context: any, payload: PurchasePutDataState) {
      await axios
        .put(getBaseUrl() + '/purchases/' + payload.purchaseId, payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '仕入情報の更新に成功しました。');
          router.push('/purchase');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '仕入情報が存在しません。');
            return router.push('/purchase');
          }
          if (e.response.status < 500) {
            error(e.response.status);
            return router.push('/purchase');
          }
          router.push('/error/server');
        });
    },
    async delete(context: any, payload: number) {
      await axios
        .delete(getBaseUrl() + '/purchases/' + payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '仕入情報の削除に成功しました。');
          router.push('/purchase');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '仕入情報が存在しません。');
            return router.push('/purchase');
          }
          if (e.response.status < 500) {
            error(e.response.status);
            return router.push('/purchase');
          }
          router.push('/error/server');
        });
    }
  }
};
