<template>
  <div class="base-select-harness">
    <div class="filter-area">
      <span>メーカー:</span>
      <el-select
        v-model="makerId"
        filterable
        clearable
        placeholder="選択"
        class="maker-select"
        @change="filterHarnessList"
      >
        <el-option
          v-for="item in carMakerList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <span>車種:</span>
      <el-input
        v-model="carName"
        clearable
        placeholder="検索"
        class="name-input"
        @input="filterHarnessList"
      />
      <span>型式:</span>
      <el-input
        v-model="carModel"
        clearable
        placeholder="検索"
        class="model-input"
        @input="filterHarnessList"
      />
    </div>
    <el-table
      :data="tableData"
      stripe
      max-height="288"
      highlight-current-row
      empty-text="メーカー、車種、型式いずれかを入力してください"
      @row-click="rowClick"
    >
      <el-table-column property="id" label="ID" align="center" width="100" />
      <el-table-column label="メーカー" align="center">
        <template #default="scope">
          {{
            carMakerReplaceData[
              carInfoReplaceData[scope.row.carInfoId].carMakerId
            ]
          }}
        </template>
      </el-table-column>
      <el-table-column label="車種" header-align="center">
        <template #default="scope">
          {{ carInfoReplaceData[scope.row.carInfoId].name }}
        </template>
      </el-table-column>
      <el-table-column label="型式" align="center">
        <template #default="scope">
          {{ carInfoReplaceData[scope.row.carInfoId].model }}
        </template>
      </el-table-column>
      <el-table-column label="モデル区分" align="center">
        <template #default="scope">
          {{ carInfoReplaceData[scope.row.carInfoId].modelType }}
        </template>
      </el-table-column>
      <el-table-column label="年式" align="center">
        <template #default="scope">
          {{ carInfoReplaceData[scope.row.carInfoId].modelYear }}
        </template>
      </el-table-column>
      <el-table-column label="E/Gスタート方式" align="center">
        <template #default="scope">
          {{ ignType[scope.row.ignitionType] }}
        </template>
      </el-table-column>
      <el-table-column property="matchKinds" label="適合区分" align="center" />
      <el-table-column label="適合判定" align="center">
        <template #default="scope">
          {{ compReplace[scope.row.compatibilityResultJudge] }}
        </template>
      </el-table-column>
      <el-table-column
        property="itemName"
        label="ハーネス名称"
        align="center"
        width="110"
      />
    </el-table>
    <el-dialog
      v-model="dialogVisible"
      title="確認"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false"
      :modal="false"
    >
      <span><b>適合判定が ✖ ですが、よろしいですか？</b></span>
      <template #footer>
        <el-button size="large" @click="dialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="primary" @click="emitHarnessData(true)">
          追加
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
  import { ref, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import {
    CarMakersDataState,
    CarMakerReplaceData
  } from '@/types/master/carMaker';
  import {
    CarInfoListDataState,
    CarInfoReplaceData
  } from '@/types/master/carInfo';
  import { HarnessesDataState, HarnessEmitData } from '@/types/master/harness';
  import { ignitionType, compatibility } from '@/libs/harness';

  export default defineComponent({
    name: 'BaseSelectHarness',
    props: {
      selectMCCS: {
        type: Number,
        required: true
      }
    },
    emits: ['emitting'],
    setup(props, { emit }) {
      const store = useStore();
      const tableData = ref<HarnessesDataState[]>([]);
      const carMakerList = ref<CarMakersDataState[]>([]);
      const carInfoList = ref<CarInfoListDataState[]>([]);
      const carMakerReplaceData = ref<CarMakerReplaceData>({});
      const carInfoReplaceData = ref<CarInfoReplaceData>({});
      const makerId = ref<number | null>(null);
      const carName = ref<string | null>(null);
      const carModel = ref<string | null>(null);
      const dialogVisible = ref(false);
      let emitData: HarnessEmitData | null = null;
      const ignType = ignitionType;
      const compReplace = compatibility;

      const initialize = async () => {
        carMakerList.value = store.state.carMakersMaster.listData;
        carInfoList.value = store.state.carInfoListMaster.listData;
        carMakerReplaceData.value = store.state.carMakersMaster.replaceData;
        carInfoReplaceData.value = store.state.carInfoListMaster.replaceData;
        tableData.value = [];
      };

      const filterHarnessList = () => {
        if (!makerId.value && !carName.value && !carModel.value) {
          return (tableData.value = []);
        }
        tableData.value = store.state.harnessesMaster.listData.filter(
          (data: HarnessesDataState) => {
            let makerResult = !makerId.value;
            let nameResult = !carName.value;
            let modelResult = !carModel.value;
            const deviceTypeResult = data.deviceType === props.selectMCCS;
            if (makerId.value) {
              makerResult =
                carInfoReplaceData.value[data.carInfoId].carMakerId ===
                makerId.value;
            }
            if (carName.value) {
              if (carInfoReplaceData.value[data.carInfoId].name) {
                nameResult = carInfoReplaceData.value[
                  data.carInfoId
                ].name.includes(carName.value);
              }
            }
            if (carModel.value) {
              if (carInfoReplaceData.value[data.carInfoId].model) {
                modelResult = carInfoReplaceData.value[
                  data.carInfoId
                ].model.includes(carModel.value);
              }
            }
            return makerResult && nameResult && modelResult && deviceTypeResult;
          }
        );
      };

      const rowClick = (val: HarnessesDataState) => {
        emitData = {
          harnessId: Number(val.id),
          carInfo: carInfoReplaceData.value[val.carInfoId],
          carMakerId: carInfoReplaceData.value[val.carInfoId].carMakerId,
          ignitionType: val.ignitionType.toString(),
          itemId: val.itemId,
          deviceType: val.deviceType
        };
        if (!val.compatibilityResultJudge) {
          return (dialogVisible.value = true);
        }
        emitHarnessData();
      };

      const emitHarnessData = (isHarnessCut = false) => {
        if (isHarnessCut && emitData) emitData.harnessId = null;
        dialogVisible.value = false;
        emit('emitting', emitData);
      };
      initialize();
      return {
        tableData,
        carMakerList,
        carInfoList,
        carMakerReplaceData,
        carInfoReplaceData,
        makerId,
        carName,
        carModel,
        dialogVisible,
        filterHarnessList,
        rowClick,
        emitHarnessData,
        ignType,
        compReplace
      };
    }
  });
</script>
<style lang="scss">
  .base-select-harness {
    .filter-area {
      margin: auto;
      margin-bottom: 10px;
      width: 1000px;

      span {
        font-weight: 600;
        margin-left: 20px;
      }

      .el-select {
        float: initial;
      }

      .el-input {
        margin: 0px 10px;
      }

      .maker-select {
        .el-input {
          width: 150px;
        }
      }

      .name-input {
        width: 400px;
      }

      .model-input {
        width: 200px;
      }
    }
  }
</style>
