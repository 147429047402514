<template>
  <BaseSimpleLayout class="user-create">
    <template #title>ユーザ登録</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="120px"
      >
        <el-form-item label="ユーザ名" prop="name">
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          ></el-input>
        </el-form-item>
        <el-form-item label="メールアドレス" prop="email">
          <el-input
            v-model="createForm.email"
            autocomplete="off"
            :disabled="isConfirm"
          ></el-input>
        </el-form-item>
        <el-form-item label="権限" prop="role">
          <el-select
            v-model="createForm.role"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="パスワード" prop="password">
          <el-input
            v-model="createForm.password"
            autocomplete="off"
            :disabled="isConfirm"
          ></el-input>
        </el-form-item>
        <div v-if="isConfirm">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit">
            登録
          </el-button>
        </div>
        <div v-else>
          <el-button type="info" size="large" @click="router.push('/user')">
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { UserSubmitDataState } from '@/types/user';

  const store = useStore();
  const createForm = ref<UserSubmitDataState>({
    name: '',
    email: '',
    role: 1,
    password: ''
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'ユーザ名は必須です。' }],
    email: [
      {
        required: true,
        message: 'メールアドレスは必須です。'
      },
      { type: 'email', message: 'メールアドレス形式で入力してください。' }
    ],
    password: [{ required: true, message: 'パスワードは必須です。' }]
  });
  const options = [
    {
      value: 1,
      label: 'ORDERING'
    },
    {
      value: 2,
      label: 'SHIPPING'
    },
    {
      value: 3,
      label: 'SHIPPING_PLUS'
    },
    {
      value: 4,
      label: 'TECH'
    },
    {
      value: 5,
      label: 'VIEW'
    },
    {
      value: 6,
      label: 'SHIPADMIN'
    },
    {
      value: 7,
      label: 'ORDERADMIN'
    },
    {
      value: 9,
      label: 'ALL'
    }
  ];
  const isConfirm = ref(false);
  const formRef = ref<FormInstance>();
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('users/submit', createForm.value);
  };
</script>
<style lang="scss">
  .user-create {
    form {
      width: 400px;
      margin: auto;
    }
    .el-select {
      width: 150px;
      float: left;
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
