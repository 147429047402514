<template>
  <BaseSimpleLayout class="customer-detail">
    <template #title>顧客管理情報詳細</template>
    <template v-if="loadingFlag" #main>
      <div class="customer-detail-table-area">
        <div class="customer-detail-table-line">
          <BaseDataTable
            :table-data="leftData"
            :label-width="120"
            table-size="small"
          />
        </div>
        <div class="customer-detail-table-line management-table-line">
          <BaseDataTable
            :table-data="rightData"
            :label-width="115"
            table-size="small"
          />
        </div>
        <div class="customer-detail-table-line accept-table-line">
          <div class="table-title">受注履歴</div>
          <el-table
            :data="tableData"
            stripe
            border
            empty-text="受注が存在しません"
            size="small"
          >
            <el-table-column prop="id" label="ID" align="center" width="80px">
              <template #default="scope">
                <a href="#" @click="router.push('/accept/' + scope.row.id)">
                  {{ scope.row.id }}
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="acceptDate" label="受注日" align="center">
              <template #default="scope">
                {{ formatDate(Number(scope.row.acceptDate)) }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="ステータス" align="center">
              <template #default="scope">
                {{ acceptStatus(scope.row.status, false) }}
              </template>
            </el-table-column>
          </el-table>
          <div class="table-title">SIM情報</div>
          <el-table
            :data="simData"
            stripe
            border
            empty-text="SIM情報が存在しません"
            size="small"
          >
            <el-table-column prop="label" label="SIM番号" align="center" />
            <el-table-column prop="value" label="ステータス" align="center" />
          </el-table>
          <div class="table-title">その他</div>
          <BaseDataTable
            :table-data="otherData"
            :label-width="80"
            table-size="mini"
          />
          <div class="table-title">SF情報</div>
          <el-table
            :data="sfData"
            style="margin: 0px auto 10px"
            class="accept-detail-sf"
            empty-text="SF情報が存在しません"
            size="small"
          >
            <el-table-column prop="label" label="項目" width="120px" />
            <el-table-column prop="value" label="リンク">
              <template #default="scope">
                <a :href="scope.row.link" target="_blank">{{
                  scope.row.value
                }}</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="customer-detail-button-area">
        <el-button size="large" type="info" @click="router.push('/management')">
          戻る
        </el-button>
        <el-button
          v-if="orderRole"
          size="large"
          type="success"
          @click="router.push('/management/edit')"
        >
          編集
        </el-button>
        <el-button
          v-if="deviceLedgerId"
          size="large"
          type="primary"
          @click="router.push('/device/' + deviceLedgerId + '/detail')"
        >
          端末詳細
        </el-button>
        <el-button
          v-if="orderRole && !deviceLedgerId"
          size="large"
          type="primary"
          @click="deviceLinkDialog = true"
        >
          端末リンク
        </el-button>
        <el-button
          v-if="orderRole && !deviceLedgerId"
          size="large"
          type="warning"
          @click="openCopyDialog"
        >
          顧客情報複製
        </el-button>
        <el-button
          v-if="orderRole && deviceLedgerId && !displayDeviceButton"
          size="large"
          type="warning"
          @click="displayDeviceButton = true"
        >
          端末操作
        </el-button>
        <el-button
          v-if="orderRole && deviceLedgerId && displayDeviceButton"
          size="large"
          type="info"
          @click="displayDeviceButton = false"
        >
          操作非表示
        </el-button>
        <el-button
          v-if="orderRole && deviceLedgerId && displayDeviceButton"
          size="large"
          type="warning"
          @click="displaySimOperate"
        >
          SIM操作
        </el-button>
        <el-button
          v-if="orderRole && deviceLedgerId && displayDeviceButton"
          size="large"
          type="warning"
          @click="tmkeepaintDialogVisible = true"
        >
          TMK
        </el-button>
        <el-button
          v-if="orderRole && deviceLedgerId && displayDeviceButton"
          size="large"
          type="danger"
          @click="dialogVisible = true"
        >
          リンク解除
        </el-button>
      </div>
      <el-dialog
        v-model="deviceLinkDialog"
        title="リンク端末登録"
        width="400px"
      >
        <el-form
          ref="linkFormRef"
          :model="deviceForm"
          :rules="deviceRules"
          size="large"
          label-width="150px"
        >
          <el-form-item label="シリアル番号" prop="serial">
            <el-input
              v-model="deviceForm.serial"
              maxlength="16"
              @change="changeInput"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="deviceLinkDialog = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="linkDevice(linkFormRef)"
          >
            登録
          </el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="dialogVisible" title="端末リンク解除" width="450px">
        <div>
          <b>この案件で使用されている端末リンクを解除します。</b>
        </div>
        <div>※MSPF側の情報削除は別途作業が必要です。</div>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="unlinkDevice">
            解除
          </el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="simDialogVisible" title="SIM操作" width="300px">
        <span>
          <b>{{ dialogMessage }}</b>
        </span>
        <template #footer>
          <el-button size="large" @click="simDialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="simOperate">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="tmkeepaintDialogVisible"
        title="tmkeepaint"
        width="300px"
      >
        <span>
          <b>"tmkeepaint=60/tmkeepaint2=720"をセットします。</b>
        </span>
        <template #footer>
          <el-button size="large" @click="tmkeepaintDialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="mspfCommand">
            実行
          </el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="copyDialog" title="顧客情報複製" width="500px">
        <el-form
          ref="copyFormRef"
          :model="copyForm"
          :rules="copyRules"
          label-width="130px"
          size="large"
          width="350px"
          class="copy-data"
        >
          <el-form-item label="顧客管理番号" prop="customerCode">
            <el-input
              v-model="copyForm.customerCode"
              :disabled="copyFormDisabled"
            />
          </el-form-item>
          <el-form-item label="販売店" prop="shopId">
            <el-select
              v-model="copyForm.shopId"
              filterable
              clearable
              placeholder="選択"
              :disabled="copyFormDisabled"
            >
              <el-option
                v-for="shop in shopList"
                :key="shop.id"
                :label="shop.name"
                :value="shop.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="備考" prop="note">
            <el-input
              v-model="copyForm.note"
              type="textarea"
              :disabled="copyFormDisabled"
            />
          </el-form-item>
        </el-form>
        <template v-if="copyFormDisabled" #footer>
          <el-button size="large" @click="copyFormDisabled = false">
            戻る
          </el-button>
          <el-button size="large" type="primary" @click="copySubmit">
            登録
          </el-button>
        </template>
        <template v-else #footer>
          <el-button size="large" @click="copyDialog = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="copyConfirm(copyFormRef)"
          >
            確認
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import type { FormInstance, FormRules } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createLeftTableData,
    createRightTableData,
    createOtherTableData
  } from '@/libs/management';
  import { acceptStatus } from '@/libs/accept';
  import { formatDate } from '@/libs/dateFormat';
  import { ManagementAcceptTableState } from '@/types/management';
  import { DetailTableData } from '@/types/common';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { ShopsDataState } from '@/types/master/shop';
  import { alphanumericRestriction } from '@/libs/restriction';
  import { validateSerial } from '@/libs/device';

  const props = defineProps({
    customerId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const otherData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const dialogVisible = ref(false);
  const tmkeepaintDialogVisible = ref(false);
  const deviceLedgerId = ref<number | null>(null);
  const tableData = ref<ManagementAcceptTableState[]>([]);
  const dialogMessage = ref('');
  const isActiveSim = ref(false);
  const simDialogVisible = ref(false);
  const simData = ref<DetailTableData[]>([]);
  const displayDeviceButton = ref(false);
  const deviceLinkDialog = ref(false);
  const linkFormRef = ref<FormInstance>();
  const copyFormRef = ref<FormInstance>();
  const deviceForm = ref({
    serial: ''
  });
  const deviceRules = ref<FormRules>({
    serial: [
      { validator: validateSerial, trigger: 'blur' },
      {
        required: true,
        message: 'シリアル番号は必須です。',
        trigger: 'blur'
      }
    ]
  });
  const copyDialog = ref(false);
  const copyForm = ref({
    customerCode: '',
    shopId: null,
    note: ''
  });
  const copyRules = ref<FormRules>({
    customerCode: [{ required: true, message: '顧客管理番号は必須です。' }]
  });
  const shopList = ref<ShopsDataState[]>([]);
  const companyId = ref<number | null>(null);
  const copyFormDisabled = ref(false);
  store.commit('accept/setDetail', null); //acceptのstoreに既に入っている内容を一度リセットする
  const sfData = computed(() => {
    const data: DetailTableData[] = [];
    try {
      const parsedData = JSON.parse(store.state.accept.detailData.sfOrderCode);
      if (parsedData.opportunityId) {
        data.push({
          label: '商談',
          value: parsedData.opportunityId,
          link: `https://gmsjp.lightning.force.com/lightning/r/Opportunity/${parsedData.opportunityId}/view`
        });
      }
      if (parsedData.salesforceId) {
        data.push({
          label: 'MCCS取付',
          value: parsedData.salesforceId,
          link: `https://gmsjp.lightning.force.com/lightning/r/Opportunity/${parsedData.salesforceId}/view`
        });
      }
    } catch (error) {
      // sfOrderCodeがJSON形式ではない場合何もしない
    }
    return data;
  });

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await createData();
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const createData = async () => {
    await Promise.all([
      store.dispatch('management/fetchDetail', props.customerId),
      store.dispatch('reasonCancel/fetch'),
      store.dispatch('shopsMaster/fetch')
    ]);
    const detailData = store.state.management.detailData;
    if (!detailData) return router.push('/management');
    leftData.value = createLeftTableData(detailData);
    rightData.value = createRightTableData(detailData);
    otherData.value = createOtherTableData(detailData);
    if (detailData.reasonCancelId) {
      rightData.value[1].value =
        store.state.reasonCancel.replaceData[detailData.reasonCancelId];
    }
    deviceLedgerId.value = detailData.deviceLedgerId;
    companyId.value = detailData.companyId;
    copyForm.value.shopId = detailData.shopId;
    if (deviceLedgerId.value) {
      await Promise.all([
        store.dispatch('device/fetch', deviceLedgerId.value),
        store.dispatch('sim/fetchStatus', deviceLedgerId.value)
      ]);
      isActiveSim.value = store.state.sim.status === 'ACTIVATED';
      const simNumber = store.state.device.detailData.simNumber;
      let simStatus = '-';
      if (store.state.sim.status === 'ACTIVATED') {
        isActiveSim.value = true;
        simStatus = '開通';
      } else if (store.state.sim.status === 'DEACTIVATED') {
        isActiveSim.value = false;
        simStatus = '休止';
      }
      simData.value = [
        {
          label: simNumber,
          value: simStatus
        }
      ];
    }
    tableData.value = detailData.accept;
    for (const row of detailData.accept) {
      await store.dispatch('accept/tryFetchDetail', row.id);
    }
  };

  const unlinkDevice = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('management/unlinkDevice', props.customerId);
    dialogVisible.value = false;
    await createData();
    loadingInstance.close();
  };

  const displaySimOperate = () => {
    const simtext = isActiveSim.value ? '休止' : '開通';
    dialogMessage.value = 'SIMを ' + simtext + ' しますがよろしいですか？';
    simDialogVisible.value = true;
  };

  const simOperate = async () => {
    if (isActiveSim.value) {
      await store.dispatch('sim/stop', deviceLedgerId.value);
    } else {
      await store.dispatch('sim/active', {
        deviceId: deviceLedgerId.value,
        isTimeKeepStart: true
      });
    }
    simDialogVisible.value = false;
    createData();
  };

  const mspfCommand = async () => {
    await store.dispatch('mspf/tmkeepaint', {
      deviceId: deviceLedgerId.value
    });
    tmkeepaintDialogVisible.value = false;
    createData();
  };

  const changeInput = () => {
    deviceForm.value.serial = alphanumericRestriction(deviceForm.value.serial);
  };

  const linkDevice = async (linkFormRef: FormInstance | undefined) => {
    if (!linkFormRef) return;
    await linkFormRef.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await store.dispatch('management/linkDevice', {
        id: props.customerId,
        serial: deviceForm.value.serial
      });
      deviceLinkDialog.value = false;
      await createData();
      loadingInstance.close();
    });
  };

  const openCopyDialog = () => {
    shopList.value = store.state.shopsMaster.listData.filter(
      (shop: ShopsDataState) => shop.companyId === companyId.value
    );
    copyDialog.value = true;
  };

  const copyConfirm = async (copyFormRef: FormInstance | undefined) => {
    if (!copyFormRef) return;
    await copyFormRef.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      copyFormDisabled.value = true;
    });
  };

  const copySubmit = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    if (!copyForm.value.shopId) copyForm.value.shopId = null;
    await store.dispatch('management/copy', {
      id: props.customerId,
      customerCode: copyForm.value.customerCode,
      shopId: copyForm.value.shopId,
      note: copyForm.value.note
    });
    copyDialog.value = false;
    copyFormDisabled.value = false;
    await createData();
    loadingInstance.close();
  };
  initialize();
</script>
<style lang="scss">
  .customer-detail {
    .main-container {
      width: 95%;
    }
    .table-title {
      font-weight: 600;
    }
    .customer-detail-table-area {
      display: inline-block;
    }
    .customer-detail-table-line {
      width: 450px;
      margin-left: 10px;
      float: left;
    }
    .management-table-line {
      width: 350px;
    }
    .accept-table-line {
      width: 350px;
      .el-table {
        margin-bottom: 20px;
      }
    }
    .customer-detail-button-area {
      margin: 10px 0px 10px;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    td {
      font-size: 14px;
      font-weight: 600;
      a {
        color: #007bff;
        cursor: pointer;
      }
    }
    .el-checkbox {
      margin: 0px 10px;
      font-weight: 600;
    }
    .el-checkbox:last-of-type {
      margin-right: 10px;
    }
    .el-dialog {
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .el-input {
      width: 180px;
    }
    .copy-data {
      .el-input {
        width: 300px;
      }
    }
  }
</style>
