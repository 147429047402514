<template>
  <BaseSimpleLayout class="advance-order-list">
    <template #title>先行受注一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        type="primary"
        size="large"
        @click="router.push('/advance/stock')"
      >
        預かり端末在庫
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="moveToCreate"
      >
        先行受注登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">受注日</span>
        <span class="index">開始:</span>
        <el-date-picker
          v-model="filterForm.acceptStartDate"
          type="date"
          placeholder="選択"
          style="width: 140px"
          format="YYYY-MM-DD"
          value-format="x"
          @change="filterData"
        />
        <span class="index">終了:</span>
        <el-date-picker
          v-model="filterForm.acceptEndDate"
          type="date"
          placeholder="選択"
          style="width: 140px"
          format="YYYY-MM-DD"
          value-format="x"
          @change="filterData"
        />
        <span class="label">フィルター</span>
        <el-input
          v-model="filterForm.filterText"
          placeholder="検索"
          @input="filterData"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @row-click="rowClick"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="70"
          align="center"
          sortable
        />
        <el-table-column
          property="financeName"
          label="ファイナンス"
          align="center"
          sortable
        />
        <el-table-column
          property="companyName"
          label="販売店企業名"
          align="center"
          sortable
        />
        <el-table-column
          property="productName"
          label="製品名"
          align="center"
          sortable
        />
        <el-table-column
          property="productPrice"
          label="製品単価"
          align="center"
          width="110"
          sortable
        />
        <el-table-column
          property="productQty"
          label="個数"
          align="center"
          width="80"
          sortable
        />
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="130"
          sortable
        >
          <template #default="scope">
            {{ scope.row.status === 1 ? '下書き' : '受注確定' }}
          </template>
        </el-table-column>
        <el-table-column
          property="acceptDate"
          label="受注日"
          align="center"
          width="130"
          sortable
        >
          <template #default="scope">
            {{ convertAcceptDate(scope.row.acceptDate) }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { formatDate, addDay } from '@/libs/dateFormat';
  import { defaultInputData } from '@/libs/advanceOrder';
  import {
    AdvanceOrderDataState,
    AdvanceOrderFilterForm
  } from '@/types/advanceOrder';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<AdvanceOrderDataState[]>([]);
  const filterForm = ref<AdvanceOrderFilterForm>({
    acceptStartDate: null,
    acceptEndDate: null,
    filterText: null
  });
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('advanceOrder/fetch');
    tableData.value = store.state.advanceOrder.listData;
    tableHeight.value = window.innerHeight - 180;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const convertAcceptDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const filterData = () => {
    tableData.value = store.state.advanceOrder.listData.filter(
      (data: AdvanceOrderDataState) => {
        let acceptFromResult = !filterForm.value.acceptStartDate;
        let acceptToResult = !filterForm.value.acceptEndDate;
        if (filterForm.value.acceptStartDate && data.acceptDate) {
          acceptFromResult =
            filterForm.value.acceptStartDate <= data.acceptDate;
        }
        if (filterForm.value.acceptEndDate && data.acceptDate) {
          acceptToResult =
            data.acceptDate < addDay(filterForm.value.acceptEndDate, 1);
        }
        if (!acceptFromResult || !acceptToResult) return false;
        const status = data.status === 1 ? '下書き' : '受注確定';
        if (!filterForm.value.filterText) return true;
        return (
          status.includes(filterForm.value.filterText) ||
          data.companyName.includes(filterForm.value.filterText) ||
          data.financeName.includes(filterForm.value.filterText) ||
          data.productName.includes(filterForm.value.filterText) ||
          data.productPrice.toString().includes(filterForm.value.filterText) ||
          data.productQty.toString().includes(filterForm.value.filterText)
        );
      }
    );
  };
  const rowClick = (order: AdvanceOrderDataState) => {
    router.push('/advance/' + order.id);
  };
  const moveToCreate = async () => {
    await Promise.all([
      store.dispatch('advanceOrder/setInputData', defaultInputData),
      store.dispatch('advanceOrder/resetAdvanceOrderId')
    ]);
    router.push('/advance/input');
  };
  initialize();
</script>
<style lang="scss">
  .advance-order-list {
    .main-container {
      width: 95%;
    }
    .filter-area {
      margin: 10px 0px;

      .label {
        margin: 0 10px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 300px;
      }
    }
    .el-table {
      margin: 10px auto;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
