/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  BulkState,
  BulkDataState,
  BulkInputData,
  BulkDetailData
} from '@/types/bulk';
import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    submitData: null,
    detailData: null,
    bulkId: ''
  },
  mutations: {
    setList(state: BulkState, payload: BulkDataState[]) {
      state.listData = payload;
    },
    setSubmit(state: BulkState, payload: BulkInputData) {
      state.submitData = payload;
    },
    setDetail(state: BulkState, payload: BulkDetailData) {
      state.detailData = payload;
    },
    setBulkId(state: BulkState, payload: string) {
      state.bulkId = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/bulk/accept', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, bulkId: any) {
      await axios
        .get(getBaseUrl() + '/bulk/accept/' + bulkId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setBulkId', bulkId);
          context.commit('setDetail', response.data);
          const submitData = _.cloneDeep(response.data);
          delete submitData.id;
          delete submitData.status;
          delete submitData.customerCode;
          delete submitData.productName;
          delete submitData.productPrice;
          delete submitData.financeName;
          context.commit('setSubmit', submitData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のバルク情報が存在しません。');
            router.push('/bulk');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      if (context.state.bulkId) {
        await axios
          .put(
            getBaseUrl() + '/bulk/accept/' + context.state.bulkId,
            context.state.submitData,
            {
              headers: { auth: await getAccessToken() }
            }
          )
          .then(() => {
            success('Success', 'バルク情報の更新に成功しました。');
            router.push('/bulk');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status == 404) {
              error(e.response.status, '対象のバルク情報が存在しません。');
              router.push('/bulk');
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      } else {
        await axios
          .post(getBaseUrl() + '/bulk/accept', context.state.submitData, {
            headers: { auth: await getAccessToken() }
          })
          .then(() => {
            success('Success', 'バルク情報の登録に成功しました。');
            router.push('/bulk');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      }
    },
    async request(context: any, bulkId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/bulk/accept/ship/' + bulkId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '出荷依頼に成功しました。');
          router.push('/bulk');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のバルク情報が存在しません。');
            router.push('/bulk');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, bulkId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/bulk/accept/' + bulkId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'バルク情報の削除に成功しました。');
          router.push('/bulk');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のバルク情報が存在しません。');
            router.push('/bulk');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    resetBulkId(context: any) {
      context.commit('setBulkId', '');
    },
    setSubmitData(context: any, submitData: any) {
      context.commit('setSubmit', submitData);
    }
  }
};
