<template>
  <div class="device-detail">
    <BaseSimpleLayout>
      <template #title>【AUTOBACS】端末詳細</template>
      <template v-if="loadingFlag" #main>
        <div class="device-detail-table-line">
          <BaseDataTable :table-data="leftTableData" :label-width="140" />
        </div>
        <div class="device-detail-table-line">
          <BaseDataTable :table-data="rightTableData" :label-width="120" />
        </div>
        <div class="device-detail-button-area">
          <el-button
            v-if="customerId"
            size="large"
            type="info"
            @click="backCustomerDetail"
          >
            顧客管理情報
          </el-button>
          <el-button
            size="large"
            class="unit-master-support-shop-detail-button-list"
            type="info"
            @click="router.push('/autobacs/device')"
          >
            端末一覧
          </el-button>
          <el-button
            v-if="status === 1 && deleteRole"
            size="large"
            type="primary"
            @click="displayVerifyDialog"
          >
            端末検証
          </el-button>
          <el-button
            v-if="editRole"
            size="large"
            type="success"
            @click="router.push('/autobacs/device/edit')"
          >
            編集
          </el-button>
          <el-button
            v-if="status === 3 && editRole"
            size="large"
            type="primary"
            @click="router.push('/ship/' + shipId)"
          >
            出荷詳細
          </el-button>
          <el-button
            v-if="deleteRole"
            size="large"
            type="warning"
            @click="displaySimOperate"
          >
            SIM操作
          </el-button>
          <el-button
            v-if="status < 3 && deleteRole"
            size="large"
            type="danger"
            @click="stopDialogVisible = true"
          >
            使用不可
          </el-button>
          <el-button
            v-if="status === 2"
            size="large"
            type="info"
            @click="orderEntryDialogVisible = true"
          >
            発送処理
          </el-button>
          <el-button
            v-if="status === 3"
            size="large"
            type="danger"
            @click="router.push('/autobacs/device/qrcode')"
          >
            QR発行
          </el-button>
        </div>
      </template>
    </BaseSimpleLayout>
    <el-dialog v-model="dialogVisible" title="端末検証" width="450px">
      <el-form
        ref="formRef"
        :model="deviceForm"
        :rules="rules"
        size="large"
        label-width="145px"
      >
        <el-form-item label="SIM番号">
          <el-input v-model="deviceForm.simNumber" disabled />
        </el-form-item>
        <el-form-item label="シリアル番号">
          <el-input v-model="deviceForm.serialCode" disabled />
        </el-form-item>
        <el-form-item label="出荷ファームVer" prop="firmVersion">
          <el-input v-model="deviceForm.firmVersion" />
        </el-form-item>
        <el-form-item label="IBバッテリー" prop="internalBatteryVoltage">
          <el-input v-model="deviceForm.internalBatteryVoltage" />
        </el-form-item>
        <el-form-item label="Mboot" prop="mboot">
          <el-input v-model="deviceForm.mboot" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="large" @click="dialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="primary" @click="verifyDevice(formRef)">
          登録
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="stopDialogVisible" title="確認" width="400px">
      <span>
        <b>この端末を使用不可にしますか。</b>
      </span>
      <template #footer>
        <el-button size="large" @click="stopDialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="danger" @click="stopDevice">
          実行
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="displaySimDialog" title="SIM操作" width="300px">
      <span>
        <b>{{ dialogMessage }}</b>
      </span>
      <template #footer>
        <el-button size="large" @click="displaySimDialog = false">
          キャンセル
        </el-button>
        <el-button size="large" type="primary" @click="simOperate">
          実行
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="orderEntryDialogVisible" title="発注登録" width="300px">
      <el-form
        ref="orderEntryFormRef"
        :model="orderEntryForm"
        :rules="orderEntryRules"
        size="large"
        label-width="100px"
        class="device-detail-sim-form"
      >
        <el-form-item label="注文番号" prop="autobacsOrderNumber">
          <el-input v-model="orderEntryForm.autobacsOrderNumber" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="large" @click="orderEntryDialogVisible = false">
          キャンセル
        </el-button>
        <el-button
          size="large"
          type="primary"
          @click="orderEntry(orderEntryFormRef)"
        >
          登録
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="QRDialogVisible" title="確認" width="400px">
      <span>
        <b>この端末のQRコードを発行しますか。</b>
      </span>
      <template #footer>
        <el-button size="large" @click="QRDialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="danger" @click="QRIssue">
          実行
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref, toRefs, watch } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import type { FormInstance, FormRules } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { formatDateTime } from '@/libs/dateFormat';
  import { deviceStatus, deviceType, handlingType } from '@/libs/device';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    deviceId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const formRef = ref<FormInstance>();
  const orderEntryFormRef = ref<FormInstance>();
  const leftTableData = ref<DetailTableData[]>([]);
  const rightTableData = ref<DetailTableData[]>([]);
  const dialogVisible = ref(false);
  const stopDialogVisible = ref(false);
  const QRDialogVisible = ref(false);
  const orderEntryDialogVisible = ref(false);
  const deviceForm = ref({
    simNumber: 0,
    serialCode: 0,
    firmVersion: '',
    internalBatteryVoltage: '',
    mboot: ''
  });
  const orderEntryForm = ref({
    autobacsOrderNumber: ''
  });
  const rules = ref<FormRules>({
    firmVersion: [{ required: true, message: '出荷ファームVerは必須です。' }],
    internalBatteryVoltage: [
      { required: true, message: 'IBバッテリーは必須です。' }
    ],
    mboot: [{ required: true, message: 'Mbootは必須です。' }]
  });
  const orderEntryRules = ref<FormRules>({
    autobacsOrderNumber: { required: true, message: '発注番号は必須です。' }
  });
  const status = ref(0);
  const shipId = ref(0);
  const loadingFlag = ref(false);
  const dialogMessage = ref('');
  const displaySimDialog = ref(false);
  const isActiveSim = ref(false);
  const editRole = ref<boolean>(
    rolePermission(['SHIPPING_PLUS', 'SHIPADMIN', 'ORDERADMIN'])
  );
  const deleteRole = ref<boolean>(
    rolePermission(['SHIPPING_PLUS', 'SHIPADMIN'])
  );
  const customerId = ref<string | null>(null);
  const { deviceId } = toRefs(props);

  watch(deviceId, () => {
    createDetailData();
  });

  const createDetailData = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('autobacsDevice/fetch', deviceId.value),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('sim/fetchStatus', deviceId.value)
    ]);
    const detailData = store.state.autobacsDevice.detailData;
    leftTableData.value = [
      {
        label: 'ステータス',
        value: deviceStatus[detailData.status]
      },
      {
        label: 'SIMステータス',
        value: store.state.sim.status === 'ACTIVATED' ? '開通' : '休止'
      },
      {
        label: 'シリアル番号',
        value: detailData.serialCode
      },
      {
        label: '製造番号',
        value: detailData.deviceCode ? detailData.deviceCode : '-'
      },
      {
        label: '端末タイプ',
        value: deviceType[detailData.deviceType]
      },
      {
        label: 'SIM番号',
        value: detailData.simNumber
      },
      {
        label: 'IMSI',
        value: detailData.simImsi
      },
      {
        label: '出荷ファームVer',
        value: detailData.firmVersion
      },
      {
        label: 'IBバッテリー',
        value: detailData.internalBatteryVoltage
      },
      {
        label: 'Mboot',
        value: detailData.mboot
      },
      {
        label: '設定ID',
        value: detailData.deviceSettingUser
      },
      {
        label: '設定パスワード',
        value: detailData.deviceSettingPassword
      }
    ];
    rightTableData.value = [
      {
        label: 'MSPF登録ID',
        value: detailData.deviceId ? detailData.deviceId : '-'
      },
      {
        label: '取扱',
        value: handlingType[detailData.handlingType]
      },
      {
        label: '確定端末',
        value: detailData.orderCompanyName ? detailData.orderCompanyName : '-'
      },
      {
        label: '発注番号',
        value: detailData.autobacsOrderNumber
          ? detailData.autobacsOrderNumber
          : '-'
      },
      {
        label: '備考',
        value: detailData.note
      },
      {
        label: '更新者',
        value: detailData.updateUserName
      },
      {
        label: '更新日時',
        value: formatDateTime(Number(detailData.updatedAt))
      }
    ];
    status.value = detailData.status;
    shipId.value = detailData.shipId;
    isActiveSim.value = store.state.sim.status === 'ACTIVATED';
    customerId.value = store.state.management.customerId;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const displayVerifyDialog = () => {
    const detailData = store.state.autobacsDevice.detailData;
    deviceForm.value.simNumber = detailData.simNumber;
    deviceForm.value.serialCode = detailData.serialCode;
    deviceForm.value.firmVersion = detailData.firmVersion;
    deviceForm.value.internalBatteryVoltage = detailData.internalBatteryVoltage;
    deviceForm.value.mboot = detailData.mboot;
    dialogVisible.value = true;
  };
  const verifyDevice = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      ElLoading.service({ fullscreen: true });
      await store.dispatch('autobacsDevice/verify', {
        id: deviceId.value,
        firmVersion: deviceForm.value.firmVersion,
        mboot: deviceForm.value.mboot,
        internalBatteryVoltage: deviceForm.value.internalBatteryVoltage
      });
      dialogVisible.value = false;
      router.push('/autobacs/device');
    });
  };
  const stopDevice = async () => {
    ElLoading.service({ fullscreen: true });
    await store.dispatch('autobacsDevice/stop', deviceId.value);
    stopDialogVisible.value = false;
    createDetailData();
  };
  const QRIssue = async () => {
    QRDialogVisible.value = false;
  };
  const displaySimOperate = () => {
    const simText = isActiveSim.value ? '休止' : '開通';
    dialogMessage.value = 'SIMを ' + simText + ' しますがよろしいですか？';
    displaySimDialog.value = true;
  };
  const simOperate = async () => {
    ElLoading.service({ fullscreen: true });
    if (isActiveSim.value) {
      await store.dispatch('sim/stop', deviceId.value);
    } else {
      await store.dispatch('sim/active', {
        deviceId: deviceId.value,
        isTimeKeepStart: false
      });
    }
    displaySimDialog.value = false;
    createDetailData();
  };
  const backCustomerDetail = () => {
    router.push('/management/' + customerId.value);
  };
  const orderEntry = async (formEl: FormInstance | undefined) => {
    ElLoading.service({ fullscreen: true });
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      await store.dispatch('autobacsDevice/link', {
        id: deviceId.value,
        autobacsOrderNumber: orderEntryForm.value.autobacsOrderNumber
      });
    });
    orderEntryDialogVisible.value = false;
    router.push(`/autobacs/device`);
  };
  createDetailData();
</script>
<style lang="scss">
  .device-detail {
    .main-container {
      width: 900px;
    }
    .device-detail-table-line {
      width: 400px;
      margin: 0px 25px 20px;
      float: left;
    }
    .device-detail-button-area {
      clear: left;
      margin: 20px 0px;
    }
    form {
      width: 350px;
    }
    .dialog-title {
      font-weight: 600;
    }
    .el-input.is-disabled {
      .el-input__inner {
        color: #606266;
      }
    }
    .el-button {
      font-weight: 600;
    }
    .device-detail-sim-form {
      width: 225px;
    }
  }
</style>
