<template>
  <BaseSimpleLayout class="bulk-confirm">
    <template #title>バルク受注確認</template>
    <template #main>
      <BaseDataTable :table-data="confirmTableData" :label-width="150" />
      <el-button type="info" size="large" @click="router.push('/bulk/input')">
        戻る
      </el-button>
      <el-button type="primary" size="large" @click="submit()">
        登録
      </el-button>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { formatDate } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';

  const store = useStore();
  const submitData = store.state.bulk.submitData;
  const confirmTableData = ref<DetailTableData[]>([]);

  const initialize = () => {
    if (!submitData) {
      return router.push('/bulk');
    }
    confirmTableData.value = [
      {
        label: '受注日',
        value: formatDate(Number(submitData.acceptDate))
      },
      {
        label: 'ファイナンス',
        value: store.state.financesMaster.replaceData[submitData.financeId]
      },
      {
        label: '製品名',
        value: store.state.productsMaster.replaceData[submitData.productId].name
      },
      {
        label: '個数',
        value: submitData.productQty
      },
      {
        label: '東京専用備考',
        value: submitData.note
      },
      {
        label: '発送時連携用備考',
        value: submitData.shipNote
      }
    ];
  };
  const submit = () => {
    store.dispatch('bulk/submit');
  };
  initialize();
</script>
<style lang="scss">
  .bulk-confirm {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
    }
  }
</style>
