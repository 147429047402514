<template>
  <div v-if="loadingFlag" class="ship-label">
    <div class="button-area">
      印刷の際は「余白」を「なし」に設定の上、印刷してください。<br />
      <el-button
        size="large"
        type="info"
        @click="router.push('/ship/' + shipId)"
      >
        戻る
      </el-button>
      <el-button size="large" type="primary" @click="handlePrint">
        印刷
      </el-button>
      <el-select v-model="selectedOption" class="select">
        <el-option label="上部" value="上部"></el-option>
        <el-option label="中部" value="中部"></el-option>
        <el-option label="下部" value="下部"></el-option>
      </el-select>
    </div>
    <section class="sheet">
      <div class="paper-padding">
        <span v-for="data in labelData" :key="data.serial">
          <div
            :style="{ height: stretchControlDivHeight }"
            class="stretch-controll-div"
          ></div>
          <div class="one-label left-column">
            <img src="@/assets/logo_gms_h_jp.jpg" />
            <div>
              <ul>
                <li class="text-left">車台番号：{{ data.vin }}</li>
                <li class="text-left">シリアル番号：{{ data.serial }}</li>
              </ul>
            </div>
          </div>
          <div class="one-label">
            <img src="@/assets/logo_gms_h_jp.jpg" />
            <div>
              <ul>
                <li class="text-left">車台番号：{{ data.vin }}</li>
                <li class="text-left">シリアル番号：{{ data.serial }}</li>
              </ul>
            </div>
          </div>
          <div class="one-label left-column">
            <img src="@/assets/logo_gms_h_jp.jpg" />
            <div>
              <ul>
                <li class="text-left">車台番号：{{ data.vin }}</li>
                <li class="text-left">シリアル番号：{{ data.serial }}</li>
              </ul>
            </div>
          </div>
          <div class="one-label" v-if="data.qrURL">
            <div style="padding-left: 10px">
              <vue-qrcode
                :value="typeof data.qrURL === 'string' ? data.qrURL : 'Error'"
                tag="svg"
                :options="{
                  errorCorrectionLevel: 'Q',
                  width: 100
                }"
              ></vue-qrcode>
            </div>
            <div>
              取り付けマニュアル<br />
              ハーネス：{{ data.harnessName }}
            </div>
          </div>
        </span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ShipDetailData } from '@/types/ship';
  import { getQrURL } from '@/libs/ship';
  import { getHarnessByDeviceType } from '@/libs/device';
  import { ElLoading } from 'element-plus';
  import VueQrcode from '@chenfengyuan/vue-qrcode';
  const props = defineProps({
    shipId: {
      type: String,
      required: true
    },
    position: {
      type: Number
    }
  });
  const store = useStore();
  const labelData = ref<
    {
      vin: string | null;
      serial: string;
      harnessName: string;
      qrURL: string | boolean;
    }[]
  >([]);
  const selectedOption = ref('上部');
  const stretchControlDivHeight = ref('0mm');
  const loadingFlag = ref(false);
  watch(selectedOption, (newValue) => {
    switch (newValue) {
      case '上部':
        stretchControlDivHeight.value = '0mm';
        break;
      case '中部':
        stretchControlDivHeight.value = '84.6mm';
        break;
      case '下部':
        stretchControlDivHeight.value = '169.2mm';
        break;
      default:
        stretchControlDivHeight.value = '0mm';
        break;
    }
  });
  let detailData: ShipDetailData | null = null;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('ship/fetchDetail', props.shipId);
    detailData = store.state.ship.detailData;
    if (!detailData) return router.push('/ship');
    let harnessName = '-';
    if (detailData.harnessId) {
      const item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        detailData.harnessId,
        detailData.deviceType
      );
      if (item?.itemName) {
        harnessName = item.itemName;
      }
    } else {
      harnessName = 'キーカット';
    }
    const qrURL = getQrURL(harnessName);
    const createDeviceSerialList = (): (string | null)[] => {
      if (!detailData) return [''];
      if (detailData.devices.length === 0) {
        return [''];
      }
      return detailData.devices.map((data) => data.deviceSerialCode || '');
    };
    labelData.value = createDeviceSerialList().map((serial: string | null) => {
      return {
        vin: detailData?.vin ? String(detailData?.vin) : '',
        serial: serial ? serial : '',
        harnessName,
        qrURL: qrURL
      };
    });
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const handlePrint = () => {
    window.print();
  };
  initialize();
</script>

<style lang="scss">
  .ship-label {
    @media print {
      .sheet {
        zoom: 150%;
        margin: 15mm;
        padding: 0;
        box-shadow: none;
      }

      #content {
        float: none;
        width: 100%;
      }

      .button-area {
        display: none;
      }
    }

    .button-area {
      margin-top: 20px;
    }

    .el-button {
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }

    * {
      color: #000000;
      font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体',
        YuGothic, sans-serif;
      font-size: 13pt;
      font-weight: bold;
      -webkit-print-color-adjust: exact;
    }

    .select {
      padding-left: 20px;
    }

    /* リスト初期化 */
    ul {
      list-style: none;
      padding-left: 0;
    }

    /* ページレイアウト (section.sheet を１ページとする) */
    .sheet {
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      break-after: auto;
      /* 用紙サイズ A4 */
      height: 294mm;
      width: 210mm;
      /* 余白サイズ */
      box-sizing: border-box;
      background: #ffffff;
      /* 背景を白く */
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
      /* ドロップシャドウ */
      margin: 1mm auto;
    }

    .paper-padding {
      padding-top: 21.5mm;
      padding-left: 19.3mm;
      padding-right: 19.3mm;
      padding-bottom: 21.5mm;
    }

    .text-left {
      text-align: left;
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .stretch-controll-div {
      height: 0;
      width: 100%;
      clear: both;
    }

    .one-label {
      width: 83.8mm;
      /* 横のサイズを設定 */
      height: 42.3mm;
      /* 縦のサイズを設定 */
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        height: 120px;
        margin-left: 80px;
        opacity: 0.2;
        position: absolute;
      }
    }

    .one-label div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: auto;
      margin-right: auto;
    }

    .one-label li {
      margin-left: 5px;
      text-align: left;
    }

    .one-label.left-column {
      float: left;
      margin-right: 3.8mm;
    }
  }
</style>
