<template>
  <BaseSimpleLayout class="user-detail">
    <template #title>ユーザ詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        :label-width="150"
      ></BaseDataTable>
      <el-button type="info" size="large" @click="router.push('/user')">
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/user/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        type="danger"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="400px">
        <span><b>このユーザを削除しますか。</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteUser">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { Role } from '@/libs/auth';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    userId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const orderRole = rolePermission(['ORDERADMIN', 'SHIPADMIN']);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('user/fetch', props.userId);
    const detailData = store.state.user.detailData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: 'ユーザ名',
        value: detailData.name
      },
      {
        label: 'メールアドレス',
        value: detailData.email
      },
      {
        label: '権限',
        value: Role[detailData.role]
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const deleteUser = () => {
    store.dispatch('user/delete', props.userId);
  };
  initialize();
</script>
<style lang="scss">
  .user-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
