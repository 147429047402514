<template>
  <BaseSimpleLayout class="master-package-create">
    <template #title>パッケージ登録</template>
    <template v-if="loadingFlag" #button-area>
      <el-text v-if="copyId" class="highlighted-label"
        >コピー元： {{ copyId }}</el-text
      >
    </template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item
          class="unit-master-package-create-name"
          label="パッケージ名称"
          prop="name"
        >
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-package-create-packCode"
          label="品番"
          prop="packCode"
        >
          <el-input
            v-model="createForm.packCode"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-package-create-harness"
          label="専用ハーネス変換"
          prop="isChangeHarnessItem"
        >
          <el-radio-group
            v-model="createForm.isChangeHarnessItem"
            :disabled="isConfirm"
            @change="selectHarness"
          >
            <el-radio :label="true">対象</el-radio>
            <el-radio :label="false">対象外</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button
        v-if="displayItemSelect"
        size="large"
        type="success"
        class="package-input-item-button-add"
        :disabled="isConfirm"
        @click="addDialogOpen"
      >
        単品追加
      </el-button>
      <el-table
        v-if="displayItemSelect"
        :data="createForm.details"
        stripe
        border
        style="width: 850px"
        class="item-table unit-master-package-create-details-table"
        empty-text="組付単品が存在しません"
      >
        <el-table-column
          prop="itemId"
          label="単品ID"
          width="80px"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.itemId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="itemName" label="品名" header-align="center">
          <template #default="scope">
            <span>{{ itemDataChanger[scope.row.itemId].name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="itemHandlingType"
          label="種類"
          width="80px"
          align="center"
          class-name="unit-master-package-details-type"
        >
          <template #default="scope">
            <span>{{ handlingType[scope.row.itemHandlingType] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="itemQty"
          label="個数"
          width="80px"
          align="center"
          class-name="unit-master-package-details-qty"
        />
        <el-table-column
          label="操作"
          width="160px"
          align="center"
          class-name="unit-master-package-details-ope"
        >
          <template #default="scope">
            <el-button
              type="success"
              @click="editDialogOpen(scope.$index)"
              :disabled="isConfirm"
            >
              編集
            </el-button>
            <el-button
              type="danger"
              @click="deleteItem(scope.$index)"
              :disabled="isConfirm"
            >
              削除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="isConfirm" class="package-input-button-area">
        <el-button
          class="unit-master-package-create-button-list"
          type="info"
          size="large"
          @click="isConfirm = false"
        >
          キャンセル
        </el-button>
        <el-button
          class="unit-master-package-create-button-confirm"
          type="primary"
          size="large"
          @click="submit()"
        >
          登録
        </el-button>
      </div>
      <div v-else class="package-input-button-area">
        <el-button
          class="unit-master-package-create-button-list"
          type="info"
          size="large"
          @click="router.push('/master/package')"
        >
          戻る
        </el-button>
        <el-button
          class="unit-master-package-create-button-confirm"
          type="primary"
          size="large"
          @click="changeConfirm(formRef)"
        >
          確認
        </el-button>
      </div>
      <el-dialog
        v-model="dialogFormVisible"
        :title="editIndex !== null ? '組付単品修正' : '組付単品登録'"
        width="600px"
      >
        <el-form
          ref="packƒormRef"
          :model="itemForm"
          size="large"
          class="package-input-item-form"
        >
          <el-form-item
            label="単品ID"
            label-width="90px"
            prop="itemId"
            class="package-input-item-qty"
          >
            <el-input-number
              v-model="itemForm.itemId"
              class="package-input-item-qty-input"
              placeholder=""
              :min="1"
              :max="10000"
              :precision="0"
              :step="1"
              :controls="false"
            />
          </el-form-item>
          <el-form-item label="品名" label-width="90px" prop="itemId">
            <el-select
              v-model="itemForm.itemId"
              filterable
              placeholder="選択"
              class="package-input-item-id-input"
            >
              <el-option
                v-for="option in itemList"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="種類"
            label-width="90px"
            class="package-input-item-type"
            prop="itemHandlingType"
          >
            <el-radio-group v-model="itemForm.itemHandlingType">
              <el-radio :label="1">新品</el-radio>
              <el-radio :label="2">流用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="個数"
            label-width="90px"
            class="package-input-item-qty"
            prop="itemQty"
          >
            <el-input-number
              v-model="itemForm.itemQty"
              class="package-input-item-qty-input"
              placeholder=""
              :min="1"
              :precision="0"
              :step="1"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button
            v-if="editIndex !== null"
            size="large"
            type="success"
            @click="editItem"
          >
            更新
          </el-button>
          <el-button v-else size="large" type="primary" @click="addItem">
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { PackagesSubmitDataState, AddItemData } from '@/types/master/package';
  import { ItemsDataState, ItemReplaceData } from '@/types/master/item';
  import { ElLoading } from 'element-plus';
  import { handlingType } from '@/libs/device';

  const store = useStore();
  const route = useRoute();
  const copyId = route.query.copy_id;
  const formRef = ref<FormInstance>();
  const packƒormRef = ref<FormInstance>();
  const dialogFormVisible = ref(false);
  const createForm = ref<PackagesSubmitDataState>({
    name: '',
    packCode: '',
    isChangeHarnessItem: false,
    details: []
  });
  const itemForm = ref<AddItemData>({
    itemId: null,
    itemQty: 1,
    itemHandlingType: 1
  });
  const editIndex = ref<number | null>(null);
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'パッケージ名称は必須です。' }]
  });
  const itemList = ref<ItemsDataState[]>([]);
  const itemDataChanger = ref<ItemReplaceData>({});
  const loadingFlag = ref(false);
  const isConfirm = ref(false);
  const displayItemSelect = ref(true);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('itemsMaster/fetch');
    itemList.value = store.state.itemsMaster.listData;
    itemForm.value.itemId = itemList.value[0].id;
    itemDataChanger.value = store.state.itemsMaster.replaceData;
    selectHarness();

    if (copyId) {
      await Promise.all([store.dispatch('packageMaster/fetch', copyId)]);
      const detailData = store.state.packageMaster.detailData;
      if (detailData) {
        createForm.value.name = detailData.name + '（コピー）';
        createForm.value.packCode = detailData.packCode;
        createForm.value.isChangeHarnessItem = detailData.isChangeHarnessItem;
        for (const data of detailData.details) {
          itemForm.value = {
            itemId: data.itemId,
            itemQty: data.itemQty,
            itemHandlingType: data.itemHandlingType
          };
          addItem();
        }
      }
    }

    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      if (createForm.value.isChangeHarnessItem) createForm.value.details = [];
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('packagesMaster/submit', createForm.value);
  };
  const addItem = () => {
    createForm.value.details.push(itemForm.value);
    dialogFormVisible.value = false;
    itemForm.value = {
      itemId: itemList.value[0].id,
      itemQty: 1,
      itemHandlingType: 1
    };
  };
  const addDialogOpen = () => {
    itemForm.value = {
      itemId: itemList.value[0].id,
      itemQty: 1,
      itemHandlingType: 1
    };
    editIndex.value = null;
    dialogFormVisible.value = true;
  };

  const editDialogOpen = (index: number) => {
    itemForm.value = {
      itemId: createForm.value.details[index].itemId,
      itemQty: createForm.value.details[index].itemQty,
      itemHandlingType: createForm.value.details[index].itemHandlingType
    };
    editIndex.value = index;
    dialogFormVisible.value = true;
  };

  const deleteItem = (index: number) => {
    createForm.value.details.splice(index, 1);
  };

  const editItem = () => {
    if (editIndex.value === null) return;
    createForm.value.details.splice(editIndex.value, 1, itemForm.value);
    dialogFormVisible.value = false;
  };

  const selectHarness = () => {
    displayItemSelect.value = !createForm.value.isChangeHarnessItem;
  };
  initialize();
</script>
<style lang="scss">
  .master-package-create {
    .el-button {
      font-weight: 600;
    }
    form {
      width: 500px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .package-input-item-form {
      width: 520px;
    }
    .package-input-item-button-add {
      margin-bottom: 20px;
    }
    .package-input-item-id-input {
      float: left;
      width: 400px;
    }
    .package-input-item-qty-input {
      float: left;
      width: 65px;
    }
    .package-input-button-area {
      margin: 10px 0;
    }
    .el-table {
      margin: auto;
      thead {
        th {
          background: #f5f7fa;
        }
      }
      td {
        div {
          font-weight: 600;
        }
      }
    }
    .highlighted-label {
      font-size: 25px;
      font-weight: bold;
      background-color: yellow;
    }
  }
</style>
