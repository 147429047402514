<template>
  <div>
    <BaseSimpleLayout class="bill-confirm">
      <template #title>売上情報{{ saleId ? '編集' : '登録' }}確認</template>
      <template v-if="loadingFlag" #main>
        <div class="bill-confirm-table-area">
          <div class="bill-confirm-table-line">
            <BaseDataTable :table-data="leftData" :label-width="125" />
          </div>
          <div class="bill-confirm-table-line">
            <BaseDataTable :table-data="rightData" :label-width="125" />
          </div>
        </div>
        <div class="bill-confirm-details-area">
          <div class="table-title">製品情報</div>
          <el-table
            :data="productData"
            stripe
            border
            class="accept-create-product-details-table"
            empty-text="製品が存在しません"
            size="small"
          >
            <el-table-column
              prop="productName"
              label="名称"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="productPrice"
              label="価格"
              width="80px"
              align="center"
              class-name="unit-master-product-details-name"
            />
            <el-table-column
              prop="productType"
              label="種別"
              width="120px"
              align="center"
              class-name="unit-master-product-details-qty"
            >
              <template #default="scope">
                <span>{{ productType[scope.row.productType] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="productQty"
              label="個数"
              width="60px"
              align="center"
              class-name="unit-master-product-details-qty"
            />
          </el-table>
        </div>
        <div class="bill-confirm-button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/sale/input/ship')"
          >
            戻る
          </el-button>
          <el-button type="primary" size="large" @click="submitData">
            {{ saleId ? '更新' : '登録' }}
          </el-button>
        </div>
      </template>
    </BaseSimpleLayout>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { deviceSaleType } from '@/libs/sale';
  import {
    UnrecordProductsState,
    UnrecordSubmitProductsState
  } from '@/types/unrecord';
  import { DetailTableData } from '@/types/common';
  import { productType } from '@/libs/product';
  import { ElLoading } from 'element-plus';
  import { shipSelectType } from '@/libs/accept';
  import { formatDate } from '@/libs/dateFormat';

  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const productData = ref<UnrecordProductsState[]>([]);
  const saleId = ref<string | null>(null);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.sale.submitData;
    const productReplaceData = store.state.productsMaster.replaceData;
    if (!submitData) return router.push('/sale');
    saleId.value = store.state.sale.saleId;
    leftData.value = [
      {
        label: '出荷日',
        value: submitData.saleDate
          ? formatDate(Number(submitData.saleDate))
          : '-'
      },
      {
        label: '受注区分',
        value: submitData.type === 1 ? '通常' : '返品'
      },
      {
        label: '売上種別',
        value: deviceSaleType[submitData.saleDeviceType]
      },
      {
        label: '顧客管理番号',
        value: submitData.customerLedgerId
          ? store.state.management.replaceData[submitData.customerLedgerId]
          : '-'
      },
      {
        label: '出荷先種別',
        value: submitData.shipSelectType
          ? shipSelectType[submitData.shipSelectType]
          : '-'
      },
      {
        label: '備考',
        value: submitData.note
      }
    ];
    rightData.value = [
      {
        label: 'ファイナンス',
        value: store.state.financesMaster.replaceData[submitData.financeId]
      },
      {
        label: '販売店グループ',
        value:
          store.state.companyGroupsMaster.replaceData[submitData.companyGroupId]
            .name
      },
      {
        label: '販売店企業',
        value:
          store.state.companiesMaster.replaceData[submitData.companyId].name
      },
      {
        label: '販売店',
        value: submitData.shopId
          ? store.state.shopsMaster.replaceData[submitData.shopId].name
          : '-'
      },
      {
        label: 'サポート店',
        value: submitData.supportShopId
          ? store.state.supportShopsMaster.replaceData[submitData.supportShopId]
          : '-'
      },
      {
        label: 'その他発送先',
        value: submitData.otherShipName ? submitData.otherShipName : '-'
      }
    ];
    productData.value = submitData.details.map(
      (data: UnrecordSubmitProductsState) => {
        const product = productReplaceData[data.productId];
        return {
          productId: data.productId,
          productName: product.name,
          productType: product.productType,
          productQty: data.productQty,
          productPrice: data.productPrice
        };
      }
    );
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const submitData = () => {
    if (saleId.value) {
      return store.dispatch('sale/put');
    }
    store.dispatch('sale/submit');
  };
  initialize();
</script>
<style lang="scss">
  .bill-confirm {
    .bill-confirm-table-area {
      display: inline-block;
      margin-top: 20px;
    }
    .bill-confirm-table-line {
      width: 500px;
      margin-left: 10px;
      float: left;
    }
    .bill-confirm-details-area {
      width: 750px;
      margin: auto;
      .el-table {
        font-size: 14px;
        font-weight: 600;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }
    .bill-confirm-button-area {
      margin-top: 20px;
    }
    .el-button {
      font-weight: 600;
    }
    .table-title {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
</style>
