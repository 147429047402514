/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  CarSecurityDevicesState,
  CarSecurityDevicesDataState,
  CarSecurityDevicesFilterData
} from '@/types/carSecurityDevice';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    readyListData: [],
    filterData: null
  },
  mutations: {
    setList(
      state: CarSecurityDevicesState,
      payload: CarSecurityDevicesDataState[]
    ) {
      state.listData = payload;
    },
    setReadyList(
      state: CarSecurityDevicesState,
      payload: CarSecurityDevicesDataState[]
    ) {
      state.readyListData = payload;
    },
    setFilter(
      state: CarSecurityDevicesState,
      payload: CarSecurityDevicesFilterData
    ) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any, MCCSver: number) {
      const headers: any = {
        auth: await getAccessToken()
      };
      if (MCCSver) {
        headers.deviceType = MCCSver;
      }
      await axios
        .get(getBaseUrl() + '/carsec/device/', {
          headers: headers
        })
        .then((response) => {
          const sortData = response.data.sort(
            (
              a: CarSecurityDevicesDataState,
              b: CarSecurityDevicesDataState
            ) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchReady(context: any) {
      await axios
        .get(getBaseUrl() + '/carsec/device', {
          params: {
            status: [2]
          },
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setReadyList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: CarSecurityDevicesFilterData) {
      context.commit('setFilter', payload);
    }
  }
};
