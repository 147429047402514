<template>
  <BaseSimpleLayout class="management-edit">
    <template #title>顧客管理情報編集</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="130px"
      >
        <div class="management-detail-form-line">
          <el-form-item label="端末シリアル番号">
            <el-input v-model="deviceSerialCode" disabled />
          </el-form-item>
          <el-form-item label="顧客管理番号" prop="customerCode">
            <el-input v-model="editForm.customerCode" />
          </el-form-item>
          <el-form-item
            class="basic-select"
            label="ファイナンス"
            prop="financeId"
          >
            <el-select
              v-model="editForm.financeId"
              filterable
              placeholder="選択"
            >
              <el-option
                v-for="finance in financeList"
                :key="finance.id"
                :label="finance.name"
                :value="finance.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="FS管理番号" prop="financeCode">
            <el-input v-model="editForm.financeCode" />
          </el-form-item>
          <el-form-item label="販売店グループ">
            <el-input v-model="companyGroupName" disabled />
          </el-form-item>
          <el-form-item label="販売店企業">
            <el-input v-model="companyName" disabled />
          </el-form-item>
          <el-form-item label="販売店">
            <el-input v-model="shopName" disabled />
          </el-form-item>
          <el-form-item
            label="ステータス"
            prop="status"
            class="management-edit-select"
          >
            <el-select v-model="editForm.status" placeholder="選択">
              <el-option
                v-for="status in managementStatusList"
                :key="status.val"
                :label="status.label"
                :value="status.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="解約理由"
            prop="reasonCancelId"
            class="management-edit-cancel-select"
          >
            <el-select
              v-model="editForm.reasonCancelId"
              placeholder="選択"
              clearable
            >
              <el-option
                v-for="reasonCancelId in reasonCancelList"
                :key="reasonCancelId.id"
                :label="reasonCancelId.reason"
                :value="reasonCancelId.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="車台番号" prop="vin">
            <el-input v-model="editForm.vin" />
          </el-form-item>
        </div>
        <div class="management-detail-form-line">
          <el-form-item label="使用月額費" prop="usedMonthlyFee">
            <el-input-number
              v-model="editForm.usedMonthlyFee"
              :precision="0"
              :min="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item label="未使用月額費" prop="unUsedMonthlyFee">
            <el-input-number
              v-model="editForm.unUsedMonthlyFee"
              :precision="0"
              :min="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item
            label="取付日"
            prop="mountDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.mountDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="selectDate('mountDate')"
            />
          </el-form-item>
          <el-form-item
            label="解約日(取外日)"
            prop="unMountDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.unMountDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="selectDate('unMountDate')"
            />
          </el-form-item>
          <el-form-item
            label="動作確認日"
            prop="operationCheckDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.operationCheckDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
            />
          </el-form-item>
          <el-form-item
            label="請求開始日"
            prop="startBillingDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.startBillingDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
            />
          </el-form-item>
          <el-form-item
            label="請求終了日"
            prop="endBillingDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.endBillingDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
            />
          </el-form-item>
          <el-form-item
            label="返品確認日"
            prop="returnedCheckDate"
            class="management-edit-accept-date"
          >
            <el-date-picker
              v-model="editForm.returnedCheckDate"
              type="date"
              placeholder="日付を選択"
              style="width: 170px"
              format="YYYY-MM-DD"
              value-format="x"
            />
          </el-form-item>
          <el-form-item label="備考" prop="note">
            <el-input v-model="editForm.note" type="textarea" />
          </el-form-item>
        </div>
        <div class="management-edit-button-area">
          <el-button
            v-if="customerId"
            type="info"
            @click="router.push('/management/' + customerId)"
          >
            戻る
          </el-button>
          <el-button type="primary" @click="moveNext(formRef)">確認</el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import type { FormInstance, FormRules } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { FinancesDataState } from '@/types/master/finance';
  import { ManagementPutData } from '@/types/management';
  import { managementStatusList } from '@/libs/management';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<ManagementPutData>({
    mountDate: null,
    unMountDate: null,
    operationCheckDate: null,
    startBillingDate: null,
    endBillingDate: null,
    returnedCheckDate: null,
    usedMonthlyFee: 0,
    unUsedMonthlyFee: 0,
    status: 0,
    reasonCancelId: 0,
    note: '',
    customerCode: '',
    financeCode: '',
    financeId: null
  });
  const financeList = ref<FinancesDataState[]>([]);
  const rules = ref<FormRules>({
    usedMonthlyFee: [{ required: true, message: '使用月額費用は必須です。' }],
    unUsedMonthlyFee: [
      { required: true, message: '未使用月額費用は必須です。' }
    ]
  });
  const deviceSerialCode = ref('');
  const customerCode = ref('');
  const financeName = ref('');
  const companyGroupName = ref('');
  const companyName = ref('');
  const shopName = ref('');
  const loadingFlag = ref(false);
  const customerId = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const putData = store.state.management.putData;
    customerId.value = store.state.management.customerId;
    if (!putData) {
      return router.push('/management');
    }
    deviceSerialCode.value = store.state.management.detailData.deviceSerialCode;
    customerCode.value = store.state.management.detailData.customerCode;
    financeName.value = store.state.management.detailData.financeName;
    companyGroupName.value = store.state.management.detailData.companyGroupName;
    companyName.value = store.state.management.detailData.companyName;
    shopName.value = store.state.management.detailData.shopName;
    editForm.value = putData;
    financeList.value = store.state.financesMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const reasonCancelList = store.state.reasonCancel.listData;
  const selectDate = (column: string) => {
    if (column === 'mountDate') {
      editForm.value.operationCheckDate = editForm.value.mountDate;
      editForm.value.startBillingDate = editForm.value.mountDate;
    }
    if (column === 'unMountDate')
      editForm.value.endBillingDate = editForm.value.unMountDate;
  };
  const moveNext = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) return false;
      store.dispatch('management/setPutData', editForm.value);
      router.push('/management/edit/confirm');
    });
  };
  initialize();
</script>
<style lang="scss">
  .management-edit {
    form {
      width: 900px;
      margin: auto;
    }
    .el-form {
      display: inline-block;
    }
    .el-form-item {
      margin-bottom: 18px;
    }

    .management-detail-form-line {
      width: 400px;
      margin-left: 10px;
      float: left;
    }

    .management-edit-button-area {
      display: inline-block;
      margin: -10px 0px 10px;
      width: 100%;
    }

    .el-input-number {
      float: left;
      width: 170px;

      .el-input__inner {
        text-align: left;
      }
    }

    .el-button {
      font-weight: 600;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-textarea {
      width: 400px;
    }

    .management-edit-input {
      text-align: left;
    }

    .management-edit-accept-date .el-input {
      float: left;
    }

    .el-input.is-disabled .el-input__inner {
      color: #606266;
    }

    .el-select {
      width: 210px;
      float: left;
    }
  }
</style>
