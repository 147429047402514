<template>
  <BaseSimpleLayout class="support-shop-master-create">
    <template #title>サポート店登録</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <div class="support-shop-master-create-form">
          <div>
            <el-form-item label="サポート店名" prop="name">
              <el-input
                v-model="createForm.name"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="所属組合" prop="affiliationUnion">
              <el-input
                v-model="createForm.affiliationUnion"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="support-shop-create-tel"
              label="電話番号"
              prop="tel"
            >
              <el-input
                v-model="createForm.tel"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="telRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="support-shop-create-mobile-number"
              label="携帯番号"
              prop="mobileNumber"
            >
              <el-input
                v-model="createForm.mobileNumber"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="mobileRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="support-shop-create-staff-name"
              label="担当者"
              prop="staffName"
            >
              <el-input
                v-model="createForm.staffName"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item
              class="support-shop-create-zip"
              label="郵便番号"
              prop="zip"
            >
              <el-input
                v-model="createForm.zip"
                autocomplete="off"
                maxlength="7"
                :disabled="isConfirm"
                @change="zipRestrict"
              />
            </el-form-item>
            <el-form-item
              class="support-shop-create-prefecture"
              label="都道府県"
              prop="prefecture"
            >
              <el-select
                v-model="createForm.prefecture"
                filterable
                placeholder="選択"
                :disabled="isConfirm"
              >
                <el-option
                  v-for="prefecture in prefectureList"
                  :key="prefecture"
                  :label="prefecture"
                  :value="prefecture"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="support-shop-create-city"
              label="市区町村"
              prop="city"
            >
              <el-input
                v-model="createForm.city"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="住所" prop="address">
              <el-input
                v-model="createForm.address"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="住所サブ" prop="addressSub">
              <el-input
                v-model="createForm.addressSub"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/master/supportshop')"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { SupportShopsSubmitData } from '@/types/master/supportShop';
  import { prefectureList } from '@/libs/prefecture';
  import { telRestriction, zipRestriction } from '@/libs/common';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<SupportShopsSubmitData>({
    name: '',
    affiliationUnion: '',
    zip: '',
    prefecture: '',
    city: '',
    address: '',
    addressSub: '',
    tel: '',
    mobileNumber: '',
    staffName: ''
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'サポート店名称は必須です。' }],
    affiliationUnion: [{ required: true, message: '所属組合は必須です。' }],
    zip: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    prefecture: [{ required: true, message: '都道府県は必須です。' }],
    city: [{ required: true, message: '市区町村は必須です。' }],
    address: [{ required: true, message: '住所は必須です。' }],
    tel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    mobileNumber: [
      {
        min: 11,
        max: 13,
        message:
          '携帯番号は11桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    staffName: [{ required: true, message: '担当者は必須です。' }]
  });
  const isConfirm = ref(false);

  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('supportShopsMaster/submit', createForm.value);
  };
  const zipRestrict = () => {
    createForm.value.zip = zipRestriction(createForm.value.zip);
  };
  const telRestrict = () => {
    createForm.value.tel = telRestriction(createForm.value.tel);
  };
  const mobileRestrict = () => {
    createForm.value.mobileNumber = telRestriction(
      createForm.value.mobileNumber
    );
  };
</script>
<style lang="scss">
  .support-shop-master-create {
    .support-shop-master-create-form {
      display: inline-flex;
    }
    form {
      width: 100%;
      display: inline-block;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-select {
      width: 120px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .support-shop-create-zip .el-input {
      width: 90px;
      float: left;
    }

    .support-shop-create-city .el-input {
      width: 140px;
      float: left;
    }

    .support-shop-create-tel .el-input {
      width: 135px;
      float: left;
    }

    .support-shop-create-mobile-number .el-input {
      width: 135px;
      float: left;
    }

    .support-shop-create-staff-name .el-input {
      width: 200px;
      float: left;
    }

    .button-area {
      margin: 10px 0px;
    }

    .support-shop-create-prefecture {
      .el-input {
        width: 140px;
      }
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
