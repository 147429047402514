/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { UserState, UserDetailDataState, UserPutDataState } from '@/types/user';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setUser(state: UserState, payload: UserDetailDataState) {
      state.detailData = payload;
    },
    setPut(state: UserState, payload: UserPutDataState) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, userId: any) {
      await axios
        .get(getBaseUrl() + '/users/' + userId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setUser', response.data);
          context.commit('setPut', {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            role: response.data.role
          });
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のユーザ情報が存在しません。');
            router.push('/user');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/user');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, userId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/users/' + userId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', 'ユーザ情報の削除に成功しました。');
          router.push('/user');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のユーザ情報が存在しません。');
            router.push('/user');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: UserPutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/users/' + payload.id,
          {
            name: payload.name,
            email: payload.email,
            role: payload.role
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ユーザ情報の変更に成功しました。');
          router.push('/user');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のユーザ情報が存在しません。');
            router.push('/user');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setPutData(context: any, payload: UserPutDataState) {
      context.commit('setPut', payload);
    }
  }
};
