<template>
  <BaseSimpleLayout class="bill-input">
    <template #title>
      売上情報{{ saleId ? '編集' : '登録' }}-請求情報
    </template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="180px"
      >
        <el-form-item
          label="売上日"
          prop="saleDate"
          class="bill-accept-input-accept-date"
        >
          <el-date-picker
            v-model="createForm.saleDate"
            type="date"
            placeholder="日付を選択"
            style="width: 140px"
            format="YYYY-MM-DD"
            value-format="x"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item
          class="bill-accept-input-select"
          label="ファイナンス"
          prop="financeId"
        >
          <el-select
            v-model="createForm.financeId"
            filterable
            placeholder="選択"
          >
            <el-option
              v-for="finance in financeList"
              :key="finance.id"
              :label="finance.name"
              :value="finance.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="bill-accept-input-select"
          label="FC"
          prop="companyGroupId"
        >
          <el-select
            v-model="createForm.companyGroupId"
            filterable
            placeholder="選択"
            @change="setFC(false)"
          >
            <el-option
              v-for="fc in fcList"
              :key="fc.id"
              :label="fc.name"
              :value="fc.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="bill-accept-input-select bill-accept-input-notice-input"
          label="販売店企業"
          prop="companyId"
        >
          <el-select
            v-model="createForm.companyId"
            filterable
            placeholder="選択"
            :disabled="disableCompanySelect"
            @change="setCompany(false)"
          >
            <el-option
              v-for="company in companyList"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            />
          </el-select>
          <span>※ FC選択後選択可</span>
        </el-form-item>
        <el-form-item
          class="bill-accept-input-select bill-accept-input-notice-input"
          label="販売店"
          prop="shopId"
        >
          <el-select
            v-model="createForm.shopId"
            filterable
            placeholder="選択"
            clearable
            :disabled="disableShopSelect"
          >
            <el-option
              v-for="shop in shopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
          <span> ※ 販売店企業選択後選択可 </span>
        </el-form-item>
        <el-form-item
          class="bill-accept-input-input bill-accept-input-notice-input"
          label="顧客管理番号"
          prop="customerLedgerId"
        >
          <el-select-v2
            v-model="createForm.customerLedgerId"
            filterable
            clearable
            placeholder="選択"
            size="large"
            :options="customerListOptions"
          />
        </el-form-item>
        <el-form-item
          class="bill-accept-input-radio"
          label="伝票区分"
          prop="type"
        >
          <el-radio-group v-model="createForm.type">
            <el-radio :label="1">通常</el-radio>
            <el-radio :label="2">返品</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="bill-accept-input-button-area">
          <el-button
            class="unit-master-package-create-button-list"
            type="info"
            @click="backPage"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-package-create-button-confirm"
            type="primary"
            @click="moveNext(formRef)"
          >
            次へ
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance } from 'element-plus';
  import { SaleAcceptFormData } from '@/types/sale';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompaniesDataState } from '@/types/master/company';
  import { CompanyGroupsDataState } from '@/types/master/companyGroup';
  import { ShopsDataState } from '@/types/master/shop';
  import { ManagementListState } from '@/types/management';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const createForm = ref<SaleAcceptFormData>({
    saleDate: 0,
    type: 1,
    saleType: 1,
    customerLedgerId: null,
    financeId: 0,
    companyGroupId: null,
    companyId: null,
    shopId: null,
    saleDeviceType: 1
  });
  const rules = ref({
    saleDate: [{ required: true, message: '売上日は必須です。' }],
    companyGroupId: [{ required: true, message: 'FCは必須です。' }],
    companyId: [{ required: true, message: '販売店企業は必須です。' }],
    financeId: [{ required: true, message: 'ファイナンスは必須です。' }]
  });
  const financeList = ref<FinancesDataState[]>([]);
  const fcList = ref<CompanyGroupsDataState[]>([]);
  const companyList = ref<CompaniesDataState[]>([]);
  const shopList = ref<ShopsDataState[]>([]);
  const customerListOptions = ref<{ value: number; label: string }[]>([]);
  const loadingFlag = ref(false);
  const disableCompanySelect = ref(true);
  const disableShopSelect = ref(true);
  const saleId = ref<string | null>(null);
  const formRef = ref<FormInstance>();

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.sale.submitData;
    saleId.value = store.state.sale.saleId;
    if (!submitData) {
      return router.push('/sale');
    }
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch'),
      store.dispatch('management/fetch')
    ]);
    fcList.value = store.state.companyGroupsMaster.listData;
    const customerList: ManagementListState[] = store.state.management.listData;
    customerListOptions.value = customerList.map((customer) => {
      return {
        value: customer.customerLedgerId,
        label: customer.customerCode
      };
    });
    createForm.value = {
      saleDate: submitData.saleDate ? submitData.saleDate : Date.now(),
      type: submitData.type,
      saleType: submitData.saleType,
      customerLedgerId: submitData.customerLedgerId,
      financeId: submitData.financeId,
      companyGroupId: submitData.companyGroupId,
      companyId: submitData.companyId,
      shopId: submitData.shopId,
      saleDeviceType: submitData.saleDeviceType
    };
    if (createForm.value.companyId) {
      await setFC(true);
      setCompany(true);
    }
    financeList.value = store.state.financesMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const moveNext = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) return false;
      if (!createForm.value.shopId) {
        createForm.value.shopId = null;
      }
      if (!createForm.value.customerLedgerId) {
        createForm.value.customerLedgerId = null;
      }
      await store.dispatch('sale/setAcceptSubmitData', createForm.value);
      router.push('/sale/input/ship');
    });
  };

  const backPage = () => {
    if (saleId.value) {
      return router.push('/sale/' + saleId.value);
    }
    router.push('/sale');
  };

  const setFC = async (initial = false) => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch(
      'companyGroupMaster/fetch',
      createForm.value.companyGroupId
    );
    companyList.value =
      store.state.companyGroupMaster.detailData.affiliatedCompanies;
    disableCompanySelect.value = false;
    if (!initial) {
      createForm.value.companyId = null;
      createForm.value.shopId = null;
    }
    loadingInstance.close();
  };

  const setCompany = (initial = false) => {
    shopList.value = store.state.shopsMaster.listData.filter(
      (shop: ShopsDataState) => shop.companyId === createForm.value.companyId
    );
    disableShopSelect.value = false;
    if (!initial) {
      createForm.value.shopId = null;
    }
  };
  initialize();
</script>
<style lang="scss">
  .bill-input {
    form {
      width: 600px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }

    .bill-accept-input-select {
      text-align: left;
    }

    .bill-accept-input-button-area {
      margin: 0px 0px 15px 0px;
    }

    .shop-require-text {
      color: #f56c6c;
      font-size: 13px;
      font-weight: 600;
    }

    .bill-accept-input-select {
      .el-select {
        width: 300px;
      }
    }

    .bill-accept-input-input {
      text-align: left;

      .el-select-v2 {
        width: 300px;
      }
    }

    .bill-accept-input-input-disable {
      text-align: left;
      .el-input {
        width: 300px;
      }
    }

    .bill-accept-input-shop-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      position: absolute;
      margin-top: -25px;
    }

    .bill-accept-input-notice-input {
      width: 800px;

      span {
        color: #606266;
        font-weight: 100;
      }
    }

    .bill-accept-input-radio {
      text-align: left;
      margin-bottom: 5px;
      .el-form-item__label {
        margin-right: 20px;
      }
    }
    .el-input.is-disabled {
      .el-input__inner {
        color: #606266;
      }
    }
    .bill-accept-input-accept-date {
      .el-input {
        float: left;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
