/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  DepotsState,
  DepotsDataState,
  DepotsReplaceData
} from '@/types/master/depot';

import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setList(state: DepotsState, payload: DepotsDataState[]) {
      state.listData = payload;
    },
    setReplace(state: DepotsState, payload: DepotsReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/depots', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceList: DepotsReplaceData = {};
          response.data.forEach((data: DepotsDataState) => {
            replaceList[data.id] = data.name;
          });
          context.commit('setReplace', replaceList);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
