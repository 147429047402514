<template>
  <div />
</template>
<script setup lang="ts">
  import router from '@/router';
  import { rolePermission } from '@/libs/auth';

  const initialize = async () => {
    if (rolePermission(['ORDERING', 'ORDERADMIN', 'VIEW']))
      return router.push('/accept');
    if (rolePermission(['TECH'])) return router.push('/harness');
    if (rolePermission(['SHIPPING', 'SHIPPING_PLUS', 'SHIPADMIN']))
      return router.push('/ship');
    router.push('/master');
  };
  initialize();
</script>
