<template>
  <BaseSimpleLayout class="inventory-detail-list">
    <template #title>棚卸明細／棚卸入力</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/inventory')">
        一覧
      </el-button>
      <el-button
        v-if="editRole && status == 1"
        size="large"
        type="primary"
        @click="checkToPrint"
        class="button-side"
      >
        記入表印刷
      </el-button>
      <el-button
        v-if="status == 2"
        size="large"
        type="primary"
        @click="csvDownload"
        class="button-side"
      >
        CSVダウンロード
      </el-button>
      <el-button
        v-if="confirmRole && status == 1"
        size="large"
        type="danger"
        @click="dialogVisible = true"
      >
        棚卸確定
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">拠点</span>
        <el-input v-model="inventoryDetailForm.depotName" disabled />
        <span class="label">棚卸日</span>
        <el-input
          v-model="inventoryDetailForm.inventoryDate"
          disabled
          :formatter="(value: string) => convertDate(value)"
        />
        <span class="label">フィルター</span>
        <el-input
          class="filter-input"
          v-model="filterForm.filterText"
          placeholder="検索"
          @input="filterData"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        :default-sort="{ prop: 'partCode', order: 'ascending' }"
      >
        <el-table-column
          property="partCode"
          label="品番"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column property="itemName" label="品名" align="center">
        </el-table-column>

        <el-table-column
          property="logicalStockQty"
          :label="status === 1 ? '現状在庫' : '過去在庫'"
          align="center"
          width="120"
        >
        </el-table-column>
        <el-table-column
          property="inventoryQty"
          label="棚卸数"
          align="center"
          width="120"
        >
          <template #default="{ row }">
            <el-input
              type="number"
              v-if="editRole && status == 1"
              v-model="row.inventoryQty"
              @blur="saveRow(row)"
            ></el-input>
            <span v-else>{{ row.inventoryQty }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="status == 2"
          property="cost"
          label="原単価"
          align="center"
          width="120"
        >
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogVisible" title="棚卸確定" width="450px">
        <span
          >現在の内容で棚卸し作業を確定します。棚卸し確定後は内容を変更できません。</span
        ><br />
        <span><b>本当に確定しても宜しいですか？</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button type="danger" size="large" @click="confirmInventory()">
            確定
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { error } from '@/libs/notification';
  import { formatDate } from '@/libs/dateFormat';
  import {
    InventoryDetailsDataState,
    InventoryDetailForm,
    InventoryDetailFilterForm
  } from '@/types/inventory';
  import { csvFormat } from '@/libs/dateFormat';
  import { ItemsDataState } from '@/types/master/item';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  // import inventories from '@/store/modules/master/inventories';

  const store = useStore();
  const tableData = ref<InventoryDetailsDataState[]>([]);
  const filterForm = ref<InventoryDetailFilterForm>({
    filterText: null
  });
  const inventoryDetailForm = ref<InventoryDetailForm>({
    depotId: 0,
    depotName: '',
    inventoryDate: ''
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(600);
  // const editingRow = ref<string | null>(null);
  const dialogVisible = ref(false);
  const editRole = ref<boolean>(
    rolePermission([
      'SHIPPING',
      'SHIPADMIN',
      'SHIPPING_PLUS',
      'ORDERING',
      'ORDERADMIN'
    ])
  );
  const confirmRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const status = ref<number | null>(null);
  const initialize = async () => {
    if (!store.state.inventories.detailListData) {
      error('400', '棚卸一覧から選択してください。');
      router.push('/inventory/');
    }
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.state.activation.inputData = null;
    status.value = store.state.inventories.status ?? 1;
    tableHeight.value = window.innerHeight - 160;
    await store.dispatch('itemsMaster/fetch');
    tableData.value =
      store.state.inventories.detailListData.map(mapDetailListData);
    inventoryDetailForm.value = {
      depotId: store.state.inventories.detailListData[0].depotId,
      depotName: store.state.inventories.detailListData[0].depotName,
      inventoryDate: store.state.inventories.detailListData[0].inventoryDate
    };
    loadingFlag.value = true;
    loadingInstance.close();
  };

  const filterData = () => {
    tableData.value = store.state.inventories.detailListData
      .filter(filterDetailListData)
      .map(mapDetailListData);
  };

  const filterDetailListData = (data: InventoryDetailsDataState) => {
    if (!filterForm.value.filterText) return true;
    return (
      (data?.partCode?.toString().includes(filterForm.value.filterText) ||
        data?.itemName?.includes(filterForm.value.filterText) ||
        data?.logicalStockQty
          ?.toString()
          .includes(filterForm.value.filterText) ||
        data?.inventoryQty?.toString().includes(filterForm.value.filterText)) ??
      false
    );
  };

  const mapDetailListData = (data: InventoryDetailsDataState) => {
    const item = store.state.itemsMaster.listData.find(
      (item: ItemsDataState) => item.id === data.itemId
    );
    const partCode = item ? item.partCode : '不明';
    return {
      itemId: data.itemId,
      itemName: data.itemName,
      logicalStockQty:
        status.value === 2 ? data.invLogicalStockQty : data.logicalStockQty,
      inventoryQty: data.inventoryQty,
      cost: data.cost,
      partCode: partCode
    };
  };

  const saveRow = async (
    InventoryDetailsDataState: InventoryDetailsDataState
  ) => {
    if (InventoryDetailsDataState.inventoryQty === null) {
      // 空の場合は保存処理を行わない
      return;
    }
    if (!Number.isInteger(Number(InventoryDetailsDataState.inventoryQty))) {
      error('400', '棚卸数は整数を入力してください。');
      return;
    }
    if (Number(InventoryDetailsDataState.inventoryQty) < 0) {
      error('400', '棚卸数にマイナスの数を入れることはできません。');
      return;
    }
    await store.dispatch('inventories/put', {
      depotId: inventoryDetailForm.value.depotId,
      inventoryDate: inventoryDetailForm.value.inventoryDate,
      itemId: InventoryDetailsDataState.itemId,
      inventoryQty: InventoryDetailsDataState.inventoryQty
    });
  };

  const confirmInventory = async () => {
    dialogVisible.value = false;
    await store.dispatch('inventories/confirm', {
      depotId: inventoryDetailForm.value.depotId,
      depotName: inventoryDetailForm.value.depotName,
      inventoryDate: inventoryDetailForm.value.inventoryDate,
      status: status.value
    });
  };
  const checkToPrint = async () => {
    const payload = tableData.value.filter((data) => {
      const item = store.state.itemsMaster.listData.find(
        (item: ItemsDataState) => item.id === data.itemId
      );
      return item.isStock;
    });
    await store.commit('inventories/setPrintTable', payload);
    router.push('/inventory/print');
  };
  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };
  const csvDownload = async () => {
    const filename = '棚卸一覧' + csvFormat(Date.now());
    const header = ['拠点,品番,品名,過去在庫,棚卸数,原単価,原価金額'];
    const baseData = store.state.inventories.detailListData.map(
      (data: InventoryDetailsDataState): string => {
        return Object.entries({
          depotName: data.depotName ? data.depotName : '-',
          partCode: data.partCode ? data.partCode : '-',
          itemName: data.itemName ? data.itemName : '-',
          logicalStockQty: data.logicalStockQty,
          inventoryQty: data.inventoryQty,
          cost: data.cost,
          amout: data.cost * (data.inventoryQty ?? 0)
        })
          .map(([, value]) => value)
          .join(',');
      }
    );
    const data = header.concat(baseData).join('\r\n');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(url);
  };
  initialize();
</script>
<style lang="scss">
  .inventory-detail-list {
    .filter-area {
      margin: 20px 0px;

      .label {
        margin: 0 10px;
        font-weight: 600;
        color: #606266;
      }

      .index {
        margin: 0 5px;
      }

      .el-input {
        width: 140px;
      }

      .filter-input {
        width: 300px;
      }
    }

    .el-table {
      width: 700px;
      margin: 10px auto;

      thead th {
        background: #f5f7fa;
      }
    }

    .el-button {
      font-weight: 600;
    }

    .button-side {
      margin-right: 50px;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>
