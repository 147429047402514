<template>
  <BaseSimpleLayout class="activation-create">
    <template #title>起動制御解除予約</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="120px"
      >
        <el-form-item
          class="unit-activation-customer-code"
          label="顧客管理番号"
          prop="customerCode"
        >
          <el-input v-model="createForm.customerCode" autocomplete="off" />
        </el-form-item>
        <el-form-item
          class="unit-activation-create-reserve-date"
          label="解除予約日時"
          prop="reserveDate"
        >
          <el-date-picker
            v-model="createForm.reserveDate"
            type="datetime"
            placeholder="日付を選択"
            format="YYYY-MM-DD HH:mm"
            value-format="x"
          />
        </el-form-item>
        <el-button
          class="unit-activation-create-button-list"
          type="info"
          @click="router.push('/activation')"
        >
          戻る
        </el-button>
        <el-button
          class="unit-activation-create-button-confirm"
          type="primary"
          @click="moveCreateConfirm(formRef)"
        >
          確認
        </el-button>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { ActivationData } from '@/types/activation';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<ActivationData>({
    customerCode: null,
    reserveDate: null
  });
  const rules = ref<FormRules>({
    customerCode: [{ required: true, message: '顧客管理番号は必須です。' }],
    reserveDate: [{ required: true, message: '解除予約日時は必須です。' }]
  });

  const initialize = () => {
    if (store.state.activation.inputData) {
      createForm.value = store.state.activation.inputData;
    }
  };
  const moveCreateConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) return;
      await store.dispatch(
        'activation/fetchDevice',
        createForm.value.customerCode
      );
      await store.dispatch('activation/setSubmitData', createForm.value);
      router.push('/activation/create/confirm');
    });
  };
  initialize();
</script>
<style lang="scss">
  .activation-create {
    form {
      width: 350px;
      display: inline-block;
    }
    .el-date-editor {
      float: left;
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
