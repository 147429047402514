<template>
  <BaseSimpleLayout class="login">
    <template #title>LOGIN</template>
    <template #main>
      <div id="firebaseui-auth-container" />
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import * as firebaseui from 'firebaseui';
  import 'firebaseui/dist/firebaseui.css';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { signInSuccessUrl } from '@/libs/firebaseAuth';

  onMounted(() => {
    let ui = firebaseui.auth.AuthUI.getInstance();
    ui = new firebaseui.auth.AuthUI(firebase.auth());

    ui.start('#firebaseui-auth-container', {
      signInSuccessUrl: signInSuccessUrl,
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
    });
  });
</script>
<style lang="scss">
  .login {
    .login-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
</style>
