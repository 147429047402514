<template>
  <BaseSimpleLayout class="receive-create">
    <template #title>{{ !isConfirm ? '入荷登録' : '入荷登録確認' }}</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item
          label="入荷日"
          prop="receiveDate"
          class="receive-create-receive-date"
        >
          <el-date-picker
            v-model="createForm.receiveDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
            :clearable="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          label="発送元"
          prop="shipSourceSelectType"
          @change="selectedSourceType"
        >
          <el-radio-group
            v-model="createForm.shipSourceSelectType"
            :disabled="isConfirm"
          >
            <el-radio :label="1">販売店</el-radio>
            <el-radio :label="2">サポート店</el-radio>
            <el-radio :label="3">GMS</el-radio>
            <el-radio :label="4">その他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="createForm.shipSourceSelectType > 2"
          label="発送元名"
          prop="shipSourceName"
          class="receive-create-name"
        >
          <el-input v-model="createForm.shipSourceName" :disabled="isConfirm" />
        </el-form-item>
        <el-form-item
          v-if="createForm.shipSourceSelectType === 1"
          label="発送元名"
          prop="shopId"
          class="receive-create-name"
        >
          <el-select
            v-model="createForm.shopId"
            filterable
            @change="selectedShop"
            :disabled="isConfirm"
          >
            <el-option
              v-for="shop in shopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="createForm.shipSourceSelectType === 2"
          label="発送元名"
          prop="supportShopId"
          :disabled="isConfirm"
          class="receive-create-name"
        >
          <el-select
            v-model="createForm.supportShopId"
            filterable
            :disabled="isConfirm"
            @change="selectedShop"
          >
            <el-option
              v-for="shop in supportShopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="入荷種別" prop="receiveType">
          <el-select v-model="createForm.receiveType" :disabled="isConfirm">
            <el-option
              v-for="data in receiveTypeList"
              :key="data.label"
              :label="data.label"
              :value="data.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="対象端末"
          prop="serialCodes"
          class="receive-create-device"
        >
          <el-select-v2
            v-model="createForm.serialCodes"
            :options="createDeviesList()"
            multiple
            filterable
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input
            :disabled="isConfirm"
            v-model="createForm.note"
            type="textarea"
          />
        </el-form-item>
        <div v-if="!isConfirm">
          <el-button type="info" size="large" @click="router.push('/receive')">
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="moveCreateConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
        <div v-if="isConfirm">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit">
            登録
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ElLoading } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { ReceiveSubmitData } from '@/types/receive';
  import { receiveTypeList } from '@/libs/receive';
  import { ShopsDataState } from '@/types/master/shop';
  import { SupportShopsDataState } from '@/types/master/supportShop';
  import { DevicesDataState } from '@/types/device';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const _d = new Date();
  const date = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
  const createForm = ref<ReceiveSubmitData>({
    receiveDate: date.getTime(),
    shipSourceSelectType: 1,
    shopId: null,
    supportShopId: null,
    shipSourceName: '',
    receiveType: 1,
    serialCodes: [],
    note: ''
  });
  const rules = ref<FormRules>({
    receiveDate: [{ required: true, message: '入荷日は必須です。' }],
    shipSourceName: [{ required: true, message: '販売元名は必須です。' }],
    shopId: [{ required: true, message: '販売元名は必須です。' }],
    supportShopId: [{ required: true, message: '販売元名は必須です。' }],
    serialCodes: [{ required: true, message: '対象端末は必須です。' }]
  });
  const loadingFlag = ref(false);
  const shopList = ref<ShopsDataState[]>([]);
  const supportShopList = ref<SupportShopsDataState[]>([]);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('supportShopsMaster/fetch')
    ]);
    const deviceList = store.state.devices.listData;
    if (!deviceList.length) await store.dispatch('devices/fetch');
    shopList.value = store.state.shopsMaster.listData;
    supportShopList.value = store.state.supportShopsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const selectedSourceType = () => {
    if (createForm.value.shipSourceSelectType < 3)
      createForm.value.shipSourceName = null;
    createForm.value.shopId = null;
    createForm.value.supportShopId = null;
  };
  const selectedShop = (id: number) => {
    const shopType = createForm.value.shipSourceSelectType;
    if (shopType === 1) {
      createForm.value.shipSourceName =
        store.state.shopsMaster.replaceData[id].name;
    }
    if (shopType === 2) {
      createForm.value.shipSourceName =
        store.state.supportShopsMaster.replaceData[id];
    }
  };
  const moveCreateConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('receive/submit', createForm.value);
  };
  const createDeviesList = () => {
    return store.state.devices.listData
      .filter((device: DevicesDataState) => {
        return !!device.serialCode;
      })
      .map((device: DevicesDataState) => {
        return {
          label: device.serialCode,
          value: device.serialCode
        };
      });
  };
  initialize();
</script>
<style lang="scss">
  .receive-create {
    form {
      width: 550px;
      margin: auto;
      .receive-create-receive-date {
        .el-input {
          width: 130px;
        }
      }
      .receive-create-name {
        .el-input {
          width: 400px;
        }
      }
      .el-select-v2 {
        width: 400px;
      }
      .el-select-v2__placeholder {
        font-weight: 500;
        text-align: left;
        color: #606266;
      }
      .el-input.is-disabled {
        .el-input__inner {
          color: #606266;
        }
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #606266;
        }
      }
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
