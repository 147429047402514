/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  PackagesState,
  PackagesDataState,
  PackagesSubmitDataState,
  PackageReplaceNameData
} from '@/types/master/package';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setList(state: PackagesState, payload: PackagesDataState[]) {
      state.listData = payload;
    },
    setReplace(state: PackagesState, payload: PackageReplaceNameData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/packs', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceNameData: PackageReplaceNameData = {};
          response.data.forEach((items: PackagesDataState) => {
            replaceNameData[items.id] = items.name;
          });
          context.commit('setReplace', replaceNameData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: PackagesSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/master/packs', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'パッケージ情報登録に成功しました。');
          router.push('/master/package');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
