<template>
  <BaseSimpleLayout class="move-edit">
    <template #title>移動訂正</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="140px"
      >
        <el-form-item label="移動日" prop="moveDate">
          <el-date-picker
            v-model="editForm.moveDate"
            type="date"
            placeholder="選択"
            format="YYYY-MM-DD"
            value-format="x"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="出庫拠点" prop="outDepotId">
          <el-select
            v-model="editForm.outDepotId"
            clearable
            placeholder="(選択なし)"
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot?.id"
              :label="depot?.name"
              :value="depot?.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="入庫拠点" prop="inDepotId">
          <el-select
            v-model="editForm.inDepotId"
            clearable
            placeholder="(選択なし)"
            :disabled="isConfirm"
          >
            <el-option
              v-for="depot in depotList"
              :key="depot?.id"
              :label="depot?.name"
              :value="depot?.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="partCode" label="品番">
          <el-input
            v-model="editForm.partCode"
            @change="changePartCode"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item prop="itemName" label="品名">
          <el-input v-model="editForm.itemName" :disabled="true" />
        </el-form-item>
        <el-form-item prop="moveQty" label="移動数">
          <el-input-number
            v-model="editForm.moveQty"
            placeholder=""
            :min="1"
            :precision="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item prop="note" label="備考" class="note">
          <el-input v-model="editForm.note" :disabled="isConfirm" />
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="update()">
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push(`/move/${moveId}`)"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormItemRule, FormRules } from 'element-plus';
  import { MoveEditForm } from '@/types/move';
  import { DepotsDataState } from '@/types/master/depot';
  import { ItemsDataState } from '@/types/master/item';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<MoveEditForm>({
    moveDate: Date.now(),
    outDepotId: null,
    inDepotId: null,
    partCode: '',
    itemName: '',
    moveQty: null,
    note: ''
  });
  const existsItemValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      itemList.value.find((item) => item.partCode === editForm.value.partCode)
    ) {
      callback();
    } else {
      callback(new Error('該当する単品がありません。'));
    }
  };
  const setDepodValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (editForm.value.inDepotId != null || editForm.value.outDepotId != null) {
      if (editForm.value.inDepotId !== editForm.value.outDepotId) {
        callback();
      } else {
        callback(new Error('出庫拠点、入庫拠点が同じです。'));
      }
    } else {
      callback(new Error('出庫拠点、入庫拠点が設定されていません。'));
    }
  };
  const rules = ref<FormRules>({
    moveDate: [{ required: true, message: '移動日は必須です。' }],
    outDepotId: [{ validator: setDepodValidator, trigger: 'blur' }],
    inDepotId: [{ validator: setDepodValidator, trigger: 'blur' }],
    partCode: [
      { required: true, message: '品番は必須です。' },
      { validator: existsItemValidator, trigger: 'blur' }
    ],
    moveQty: [{ required: true, message: '移動数は必須です。' }]
  });
  const depotList = ref<(DepotsDataState | null)[]>([]);
  const itemList = ref<ItemsDataState[]>([]);
  const itemId = ref<number | null>(null);
  let moveId: number;
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    if (!store.state.move.detailData) {
      router.push('/move');
    } else {
      editForm.value = {
        moveDate: store.state.move.detailData.moveDate
          ? Number(store.state.move.detailData.moveDate)
          : null,
        outDepotId: store.state.move.detailData.outDepotId,
        inDepotId: store.state.move.detailData.inDepotId,
        partCode: store.state.move.detailData.partCode,
        itemName: store.state.move.detailData.itemName,
        moveQty: store.state.move.detailData.moveQty,
        note: store.state.move.detailData.note
      };
      itemId.value = store.state.move.detailData.itemId;
      moveId = store.state.move.detailData.id;
      isConfirm.value = store.state.move.detailData.isConfirm;
    }
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('depotsMaster/fetch'),
      store.dispatch('itemsMaster/fetch')
    ]);
    depotList.value = store.state.depotsMaster.listData;
    depotList.value.unshift(null);
    itemList.value = store.state.itemsMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = async () => {
    const putData = {
      id: moveId,
      moveDate: editForm.value.moveDate?.toString() ?? '',
      outDepotId: editForm.value.outDepotId,
      inDepotId: editForm.value.inDepotId,
      itemId: itemId.value,
      moveQty: editForm.value.moveQty,
      note: editForm.value.note
    };
    await store.dispatch('move/put', putData);
    router.push('/move');
  };
  const changePartCode = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    editForm.value.itemName = '';
    itemId.value = 0;
    const item = itemList.value.find(
      (item) => item.partCode === editForm.value.partCode
    );
    if (item) {
      await store.dispatch('itemMaster/fetch', item.id);
      const itemDetail = store.state.itemMaster.detailData;
      if (itemDetail) {
        itemId.value = itemDetail.id;
        editForm.value.itemName = itemDetail.name;
      }
    }
    loadingInstance.close();
  };
  initialize();
</script>
<style lang="scss">
  .move-edit {
    form {
      width: 650px;
      display: inline-block;
    }
    .el-input-number {
      .el-input {
        float: left;
        width: 130px;
      }
      .el-input__inner {
        text-align: left;
      }
    }
    .el-select {
      width: 330px;
      float: left;
    }
    .el-form-item {
      font-weight: 600 !important;

      span {
        color: #606266;
        font-weight: 100;
      }
    }
    .el-button {
      font-weight: 600;
    }
    .button-area {
      margin: 0px 0px 10px 0px;
    }
    .note {
      width: 650px;
    }
  }
</style>
