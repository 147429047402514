<template>
  <BaseSimpleLayout class="master-car-maker-list">
    <template #title>車メーカマスタ一覧</template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column prop="id" label="ID" :width="100" align="center" />
        <el-table-column prop="name" label="メーカー" />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { CarMakersDataState } from '@/types/master/carMaker';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<CarMakersDataState[]>([]);
  const loadingFlag = ref(false);
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('carMakersMaster/fetch');
    tableData.value = store.state.carMakersMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .master-car-maker-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      width: 500px;
      margin: 10px auto;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
