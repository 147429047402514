<template>
  <BaseSimpleLayout class="unrecord-detail">
    <template #title>未計上受注詳細</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/unrecord')">
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        size="large"
        type="success"
        @click="router.push('/unrecord/create/input')"
      >
        売上登録
      </el-button>
      <el-button
        v-if="orderRole"
        size="large"
        type="warning"
        @click="dialogVisible = true"
      >
        計上無し
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="unrecord-detail-table-area">
        <div class="unrecord-detail-table-line">
          <BaseDataTable
            :table-data="leftData"
            :label-width="165"
            table-size="mini"
          />
        </div>
        <div class="unrecord-detail-table-line">
          <BaseDataTable
            :table-data="rightData"
            :label-width="125"
            table-size="mini"
          />
        </div>
      </div>
      <div class="unrecord-detail-details-area">
        <div class="table-title">製品情報</div>
        <el-table
          :data="productData"
          stripe
          border
          class="accept-create-product-details-table"
          empty-text="製品が存在しません"
          size="small"
        >
          <el-table-column
            prop="productName"
            label="名称"
            align="center"
            class-name="unit-master-product-details-name"
          />
          <el-table-column
            prop="productPrice"
            label="価格"
            width="80px"
            align="center"
            class-name="unit-master-product-details-name"
          />
          <el-table-column
            prop="productType"
            label="種別"
            width="120px"
            align="center"
            class-name="unit-master-product-details-qty"
          >
            <template #default="scope">
              <span>{{ productType[scope.row.productType] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="productQty"
            label="個数"
            width="60px"
            align="center"
            class-name="unit-master-product-details-qty"
          />
        </el-table>
      </div>
      <el-dialog v-model="dialogVisible" title="確認" width="500px">
        <span>
          <b>この未計上受注を「売上計上なし」にしますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="outRecordSale">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import {
    createUnrecordLeftTableData,
    createUnrecordRightTableData
  } from '@/libs/sale';
  import { UnrecordProductsState } from '@/types/unrecord';
  import { DetailTableData } from '@/types/common';
  import { productType } from '@/libs/product';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const props = defineProps({
    unrecordId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const leftData = ref<DetailTableData[]>([]);
  const rightData = ref<DetailTableData[]>([]);
  const loadingFlag = ref(false);
  const orderRole = rolePermission(['ORDERING', 'ORDERADMIN']);
  const productData = ref<UnrecordProductsState[]>([]);
  const dialogVisible = ref(false);
  let acceptId = '';

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('companiesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch'),
      store.dispatch('supportShopsMaster/fetch'),
      store.dispatch('management/fetch'),
      store.dispatch('unrecord/fetchDetail', props.unrecordId)
    ]);
    const detailData = store.state.unrecord.detailData;
    if (!detailData) return router.push('/unrecord');
    leftData.value = createUnrecordLeftTableData(detailData);
    rightData.value = createUnrecordRightTableData(detailData);
    productData.value = detailData.details;
    acceptId = detailData.acceptId;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const outRecordSale = () => {
    dialogVisible.value = false;
    store.dispatch('unrecord/noRecord', acceptId);
  };
  initialize();
</script>
<style lang="scss">
  .unrecord-detail {
    .unrecord-detail-table-area {
      display: inline-block;
      margin-top: 20px;
    }
    .unrecord-detail-table-line {
      width: 500px;
      margin-left: 10px;
      float: left;
    }
    .unrecord-detail-details-area {
      width: 750px;
      margin: 20px auto;
      .table-title {
        font-size: 18px;
        font-weight: 600;
      }
      .el-table {
        font-size: 14px;
        font-weight: 600;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
