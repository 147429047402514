<template>
  <BaseSimpleLayout class="bulk-create">
    <template #title>バルク受注入力</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="180px"
      >
        <el-form-item
          label="受注日"
          prop="acceptDate"
          class="bulk-create-accept-date"
        >
          <el-date-picker
            v-model="createForm.acceptDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
          />
        </el-form-item>
        <el-form-item
          label="ファイナンス"
          prop="financeId"
          class="bulk-create-select"
        >
          <el-select v-model="createForm.financeId" placeholder="選択">
            <el-option
              v-for="finance in financeList"
              :key="finance.id"
              :label="finance.name"
              :value="finance.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="bulk-create-select"
          label="製品名"
          prop="productId"
        >
          <el-select
            v-model="createForm.productId"
            placeholder="選択"
            @change="handleProductChange"
          >
            <el-option
              v-for="product in productList"
              :key="product.id"
              :label="product.name"
              :value="product.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="bulk-create-select" label="個数" prop="productQty">
          <el-input-number
            v-model="createForm.productQty"
            placeholder=""
            :min="1"
            :precision="0"
            :step="1"
          />
        </el-form-item>
        <el-form-item label="東京専用備考" prop="note">
          <el-input v-model="createForm.note" type="textarea" />
        </el-form-item>
        <el-form-item label="発送時連携用備考" prop="shipNote">
          <el-input v-model="createForm.shipNote" type="textarea" />
        </el-form-item>
        <div class="bulk-create-button-area">
          <el-button size="large" type="info" @click="backPage()">
            戻る
          </el-button>
          <el-button size="large" type="primary" @click="moveNext(formRef)">
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { BulkInputData } from '@/types/bulk';
  import { FinancesDataState } from '@/types/master/finance';
  import { ElLoading } from 'element-plus';
  import { ProductsDataState } from '@/types/master/product';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<BulkInputData>({
    acceptDate: null,
    financeId: null,
    productId: null,
    productQty: null,
    note: '',
    shipNote: '',
    deviceType: 1
  });
  const rules = ref<FormRules>({
    acceptDate: [{ required: true, message: '受注日は必須です。' }],
    financeId: [{ required: true, message: 'ファイナンスは必須です。' }],
    productId: [{ required: true, message: '製品名は必須です。' }],
    productQty: [{ required: true, message: '製品数は必須です。' }]
  });
  const productList = ref<ProductsDataState[]>([]);
  const financeList = ref<FinancesDataState[]>([]);
  const loadingFlag = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('financesMaster/fetch'),
      store.dispatch('productsMaster/fetch')
    ]);
    const submitData = store.state.bulk.submitData;
    if (!submitData) {
      return router.push('/bulk');
    }
    createForm.value = {
      acceptDate: submitData.acceptDate,
      financeId: submitData.financeId,
      productId: submitData.productId,
      productQty: submitData.productQty,
      note: submitData.note,
      shipNote: submitData.shipNote,
      deviceType: 1
    };
    productList.value = store.state.productsMaster.listData.filter(
      (data: ProductsDataState) => data.productType === 1
    );
    financeList.value = store.state.financesMaster.listData.filter(
      (data: FinancesDataState) => data.isBulk
    );
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const moveNext = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      store.dispatch('bulk/setSubmitData', createForm.value);
      router.push('/bulk/confirm');
    });
  };
  const backPage = () => {
    const bulkId = store.state.bulk.bulkId;
    if (bulkId) {
      router.push('/bulk/' + bulkId);
    } else {
      router.push('/bulk');
    }
  };

  const handleProductChange = (productId: number) => {
    const selectedProduct = productList.value.find(
      (product) => product.id === productId
    );
    if (selectedProduct) {
      createForm.value.deviceType = selectedProduct.deviceType;
    }
  };

  initialize();
</script>
<style lang="scss">
  .bulk-create {
    form {
      width: 600px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .bulk-create-select {
      text-align: left;
      .el-select {
        width: 300px;
      }
    }
    .bulk-create-button-area {
      margin: 0px 0px 15px 0px;
    }
    .bulk-create-accept-date {
      .el-input {
        float: left;
        width: 150px;
      }
    }
  }
</style>
