/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData, ListData } from '@/types/common';

export const receiveType: NumberData = {
  1: '預かり',
  2: '代替（先発）',
  3: '代替（後発）',
  4: 'キャンセル',
  5: '韓国検証（出荷前）',
  6: 'リビルド',
  7: '買取',
  8: '解約'
};

export const receiveTypeList: ListData[] = [
  {
    value: 1,
    label: '預かり'
  },
  {
    value: 2,
    label: '代替（先発）'
  },
  {
    value: 4,
    label: 'キャンセル'
  },
  {
    value: 5,
    label: '韓国検証（出荷前）'
  },
  {
    value: 6,
    label: 'リビルド'
  },
  {
    value: 7,
    label: '買取'
  },
  {
    value: 8,
    label: '解約'
  }
];

export const receiveStatus: NumberData = {
  1: '仮登録',
  2: '本登録'
};

export const accidentType: NumberData = {
  0: 'なし',
  1: '不具合',
  2: '処分'
};

export const accidentTypeList: ListData[] = [
  {
    value: 0,
    label: 'なし'
  },
  {
    value: 1,
    label: '不具合'
  },
  {
    value: 2,
    label: '処分'
  }
];
