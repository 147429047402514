/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  SubCategoryState,
  SubCategoryDetailDataState,
  SubCategoryPutDataState
} from '@/types/master/subCategory';
import _ from 'lodash';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: SubCategoryState, payload: SubCategoryDetailDataState) {
      state.detailData = payload;
    },
    setPut(state: SubCategoryState, payload: SubCategoryPutDataState) {
      state.putData = payload;
    },
    setSubCategoryId(state: SubCategoryState, payload: number) {
      state.subCategoryId = payload;
    }
  },
  actions: {
    async fetch(context: any, subCategoryMasterId: any) {
      await axios
        .get(getBaseUrl() + '/master/subcategories/' + subCategoryMasterId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          delete setData.averageCost;
          context.commit('setPut', setData);
          context.commit('setSubCategoryId', subCategoryMasterId);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '分類情報が存在しません。');

            return router.push('/master/subCategory');
          }
          if (e.response.status < 500) {
            error(e.response.status);

            return router.push('/master/subCategory');
          }
          router.push('/error/server');
        });
    },
    async put(context: any, payload: SubCategoryPutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/subcategories/' + payload.id,
          {
            name: payload.name,
            categoryId: payload.categoryId
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then((response) => {
          context.commit('setSubmitResponseData', response.data);
          success('Success', '分類情報の変更に成功しました。');
          router.push('/master/subCategory');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '分類情報が存在しません。');
            return router.push('/master/subCategory');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
