<template>
  <BaseSimpleLayout class="device-edit">
    <template #title>【セキュリティ】端末情報編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <div class="input-area">
          <el-form-item label="ID" prop="id">
            <el-input v-model="editForm.id" autocomplete="off" disabled />
          </el-form-item>
          <el-form-item label="シリアル番号" prop="serialCode">
            <el-input
              v-model="editForm.serialCode"
              autocomplete="off"
              :disabled="!editable"
              @change="changeNumber(editForm, 'serialCode')"
            />
          </el-form-item>
          <el-form-item label="製造番号" prop="deviceCode">
            <el-input
              v-model="editForm.deviceCode"
              autocomplete="off"
              @change="changeString(editForm, 'deviceCode')"
            />
          </el-form-item>
          <el-form-item label="端末タイプ" prop="deviceType">
            <el-select
              v-model="editForm.deviceType"
              :disabled="isReady"
              placeholder=""
            >
              <el-option
                v-for="item in deviceTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="SIM番号" prop="simNumber">
            <el-input
              v-model="editForm.simNumber"
              autocomplete="off"
              disabled
            />
          </el-form-item>
          <el-form-item label="IMSI" prop="simImsi">
            <el-input v-model="editForm.simImsi" autocomplete="off" disabled />
          </el-form-item>
          <el-form-item
            :disabled="!editable"
            label="出荷ファームVer"
            prop="firmVersion"
          >
            <el-input v-model="editForm.firmVersion" autocomplete="off" />
          </el-form-item>
          <el-form-item label="出荷コンフィグ" prop="configName">
            <el-input
              v-model="editForm.configName"
              autocomplete="off"
              :disabled="!editable"
            />
          </el-form-item>
          <el-form-item
            :disabled="!editable"
            label="IBバッテリー"
            prop="internalBatteryVoltage"
          >
            <el-input
              v-model="editForm.internalBatteryVoltage"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="Mboot" prop="mboot">
            <el-input
              v-model="editForm.mboot"
              autocomplete="off"
              :disabled="!editable"
            />
          </el-form-item>
          <el-form-item label="設定ID" prop="deviceSettingUser">
            <el-input
              v-model="editForm.deviceSettingUser"
              autocomplete="off"
              disabled
            />
          </el-form-item>
          <el-form-item label="設定パスワード" prop="deviceSettingPassword">
            <el-input
              v-model="editForm.deviceSettingPassword"
              autocomplete="off"
              disabled
            />
          </el-form-item>
        </div>
        <div class="input-area">
          <el-form-item label="取扱" prop="handlingType">
            <el-select v-model="editForm.handlingType" placeholder="">
              <el-option
                v-for="item in handlingTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="確定端末"
            prop="orderCompanyId"
            class="device-edit-order-company"
          >
            <el-select
              v-model="editForm.orderCompanyId"
              :disabled="!editable"
              filterable
              clearable
              placeholder=""
            >
              <el-option
                v-for="item in companiesList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="発注番号" prop="autobacsOrderNumber">
            <el-input
              v-model="editForm.autobacsOrderNumber"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="備考" prop="note">
            <el-input
              v-model="editForm.note"
              type="textarea"
              :rows="3"
              autocomplete="off"
            />
          </el-form-item>
        </div>
      </el-form>
      <div class="button-area">
        <el-button
          type="info"
          size="large"
          @click="router.push(`/carSecurity/device/detail/${editForm.id}`)"
        >
          戻る
        </el-button>
        <el-button type="primary" size="large" @click="moveConfirm(formRef)">
          確認
        </el-button>
      </div>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { CarSecurityDevicePutData } from '@/types/carSecurityDevice';
  import { CompaniesDataState } from '@/types/master/company';
  import {
    validateSerial,
    deviceTypeList,
    verifyEditRule,
    handlingTypeList
  } from '@/libs/device';
  import {
    numberRestriction,
    alphanumericRestriction
  } from '@/libs/restriction';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const isReady = ref(false);
  const editable = ref(false);
  const editForm = ref<CarSecurityDevicePutData>({
    id: 0,
    deviceCode: '',
    deviceType: 1,
    serialCode: '',
    simNumber: '',
    simImsi: '',
    firmVersion: '',
    deviceSettingUser: '',
    deviceSettingPassword: '',
    internalBatteryVoltage: '',
    mboot: '',
    note: '',
    handlingType: 0,
    autobacsOrderNumber: ''
  });
  const rules = ref<FormRules>({
    serialCode: [
      { required: true, message: 'シリアル番号は必須です。' },
      { validator: validateSerial, trigger: 'blur' }
    ]
  });
  const companiesList = ref<CompaniesDataState[]>([]);

  const initialize = async () => {
    if (!store.state.carSecurityDevice.putData) {
      router.push('/carSecurity/device/3');
      return;
    }
    editForm.value = store.state.carSecurityDevice.putData;
    const status = store.state.carSecurityDevice.status;
    isReady.value = status > 1;
    editable.value = status === 1 || status === 2;
    companiesList.value = store.state.companiesMaster.listData.filter(
      (data: CompaniesDataState) => data.isAdvanceOrder
    );
    if (status >= 2) {
      rules.value = verifyEditRule;
    }
  };

  const moveConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      if (!editForm.value.orderCompanyId) editForm.value.orderCompanyId = null;
      store.dispatch('carSecurityDevice/setPutData', editForm.value);
      router.push('/carSecurity/device/detail/edit/confirm');
    });
  };

  const changeNumber = (form: CarSecurityDevicePutData, key: string) => {
    form[key] = numberRestriction(form[key] as string);
  };

  const changeString = (form: CarSecurityDevicePutData, key: string) => {
    form[key] = alphanumericRestriction(form[key] as string);
  };
  initialize();
</script>
<style lang="scss">
  .device-edit {
    form {
      width: 1000px;
      margin: auto;
      height: 480px;
      .el-form-item {
        font-weight: 500 !important;
        .el-form-item__label {
          font-weight: 600;
        }
      }
      .input-area {
        float: left;
        .el-input {
          width: 200px;
          float: left;
        }
        .el-select {
          float: left;
        }
        .device-edit-order-company {
          .el-input {
            width: 300px;
            float: left;
          }
        }
        .el-input.is-disabled .el-input__inner {
          color: #606266;
        }
        textarea {
          width: 300px;
        }
      }
    }
    .button-area {
      margin: 20px 0px;
      .el-button {
        font-weight: 600;
        margin: 20px 10px;
      }
    }
  }
</style>
