<template>
  <BaseSimpleLayout class="company-master-create">
    <template #title>販売店企業登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item
          label="種別"
          prop="isAdvanceOrder"
          class="company-master-input-type"
        >
          <el-radio-group
            v-model="createForm.isAdvanceOrder"
            :disabled="isConfirm"
          >
            <el-radio :label="false">通常</el-radio>
            <el-radio :label="true">先行受注</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="company-master-create-zip"
          label="郵便番号"
          prop="zip"
        >
          <el-input
            v-model="createForm.zip"
            autocomplete="off"
            maxlength="7"
            :disabled="isConfirm"
            @change="zipRestrict"
          />
        </el-form-item>
        <el-form-item
          class="company-create-prefecture"
          label="都道府県"
          prop="prefecture"
        >
          <el-select
            v-model="createForm.prefecture"
            filterable
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="prefecture in prefectureList"
              :key="prefecture"
              :label="prefecture"
              :value="prefecture"
              autocomplete="off"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="company-master-create-city"
          label="市区町村"
          prop="city"
        >
          <el-input
            v-model="createForm.city"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="住所" prop="address">
          <el-input
            v-model="createForm.address"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="住所サブ" prop="addressSub">
          <el-input
            v-model="createForm.addressSub"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="company-master-create-tel"
          label="電話番号"
          prop="tel"
        >
          <el-input
            v-model="createForm.tel"
            autocomplete="off"
            maxlength="13"
            :disabled="isConfirm"
            @change="telRestrict()"
          />
        </el-form-item>
        <el-form-item class="company-master-create-fax" label="FAX" prop="fax">
          <el-input
            v-model="createForm.fax"
            autocomplete="off"
            maxlength="12"
            :disabled="isConfirm"
            @change="faxRestrict()"
          />
        </el-form-item>
        <el-form-item label="メールアドレス" prop="email">
          <el-input
            v-model="createForm.email"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="担当者名" prop="staffName">
          <el-input
            v-model="createForm.staffName"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-button
          type="success"
          class="company-input-group-button"
          @click="dialogFormVisible = true"
          :disabled="isConfirm"
        >
          販売店グループ追加
        </el-button>
        <el-table
          :data="createForm.companyGroupList"
          stripe
          border
          style="width: 625px"
          empty-text="所属販売店グループが存在しません"
        >
          <el-table-column prop="id" label="ID" width="60px" align="center">
            <template #default="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称">
            <template #default="scope">
              <span>{{ groupDataChanger[scope.row.id].name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="種別" width="80px" align="center">
            <template #default="scope">
              <span>{{
                groupTypeData[groupDataChanger[scope.row.id].companyGroupType]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="90px" align="center">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                class="unit-master-package-details-button-delete"
                :disabled="isConfirm"
                @click="deleteItem(scope.$index)"
              >
                削除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="itemErrorVisible" class="company-input-item-error-text">
          FC登録は必須です。
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" @click="submit()"> 登録 </el-button>
        </div>
        <div v-else class="button-area">
          <el-button type="info" @click="router.push('/master/company')">
            戻る
          </el-button>
          <el-button type="primary" @click="changeConfirm(formRef)">
            確認
          </el-button>
        </div>
      </el-form>
      <el-dialog
        v-model="dialogFormVisible"
        title="所属販売店グループ登録"
        width="525px"
      >
        <el-form :model="groupForm" class="company-input-group-form">
          <el-form-item label="名称" label-width="40px" size="large">
            <el-select v-model="groupForm.id" filterable placeholder="Select">
              <el-option
                v-for="group in companyGroups"
                :key="group.id"
                :label="
                  group.name + '(' + groupTypeData[group.companyGroupType] + ')'
                "
                :value="group.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="addItem">
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { CompaniesSubmitData } from '@/types/master/company';
  import {
    CompanyGroupsDataState,
    CompanyGroupReplaceData
  } from '@/types/master/companyGroup';
  import { prefectureList } from '@/libs/prefecture';
  import { groupTypeData } from '@/libs/companyGroup';
  import { ElLoading } from 'element-plus';
  import { telRestriction, zipRestriction } from '@/libs/common';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<CompaniesSubmitData>({
    isAdvanceOrder: false,
    name: '',
    zip: '',
    prefecture: '',
    city: '',
    address: '',
    addressSub: '',
    tel: '',
    fax: '',
    email: '',
    staffName: '',
    companyGroupList: []
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: '名称は必須です。' }],
    zip: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    prefecture: [{ required: true, message: '都道府県は必須です。' }],
    city: [{ required: true, message: '市区町村は必須です。' }],
    address: [{ required: true, message: '住所は必須です。' }],
    tel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    fax: [
      {
        min: 10,
        max: 12,
        message:
          'FAXは10桁から12桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    email: [
      {
        type: 'email',
        message: 'メールアドレス形式で入力して下さい。',
        trigger: 'blur'
      }
    ]
  });
  const companyGroups = ref<CompanyGroupsDataState[]>([]);
  const groupDataChanger = ref<CompanyGroupReplaceData>({});
  const dialogFormVisible = ref(false);
  const itemErrorVisible = ref(false);
  const loadingFlag = ref(false);
  const groupForm = ref({
    id: 0
  });
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('companyGroupsMaster/fetch');
    companyGroups.value = store.state.companyGroupsMaster.listData;
    groupDataChanger.value = store.state.companyGroupsMaster.replaceData;
    groupForm.value = {
      id: companyGroups.value[0].id
    };
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    let isValid = true;
    await formEl.validate((valid) => {
      if (!valid) {
        isValid = false;
      }
      if (createForm.value.companyGroupList.length === 0) {
        itemErrorVisible.value = true;
        isValid = false;
      }
      if (!isValid) {
        return;
      }
      isConfirm.value = true;
    });
  };
  const addItem = () => {
    createForm.value.companyGroupList.push(groupForm.value);
    dialogFormVisible.value = false;
    groupForm.value = {
      id: companyGroups.value[0].id
    };
    itemErrorVisible.value = false;
  };
  const deleteItem = (index: number) => {
    createForm.value.companyGroupList.splice(index, 1);
    if (createForm.value.companyGroupList.length === 0) {
      itemErrorVisible.value = true;
    }
  };
  const zipRestrict = () => {
    createForm.value.zip = zipRestriction(createForm.value.zip);
  };
  const telRestrict = () => {
    createForm.value.tel = telRestriction(createForm.value.tel as string);
  };
  const faxRestrict = () => {
    createForm.value.fax = telRestriction(createForm.value.fax as string);
  };
  const submit = () => {
    store.dispatch('companiesMaster/submit', createForm.value);
  };
  initialize();
</script>
<style lang="scss">
  .company-master-create {
    form {
      width: 600px;
      display: inline-block;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-select {
      width: 300px;
      float: left;
    }

    .company-master-input-type {
      .el-form-item__content {
        width: 300px;
      }
    }

    .company-master-create-zip .el-input {
      width: 90px;
      float: left;
    }

    .company-create-prefecture {
      .el-input {
        width: 140px;
        float: left;
      }
      .el-select {
        width: 140px;
      }
    }

    .company-master-create-city .el-input {
      width: 140px;
      float: left;
    }

    .company-master-create-tel .el-input {
      width: 135px;
      float: left;
    }

    .company-master-create-fax .el-input {
      width: 135px;
      float: left;
    }

    .button-area {
      margin: 20px 0px;
    }

    .el-button {
      font-weight: 600;
    }

    .company-input-group-form {
      width: 420px;
    }

    .company-input-group-button {
      margin-bottom: 20px;
    }

    .company-input-item-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      text-align: left;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        font-size: 13px;
        font-weight: 600;
      }
    }

    .company-input-group-form {
      .el-input {
        width: 380px;
      }
    }
  }
</style>
