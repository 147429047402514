<template>
  <BaseSimpleLayout class="activation-list">
    <template #title>起動制御解除一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="orderRole"
        size="large"
        type="success"
        @click="moveToCreate"
      >
        解除予約
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">受注日</span>
        <span class="index">開始:</span>
        <el-date-picker
          v-model="filterForm.reserveStartDate"
          type="date"
          placeholder="選択"
          style="width: 140px"
          format="YYYY-MM-DD"
          value-format="x"
          @change="filterData"
        />
        <span class="index">終了:</span>
        <el-date-picker
          v-model="filterForm.reserveEndDate"
          type="date"
          placeholder="選択"
          style="width: 140px"
          format="YYYY-MM-DD"
          value-format="x"
          @change="filterData"
        />
        <span class="label">フィルター</span>
        <el-input
          v-model="filterForm.filterText"
          placeholder="検索"
          @input="filterData"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        @row-click="rowClick"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column
          property="customerCode"
          label="顧客管理番号"
          align="center"
          width="240"
          sortable
        />
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ activationStatus[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          property="reserveDate"
          label="予約日時"
          align="center"
          width="200"
          sortable
        >
          <template #default="scope">
            {{ convertAcceptDate(scope.row.reserveDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="updateUserName"
          label="予約者"
          align="center"
          sortable
        />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { formatTimeStamp, addDay } from '@/libs/dateFormat';
  import {
    ActivationListData,
    ActivationTableData,
    ActivationFilterForm
  } from '@/types/activation';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';
  import { activationStatus } from '@/libs/activation';

  const store = useStore();
  const tableData = ref<ActivationListData[]>([]);
  const filterForm = ref<ActivationFilterForm>({
    reserveStartDate: null,
    reserveEndDate: null,
    filterText: null
  });
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.state.activation.inputData = null;
    await store.dispatch('activation/fetch');
    tableData.value = store.state.activation.listData;
    tableHeight.value = window.innerHeight - 160;
    loadingFlag.value = true;
    loadingInstance.close();
  };

  const filterData = () => {
    tableData.value = store.state.activation.listData.filter(
      (data: ActivationListData) => {
        let reserveFromResult = !filterForm.value.reserveStartDate;
        let reserveToResult = !filterForm.value.reserveEndDate;
        if (filterForm.value.reserveStartDate && data.reserveDate) {
          reserveFromResult =
            filterForm.value.reserveStartDate <= Date.parse(data.reserveDate);
        }
        if (filterForm.value.reserveEndDate && data.reserveDate) {
          reserveToResult =
            Date.parse(data.reserveDate) <=
            addDay(filterForm.value.reserveEndDate, 1);
        }
        if (!reserveFromResult || !reserveToResult) return false;
        const status = activationStatus[data.status];
        if (!filterForm.value.filterText) return true;
        return (
          status.includes(filterForm.value.filterText) ||
          data.customerCode.includes(filterForm.value.filterText) ||
          data.updateUserName.includes(filterForm.value.filterText)
        );
      }
    );
  };
  const rowClick = async (activationReserve: ActivationTableData) => {
    await store.dispatch(
      'activation/fetchDevice',
      activationReserve.customerCode
    );
    store.dispatch('activation/setDetailData', activationReserve);
    router.push('/activation/detail');
  };
  const moveToCreate = () => {
    router.push('/activation/create');
  };
  const convertAcceptDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatTimeStamp(date);
  };
  initialize();
</script>
<style lang="scss">
  .activation-list {
    .filter-area {
      margin: 20px 0px;

      .label {
        margin: 0 10px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 300px;
      }
    }
    .el-table {
      width: 1000px;
      margin: 10px auto;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
