/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error, success } from '@/libs/notification';
import router from '@/router';
import {
  SupplierState,
  SupplierDetailData,
  SupplierPutData
} from '@/types/master/supplier';

import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: SupplierState, payload: SupplierDetailData) {
      state.detailData = payload;
    },
    setPut(state: SupplierState, payload: SupplierPutData) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, supplierMasterId: any) {
      await axios
        .get(`${getBaseUrl()}/master/suppliers/${supplierMasterId}`, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '仕入先情報が存在しません。');
            router.push('/master/supplier');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/supplier');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, supplierMasterId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(`${getBaseUrl()}/master/suppliers/${supplierMasterId}`, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '仕入先情報の削除に成功しました。');
          router.push('/master/supplier');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '仕入先情報が存在しません。');

            return router.push('/master/supplier');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: SupplierPutData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          `${getBaseUrl()}/master/suppliers/${payload.id}`,
          {
            name: payload.name,
            post: payload.post,
            address: payload.address,
            tel: payload.tel,
            fax: payload.fax,
            managerName: payload.managerName
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '仕入先情報の変更に成功しました。');
          router.push('/master/supplier');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '仕入先情報が存在しません。');
            router.push('/master/supplier');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
