/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  SubSubCategoryState,
  SubSubCategoryDetailDataState,
  SubSubCategoryPutDataState
} from '@/types/master/subSubCategory';
import _ from 'lodash';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(
      state: SubSubCategoryState,
      payload: SubSubCategoryDetailDataState
    ) {
      state.detailData = payload;
    },
    setPut(state: SubSubCategoryState, payload: SubSubCategoryPutDataState) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, subSubCategoryMasterId: any) {
      await axios
        .get(
          getBaseUrl() + '/master/subsubcategories/' + subSubCategoryMasterId,
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '小分類情報が存在しません。');

            return router.push('/master/subSubCategory');
          }
          if (e.response.status < 500) {
            error(e.response.status);

            return router.push('/master/subSubCategory');
          }
          router.push('/error/server');
        });
    },
    async put(context: any, payload: SubSubCategoryPutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/subsubcategories/' + payload.subCategoryId,
          {
            subCategoryId: payload.subCategoryId,
            code: payload.subCategoryId,
            name: payload.name
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          router.push('/master/subSubCategory');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '小分類情報が存在しません。');
            return router.push('/master/subSubCategory');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
