<template>
  <BaseSimpleLayout class="sub-category-master-create">
    <template #title>分類登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="createFormRef"
        :model="createForm"
        :rules="createFormRules"
        size="large"
        label-width="120px"
      >
        <el-form-item label="分類名" prop="name">
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="カテゴリー" prop="categoryId">
          <el-select
            v-model="createForm.categoryId"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="category in categoryList"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </el-select>
        </el-form-item>
        <el-button
          type="success"
          class="add-sub-sub-category-button"
          size="large"
          :disabled="isConfirm"
          @click="openDialog"
        >
          小分類追加
        </el-button>
        <el-table
          :data="subSubCategories"
          stripe
          border
          style="width: 700px"
          empty-text="小分類が存在しません"
        >
          <el-table-column
            prop="code"
            label="小分類コード"
            width="120px"
            align="center"
          />
          <el-table-column prop="name" label="小分類名" />
        </el-table>
        <div
          v-if="subSubCategoryErrorVisible"
          class="sub-sub-category-input-details-error-text"
        >
          小分類登録は必須です。
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/master/subCategory')"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(createFormRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
      <el-dialog v-model="dialogFormVisible" title="小分類登録" width="600px">
        <el-form
          ref="dialogFormRef"
          :model="dialogForm"
          :rules="dialogFormRules"
          size="large"
          label-width="120px"
        >
          <el-form-item prop="code" label="小分類コード">
            <el-input-number
              ref="codeInputRef"
              v-model="dialogForm.code"
              placeholder=""
              :min="0"
              :precision="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item prop="name" label="小分類名">
            <el-input v-model="dialogForm.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button
            size="large"
            type="primary"
            @click="addSubSubCategory(dialogFormRef)"
          >
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type {
    ElInputNumber,
    FormInstance,
    FormItemRule,
    FormRules,
    InputNumberInstance
  } from 'element-plus';
  import { CategoryListState } from '@/types/category';
  import {
    SubCategoriesSubmitDataState,
    SubSubCategory
  } from '@/types/master/subCategory';
  import { SubSubCategoriesSubmitDataState } from '@/types/master/subSubCategory';
  import { ElLoading } from 'element-plus';

  const emptyDialogFormValue: SubSubCategoriesSubmitDataState = {
    subCategoryId: null,
    code: null,
    name: ''
  };

  const store = useStore();
  const createFormRef = ref<FormInstance>();
  const dialogFormRef = ref<FormInstance>();
  const codeInputRef = ref<InputNumberInstance>();
  const dialogFormVisible = ref(false);
  const subSubCategoryErrorVisible = ref(false);
  const createForm = ref<SubCategoriesSubmitDataState>({
    name: '',
    categoryId: null
  });
  const dialogForm = ref<SubSubCategoriesSubmitDataState>(emptyDialogFormValue);
  const duplicateSubSubCategoryValidator: FormItemRule['validator'] = (
    _rule,
    _value,
    callback
  ) => {
    if (
      !subSubCategories.value.find(
        (subSubCategory) => subSubCategory.code === dialogForm.value.code
      )
    ) {
      callback();
    } else {
      callback(new Error('小分類コードが重複しています。'));
    }
  };
  const createFormRules = ref<FormRules>({
    name: [{ required: true, message: '分類名は必須です。' }],
    categoryId: [{ required: true, message: 'カテゴリーは必須です。' }]
  });
  const dialogFormRules = ref<FormRules>({
    code: [
      { required: true, message: '小分類コードは必須です。' },
      { validator: duplicateSubSubCategoryValidator, trigger: 'blur' }
    ],
    name: [{ required: true, message: '小分類名は必須です。' }]
  });
  const categoryList = ref<CategoryListState[]>([]);
  const subSubCategories = ref<SubSubCategory[]>([]);
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    if (!store.state.categoryMaster.listData.length) {
      await store.dispatch('categoryMaster/fetch');
    }
    categoryList.value = store.state.categoryMaster.listData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    if (!subSubCategories.value.length) {
      subSubCategoryErrorVisible.value = true;
      return false;
    }
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = async () => {
    await store.dispatch('subCategoriesMaster/submit', createForm.value);
    subSubCategories.value.forEach((data: SubSubCategory) => {
      store.dispatch('subSubCategoriesMaster/submit', {
        subCategoryId: store.state.subCategoriesMaster.submitResponseData.id,
        code: data.code,
        name: data.name
      });
    });
  };
  const openDialog = () => {
    dialogFormVisible.value = true;
    setTimeout(() => {
      codeInputRef.value?.focus();
    }, 100);
  };
  const addSubSubCategory = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid || !dialogForm.value.code) {
        return false;
      }

      subSubCategories.value.push({
        code: dialogForm.value.code,
        name: dialogForm.value.name
      });
      dialogForm.value.name = '';
      dialogForm.value.code = null;
      dialogFormVisible.value = false;
      subSubCategoryErrorVisible.value = false;
      dialogForm.value = emptyDialogFormValue;
    });
  };
  initialize();
</script>
<style lang="scss">
  .sub-category-master-create {
    form {
      width: 450px;
      display: inline-block;
      margin: auto;
    }
    .el-input-number {
      .el-input {
        float: left;
        width: 130px;
      }
      .el-input__inner {
        text-align: left;
      }
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .el-select {
      width: 330px;
      float: left;
    }
    .el-button {
      font-weight: 600;
    }
    .button-area {
      margin: 10px 0;
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      margin: auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
    .add-sub-sub-category-button {
      margin-bottom: 20px;
    }
    .sub-sub-category-input-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: 0px;
    }
  }
</style>
