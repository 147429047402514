<template>
  <BaseSimpleLayout class="unrecord-list">
    <template #title>未計上受注一覧</template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">ファイナンス</span>
        <el-select
          v-model="filterForm.finance"
          filterable
          clearable
          placeholder="選択"
          @change="filterTable"
        >
          <el-option
            v-for="finance in financeList"
            :key="finance.id"
            :label="finance.name"
            :value="finance.name"
          />
        </el-select>
        <span class="label">販売店グループ</span>
        <el-select
          v-model="filterForm.companyGroup"
          filterable
          clearable
          placeholder="選択"
          @change="filterTable"
        >
          <el-option
            v-for="companyGroup in companyGroupList"
            :key="companyGroup.id"
            :label="companyGroup.name"
            :value="companyGroup.name"
          />
        </el-select>
        <span class="label">フィルター</span>
        <el-input
          v-model="filterForm.filterText"
          placeholder="検索"
          @change="filterTable"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        empty-text="データが存在しません。"
        size="small"
        :max-height="tableHeight"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @row-click="moveDetail"
      >
        <el-table-column
          prop="acceptId"
          label="受注ID"
          align="center"
          width="90"
          sortable
        />
        <el-table-column
          prop="customerCode"
          label="顧客管理番号"
          align="left"
          width="140"
          sortable
        />
        <el-table-column
          prop="financeCode"
          label="ファイナンス管理番号"
          align="left"
          width="190"
          sortable
        >
          <template #default="scope">
            {{ scope.row.financeCode ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="acceptDate"
          label="受注日"
          align="left"
          width="95"
          sortable
        >
          <template #default="scope">
            {{ convertDate(Number(scope.row.acceptDate)) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="shipDate"
          label="出荷日"
          align="left"
          width="95"
          sortable
        >
          <template #default="scope">
            {{ convertDate(Number(scope.row.shipDate)) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="mountDate"
          label="取付日"
          align="left"
          width="95"
          sortable
        >
          <template #default="scope">
            {{ convertDate(Number(scope.row.mountDate)) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="saleDeviceType"
          label="売上種別"
          align="left"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ deviceSaleType[scope.row.saleDeviceType] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="financeName"
          label="ファイナンス"
          align="left"
          sortable
        >
          <template #default="scope">
            {{ scope.row.financeName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="companyGroupName"
          label="販売店グループ"
          align="left"
          sortable
        >
          <template #default="scope">
            {{ scope.row.companyGroupName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="ステータス"
          align="left"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ managementStatus[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ElLoading } from 'element-plus';
  import { UnrecordListState, UnrecordFilterForm } from '@/types/unrecord';
  import { deviceSaleType } from '@/libs/sale';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompanyGroupsDataState } from '@/types/master/companyGroup';
  import { managementStatus } from '@/libs/management';
  import { formatDate } from '@/libs/dateFormat';

  const store = useStore();
  const tableData = ref<UnrecordListState[]>([]);
  const filterForm = ref<UnrecordFilterForm>({
    filterText: null,
    finance: null,
    companyGroup: null
  });
  const loadingFlag = ref(false);
  const financeList = ref<FinancesDataState[]>([]);
  const companyGroupList = ref<CompanyGroupsDataState[]>([]);
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('unrecord/fetch'),
      store.dispatch('financesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch')
    ]);
    const filterData = store.state.unrecord.filterData;
    if (filterData) {
      filterForm.value = filterData;
    }
    tableData.value = store.state.unrecord.listData;
    financeList.value = store.state.financesMaster.listData;
    companyGroupList.value = store.state.companyGroupsMaster.listData;
    tableHeight.value = window.innerHeight - 170;
    loadingFlag.value = true;
    loadingInstance.close();
  };

  const filterTable = () => {
    store.dispatch('unrecord/setFilterData', filterForm.value);
    tableData.value = store.state.unrecord.listData.filter(
      (data: UnrecordListState) => {
        if (filterForm.value.finance) {
          if (data.financeName !== filterForm.value.finance) return false;
        }
        if (filterForm.value.companyGroup) {
          if (data.companyGroupName !== filterForm.value.companyGroup)
            return false;
        }
        if (!filterForm.value.filterText) return true;
        const customerCode = data.customerCode ?? '-';
        const financeCode = data.financeCode ?? '-';
        const acceptDate = convertDate(data.acceptDate);
        const shipDate = convertDate(data.shipDate);
        const mountDate = convertDate(data.mountDate);
        const saleTypeData = data.saleDeviceType
          ? deviceSaleType[data.saleDeviceType]
          : '-';
        const status = data.status ? managementStatus[data.status] : '-';

        return (
          customerCode.includes(filterForm.value.filterText) ||
          financeCode.includes(filterForm.value.filterText) ||
          acceptDate.includes(filterForm.value.filterText) ||
          shipDate.includes(filterForm.value.filterText) ||
          mountDate.includes(filterForm.value.filterText) ||
          saleTypeData.includes(filterForm.value.filterText) ||
          status.includes(filterForm.value.filterText)
        );
      }
    );
  };

  const convertDate = (date: number | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const moveDetail = (row: UnrecordListState) => {
    router.push('/unrecord/' + row.acceptId);
  };
  initialize();
</script>
<style lang="scss">
  .unrecord-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    .filter-area {
      margin: 10px 0px;

      .label {
        margin: 0 10px 0 20px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 250px;
      }
    }
    .el-table {
      margin: 10px auto;
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        font-size: 13px;
      }
    }
    td {
      cursor: pointer;
    }
  }
</style>
