<template>
  <BaseSimpleLayout class="master-support-shop-edit">
    <template #title>サポート店編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <div class="support-shop-master-edit-form">
          <div>
            <el-form-item
              class="unit-master-support-shop-edit-edit-id"
              label="ID"
              prop="id"
            >
              <el-input v-model="editForm.id" autocomplete="off" disabled />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-name"
              label="サポート店名称"
              prop="name"
            >
              <el-input
                v-model="editForm.name"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-affiliation-union"
              label="所属組合"
              prop="affiliationUnion"
            >
              <el-input
                v-model="editForm.affiliationUnion"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-tel support-shop-edit-tel"
              label="電話番号"
              prop="tel"
            >
              <el-input
                v-model="editForm.tel"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="telRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-mobile-number support-shop-edit-mobile-number"
              label="携帯番号"
              prop="mobileNumber"
            >
              <el-input
                v-model="editForm.mobileNumber"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="mobileRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-staff-name support-shop-edit-staff-name"
              label="担当者"
              prop="staffName"
            >
              <el-input
                v-model="editForm.staffName"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item
              class="unit-master-support-shop-edit-edit-zip support-shop-edit-zip"
              label="郵便番号"
              prop="zip"
            >
              <el-input
                v-model="editForm.zip"
                autocomplete="off"
                maxlength="7"
                :disabled="isConfirm"
                @change="zipRestrict"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-prefecture support-shop-edit-prefecture"
              label="都道府県"
              prop="prefecture"
            >
              <el-select
                v-model="editForm.prefecture"
                filterable
                placeholder="選択"
                :disabled="isConfirm"
              >
                <el-option
                  v-for="prefecture in prefectureList"
                  :key="prefecture"
                  :label="prefecture"
                  :value="prefecture"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-city support-shop-edit-city"
              label="市区町村"
              prop="city"
            >
              <el-input
                v-model="editForm.city"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-address"
              label="住所"
              prop="address"
            >
              <el-input
                v-model="editForm.address"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="unit-master-support-shop-edit-edit-address-sub"
              label="住所サブ"
              prop="addressSub"
            >
              <el-input
                v-model="editForm.addressSub"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button
            class="unit-master-support-shop-edit-edit-button-detail"
            type="info"
            size="large"
            @click="isConfirm = false"
          >
            キャンセル
          </el-button>
          <el-button
            class="unit-master-support-shop-edit-edit-button-confirm"
            type="primary"
            size="large"
            @click="update()"
          >
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            class="unit-master-support-shop-edit-edit-button-detail"
            type="info"
            size="large"
            @click="router.push(`/master/supportshop/${editForm.id}`)"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-support-shop-edit-edit-button-confirm"
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { SupportShopPutData } from '@/types/master/supportShop';
  import { prefectureList } from '@/libs/prefecture';
  import { telRestriction, zipRestriction } from '@/libs/common';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<SupportShopPutData>({
    id: 0,
    name: '',
    affiliationUnion: '',
    zip: '',
    prefecture: '',
    city: '',
    address: '',
    addressSub: '',
    tel: '',
    mobileNumber: '',
    staffName: ''
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'サポート店名称は必須です。' }],
    affiliationUnion: [{ required: true, message: '所属組合は必須です。' }],
    zip: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    prefecture: [{ required: true, message: '都道府県は必須です。' }],
    city: [{ required: true, message: '市区町村は必須です。' }],
    address: [{ required: true, message: '住所は必須です。' }],
    tel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    mobileNumber: [
      {
        min: 11,
        max: 13,
        message:
          '携帯番号は11桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    staffName: [{ required: true, message: '担当者は必須です。' }]
  });
  const isConfirm = ref(false);

  const initialize = () => {
    const putData = store.state.supportShopMaster.putData;
    if (!putData) {
      return router.push('/master/supportshop');
    }
    editForm.value = putData;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = () => {
    store.dispatch('supportShopMaster/put', editForm.value);
  };
  const zipRestrict = () => {
    editForm.value.zip = zipRestriction(editForm.value.zip);
  };
  const telRestrict = () => {
    editForm.value.tel = telRestriction(editForm.value.tel);
  };
  const mobileRestrict = () => {
    editForm.value.mobileNumber = telRestriction(editForm.value.mobileNumber);
  };
  initialize();
</script>
<style lang="scss">
  .master-support-shop-edit {
    .support-shop-master-edit-form {
      display: inline-flex;
    }
    form {
      width: 100%;
      display: inline-block;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-select {
      width: 120px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .support-shop-edit-zip .el-input {
      width: 90px;
      float: left;
    }

    .support-shop-edit-city .el-input {
      width: 140px;
      float: left;
    }

    .support-shop-edit-tel .el-input {
      width: 135px;
      float: left;
    }

    .support-shop-edit-mobile-number .el-input {
      width: 135px;
      float: left;
    }

    .support-shop-edit-staff-name .el-input {
      width: 200px;
      float: left;
    }

    .button-area {
      margin: 20px 0px;
    }

    .support-shop-edit-prefecture {
      .el-input {
        width: 140px;
      }
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
