import { AdvanceOrderInputData } from '@/types/advanceOrder';
import { NumberData } from '@/types/common';

export const acceptStatus: NumberData = {
  1: '下書き',
  2: '受注確定'
};

export const defaultInputData: AdvanceOrderInputData = {
  financeId: null,
  companyId: null,
  productId: null,
  productQty: null,
  acceptDate: null,
  productPrice: null,
  note: ''
};
