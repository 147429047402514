export const numberRestriction = (value: string): string => {
  return value.replace(/[^0-9]/, '');
};

export const alphanumericRestriction = (value: string): string => {
  return value.replace(/[^A-Za-z0-9]/, '');
};

export const halfCharacterRestriction = (value: string): string => {
  return value.replace(/[^A-Za-z0-9!-/:-@¥[-`{-~]/, '');
};
