<template>
  <BaseSimpleLayout class="finance-master-create">
    <template #title>ファイナンス登録</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item
          class="unit-master-finance-create-name"
          label="ファイナンス名称"
          prop="name"
        >
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-create-bc-id"
          label="BCID"
          prop="bcId"
        >
          <el-input
            v-model="createForm.bcId"
            autocomplete="off"
            :disabled="isConfirm"
            @input="idRestriction"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-create-finance-code"
          label="ファイナンスコード"
          prop="financeCode"
        >
          <el-input
            v-model="createForm.financeCode"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-finance-create-is-bulk"
          label="バルク発注"
          prop="isBulk"
        >
          <el-radio-group v-model="createForm.isBulk" :disabled="isConfirm">
            <el-radio :label="true">有効</el-radio>
            <el-radio :label="false">無効</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/master/finance')"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { FinancesSubmitDataState } from '@/types/master/finance';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<FinancesSubmitDataState>({
    name: '',
    bcId: null,
    financeCode: '',
    isBulk: false
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'ファイナンス名称は必須です。' }],
    bcId: [{ required: true, message: 'BCIDは必須です。' }],
    financeCode: [{ required: true, message: 'ファイナンスコードは必須です。' }]
  });
  const isConfirm = ref(false);

  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('financesMaster/submit', createForm.value);
  };
  const idRestriction = (text: string) => {
    text = text.replace(/[^\d]/g, '');
    if (!text) {
      createForm.value.bcId = null;
      return;
    }
    createForm.value.bcId = parseFloat(text);
  };
</script>
<style lang="scss">
  .finance-master-create {
    form {
      width: 450px;
      margin: auto;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-button {
      font-weight: 600;
    }
    .button-area {
      margin: 0px 0px 10px 0px;
    }
  }
</style>
