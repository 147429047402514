import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import ElementPlus from 'element-plus';
import locale from 'element-plus/es/locale/lang/ja';
import 'element-plus/dist/index.css';
import firebase from 'firebase/app';
import axios from 'axios';

const app = createApp(App);
const config = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID
};
firebase.initializeApp(config);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

app.use(ElementPlus, { locale });
app.use(store);
app.use(router);
app.mount('#app');
