<template>
  <BaseSimpleLayout class="master-package-list">
    <template #title>パッケージ一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="createRole"
        class="unit-master-package-list-button-create"
        type="success"
        size="large"
        @click="router.push('/master/package/create')"
      >
        パッケージ登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" width="430" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="packCode" label="品番" width="120" sortable />
        <el-table-column prop="harness" label="専用ハーネス変換" sortable>
          <template #default="scope">
            {{ scope.row.isChangeHarnessItem ? '対象' : '対象外' }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { PackagesDataState } from '@/types/master/package';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<PackagesDataState[]>([]);
  const loadingFlag = ref(false);
  const createRole = ref(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('packagesMaster/fetch');
    tableData.value = store.state.packagesMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const moveDetail = (id: number) => {
    router.push('/master/package/' + id);
  };

  const searchData = () => {
    tableData.value = store.state.packagesMaster.listData.filter(
      (item: PackagesDataState) => {
        if (!searchText.value) return true;
        if (item.name.includes(searchText.value)) return true;
        const harness = item.isChangeHarnessItem ? '対象' : '対象外';
        if (harness.includes(searchText.value)) return true;
        else false;
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .master-package-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      width: 800px;
      margin: 10px auto 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
