// 欠品対象車両
const shortageCarModels = [
  'DBA-NV36',
  'DBA-PV36',
  'DBA-V36',
  'DBA-KV36',
  'DBA-CKV36',
  'DBA-J50',
  'DBA-NJ50',
  'CBA-J32',
  'CBA-PJ32',
  'CBA-TNJ32',
  'CBA-Z34',
  'CBA-HZ34',
  '4BA-Z34',
  'CBA-PNZ51',
  'CBA-TNZ51',
  'CBA-TZ51'
];

// 欠品見込み対象車両
const shortageExpectedCarModels = [
  '6AA-P15',
  '6AA-RP15',
  '6AA-SNP15',
  '5AA-B37W',
  'DAA-GC27',
  'DAA-GFC27',
  'DAA-GFNC27',
  'DAA-GNC27',
  'DBA-C27',
  '5BA-C27',
  '5AA-B37A',
  'DAA-HC27',
  'DAA-HFC27',
  'DAA-HC27',
  'DAA-HFC27',
  '6AA-HC27',
  '6AA-HFC27',
  '5AA-B38A',
  '5AA-GC27',
  '5AA-GFC27',
  '5AA-GFNC27',
  '5AA-GNC27',
  'DAA-SGC27',
  '4AA-B45W',
  '4AA-B48W',
  '5AA-B44W',
  '5AA-B47W',
  '5BA-B43W',
  '5BA-B46W',
  '6AA-E13',
  '6AA-SNE13',
  '6AA-FE13',
  '6AA-FSNE13',
  '4AA-B45A',
  '4AA-B48A',
  '5AA-B44A',
  '5AA-B47A',
  '4AA-B35W',
  '4AA-B38W',
  '5AA-B34W',
  '4AA-B35A',
  '4AA-B38A',
  '5AA-B34A',
  '5AA-B37A',
  '5AA-B34A',
  '5BA-B33W',
  '5BA-B36W',
  '5AA-B35A',
  'DAA-SGNC27',
  '5BA-SC27',
  '5AA-SGC27',
  '5AA-SGNC27',
  'DBA-T32',
  'DBA-NT32',
  'DAA-HT32',
  'DAA-HNT32'
];

export function isShortageCarModel(carModel: string) {
  return shortageCarModels.includes(carModel);
}

export function isShortageExpectedCarModel(
  carModel: string,
  ignitionType: number
) {
  return shortageExpectedCarModels.includes(carModel) && ignitionType === 2; //本来はignitionTypeによっても判定がある。現状は全ての車種でプッシュ式のみ欠品見込み対象車両になる
}
