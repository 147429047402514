import firebase from 'firebase/app';
import 'firebase/auth';
import { getEnv } from '@/libs/env';

export const signInSuccessUrl = location.origin;

export async function currentUser() {
  if (firebase.auth().currentUser) return firebase.auth().currentUser;
  return new Promise<firebase.User | null>((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) return resolve(user);
      else return resolve(null);
    });
  });
}

export async function getAccessToken() {
  if (getEnv() === 'development') {
    return 'dev_token';
  }
  const user = await currentUser();
  if (user) return user.getIdToken();
  return '';
}

export async function signOut() {
  await firebase.auth().signOut();
}
