<template>
  <BaseSimpleLayout class="receive-complete">
    <template #title>入荷{{ !isConfirm ? '本登録' : '本登録確認' }}</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="completeForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item label="シリアル番号" class="receive-complete-serial">
          <el-input v-model="detailData.serialCode" :disabled="true" />
        </el-form-item>
        <el-form-item
          label="入荷日"
          prop="receiveDate"
          class="receive-complete-serial"
        >
          <el-date-picker
            v-model="completeForm.receiveDate"
            type="date"
            placeholder="日付を選択"
            format="YYYY-MM-DD"
            value-format="x"
            :clearable="false"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          v-if="completeForm.shipSourceSelectType > 2"
          label="発送元名"
          prop="shipSourceName"
          class="receive-complete-name"
        >
          <el-input v-model="completeForm.shipSourceName" :disabled="true" />
        </el-form-item>
        <el-form-item label="入荷種別" prop="receiveType">
          <el-select v-model="completeForm.receiveType" :disabled="true">
            <el-option
              v-for="data in receiveTypeList"
              :key="data.label"
              :label="data.label"
              :value="data.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="completeForm.receiveType !== 8"
          label="端末不良"
          prop="accidentType"
        >
          <el-select v-model="completeForm.accidentType" :disabled="isConfirm">
            <el-option
              v-for="data in accidentTypeListData"
              :key="data.label"
              :label="data.label"
              :value="data.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="預かり販売店企業"
          prop="orderCompanyId"
          v-if="completeForm.receiveType === 1"
          class="receive-complete-name"
        >
          <el-select
            v-model="completeForm.orderCompanyId"
            :disabled="isConfirm"
          >
            <el-option
              v-for="data in companyList"
              :key="data.id"
              :label="data.name"
              :value="data.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="代替シリアル番号"
          prop="subDeviceSerialCode"
          class="receive-complete-serial"
          v-if="completeForm.receiveType === 2"
        >
          <el-select-v2
            v-model="completeForm.subDeviceSerialCode"
            :options="createDeviesList()"
            filterable
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input
            :disabled="isConfirm"
            v-model="completeForm.note"
            type="textarea"
          />
        </el-form-item>
        <div v-if="!isConfirm">
          <el-button
            type="info"
            size="large"
            @click="router.push('/receive/' + receiveId)"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="completeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
        <div v-if="isConfirm">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="put">更新</el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ElLoading } from 'element-plus';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { CompaniesDataState } from '@/types/master/company';
  import { DevicesDataState } from '@/types/device';
  import { receiveTypeList, accidentTypeList } from '@/libs/receive';
  import _ from 'lodash';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const completeForm = ref();
  const rules = ref<FormRules>({
    orderCompanyId: [
      { required: true, message: '預かり販売店企業は必須です。' }
    ],
    subDeviceSerialCode: [
      { required: true, message: '代替シリアル番号は必須です。' }
    ]
  });
  const loadingFlag = ref(false);
  const devicesList = ref<DevicesDataState[]>([]);
  const isConfirm = ref(false);
  const detailData = ref();
  const companyList = ref<CompaniesDataState[]>([]);
  const receiveId = ref('');
  const accidentTypeListData = _.cloneDeep(accidentTypeList);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    completeForm.value = store.state.receive.editData;
    detailData.value = store.state.receive.detailData;
    receiveId.value = store.state.receive.receiveId;
    if (!completeForm.value) return router.push('/receive');
    await store.dispatch('companiesMaster/fetch');
    completeForm.value.status = 2;
    completeForm.value.orderCompanyId = detailData.value.orderCompanyId;
    devicesList.value = store.state.devices.listData.filter(
      (device: DevicesDataState) => {
        if (!device.serialCode) return false;
        if (device.status < 3) return false;
        return true;
      }
    );
    companyList.value = store.state.companiesMaster.listData.filter(
      (data: CompaniesDataState) => data.isAdvanceOrder
    );
    if (detailData.value.receiveType === 1) accidentTypeListData.splice(1, 1);
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const completeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const put = () => {
    store.dispatch('receive/put', {
      receiveId: receiveId.value,
      editData: completeForm.value
    });
  };
  const createDeviesList = () => {
    return store.state.devices.listData
      .filter((device: DevicesDataState) => {
        return !!device.serialCode;
      })
      .map((device: DevicesDataState) => {
        return {
          label: device.serialCode,
          value: device.serialCode
        };
      });
  };
  initialize();
</script>
<style lang="scss">
  .receive-complete {
    form {
      width: 550px;
      margin: auto;
      .receive-complete-serial {
        .el-input {
          width: 197.5px;
        }
      }
      .receive-complete-name {
        .el-input {
          width: 400px;
        }
      }
      .receive-complete-device {
        .el-input {
          width: 400px;
        }
      }
      .el-input.is-disabled {
        .el-input__inner {
          color: #606266;
        }
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #606266;
        }
      }
    }
    .el-select-v2 {
      width: 197.5px;
    }
    .el-select-v2__placeholder {
      font-weight: 500;
      text-align: left;
      color: #606266;
    }
    .el-form-item {
      font-weight: 600 !important;
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
