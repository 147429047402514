/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { AuthState, AuthDataState, passwordData } from '@/types/auth';
import { ElLoading } from 'element-plus';
import { Role, RoleText } from '@/libs/auth';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl, getEnv } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    data: {
      id: 0,
      name: '',
      email: '',
      role: ''
    }
  },
  mutations: {
    setAuth: (state: AuthState, payload: AuthDataState) => {
      state.data = {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        role: payload.role
      };
    }
  },
  actions: {
    async fetch(context: any, path = '/top') {
      await axios
        .get(getBaseUrl() + '/profile', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setAuth', {
            id: response.data.id,
            name: response.data.name,
            email: response.data.email,
            role: response.data.role
          });
          if (getEnv() === 'development') return;
          router.push(path);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async password(context: any, payload: passwordData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/auth/change-password', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          router.push('/top');
          success('Success', 'パスワードの更新に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象のアカウントが存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  },
  getters: {
    auth(state: AuthState) {
      return state.data;
    },
    permit(state: AuthState) {
      return (payload: RoleText[]) => {
        return (
          payload.findIndex((role: string) => {
            return Role[state.data.role] === role;
          }) >= 0
        );
      };
    }
  }
};
