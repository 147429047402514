import { createStore } from 'vuex';
import authState from '@/store/modules/auth';
import itemsMasterState from '@/store/modules/master/items';
import itemMasterState from '@/store/modules/master/item';
import categoryMasterState from '@/store/modules/master/category';
import subCategoriesMasterState from '@/store/modules/master/subCategories';
import subCategoryMasterState from '@/store/modules/master/subCategory';
import subSubCategoriesMasterState from '@/store/modules/master/subSubCategories';
import subSubCategoryMasterState from '@/store/modules/master/subSubCategory';
import purchaseState from '@/store/modules/purchase';
import carInfoListMasterState from '@/store/modules/master/carInfoList';
import carInfoMasterState from '@/store/modules/master/carInfo';
import carMakersMasterState from '@/store/modules/master/carMakers';
import companiesMasterState from '@/store/modules/master/companies';
import companyMasterState from '@/store/modules/master/company';
import companyGroupsMasterState from '@/store/modules/master/companyGroups';
import companyGroupMasterState from '@/store/modules/master/companyGroup';
import financesMasterState from '@/store/modules/master/finances';
import financeMasterState from '@/store/modules/master/finance';
import harnessesMasterState from '@/store/modules/master/harnesses';
import PackagesMasterState from '@/store/modules/master/packages';
import PackageMasterState from '@/store/modules/master/package';
import ProductsMasterState from '@/store/modules/master/products';
import ProductMasterState from '@/store/modules/master/product';
import ShopsMasterState from '@/store/modules/master/shops';
import ShopMasterState from '@/store/modules/master/shop';
import SupportShopsMasterState from '@/store/modules/master/supportShops';
import SupportShopMasterState from '@/store/modules/master/supportShop';
import SupplierMasterState from '@/store/modules/master/supplier';
import SuppliersMasterState from '@/store/modules/master/suppliers';
import AcceptState from '@/store/modules/accept';
import orderDevicesState from '@/store/modules/orderDevices';
import activationState from '@/store/modules/activation';
import advanceOrderState from '@/store/modules/advanceOrder';
import bulkState from '@/store/modules/bulk';
import devicesState from '@/store/modules/devices';
import deviceState from '@/store/modules/device';
import ordersState from '@/store/modules/orders';
import orderState from '@/store/modules/order';
import autobacsDevicesState from '@/store/modules/autobacsDevices';
import autobacsDeviceState from '@/store/modules/autobacsDevice';
import carSecurityDevicesState from '@/store/modules/carSecurityDevices';
import carSecurityDeviceState from '@/store/modules/carSecurityDevice';
import simState from '@/store/modules/sim';
import managementState from '@/store/modules/management';
import reasonCancelState from '@/store/modules/reasonCancel';
import mspfState from '@/store/modules/mspf';
import saleState from '@/store/modules/sale';
import unrecordState from '@/store/modules/unrecord';
import shipState from '@/store/modules/ship';
import receiveState from '@/store/modules/receive';
import userState from '@/store/modules/user';
import usersState from '@/store/modules/users';
import depotsState from '@/store/modules/master/depots';
import inventoriesState from '@/store/modules/inventories';
import stockState from '@/store/modules/stock';
import moveState from '@/store/modules/move';
import depotsMasterState from '@/store/modules/master/depots';

export default createStore({
  modules: {
    auth: authState,
    categoryMaster: categoryMasterState,
    itemsMaster: itemsMasterState,
    itemMaster: itemMasterState,
    subCategoriesMaster: subCategoriesMasterState,
    subCategoryMaster: subCategoryMasterState,
    subSubCategoriesMaster: subSubCategoriesMasterState,
    subSubCategoryMaster: subSubCategoryMasterState,
    purchase: purchaseState,
    carInfoMaster: carInfoMasterState,
    carInfoListMaster: carInfoListMasterState,
    carMakersMaster: carMakersMasterState,
    companiesMaster: companiesMasterState,
    companyMaster: companyMasterState,
    companyGroupsMaster: companyGroupsMasterState,
    companyGroupMaster: companyGroupMasterState,
    financesMaster: financesMasterState,
    financeMaster: financeMasterState,
    harnessesMaster: harnessesMasterState,
    packagesMaster: PackagesMasterState,
    packageMaster: PackageMasterState,
    productsMaster: ProductsMasterState,
    productMaster: ProductMasterState,
    shopsMaster: ShopsMasterState,
    shopMaster: ShopMasterState,
    supportShopsMaster: SupportShopsMasterState,
    supportShopMaster: SupportShopMasterState,
    supplierMaster: SupplierMasterState,
    suppliersMaster: SuppliersMasterState,
    accept: AcceptState,
    management: managementState,
    orderDevices: orderDevicesState,
    activation: activationState,
    advanceOrder: advanceOrderState,
    bulk: bulkState,
    devices: devicesState,
    device: deviceState,
    orders: ordersState,
    order: orderState,
    autobacsDevices: autobacsDevicesState,
    autobacsDevice: autobacsDeviceState,
    carSecurityDevices: carSecurityDevicesState,
    carSecurityDevice: carSecurityDeviceState,
    sim: simState,
    reasonCancel: reasonCancelState,
    mspf: mspfState,
    sale: saleState,
    unrecord: unrecordState,
    ship: shipState,
    receive: receiveState,
    user: userState,
    users: usersState,
    depots: depotsState,
    inventories: inventoriesState,
    stock: stockState,
    move: moveState,
    depotsMaster: depotsMasterState
  }
});
