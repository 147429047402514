<template>
  <BaseSimpleLayout class="master-harness-list">
    <template #title>スマブロ適合表</template>
    <template v-if="loadingFlag" #button-area>
      <div class="last-update">
        {{ '最終更新日:' + lastUpdate }}
      </div>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="harness-list-mccs-ver-area">
        <el-radio-group v-model="selectMCCS">
          <el-radio :label="3" v-on:change="filterHarnessList">MCCSⅢ</el-radio>
          <el-radio :label="2" v-on:change="filterHarnessList">MCCSⅡ</el-radio>
        </el-radio-group>
      </div>
      <div class="harness-list-filter-area">
        <span>メーカー:</span>
        <el-select
          v-model="makerData"
          filterable
          clearable
          placeholder="選択"
          class="harness-list-filter-input-maker"
          @change="filterHarnessList"
        >
          <el-option
            v-for="item in carMakerList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
        <span>車種:</span>
        <el-input
          v-model="carName"
          clearable
          placeholder="検索"
          class="harness-list-filter-input-name"
          @input="filterHarnessList"
        />
        <span>型式:</span>
        <el-input
          v-model="carModel"
          clearable
          placeholder="検索"
          class="harness-list-filter-input-model"
          @input="filterHarnessList"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="メーカー、車種、型式いずれかを選択してください"
      >
        <el-table-column property="id" label="ID" align="center" width="70" />
        <el-table-column
          label="管理番号"
          align="center"
          property="controlNumber"
          width="90"
        />
        <el-table-column
          label="メーカー"
          align="center"
          property="carMakerName"
          width="90"
        />
        <el-table-column
          label="指定番号"
          align="center"
          property="carModelCode"
          width="90"
        />
        <el-table-column label="車種" align="center" property="carName" />
        <el-table-column
          label="型式"
          align="center"
          property="carModel"
          width="120"
        />
        <el-table-column
          label="モデル区分"
          align="center"
          property="carModelType"
          width="110"
        />
        <el-table-column
          label="年式"
          align="center"
          property="carModelYear"
          width="90"
        />
        <el-table-column
          label="終了"
          align="center"
          property="carEndSales"
          width="90"
        />
        <el-table-column label="スタート方式" align="center" width="110">
          <template #default="scope">
            {{ ignitionType[scope.row.ignitionType] }}
          </template>
        </el-table-column>
        <el-table-column
          label="判定"
          align="center"
          property="SBAdaptability"
          width="60"
        />
        <el-table-column
          label="ハーネス"
          align="center"
          property="SName"
          width="110"
        />
        <el-table-column
          label="結線"
          align="center"
          property="connectType"
          width="160"
        />
        <el-table-column label="資料" align="center" width="100">
          <template #default="scope">
            <!-- <span
              v-if="displayDocumentButton(scope.row.compatibilityResultJudge)"
            > -->
            <el-button
              :icon="Document"
              size="small"
              type="primary"
              @click="dialogOpen(scope.row)"
            />
            <!-- </span>
            <span v-else>-</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogFormVisible" :title="dialogTitle" width="700px">
        <div>
          <el-button
            type="primary"
            :disabled="!mountingManual"
            @click="openDocument(mountingManual)"
          >
            取付マニュアル
          </el-button>
        </div>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { HarnessesCarSecurityDataState } from '@/types/master/harness';
  import { ignitionType } from '@/libs/harness';
  import { ElLoading } from 'element-plus';
  import { TableData } from '@/types/common';
  import { CarMakersDataState } from '@/types/master/carMaker';
  import { Document } from '@element-plus/icons-vue';
  import { formatDate } from '@/libs/dateFormat';

  const store = useStore();
  const tableData = ref<TableData[]>([]);
  const carMakerList = ref<CarMakersDataState[]>([]);
  const makerData = ref<string | null>(null);
  const selectMCCS = ref<number>(3);
  const carName = ref<string | null>(null);
  const carModel = ref<string | null>(null);
  const loadingFlag = ref(false);
  const dialogFormVisible = ref(false);
  const dialogTitle = ref('');
  const mountingManual = ref<string | null>(null);
  const tableHeight = ref(600);
  const lastUpdate = ref('-');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('carMakersMaster/fetch'),
      store.dispatch('harnessesMaster/fetchLastDate')
    ]);
    carMakerList.value = store.state.carMakersMaster.listData;
    console.log(carMakerList.value);
    tableData.value = [];
    tableHeight.value = window.innerHeight - 180;
    const lastDate = store.state.harnessesMaster.lastDate;
    if (lastDate) {
      lastUpdate.value = formatDate(Number(lastDate));
    }
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const filterHarnessList = async () => {
    console.log(makerData.value);
    if (!makerData.value && !carName.value && !carModel.value) {
      return (tableData.value = []);
    }
    tableData.value = store.state.harnessesMaster.listData;
    if (!tableData.value.length) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await store.dispatch('harnessesMaster/fetchCarSecurity');
      loadingInstance.close();
    }
    tableData.value = store.state.harnessesMaster.listData.filter(
      (data: HarnessesCarSecurityDataState) => {
        let makerResult = !makerData.value;
        let nameResult = !carName.value;
        let modelResult = !carModel.value;
        const deviceTypeResult = data.deviceType === selectMCCS.value;

        if (makerData.value) {
          if (data.carMakerName) {
            makerResult = data.carMakerName.includes(makerData.value);
          }
        }
        if (carName.value) {
          if (data.carName) {
            nameResult = data.carName.includes(carName.value);
          }
        }

        if (carModel.value) {
          if (data.carModel) {
            modelResult = data.carModel.includes(carModel.value);
          }
        }
        return makerResult && nameResult && modelResult && deviceTypeResult;
      }
    );
  };

  const dialogOpen = (data: HarnessesCarSecurityDataState) => {
    dialogFormVisible.value = true;
    dialogTitle.value = `資料一覧 ${data.carMakerName}（${data.carModel}）`;
    mountingManual.value = data.SBManualURL;
  };

  const openDocument = (url: string | null) => {
    if (!url) return;
    window.open(url, '_blank');
  };
  initialize();
</script>
<style lang="scss">
  .master-harness-list {
    .main-container {
      width: 98%;
    }
    .header-container {
      height: 50px;
    }
    .last-update {
      margin: 0 3px;
    }
    .harness-list-mccs-ver-area {
      font-weight: 600;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      width: 30%;
    }

    .harness-list-filter-area {
      margin: 20px 0px;

      span {
        font-weight: 600;
        margin-left: 20px;
      }

      .el-select {
        float: initial;
      }
    }

    .harness-list-filter-input-maker {
      margin: 0px 10px;
      width: 150px;
    }

    .harness-list-filter-input-name {
      margin: 0px 10px;
      width: 400px;
    }

    .harness-list-filter-input-model {
      margin: 0px 10px;
      width: 200px;
    }

    .el-button {
      font-weight: 600;
    }

    .el-table {
      margin: 10px 0 0;
      thead th {
        background: #f5f7fa;
      }
    }

    .el-dialog__title {
      font-weight: 600;
    }

    .button-line-bottom {
      margin-top: 30px;
    }
  }
</style>
