<template>
  <BaseSimpleLayout class="advance-confirm">
    <template #title>先行受注入力確認</template>
    <template #main>
      <BaseDataTable :table-data="confirmTableData" :label-width="130" />
      <el-button
        type="info"
        size="large"
        @click="router.push('/advance/input')"
      >
        戻る
      </el-button>
      <el-button type="primary" size="large" @click="submit()">
        登録
      </el-button>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { formatDate } from '@/libs/dateFormat';

  const store = useStore();
  const inputData = store.state.advanceOrder.inputData;
  const confirmTableData = ref([
    {
      label: '受注日',
      value: ''
    },
    {
      label: 'ファイナンス',
      value: ''
    },
    {
      label: '販売店企業',
      value: ''
    },
    {
      label: '製品名',
      value: ''
    },
    {
      label: '単価',
      value: ''
    },
    {
      label: '個数',
      value: ''
    },
    {
      label: '備考',
      value: ''
    }
  ]);
  const initialize = () => {
    if (!inputData) {
      return router.push('/advance');
    }
    const financeName =
      store.state.financesMaster.replaceData[inputData.financeId];
    const companyName =
      store.state.companiesMaster.replaceData[inputData.companyId].name;
    const productName =
      store.state.productsMaster.replaceData[inputData.productId].name;
    confirmTableData.value = [
      {
        label: '受注日',
        value: formatDate(Number(inputData.acceptDate))
      },
      {
        label: 'ファイナンス',
        value: financeName
      },
      {
        label: '販売店企業',
        value: companyName
      },
      {
        label: '製品名',
        value: productName
      },
      {
        label: '単価',
        value: inputData.productPrice
      },
      {
        label: '個数',
        value: inputData.productQty
      },
      {
        label: '備考',
        value: inputData.note
      }
    ];
  };
  const submit = () => {
    store.dispatch('advanceOrder/submit');
  };
  initialize();
</script>
<style lang="scss">
  .advance-confirm {
    .el-table {
      width: 400px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
    }
  }
</style>
