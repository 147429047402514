<template>
  <BaseSimpleLayout class="bill-ship-input">
    <template #title>
      売上情報{{ saleId ? '編集' : '登録' }}-請求情報
    </template>
    <template v-if="loadingFlag" #main>
      <el-button size="large" type="success" @click="dialogFormVisible = true">
        製品追加
      </el-button>
      <el-table
        :data="createForm.details"
        stripe
        border
        class="bill-ship-input-details-table"
        empty-text="製品が存在しません"
      >
        <el-table-column
          prop="productName"
          label="名称"
          align="center"
          class-name="unit-master-product-details-name"
        >
          <template #default="scope">
            <span>{{ productReplaceData[scope.row.productId].name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productPrice"
          label="価格"
          width="90px"
          align="center"
          class-name="unit-master-product-details-name"
        />
        <el-table-column
          prop="productType"
          label="種別"
          width="120px"
          align="center"
          class-name="unit-master-product-details-qty"
        >
          <template #default="scope">
            <span>{{
              productType[productReplaceData[scope.row.productId].productType]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productQty"
          label="個数"
          width="70px"
          align="center"
          class-name="unit-master-product-details-qty"
        />
        <el-table-column
          label="操作"
          width="85px"
          align="center"
          class-name="unit-master-product-details-ope"
        >
          <template #default="scope">
            <el-button
              type="danger"
              class="unit-master-product-details-button-delete"
              @click="deletePackage(scope.$index)"
            >
              削除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="productErrorVisible"
        class="bill-ship-input-details-error-text"
      >
        製品登録は必須です。
      </div>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="100px"
      >
        <el-form-item label="発送先種別" prop="shipSelectType">
          <el-radio-group
            v-model="createForm.shipSelectType"
            @change="changeShipSelectType"
          >
            <el-radio :label="1">販売店</el-radio>
            <el-radio :label="2">サポート店</el-radio>
            <el-radio :label="3">その他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="createForm.shipSelectType === 2"
          class="bill-ship-input-select"
          label="サポート店"
          prop="supportShopId"
        >
          <el-select v-model="createForm.supportShopId" disabled>
            <el-option
              v-for="shop in supportShopList"
              :key="shop.id"
              :label="shop.name"
              :value="shop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="createForm.shipSelectType === 3"
          class="bill-accept-input-input bill-accept-input-notice-input"
          label="その他"
          prop="otherShipName"
        >
          <el-input v-model="createForm.otherShipName" />
        </el-form-item>
        <el-form-item label="備考" prop="note">
          <el-input v-model="createForm.note" type="textarea" />
        </el-form-item>
      </el-form>
      <div class="bill-ship-input-button-area">
        <el-button
          type="info"
          size="large"
          @click="router.push('/sale/input/accept')"
        >
          戻る
        </el-button>
        <el-button type="primary" size="large" @click="moveNext(formRef)">
          次へ
        </el-button>
      </div>
      <el-dialog v-model="dialogFormVisible" title="製品登録" width="500px">
        <el-form
          :model="productForm"
          class="bill-ship-input-details-form"
          size="large"
          label-width="80px"
        >
          <el-form-item
            label="製品名称"
            class="bill-ship-input-details-form-id"
          >
            <el-select
              v-model="productForm.productId"
              filterable
              placeholder="選択"
              class="bill-ship-input-details-id"
              @change="setProductInfo()"
            >
              <el-option
                v-for="option in productList"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="製品種別">
            <el-input v-model="productForm.productType" disabled />
          </el-form-item>
          <el-form-item label="製品価格">
            <el-input-number
              v-model="productForm.productPrice"
              :precision="0"
              :min="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item label="個数">
            <el-input-number
              v-model="productForm.productQty"
              :precision="0"
              :min="1"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="addProduct">
            追加
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="supportShopDialogFormVisible"
        title="サポート店選択"
        width="80%"
      >
        <BaseSelectSupportShop @emitting="addSupportShopData($event)" />
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseSelectSupportShop from '@/components/BaseSelectSupportShop.vue';
  import type { FormInstance } from 'element-plus';
  import { AddProductForm } from '@/types/accept';
  import { UnrecordShipFormData } from '@/types/unrecord';
  import {
    ProductsDataState,
    ProductReplaceData
  } from '@/types/master/product';
  import { SupportShopsDataState } from '@/types/master/supportShop';
  import { ElLoading } from 'element-plus';
  import { productType } from '@/libs/product';

  const store = useStore();
  const dialogFormVisible = ref(false);
  const productErrorVisible = ref(false);
  const createForm = ref<UnrecordShipFormData>({
    shipSelectType: 0,
    supportShopId: null,
    otherShipName: null,
    note: '',
    details: []
  });
  const productForm = ref<AddProductForm>({
    productId: 0,
    productType: '',
    productQty: 1,
    productPrice: 1
  });
  const rules = ref({
    supportShopId: [{ required: true, message: 'サポート店は必須です。' }],
    otherShipName: [{ required: true, message: 'その他は必須です。' }]
  });
  const productList = ref<ProductsDataState[]>([]);
  const productReplaceData = ref<ProductReplaceData>({});
  const loadingFlag = ref(false);
  const supportShopDialogFormVisible = ref(false);
  const supportShopList = ref<SupportShopsDataState[]>([]);
  const saleId = ref<string | null>(null);
  const formRef = ref<FormInstance>();

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.sale.submitData;
    if (!submitData) {
      return router.push('/sale');
    }
    saleId.value = store.state.sale.saleId;
    await Promise.all([
      store.dispatch(
        'productsMaster/fetchSelectList',
        submitData.companyGroupId
      ),
      store.dispatch('supportShopsMaster/fetch')
    ]);
    createForm.value = {
      shipSelectType: submitData.shipSelectType,
      supportShopId: submitData.supportShopId,
      otherShipName: submitData.otherShipName,
      note: submitData.note,
      details: submitData.details
    };
    productList.value = store.state.productsMaster.listData;
    productForm.value.productId = productList.value[0]['id'];
    productReplaceData.value = store.state.productsMaster.replaceData;
    supportShopList.value = store.state.supportShopsMaster.listData;
    setProductInfo();
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const setProductInfo = () => {
    const productId = productForm.value.productId;
    if (!productId) return;
    const productData = productReplaceData.value[productId];
    if (!productData) return;

    productForm.value.productType = productType[productData.productType];
    productForm.value.productPrice = Number(productData.price);
  };

  const addProduct = () => {
    createForm.value.details.push({
      productId: productForm.value.productId,
      productPrice: productForm.value.productPrice,
      productQty: productForm.value.productQty
    });
    dialogFormVisible.value = false;
    productForm.value = {
      productId: productList.value[0]['id'],
      productType: '',
      productPrice: 0,
      productQty: 1
    };
    setProductInfo();
    productErrorVisible.value = false;
  };

  const deletePackage = (index: number) => {
    createForm.value.details.splice(index, 1);
    if (createForm.value.details.length === 0) {
      productErrorVisible.value = true;
    }
  };

  const addSupportShopData = (id: number) => {
    createForm.value.supportShopId = id;
    supportShopDialogFormVisible.value = false;
  };

  const changeShipSelectType = () => {
    if (createForm.value.shipSelectType === 2) {
      supportShopDialogFormVisible.value = true;
    }
  };

  const moveNext = async (formEl: FormInstance | undefined) => {
    let selectProdut = true;
    if (!formEl) return false;
    await formEl.validate(async (valid) => {
      if (!createForm.value.details.length) {
        productErrorVisible.value = true;
        selectProdut = false;
      }
      if (!valid || !selectProdut) {
        return;
      }
      if (createForm.value.shipSelectType === 1) {
        createForm.value.otherShipName = null;
        createForm.value.supportShopId = null;
      }
      if (createForm.value.shipSelectType === 2) {
        createForm.value.otherShipName = null;
      }
      if (createForm.value.shipSelectType === 3) {
        createForm.value.supportShopId = null;
      }
      store.dispatch('sale/setShipSubmitData', createForm.value);
      router.push('/sale/input/confirm');
    });
  };
  initialize();
</script>
<style lang="scss">
  .bill-ship-input {
    form {
      width: 500px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }

    .bill-ship-input-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: -30px 0px 0px 0px;
    }

    .bill-ship-input-details-table {
      margin: 30px auto;
      width: 800px;
      div {
        font-size: 14px;
      }
      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }
    }

    .bill-ship-input-details-form {
      width: 400px;

      .bill-ship-input-details-form-id {
        .el-input {
          float: left;
          width: 320px;
        }
      }
      .el-input-number {
        float: left;
        .el-input {
          width: 80px;
        }
      }
      .el-input {
        float: left;
        width: 150px;
      }
    }

    .el-input.is-disabled .el-input__inner {
      color: #606266;
    }

    .bill-ship-input-radio {
      text-align: left;
      .el-form-item__label {
        margin-right: 20px;
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #606266;
      }
    }

    .bill-ship-input-button-area {
      margin: 30px 0px;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .bill-ship-input-select {
      .el-select {
        width: 300px;
        float: left;
      }
      .el-input {
        width: 300px;
        float: left;
      }
    }
    .el-textarea {
      width: 500px;
    }
  }
</style>
