/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  ShipState,
  ShipsDataState,
  ShipDetailData,
  ShipPatchNoteData,
  ShipInvoiceData,
  ShipLinkDeviceData,
  ShipCompleteData,
  ShipFilterData
} from '@/types/ship';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    filterData: null
  },
  mutations: {
    setList(state: ShipState, payload: ShipsDataState[]) {
      state.listData = payload;
    },
    setDetail(state: ShipState, payload: ShipDetailData) {
      state.detailData = payload;
    },
    setFilter(state: ShipState, payload: ShipFilterData) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any, status?: string[]) {
      const query = status
        ? `?${status.map((s) => `status[]=${s}`).join('&')}`
        : '';
      await axios
        .get(`${getBaseUrl()}/ship${query}`, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: ShipsDataState, b: ShipsDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, shipId: any) {
      await axios
        .get(getBaseUrl() + '/ship/' + shipId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async complete(context: any, payload: ShipCompleteData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/ship/done/' + payload.id, payload.data, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '出荷完了処理に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async patchNote(context: any, payload: ShipPatchNoteData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .patch(
          getBaseUrl() + '/ship/note/' + payload.id,
          { note: payload.note },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '出荷用備考を更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async patchInvoice(context: any, payload: ShipInvoiceData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .patch(
          getBaseUrl() + '/ship/invoice/' + payload.id,
          { invoiceCode: payload.invoiceCode },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '送り状番号を更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async patchDeliveryNote(context: any, payload: ShipPatchNoteData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .patch(
          getBaseUrl() + '/ship/note/delivery/' + payload.id,
          { deliveryNote: payload.note },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '納品書備考を更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async preparation(context: any, shipId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/ship/response/' + shipId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', 'ステータスを 準備中 に更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async unpreparation(context: any, shipId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/ship/unresponse/' + shipId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', 'ステータスを 依頼 に更新しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async linkDevice(context: any, payload: ShipLinkDeviceData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/ship/link/' + payload.id,
          { deviceLedgerIds: payload.devices },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '端末のリンクに成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async unlinkDevice(context: any, shipId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/ship/unlink/' + shipId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          loadingInstance.close();
          success('Success', '端末のリンク解除に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の出荷情報が存在しません。');
            router.push('/ship');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async remand(context: any, shipId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/ship/remand/' + shipId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '出荷情報を差し戻しました。');
          router.push('/ship');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/ship');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: ShipFilterData) {
      context.commit('setFilter', payload);
    }
  }
};
