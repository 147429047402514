/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  AcceptState,
  AcceptDataState,
  AcceptSubmitData,
  AcceptDetailData,
  AcceptDetailsElement,
  AcceptFilterForm
} from '@/types/accept';
import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    submitData: null,
    acceptId: '',
    filterData: null
  },
  mutations: {
    setList(state: AcceptState, payload: AcceptDataState[]) {
      state.listData = payload;
    },
    setSubmit(state: AcceptState, payload: AcceptSubmitData) {
      state.submitData = payload;
    },
    setDetail(state: AcceptState, payload: AcceptDetailData) {
      state.detailData = payload;
    },
    setAcceptId(state: AcceptState, payload: string) {
      state.acceptId = payload;
    },
    setFilter(state: AcceptState, payload: AcceptFilterForm) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/accept', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: AcceptDataState, b: AcceptDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, acceptId: any) {
      await axios
        .get(getBaseUrl() + '/accept/' + acceptId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setAcceptId', acceptId);
          context.commit('setDetail', response.data);
          const submitData = _.cloneDeep(response.data);
          delete submitData.id;
          delete submitData.status;
          delete submitData.shopName;
          delete submitData.companyGroupName;
          delete submitData.companyName;
          delete submitData.financeName;
          delete submitData.supportShopName;
          delete submitData.depotName;
          delete submitData.registerUserName;
          delete submitData.updateUserName;
          delete submitData.carMakerName;
          delete submitData.createdAt;
          delete submitData.updatedAt;
          delete submitData.harnessName;
          delete submitData.harnessPartCode;
          delete submitData.orderCompanyName;
          submitData.details = submitData.details.map(
            (data: AcceptDetailsElement) => {
              return {
                productId: data.productId,
                productQty: data.productQty,
                productPrice: data.productPrice
              };
            }
          );
          context.commit('setSubmit', submitData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の受注情報が存在しません。');
            router.push('/accept');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async tryFetchDetail(context: any, acceptId: any) {
      await axios
        .get(getBaseUrl() + '/accept/' + acceptId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setAcceptId', acceptId);
          context.commit('setDetail', response.data);
          const submitData = _.cloneDeep(response.data);
          delete submitData.id;
          delete submitData.status;
          delete submitData.shopName;
          delete submitData.companyGroupName;
          delete submitData.companyName;
          delete submitData.financeName;
          delete submitData.supportShopName;
          delete submitData.depotName;
          delete submitData.registerUserName;
          delete submitData.updateUserName;
          delete submitData.carMakerName;
          delete submitData.createdAt;
          delete submitData.updatedAt;
          delete submitData.harnessName;
          delete submitData.harnessPartCode;
          delete submitData.orderCompanyName;
          submitData.details = submitData.details.map(
            (data: AcceptDetailsElement) => {
              return {
                productId: data.productId,
                productQty: data.productQty,
                productPrice: data.productPrice
              };
            }
          );
          context.commit('setSubmit', submitData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
            // このアクションでは404を許容する。バルク受注かわからないレコードに対してacceptDetailを取得する場合に利用する
          } else if (e.response.status == 404) {
            context.commit('setDetail', null);
            context.commit('setSubmit', null);
            context.commit('setAcceptId', '');
          } else if (e.response.status < 500 && e.response.status != 404) {
            error(e.response.status);
          } else if (e.response.status != 404) {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      if (context.state.acceptId) {
        await axios
          .put(
            getBaseUrl() + '/accept/' + context.state.acceptId,
            context.state.submitData,
            {
              headers: { auth: await getAccessToken() }
            }
          )
          .then(() => {
            success('Success', '受注情報の更新に成功しました。');
            router.push('/accept');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status == 404) {
              error(e.response.status, '対象の受注情報が存在しません。');
              router.push('/accept');
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      } else {
        await axios
          .post(getBaseUrl() + '/accept', context.state.submitData, {
            headers: { auth: await getAccessToken() }
          })
          .then(() => {
            success('Success', '受注情報の登録に成功しました。');
            router.push('/accept');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
          });
      }
    },
    async request(
      context: any,
      payload: { acceptId: any; processType: number }
    ) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/accept/ship/' + payload.acceptId,
          {
            processType: payload.processType
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success(
            'Success',
            {
              1: '出荷依頼しました',
              2: '出荷依頼を確定しました'
            }[payload.processType] || '差し戻ししました'
          );
          router.push('/accept');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の受注情報が存在しません。');
            router.push('/accept');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, acceptId: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/accept/' + acceptId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '受注情報の削除に成功しました。');
          router.push('/accept');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の受注情報が存在しません。');
            router.push('/accept');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    resetAcceptId(context: any) {
      context.commit('setAcceptId', '');
    },
    setSubmitData(context: any, submitData: any) {
      context.commit('setSubmit', submitData);
    },
    setSubmitPartData(context: any, partData: any) {
      const payload = _.cloneDeep(context.state.submitData);
      Object.keys(partData).forEach((key: string) => {
        payload[key] = partData[key];
      });
      context.commit('setSubmit', payload);
    },
    setFilterData(context: any, payload: AcceptFilterForm) {
      context.commit('setFilter', payload);
    }
  }
};
