<template>
  <BaseSimpleLayout class="supplier-edit">
    <template #title>仕入先編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <div class="supplier-master-edit-form">
          <div>
            <el-form-item label="ID" prop="id">
              <el-input v-model="editForm.id" disabled />
            </el-form-item>
            <el-form-item label="仕入先名" prop="name">
              <el-input
                v-model="editForm.name"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item class="supplier-edit-tel" label="電話番号" prop="tel">
              <el-input
                v-model="editForm.tel"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="telRestrict()"
              />
            </el-form-item>
            <el-form-item
              label="郵便番号"
              prop="post"
              class="supplier-edit-post"
            >
              <el-input
                v-model="editForm.post"
                autocomplete="off"
                maxlength="7"
                :disabled="isConfirm"
                @change="zipRestrict()"
              />
            </el-form-item>
            <el-form-item label="住所" prop="address">
              <el-input
                v-model="editForm.address"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item class="supplier-edit-fax" label="FAX" prop="fax">
              <el-input
                v-model="editForm.fax"
                autocomplete="off"
                maxlength="12"
                :disabled="isConfirm"
                @change="faxRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="supplier-edit-manager-name"
              label="担当者名"
              prop="managerName"
            >
              <el-input
                v-model="editForm.managerName"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button
            class="unit-master-supplier-edit-button-list"
            type="info"
            @click="isConfirm = false"
          >
            キャンセル
          </el-button>
          <el-button
            class="unit-master-supplier-edit-button-confirm"
            type="primary"
            @click="update()"
          >
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            class="unit-master-supplier-edit-button-list"
            type="info"
            @click="router.push('/master/supplier/' + supplierId)"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-supplier-edit-button-confirm"
            type="primary"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { SupplierPutData } from '@/types/master/supplier';
  import { telRestriction, zipRestriction } from '@/libs/common';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<SupplierPutData>({
    name: '',
    post: '',
    address: '',
    tel: '',
    fax: '',
    managerName: ''
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: '仕入先名は必須です。' }],
    post: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    address: [{ required: true, message: '住所は必須です。' }],
    tel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    fax: [
      {
        min: 10,
        max: 12,
        message:
          'FAXは10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    managerName: [{ required: true, message: '担当者は必須です。' }]
  });
  const supplierId = ref(0);
  const isConfirm = ref(false);

  const initialize = () => {
    const putData = store.state.supplierMaster.putData;
    if (!putData) {
      return router.push('/master/supplier');
    }
    editForm.value = putData;
    supplierId.value = putData.id;
    if (store.state.suppliersMaster.submitData) {
      editForm.value = store.state.suppliersMaster.submitData;
    }
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = () => {
    store.dispatch('supplierMaster/put', editForm.value);
  };
  const zipRestrict = () => {
    editForm.value.post = zipRestriction(editForm.value.post);
  };
  const telRestrict = () => {
    editForm.value.tel = telRestriction(editForm.value.tel);
  };
  const faxRestrict = () => {
    editForm.value.fax = telRestriction(editForm.value.fax);
  };
  initialize();
</script>
<style lang="scss">
  .supplier-edit {
    .supplier-master-edit-form {
      display: inline-flex;
    }
    form {
      width: 100%;
      display: inline-block;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .supplier-edit-post .el-input {
      width: 90px;
      float: left;
    }

    .supplier-edit-address {
      .el-input {
        width: 300px;
        float: left;
      }
    }

    .supplier-edit-tel {
      .el-input {
        width: 135px;
        float: left;
      }
    }

    .supplier-edit-fax {
      .el-input {
        width: 135px;
        float: left;
      }
    }

    .supplier-edit-manager-name {
      .el-input {
        width: 200px;
        float: left;
      }
    }

    .button-area {
      margin: 10px 0px;
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
