/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  CategoryState,
  CategoryListState,
  CategoryReplaceData
} from '@/types/category';

import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setList(state: CategoryState, payload: CategoryListState[]) {
      state.listData = payload;
    },
    setReplace(state: CategoryState, payload: CategoryReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/categories', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceData: CategoryReplaceData = {};
          response.data.forEach((data: CategoryListState) => {
            replaceData[data.id] = data.name;
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
