/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  FinancesState,
  FinancesDataState,
  FinancesSubmitDataState,
  FinanceReplaceData
} from '@/types/master/finance';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setFinances(state: FinancesState, payload: FinancesDataState[]) {
      state.listData = payload;
    },
    setReplace(state: FinancesState, payload: FinanceReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/finances', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setFinances', response.data);
          const replaceData: FinanceReplaceData = {};
          response.data.forEach((finance: FinancesDataState) => {
            replaceData[finance.id] = finance.name;
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: FinancesSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/master/finances', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'ファイナンス登録に成功しました。');
          router.push('/master/finance');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
