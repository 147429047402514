/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  UnrecordState,
  UnrecordListState,
  UnrecordDetailData,
  UnrecordSubmitData,
  UnrecordFormData,
  UnrecordProductsState,
  UnrecordFilterForm
} from '@/types/unrecord';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    detailData: null,
    submitData: null,
    filterData: null
  },
  mutations: {
    setList(state: UnrecordState, payload: UnrecordListState[]) {
      state.listData = payload;
    },
    setDetail(state: UnrecordState, payload: UnrecordDetailData) {
      state.detailData = payload;
    },
    setSubmit(state: UnrecordState, payload: UnrecordSubmitData) {
      state.submitData = payload;
    },
    setUnrecordSubmit(state: UnrecordState, payload: UnrecordFormData) {
      if (!state.submitData) return;
      state.submitData.saleDate = payload.saleDate;
      state.submitData.note = payload.note;
      state.submitData.details = payload.details;
    },
    setFilter(state: UnrecordState, payload: UnrecordFilterForm) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/sales/accept', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, id: number) {
      await axios
        .get(getBaseUrl() + '/sales/accept/' + id, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const submitDetails = response.data.details.map(
            (data: UnrecordProductsState) => {
              return {
                productId: data.productId,
                productQty: data.productQty,
                productPrice: data.productPrice
              };
            }
          );
          context.commit('setSubmit', {
            acceptDate: response.data.acceptDate,
            type: response.data.type,
            acceptId: response.data.acceptId,
            saleType: response.data.saleType,
            customerLedgerId: response.data.customerLedgerId,
            financeId: response.data.financeId,
            companyGroupId: response.data.companyGroupId,
            companyId: response.data.companyId,
            shopId: response.data.shopId,
            shipSelectType: response.data.shipSelectType,
            supportShopId: response.data.supportShopId,
            note: response.data.note,
            otherShipName: response.data.otherShipName,
            details: submitDetails,
            moutDate: Number(response.data.mountDate),
            saleDate: null,
            saleDeviceType: response.data.saleDeviceType
          });
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の受注情報が存在しません。');
            router.push('/unrecord');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/sales', context.state.submitData, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '売上登録に成功しました。');
          router.push('/unrecord');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async noRecord(context: any, acceptId: number) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/sales/norecorded/' + acceptId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '売上計上なしに変更しました。');
          router.push('/unrecord');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の受注情報が存在しません。');
            router.push('/unrecord');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    setUnrecordSubmitData(context: any, submitData: UnrecordFormData) {
      context.commit('setUnrecordSubmit', submitData);
    },
    setFilterData(context: any, filterData: UnrecordFilterForm) {
      context.commit('setFilter', filterData);
    }
  }
};
