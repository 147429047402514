<template>
  <BaseSimpleLayout class="company-group-master-create">
    <template #title>販売店グループ登録</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="180px"
      >
        <div class="company-group-master-create-form">
          <div>
            <el-form-item label="販売店グループ名" prop="name">
              <el-input
                v-model="createForm.name"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="FCコード(大文字英数字)" prop="fcCode">
              <el-input
                v-model="createForm.fcCode"
                autocomplete="off"
                maxlength="3"
                minlength="3"
                :disabled="isConfirm"
                @change="fcCodeRestriction"
              />
            </el-form-item>
            <el-form-item label="種別" prop="companyGroupType">
              <el-radio-group
                v-model="createForm.companyGroupType"
                :disabled="isConfirm"
              >
                <el-radio :label="1">FC</el-radio>
                <el-radio :label="2">自社</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="次回顧客番号" prop="customerNextNumber">
              <el-input-number
                v-model="createForm.customerNextNumber"
                :precision="0"
                :min="1"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item label="MCCS卸価格" prop="deviceTradePrice">
              <el-input-number
                v-model="createForm.deviceTradePrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="company-group-master-create-target"
              label="発生費用対象"
              prop="incurredPriceType"
            >
              <el-select
                v-model="createForm.incurredPriceType"
                placeholder="選択"
                :disabled="isConfirm"
              >
                <el-option
                  v-for="data in incurredPriceSelect"
                  :key="data.val"
                  :label="data.label"
                  :value="data.val"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="取付価格" prop="deviceAttachedPrice">
              <el-input-number
                v-model="createForm.deviceAttachedPrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="取外価格" prop="deviceDettachedPrice">
              <el-input-number
                v-model="createForm.deviceDettachedPrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="月額費用" prop="monthlyPrice">
              <el-input-number
                v-model="createForm.monthlyPrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="未使用費用" prop="unusedPrice">
              <el-input-number
                v-model="createForm.unusedPrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="ハーネス価格" prop="harnessSalesPrice">
              <el-input-number
                v-model="createForm.harnessSalesPrice"
                :precision="0"
                :min="0"
                :controls="false"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button type="info" size="large" @click="isConfirm = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="submit()">
            登録
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            type="info"
            size="large"
            @click="router.push('/master/companygroup')"
          >
            戻る
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { CompanyGroupsSubmitData } from '@/types/master/companyGroup';
  import { incurredPriceSelect } from '@/libs/companyGroup';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const createForm = ref<CompanyGroupsSubmitData>({
    name: '',
    fcCode: '',
    companyGroupType: 1,
    customerNextNumber: 0,
    deviceTradePrice: null,
    deviceAttachedPrice: null,
    deviceDettachedPrice: null,
    incurredPriceType: 0,
    monthlyPrice: null,
    unusedPrice: null,
    harnessSalesPrice: null
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: '販売店グループ名は必須です。' }],
    fcCode: [{ required: true, message: 'FCコードは必須です。' }],
    customerNextNumber: [
      { required: true, message: '次回顧客番号は必須です。' }
    ],
    deviceTradePrice: [{ required: true, message: 'MCCS卸価格は必須です。' }],
    deviceAttachedPrice: [{ required: true, message: '取付価格は必須です。' }],
    deviceDettachedPrice: [{ required: true, message: '取外価格は必須です。' }],
    incurredPriceType: [
      { required: true, message: '発生費用対象は必須です。' }
    ],
    monthlyPrice: [{ required: true, message: '月額費用は必須です。' }],
    unusedPrice: [{ required: true, message: '未使用費用は必須です。' }],
    harnessSalesPrice: [{ required: true, message: 'ハーネス価格は必須です。' }]
  });
  const isConfirm = ref(false);

  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid: boolean) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('companyGroupsMaster/submit', createForm.value);
  };
  const fcCodeRestriction = () => {
    createForm.value.fcCode = createForm.value.fcCode.replace(/[^A-Z0-9]/g, '');
  };
</script>
<style lang="scss">
  .company-group-master-create {
    .company-group-master-create-form {
      display: inline-flex;
    }
    form {
      width: 100%;
      display: inline-block;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .el-select {
      float: left;
    }

    .el-button {
      font-weight: 600;
    }

    .company-group-master-create-city {
      .el-input {
        width: 140px;
        float: left;
      }
    }

    .el-input-number {
      .el-input {
        float: left;
        width: 130px;
      }
      .el-input__inner {
        text-align: left;
      }
    }

    .company-group-master-create-staff-name {
      .el-input {
        width: 200px;
        float: left;
      }
    }

    .company-group-master-create-target {
      .el-input {
        width: 130px;
        float: left;
      }
    }

    .button-area {
      margin: 0px 0px 10px;
    }
  }
</style>
