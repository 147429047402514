<template>
  <BaseSimpleLayout class="activation-confirm">
    <template #title>起動制御解除予約確認</template>
    <template #main>
      <BaseDataTable :table-data="confirmTableData" :table-width="400" />
      <el-button
        type="info"
        size="large"
        @click="router.push('/activation/create')"
      >
        戻る
      </el-button>
      <el-button type="primary" size="large" @click="submit()">
        登録
      </el-button>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { formatDateTime } from '@/libs/dateFormat';
  import { DetailTableData } from '@/types/common';

  const store = useStore();
  const confirmTableData = ref<DetailTableData[]>([]);

  const initialize = () => {
    const submitData = store.state.activation.submitData;
    const deviceData = store.state.activation.deviceData;
    if (!submitData) {
      return router.push('/activation');
    }
    confirmTableData.value = [
      {
        label: '顧客管理番号',
        value: submitData.customerCode
      },
      {
        label: '解除予約日時',
        value: formatDateTime(submitData.reserveDate)
      },
      {
        label: 'VIN',
        value: deviceData.tags.VIN
      },
      {
        label: 'Device SerialNo',
        value: deviceData.tags.deviceSerialNo
      },
      {
        label: 'Last Connection',
        value: formatDateTime(deviceData.detailData.lastCommunicatedAt)
      },
      {
        label: 'Current Status',
        value: deviceData.detailData.activation.currentStatus
      }
    ];
  };
  const submit = () => {
    store.dispatch('activation/submit');
  };
  initialize();
</script>
<style lang="scss">
  .activation-confirm {
    .el-table {
      width: 400px;
      margin: auto;
    }
    .el-button {
      font-weight: 600;
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
