<template>
  <BaseSimpleLayout class="sale-list">
    <template #title>売上一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="orderRole"
        size="large"
        type="success"
        @click="displayDialog"
      >
        CSVダウンロード
      </el-button>
      <el-button
        v-if="orderRole"
        size="large"
        type="primary"
        @click="saleDialogVisible = true"
      >
        サービス利用計上
      </el-button>
      <el-button
        v-if="orderRole"
        size="large"
        type="primary"
        @click="router.push('/sale/input/accept')"
      >
        新規登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="filter-area">
        <span class="label">ファイナンス</span>
        <el-select
          v-model="filterForm.finance"
          filterable
          clearable
          placeholder="選択"
          @change="filterTable"
        >
          <el-option
            v-for="finance in financeList"
            :key="finance.id"
            :label="finance.name"
            :value="finance.name"
          />
        </el-select>
        <span class="label">販売店グループ</span>
        <el-select
          v-model="filterForm.companyGroup"
          filterable
          clearable
          placeholder="選択"
          @change="filterTable"
        >
          <el-option
            v-for="companyGroup in companyGroupList"
            :key="companyGroup.id"
            :label="companyGroup.name"
            :value="companyGroup.name"
          />
        </el-select>
        <span class="label">フィルター</span>
        <el-input
          v-model="filterForm.filterText"
          placeholder="検索"
          @change="filterTable"
        />
        <span class="label">件数</span>
        <el-input-number
          class="form-count"
          v-model="filterForm.count"
          :min="1"
          :max="100000"
          :controls="false"
          :precision="0"
          @change="filterTable"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        empty-text="データが存在しません。"
        size="small"
        :max-height="tableHeight"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @row-click="moveDetail"
      >
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="80"
          sortable
        />
        <el-table-column
          prop="customerCode"
          label="顧客管理番号"
          align="left"
          width="170"
          sortable
        />
        <el-table-column
          prop="financeCode"
          label="ファイナンス管理番号"
          align="left"
          width="200"
          sortable
        >
          <template #default="scope">
            {{ scope.row.financeCode ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="受注区分"
          align="left"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ acceptType[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="saleDeviceType"
          label="売上種別"
          align="left"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ deviceSaleType[scope.row.saleDeviceType] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="saleDate"
          label="売上日"
          align="left"
          width="95"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.saleDate) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="financeName"
          label="ファイナンス"
          align="left"
          sortable
        >
          <template #default="scope">
            {{ scope.row.financeName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="companyGroupName"
          label="販売店グループ"
          align="left"
          sortable
        >
          <template #default="scope">
            {{ scope.row.companyGroupName ?? '-' }}
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogVisible" title="CSV出力" width="350px">
        <el-form :model="billingForm" label-width="100px" size="large">
          <el-form-item label="請求月" prop="yearMonth">
            <el-date-picker
              v-model="billingForm.yearMonth"
              type="month"
              placeholder="対象月を選択"
              style="width: 140px"
              format="YYYY-MM"
              value-format="x"
              :clearable="false"
              @change="fetchCsvData"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button
            v-if="orderRole"
            size="large"
            type="primary"
            @click="csvDownload"
          >
            ダウンロード
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="saleDialogVisible"
        title="サービス利用計上"
        width="350px"
      >
        <el-form :model="saleForm" label-width="100px">
          <el-form-item label="出荷月" prop="month">
            <el-date-picker
              v-model="saleForm.month"
              type="month"
              placeholder="対象月を選択"
              style="width: 140px"
              format="YYYY-MM"
              value-format="x"
              :clearable="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="saleDialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="recordSale">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ElLoading } from 'element-plus';
  import {
    SaleListState,
    SaleFilterForm,
    SaleCsvBaseState
  } from '@/types/sale';
  import { FinancesDataState } from '@/types/master/finance';
  import { CompanyGroupsDataState } from '@/types/master/companyGroup';
  import { deviceSaleType } from '@/libs/sale';
  import { formatDate, csvFormat } from '@/libs/dateFormat';
  import { acceptType } from '@/libs/accept';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<SaleListState[]>([]);
  const filterForm = ref<SaleFilterForm>({
    filterText: null,
    finance: null,
    companyGroup: null,
    count: 500
  });
  const billingForm = ref({
    yearMonth: 0
  });
  const loadingFlag = ref(false);
  const financeList = ref<FinancesDataState[]>([]);
  const companyGroupList = ref<CompanyGroupsDataState[]>([]);
  const orderRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const dialogVisible = ref(false);
  const saleDialogVisible = ref(false);
  const saleForm = ref({
    month: 0
  });
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('sale/fetch'),
      store.dispatch('financesMaster/fetch'),
      store.dispatch('companyGroupsMaster/fetch'),
      store.dispatch('shopsMaster/fetch'),
      store.dispatch('productsMaster/fetch')
    ]);
    const filterData = store.state.sale.filterData;
    if (filterData) {
      filterForm.value = filterData;
    }
    filterTable();
    store.dispatch('sale/resetSubmitData');
    financeList.value = store.state.financesMaster.listData;
    companyGroupList.value = store.state.companyGroupsMaster.listData;
    const defaultTime = new Date();
    defaultTime.setDate(1);
    defaultTime.setHours(0);
    defaultTime.setMinutes(0);
    defaultTime.setSeconds(0);
    defaultTime.setUTCMilliseconds(0);
    billingForm.value.yearMonth = defaultTime.getTime();
    saleForm.value.month = defaultTime.getTime();
    tableHeight.value = window.innerHeight - 170;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterTable = () => {
    let count = 0;
    store.dispatch('sale/setFilterData', filterForm.value);
    tableData.value = store.state.sale.listData.filter(
      (data: SaleListState) => {
        if (count >= filterForm.value.count) return false;
        count += 1;
        if (filterForm.value.finance) {
          if (data.financeName !== filterForm.value.finance) return false;
        }
        if (filterForm.value.companyGroup) {
          if (data.companyGroupName !== filterForm.value.companyGroup)
            return false;
        }
        if (!filterForm.value.filterText) return true;
        const customerCode = data.customerCode ?? '-';
        const financeCode = data.financeCode ?? '-';
        const typeData = data.type ? acceptType[data.type] : '-';
        const saleTypeData = data.saleDeviceType
          ? deviceSaleType[data.saleDeviceType]
          : '-';
        const saleDate = convertDate(data.saleDate);
        return (
          customerCode.includes(filterForm.value.filterText) ||
          financeCode.includes(filterForm.value.filterText) ||
          typeData.includes(filterForm.value.filterText) ||
          saleTypeData.includes(filterForm.value.filterText) ||
          saleDate.includes(filterForm.value.filterText)
        );
      }
    );
  };

  const convertDate = (date: number | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const moveDetail = (row: SaleListState) => {
    router.push('/sale/' + row.id);
  };

  const displayDialog = async () => {
    await fetchCsvData();
    dialogVisible.value = true;
  };

  const fetchCsvData = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('sale/fetchCsv', billingForm.value.yearMonth);
    loadingInstance.close();
  };

  const csvDownload = async () => {
    const filename = '請求一覧' + csvFormat(Date.now());
    const header = [
      '受注日,売上日,受注区分,売上種別,ファイナンス,販売店グループ,販売店企業,販売店,取付店種別,顧客管理番号,ファイナンス管理番号,車台番号,端末シリアル,取付日,製品ID,製品名,個数,金額,請求開始日,請求終了日,備考'
    ];
    const baseData = store.state.sale.csvData.map(
      (data: SaleCsvBaseState): string => {
        return Object.entries({
          acceptDate: convertDate(data.acceptDate),
          saleDate: convertDate(data.saleDate),
          type: data.type ? acceptType[data.type] : '-',
          saleType: data.saleDeviceType
            ? deviceSaleType[data.saleDeviceType]
            : '-',
          financeName: data.financeName ? data.financeName : '-',
          companyGroupName: data.companyGroupName ? data.companyGroupName : '-',
          companyName: data.companyName ? data.companyName : '-',
          shopName: data.shopName ? data.shopName : '-',
          mountType: data.mountType ?? '-',
          customerCode: data.customerCode ? data.customerCode : '-',
          financeCode: data.financeCode ? data.financeCode : '-',
          vin: data.vin ? data.vin : '-',
          serialCode: data.serialCode ? data.serialCode : '-',
          mountDate: convertDate(data.mountDate),
          productId: data.productId ? String(data.productId) : '-',
          productName: data.productName,
          productQty: data.productQty,
          productPrice: data.productPrice,
          startBillingDate: convertDate(data.startBillingDate),
          endBillingDate: convertDate(data.endBillingDate),
          note: data.note ? data.note : ''
        })
          .map(([, value]) => value)
          .join(',');
      }
    );
    const data = header.concat(baseData).join('\r\n');
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const download = document.createElement('a');
    download.href = url;
    download.download = filename;
    download.click();
    (window.URL || window.webkitURL).revokeObjectURL(url);
    dialogVisible.value = false;
  };

  const recordSale = async () => {
    await store.dispatch('sale/record', saleForm.value.month);
    saleDialogVisible.value = false;
  };
  initialize();
</script>
<style lang="scss">
  .sale-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 13px;
    }
    .filter-area {
      margin: 10px 0px;

      .label {
        margin: 0 10px 0 20px;
        font-weight: 600;
        color: #606266;
      }
      .index {
        margin: 0 5px;
      }
      .el-input {
        width: 250px;
      }
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }

    .header-button-area > span {
      margin-right: 10px;
    }
    .dialog-footer > span {
      margin-left: 10px;
    }
    td {
      cursor: pointer;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table {
      margin: 10px auto;
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        font-size: 13px;
      }
    }
  }
</style>
