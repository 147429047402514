<template>
  <BaseSimpleLayout class="ship-list">
    <template #title>出荷一覧</template>
    <template v-if="loadingFlag" #main>
      <el-form :model="filterForm" label-width="95px">
        <div class="date-filter-area">
          <el-form-item label="受注日">
            <span>開始:</span>
            <el-date-picker
              v-model="filterForm.acceptStartDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterShip"
            />
            <span>終了:</span>
            <el-date-picker
              v-model="filterForm.acceptEndDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterShip"
            />
          </el-form-item>
          <el-form-item label="出荷日">
            <span>開始:</span>
            <el-date-picker
              v-model="filterForm.shipStartDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterShip"
            />
            <span>終了:</span>
            <el-date-picker
              v-model="filterForm.shipEndDate"
              type="date"
              placeholder="日付を選択"
              style="width: 140px; margin: 0px 10px 0px 5px"
              format="YYYY-MM-DD"
              value-format="x"
              @change="filterShip"
            />
          </el-form-item>
        </div>
        <div class="id-filter-area">
          <el-form-item label="受注ID">
            <el-input
              v-model="filterForm.acceptId"
              style="width: 100px"
              clearable
              placeholder="検索"
              @input="filterShip"
            />
          </el-form-item>
          <el-form-item label="出荷ID">
            <el-input
              v-model="filterForm.shipId"
              style="width: 100px"
              clearable
              placeholder="検索"
              @input="filterShip"
            />
          </el-form-item>
        </div>
        <div class="text-filter-area">
          <el-form-item label="フィルター">
            <el-input
              v-model="filterForm.filterText"
              style="width: 140px"
              clearable
              placeholder="フィルター"
              @input="filterShip"
            />
          </el-form-item>
          <el-form-item label="フィルター2">
            <el-input
              v-model="filterForm.filterText2"
              style="width: 140px"
              clearable
              placeholder="フィルター2"
              @input="filterShip"
            />
          </el-form-item>
        </div>
        <div class="text-filter-area">
          <el-form-item label="ステータス">
            <el-checkbox-group
              v-model="filterForm.status"
              @change="filterShipStatus"
            >
              <el-checkbox label="依頼" />
              <el-checkbox label="準備中" />
              <el-checkbox label="完了" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="出荷種別">
            <el-checkbox-group
              v-model="filterForm.shipType"
              @change="filterShip"
            >
              <el-checkbox label="出荷 " />
              <el-checkbox label="出荷(確定)" />
              <el-checkbox label="バルク" />
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="id-filter-area">
          <el-form-item label="件数">
            <el-input-number
              class="form-count"
              v-model="filterForm.count"
              :min="1"
              :max="100000"
              :controls="false"
              :precision="0"
              @change="filterShip"
            />
          </el-form-item>
        </div>
      </el-form>
      <div class="disp-count">
        USS件数：<span style="font-weight: bold">{{ USSOrderCount }}</span>
        GMS件数：<span style="font-weight: bold">{{ orderCount }}</span>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="データが存在しません。"
        @row-click="rowClick"
      >
        <el-table-column
          property="id"
          label="ID"
          align="center"
          width="80"
          sortable
        />
        <el-table-column
          property="type"
          label="出荷種別"
          align="center"
          width="110"
          sortable
        >
          <template #default="scope">
            {{ replaceShipType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          property="customerCode"
          label="顧客管理番号"
          align="center"
          width="140"
          sortable
        >
          <template #default="scope">
            {{ scope.row.customerCode ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="orderCompanyName"
          label="確定端末"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ scope.row.orderCompanyName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="deviceSerialCode"
          label="シリアル番号"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ replaceSerial(scope.row.deviceSerialCode) }}
          </template>
        </el-table-column>
        <el-table-column
          property="acceptId"
          label="受注ID"
          align="center"
          width="95"
          sortable
        />
        <el-table-column
          property="acceptDate"
          label="受注日"
          align="center"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.acceptDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="shipDate"
          label="出荷日"
          align="center"
          width="100"
          sortable
        >
          <template #default="scope">
            {{ convertDate(scope.row.shipDate) }}
          </template>
        </el-table-column>
        <el-table-column
          property="shipName"
          label="出荷先名"
          header-align="center"
          sortable
        />
        <el-table-column
          property="zip"
          label="出荷先住所"
          header-align="center"
          sortable
        >
          <template #default="scope">
            {{ replaceZip(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ shipStatus[scope.row.status] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref, onBeforeUnmount } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ShipsDataState, ShipFilterData } from '@/types/ship';
  import { formatDate, addDay } from '@/libs/dateFormat';
  import { shipStatus, shipType } from '@/libs/ship';
  import { ElLoading } from 'element-plus';

  onBeforeUnmount(() => {
    clearInterval(reload);
  });

  const store = useStore();
  const tableData = ref<ShipsDataState[]>([]);
  const filterForm = ref<ShipFilterData>({
    acceptStartDate: null,
    acceptEndDate: null,
    shipStartDate: null,
    shipEndDate: null,
    filterText: null,
    filterText2: null,
    acceptId: null,
    shipId: null,
    status: ['依頼', '準備中'],
    shipType: ['出荷 ', '出荷(確定)', 'バルク'],
    count: 100
  });
  const loadingFlag = ref(false);
  const tableHeight = ref(550);
  let USSOrderCount: number;
  let orderCount: number;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    if (store.state.ship.filterData) {
      filterForm.value = store.state.ship.filterData;
    }
    await store.dispatch('ship/fetch', getFilterStatusIds());
    filterShip();
    tableHeight.value = window.innerHeight - 200;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const getFilterStatusIds = () => {
    return filterForm.value.status.map((data) => {
      return Object.entries(shipStatus).find((value) => value[1] === data)?.[0];
    });
  };

  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };

  const replaceSerial = (serialList: string[]) => {
    let serialData = '-';
    if (serialList.length === 1) {
      serialData = serialList[0].toString();
    }
    if (serialList.length > 1) {
      serialData = 'リンク済';
    }
    return serialData;
  };

  const replaceShipType = (data: ShipsDataState) => {
    let retunValue = shipType[data.type];
    if (data.type === 1) {
      const addInfo = data.orderCompanyId ? '(確定)' : ' ';
      retunValue = retunValue + addInfo;
    }
    return retunValue;
  };

  const replaceZip = (data: ShipsDataState) => {
    let zip = data.shipPrefecture + data.shipCity + data.shipAddress;
    if (data.shipAddressSub) zip = zip + data.shipAddressSub;
    return zip;
  };

  const filterShipStatus = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('ship/fetch', getFilterStatusIds());
    filterShip();
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterShip = () => {
    let count = 0;
    USSOrderCount = 0;
    orderCount = 0;
    store.dispatch('ship/setFilterData', filterForm.value);
    tableData.value = store.state.ship.listData.filter(
      (data: ShipsDataState) => {
        const customerCode = data.customerCode ?? '-';
        if (count >= filterForm.value.count) return false;
        if (!filterDate(data)) return false;
        if (
          filterForm.value.shipType &&
          !filterForm.value.shipType.includes(replaceShipType(data))
        )
          return false;
        if (
          filterForm.value.shipId &&
          !data.id.toString().includes(filterForm.value.shipId)
        )
          return false;
        if (
          filterForm.value.acceptId &&
          !data.acceptId.toString().includes(filterForm.value.acceptId)
        )
          return false;
        if (!filterTextData(data, 'filterText')) return false;
        if (!filterTextData(data, 'filterText2')) return false;
        customerCode.substring(0, 3) === 'USS' ? ++USSOrderCount : ++orderCount;
        count += 1;
        return true;
      }
    );
  };

  const filterDate = (data: ShipsDataState): boolean => {
    let acceptFromResult = !filterForm.value.acceptStartDate;
    let acceptToResult = !filterForm.value.acceptEndDate;
    let shipFromResult = !filterForm.value.shipStartDate;
    let shipToResult = !filterForm.value.shipEndDate;
    if (filterForm.value.acceptStartDate && data.acceptDate) {
      acceptFromResult = filterForm.value.acceptStartDate <= data.acceptDate;
    }
    if (filterForm.value.acceptEndDate && data.acceptDate) {
      acceptToResult =
        data.acceptDate < addDay(filterForm.value.acceptEndDate, 1);
    }
    if (filterForm.value.shipStartDate && data.shipDate) {
      shipFromResult = filterForm.value.shipStartDate <= data.shipDate;
    }
    if (filterForm.value.shipEndDate && data.shipDate) {
      shipToResult = data.shipDate < addDay(filterForm.value.shipEndDate, 1);
    }
    return acceptFromResult && acceptToResult && shipFromResult && shipToResult;
  };

  const filterTextData = (
    data: ShipsDataState,
    text: 'filterText' | 'filterText2'
  ): boolean => {
    if (!filterForm.value[text]) return true;
    const value = filterForm.value[text] ?? '';
    const orderCompanyName = data.orderCompanyName ?? '-';
    const zip = replaceZip(data);
    const serialCode = replaceSerial(data.deviceSerialCode);
    const customerCode = data.customerCode ?? '-';
    return (
      orderCompanyName.includes(value) ||
      zip.includes(value) ||
      serialCode.includes(value) ||
      customerCode.includes(value) ||
      data.shipName.includes(value)
    );
  };

  const rowClick = (data: ShipsDataState) => {
    router.push('/ship/' + data.id);
  };
  initialize();
  const reload = setInterval(initialize, 1000 * 60 * 3);
</script>
<style lang="scss">
  .ship-list {
    .main-container {
      width: 98%;
    }
    tbody {
      font-size: 13px;
      font-weight: 500;
    }
    thead {
      font-size: 14px;
    }
    .el-form {
      width: 100%;
      display: inline-flex;
    }
    .el-form-item {
      text-align: left;
      margin-bottom: 10px;
      .el-form-item__label {
        font-weight: 600;
      }
    }
    .form-group {
      margin-bottom: 0px;
    }
    .date-filter-area {
      width: 480px;
    }
    .text-filter-area {
      .el-input {
        width: 300px;
      }
    }
    .id-filter-area {
      label {
        width: 60px !important;
      }
    }
    .form-count {
      width: 90px;
      .el-input {
        width: 90px;
      }
    }
    .el-checkbox {
      font-weight: 600;
    }
    .el-table {
      margin: 10px auto 0;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    @media print {
      .main-container {
        width: 100%;
      }
      .base-header {
        display: none;
      }
      .el-form {
        display: none;
      }
      .main {
        padding-top: 0;
      }
    }
    .disp-count {
      text-align: left;
    }
  }
</style>
