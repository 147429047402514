export function telRestriction(data: string): string {
  return data.replace(/[^\d-]/g, '');
}

export function zipRestriction(data: string) {
  return data.replace(/[^\d]/g, '');
}

export function nodeEnv() {
  return import.meta.env?.NODE_ENV ?? 'local';
}
