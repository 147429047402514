/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  ReasonCancelState,
  ReasonCancelListState,
  ReasonCancelReplaceData
} from '@/types/reasonCancel';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: null,
    replaceData: null
  },
  mutations: {
    setReasonCancel(
      state: ReasonCancelState,
      payload: ReasonCancelListState[]
    ) {
      state.listData = payload;
    },
    setReplace(state: ReasonCancelState, payload: ReasonCancelListState) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/reason-cancel/external/list', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setReasonCancel', response.data);
          const replaceData: ReasonCancelReplaceData = {};
          response.data.forEach((data: ReasonCancelListState) => {
            replaceData[data.id] = data.reason;
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/accept');
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
