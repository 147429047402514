/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  CarInfoListState,
  CarInfoReplaceData,
  CarInfoListDataState
} from '@/types/master/carInfo';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: []
  },
  mutations: {
    setList(state: CarInfoListState, payload: CarInfoListDataState[]) {
      state.listData = payload;
    },
    setReplace(state: CarInfoListState, payload: CarInfoReplaceData) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/car-info', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceData: CarInfoReplaceData = {};
          response.data.forEach((data: CarInfoListDataState) => {
            replaceData[data.id] = {
              name: data.name,
              carMakerId: data.carMakerId,
              model: data.model,
              modelYear: data.modelYear,
              modelCode: data.modelCode,
              modelType: data.modelType,
              endSales: data.endSales
            };
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
