<template>
  <BaseSimpleLayout class="user-list">
    <template #title>ユーザ一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="orderRole"
        class="unit-user-list-button-create"
        type="success"
        size="large"
        @click="router.push('/user/create')"
      >
        ユーザ登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column
          prop="name"
          label="利用者名"
          width="200"
          header-align="center"
          sortable
        >
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="メールアドレス" sortable />
        <el-table-column
          prop="role"
          label="権限"
          width="200"
          align="center"
          sortable
        >
          <template #default="scope">
            {{ Role[scope.row.role] }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { UsersDataState } from '@/types/user';
  import { Role } from '@/libs/auth';
  import { rolePermission } from '@/libs/auth';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<UsersDataState[]>([]);
  const loadingFlag = ref(false);
  const orderRole = rolePermission(['ORDERADMIN', 'SHIPADMIN']);
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('users/fetch');
    tableData.value = store.state.users.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/user/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.users.listData.filter(
      (data: UsersDataState) => {
        const role = Role[data.role];
        return (
          data.name.includes(searchText.value) ||
          data.email.includes(searchText.value) ||
          role.includes(searchText.value)
        );
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .user-list {
    .el-input {
      width: 300px;
      margin: auto 20px;
    }
    .el-table {
      width: 900px;
      margin: 10px auto 0;
      font-size: 15px;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
