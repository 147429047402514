<template>
  <router-view :key="reloadKey" />
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  const route = useRoute();
  //TODO: search better method, https://github.com/global-mobility-service/sips/pull/182#discussion_r1304389382
  const reloadKey = ref(Date.now());
  watch(
    () => route.path,
    (newPath, oldPath) => {
      if (
        (newPath === '/device/2' && oldPath === '/device/3') ||
        (newPath === '/device/3' && oldPath === '/device/2') ||
        (newPath === '/carSecurity/device/3' &&
          oldPath === '/carSecurity/device/2') ||
        (newPath === '/carSecurity/device/2' &&
          oldPath === '/carSecurity/device/3')
      ) {
        reloadKey.value = Date.now();
      }
    }
  );
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  body {
    margin: 0px;
  }
</style>
