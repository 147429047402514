/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  DeviceState,
  DeviceDetailData,
  DevicePutData,
  DevicePrepareData,
  DeviceVerifyData
} from '@/types/device';
import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null,
    status: 0
  },
  mutations: {
    setDetail(state: DeviceState, payload: DeviceDetailData) {
      state.detailData = payload;
    },
    setPut(state: DeviceState, payload: DevicePutData) {
      state.putData = payload;
    },
    setStatus(state: DeviceState, payload: number) {
      state.status = payload;
    }
  },
  actions: {
    async fetch(context: any, deviceId: any) {
      await axios
        .get(getBaseUrl() + '/device/' + deviceId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const putData = _.cloneDeep(response.data);
          putData['id'] = deviceId;
          delete putData.financeId;
          delete putData.shipId;
          delete putData.status;
          delete putData.orderCompanyName;
          delete putData.updateUserName;
          delete putData.updatedAt;
          context.commit('setPut', putData);
          context.commit('setStatus', response.data.status);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push(`/device/${context.state.putData.deviceType}`);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/device/' + context.state.putData.id,
          {
            deviceId: context.state.putData.deviceId,
            deviceCode: context.state.putData.deviceCode,
            deviceType: context.state.putData.deviceType,
            serialCode: context.state.putData.serialCode,
            firmVersion: context.state.putData.firmVersion,
            mboot: context.state.putData.mboot,
            internalBatteryVoltage:
              context.state.putData.internalBatteryVoltage,
            orderCompanyId: context.state.putData.orderCompanyId,
            note: context.state.putData.note,
            handlingType: context.state.putData.handlingType,
            deviceMinorVersion: context.state.putData.deviceMinorVersion,
            itemId: context.state.putData.itemId
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '端末情報の変更に成功しました。');
          router.push(`/device/${context.state.putData.id}/detail`);
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, userId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/device/' + userId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '端末情報の削除に成功しました。');
          router.push(`/device/${context.state.putData.deviceType}`);
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            return router.push(`/device/${context.state.putData.deviceType}`);
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async prepare(context: any, payload: DevicePrepareData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .patch(
          getBaseUrl() + '/device/prepare/' + payload.id,
          {
            serialCode: payload.serialCode,
            deviceCode: payload.deviceCode,
            deviceType: payload.deviceType,
            deviceMinorVersion: payload.deviceMinorVersion,
            itemId: payload.itemId,
            orderCompanyId: payload.orderCompanyId,
            note: payload.note
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(async () => {
          await axios.put(
            getBaseUrl() + '/sim/sb/' + payload.id,
            { status: 1 },
            {
              headers: { auth: await getAccessToken() }
            }
          );
        })
        .then(() => {
          success('Success', 'ステータスを準備中に変更しました。');
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async verify(context: any, payload: DeviceVerifyData) {
      await axios
        .patch(
          getBaseUrl() + '/device/verify/' + payload.id,
          {
            firmVersion: payload.firmVersion,
            mboot: payload.mboot,
            internalBatteryVoltage: payload.internalBatteryVoltage
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを検証済に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async stop(context: any, deviceId: string) {
      await axios
        .patch(
          getBaseUrl() + '/device/stop/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを使用中止に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async restart(context: any, deviceId: string) {
      await axios
        .patch(
          getBaseUrl() + '/device/restart/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを検証済に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push(`/device/${context.state.putData.deviceType}`);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async unlink(context: any, deviceId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/device/unlink/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '対象端末のリンク解除に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async exchange(
      context: any,
      payload: {
        deviceId: string;
        sim: string;
      }
    ) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/device/sim/exchange/' + payload.deviceId,
          {
            SIMNo: payload.sim
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then((response) => {
          success('Success', 'sim情報切り替えに成功しました');
          router.push('/device/' + response.data.deviceLedgerId + '/detail');
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, e.response.data);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setPutData(context: any, payload: DevicePutData) {
      context.commit('setPut', payload);
    }
  }
};
