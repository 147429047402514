<template>
  <div class="package-detail">
    <BaseSimpleLayout>
      <template #title>パッケージ詳細</template>
      <template v-if="loadingFlag" #main>
        <div class="table-title">基本情報</div>
        <BaseDataTable
          class="package-detail-table"
          :table-data="detailTableData"
          :label-width="150"
        />
        <BaseProductList :table-data="itemTableData" :part-code="true" />
        <el-button
          type="info"
          size="large"
          @click="router.push('/master/package')"
        >
          戻る
        </el-button>
        <el-button
          v-if="editRole"
          type="success"
          size="large"
          @click="router.push('/master/package/edit')"
        >
          編集
        </el-button>
        <el-button
          v-if="editRole"
          type="warning"
          size="large"
          @click="
            router.push('/master/package/create?copy_id=' + packageMasterId)
          "
        >
          コピー
        </el-button>
        <el-button
          v-if="deleteRole"
          type="danger"
          size="large"
          @click="dialogVisible = true"
        >
          削除
        </el-button>
      </template>
    </BaseSimpleLayout>
    <el-dialog v-model="dialogVisible" title="確認" width="400px">
      <span>
        <b>このパッケージマスタを削除しますか。</b>
      </span>
      <template #footer>
        <el-button size="large" @click="dialogVisible = false">
          キャンセル
        </el-button>
        <el-button size="large" type="danger" @click="deleteItem">
          削除
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import BaseProductList from '@/components/BaseProductList.vue';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData, ProductListData } from '@/types/common';
  import { PackageItemData } from '@/types/master/package';
  import { handlingType } from '@/libs/device';

  const props = defineProps({
    packageMasterId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const editRole = ref(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const deleteRole = ref(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const itemTableData = ref<ProductListData[]>([]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('packageMaster/fetch', props.packageMasterId),
      store.dispatch('itemsMaster/fetch')
    ]);
    const detailData = store.state.packageMaster.detailData;
    const itemDataChanger = store.state.itemsMaster.replaceData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: 'パッケージ名称',
        value: detailData.name
      },
      {
        label: '品番',
        value: detailData.packCode
      },
      {
        label: '専用ハーネス変換',
        value: detailData.isChangeHarnessItem ? '対象' : '対象外'
      }
    ];
    itemTableData.value = detailData.details.map((data: PackageItemData) => {
      return {
        id: data.itemId,
        partCode: data.partCode,
        name: itemDataChanger[data.itemId].name,
        type: handlingType[data.itemHandlingType],
        qty: data.itemQty
      };
    });
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const deleteItem = () => {
    store.dispatch('packageMaster/delete', props.packageMasterId);
  };
  initialize();
</script>
<style lang="scss">
  .package-detail {
    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    .package-detail-table {
      width: 650px;
    }
    .el-table {
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
