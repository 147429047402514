import { NumberData } from '@/types/common';

export const ignitionType: NumberData = {
  1: 'キー式',
  2: 'プッシュ式',
  3: 'その他',
  4: 'キー02式'
};

export const compatibility: NumberData = {
  0: '✖',
  1: '◎',
  2: '〇※',
  3: '〇サポ',
  4: '〇カット',
  9: '不可'
};
