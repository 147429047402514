<template>
  <BaseSimpleLayout class="user-edit">
    <template #title>ユーザ情報編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="120px"
      >
        <el-form-item class="unit-user-edit-id" label="ID" prop="id">
          <el-input
            v-model="editForm.id"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item class="unit-user-edit-name" label="ユーザ名" prop="name">
          <el-input
            v-model="editForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="unit-user-edit-email"
          label="メールアドレス"
          prop="email"
        >
          <el-input
            v-model="editForm.email"
            autocomplete="off"
            :disabled="isConfirm"
          ></el-input>
        </el-form-item>
        <el-form-item class="unit-user-edit-role" label="権限" prop="role">
          <el-select
            v-model="editForm.role"
            placeholder=""
            :disabled="isConfirm"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="isConfirm">
          <el-button
            class="unit-user-edit-button-detail"
            type="info"
            @click="isConfirm = false"
          >
            キャンセル
          </el-button>
          <el-button
            class="unit-user-edit-button-confirm"
            type="primary"
            @click="update"
          >
            更新
          </el-button>
        </div>
        <div v-else>
          <el-button
            class="unit-user-edit-button-detail"
            type="info"
            @click="router.push(`/user/${editForm.id}`)"
          >
            戻る
          </el-button>
          <el-button
            class="unit-user-edit-button-confirm"
            type="primary"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { UserPutDataState } from '@/types/user';

  const store = useStore();
  const editForm = ref<UserPutDataState>({
    id: 0,
    name: '',
    email: '',
    role: 1
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: 'ユーザ名は必須です。' }],
    email: [
      {
        required: true,
        message: 'メールアドレスは必須です。'
      },
      { type: 'email', message: 'メールアドレス形式で入力してください。' }
    ]
  });
  const options = [
    {
      value: 1,
      label: 'ORDERING'
    },
    {
      value: 2,
      label: 'SHIPPING'
    },
    {
      value: 3,
      label: 'SHIPPING_PLUS'
    },
    {
      value: 4,
      label: 'TECH'
    },
    {
      value: 5,
      label: 'VIEW'
    },
    {
      value: 6,
      label: 'SHIPADMIN'
    },
    {
      value: 7,
      label: 'ORDERADMIN'
    },
    {
      value: 9,
      label: 'ALL'
    }
  ];
  const formRef = ref<FormInstance>();
  const isConfirm = ref(false);

  const initialize = async () => {
    if (!store.state.user.putData) {
      return router.push('/user');
    }
    editForm.value = store.state.user.putData;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = () => {
    store.dispatch('user/put', editForm.value);
  };
  initialize();
</script>
<style lang="scss">
  .user-edit {
    form {
      width: 400px;
      margin: auto;
    }
    .el-select {
      width: 150px;
      float: left;
    }
    .el-button {
      margin: 10px auto;
      font-weight: 600;
    }
  }
</style>
