/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import { ElLoading } from 'element-plus';
import {
  MoveState,
  MoveDataState,
  MoveDetailDataState,
  MovePutDataState,
  MoveFilterForm,
  MoveSubmitDataState
} from '@/types/move';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    listData: [],
    filterData: null
  },
  mutations: {
    setList(state: MoveState, payload: MoveDataState[]) {
      state.listData = payload;
    },
    setDetail(state: MoveState, payload: MoveDetailDataState) {
      state.detailData = payload;
    },
    setFilter(state: MoveState, payload: MoveFilterForm) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/moves', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: MoveDataState, b: MoveDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchDetail(context: any, moveId: any) {
      await axios
        .get(getBaseUrl() + '/moves/' + moveId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '移動情報が存在しません。');

            return router.push('/move');
          }
          if (e.response.status < 500) {
            error(e.response.status);

            return router.push('/move');
          }
          router.push('/error/server');
        });
    },
    async put(context: any, payload: MovePutDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const putData = _.omit(payload, ['id']);
      await axios
        .put(getBaseUrl() + '/moves/' + payload.id, putData, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          loadingInstance.close();
          success('success', '更新に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '移動情報が存在しません。');
            return router.push('/move');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    setFilterData(context: any, payload: MoveFilterForm) {
      context.commit('setFilter', payload);
    },
    async submit(context: any, payload: MoveSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/moves', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '移動情報の登録に成功しました。');
          router.push('/move');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, moveId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/moves/' + moveId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '移動情報の削除に成功しました。');
          router.push('/move');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '移動情報が存在しません。');
            return router.push('/move');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
