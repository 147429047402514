import { format, addDays } from 'date-fns';
import ja from 'date-fns/locale/ja';

export function formatDateTime(date: number) {
  const dateTime = new Date(date);
  return format(dateTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja });
}

export function formatDate(date: number | null) {
  if (!date) return '-';
  const dateTime = new Date(date);
  return format(dateTime, 'yyyy-MM-dd', { locale: ja });
}

export function formatJpDate(date: number | null) {
  if (!date) return '-';
  const dateTime = new Date(date);
  return format(dateTime, 'yyyy年M月d日', { locale: ja });
}

export function formatTimeStamp(date: string | null) {
  if (!date) return '-';
  const dateTime = new Date(date);
  return format(dateTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja });
}

export function csvFormat(date: number | null) {
  if (!date) return '-';
  const dateTime = new Date(date);
  return format(dateTime, 'yyyyMMdd-HHmmss', { locale: ja });
}

export function addDay(date: number, day: number) {
  return addDays(new Date(date), day).getTime();
}
