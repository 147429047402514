<template>
  <BaseSimpleLayout class="receive-detail">
    <template #title>入荷詳細</template>
    <template v-if="loadingFlag" #main>
      <el-table
        :data="detailTableData"
        :show-header="false"
        border
        class="base-data-table"
      >
        <el-table-column
          prop="label"
          align="left"
          class-name="label-column"
          :width="155"
        />
        <el-table-column prop="value">
          <template #default="scope">
            <a
              v-if="scope.row.label === 'シリアル番号'"
              href="#"
              @click="router.push('/device/' + deviceLedgerId + '/detail')"
              >{{ scope.row.value }}</a
            >
            <span v-else>{{ scope.row.value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="info" size="large" @click="router.push('/receive')">
        戻る
      </el-button>
      <el-button
        v-if="status === 1 && shipAdminRole"
        type="primary"
        size="large"
        @click="router.push('/receive/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="status === 1 && shipAdminRole"
        type="success"
        size="large"
        @click="router.push('/receive/complete')"
      >
        本登録
      </el-button>
      <el-button
        v-if="status === 1 && shipAdminRole"
        type="danger"
        size="large"
        @click="displayDialog = true"
      >
        削除
      </el-button>
      <el-dialog v-model="displayDialog" title="入荷情報削除" width="400px">
        <span>
          <b>この入荷情報を削除しますがよろしいですか？</b>
        </span>
        <template #footer>
          <el-button size="large" @click="displayDialog = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteReceive">
            実行
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ElLoading } from 'element-plus';
  import { DetailTableData } from '@/types/common';
  import { receiveStatus, receiveType, accidentType } from '@/libs/receive';
  import { formatDate } from '@/libs/dateFormat';
  import { deviceStatus } from '@/libs/device';
  import { rolePermission } from '@/libs/auth';

  const props = defineProps({
    receiveId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const status = ref(1);
  const loadingFlag = ref(false);
  const displayDialog = ref(false);
  const deviceLedgerId = ref(0);
  const shipAdminRole = ref<boolean>(rolePermission(['SHIPADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('receive/fetchDetail', props.receiveId);
    const detailData = store.state.receive.detailData;
    deviceLedgerId.value = detailData.deviceLedgerId;
    detailTableData.value = [
      {
        label: 'シリアル番号',
        value: detailData.serialCode ?? '-'
      },
      {
        label: '端末ステータス',
        value: detailData.deviceStatus
          ? deviceStatus[detailData.deviceStatus]
          : '-'
      },
      {
        label: '登録',
        value: receiveStatus[detailData.status]
      },
      {
        label: '入庫日',
        value: convertDate(detailData.receiveDate)
      },
      {
        label: '発送元',
        value: detailData.shipSourceName ?? '-'
      },
      {
        label: '入庫種別',
        value: receiveType[detailData.receiveType]
      },
      {
        label: '端末不良',
        value: accidentType[detailData.accidentType]
      },
      {
        label: '預かり販売店企業',
        value: detailData.orderCompanyName ?? '-'
      },
      {
        label: '代替シリアル番号',
        value: detailData.subDeviceSerialCode ?? '-'
      },
      {
        label: '備考',
        value: detailData.note
      }
    ];
    status.value = detailData.status;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const convertDate = (date: string | null) => {
    if (!date) {
      return '-';
    }
    return formatDate(Number(date));
  };
  const deleteReceive = async () => {
    await store.dispatch('receive/delete', props.receiveId);
  };
  initialize();
</script>
<style lang="scss">
  .receive-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
