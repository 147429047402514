/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error, success } from '@/libs/notification';
import router from '@/router';
import {
  DevicesState,
  DevicesDataState,
  DevicesFilterData,
  DeviceSimRegistData
} from '@/types/device';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    readyListData: [],
    filterData: null
  },
  mutations: {
    setList(state: DevicesState, payload: DevicesDataState[]) {
      state.listData = payload;
    },
    setReadyList(state: DevicesState, payload: DevicesDataState[]) {
      state.readyListData = payload;
    },
    setFilter(state: DevicesState, payload: DevicesFilterData) {
      state.filterData = payload;
    }
  },
  actions: {
    async fetch(context: any, MCCSver: number) {
      const headers: any = {
        auth: await getAccessToken()
      };
      if (MCCSver) {
        headers.deviceType = MCCSver;
      }
      await axios
        .get(getBaseUrl() + '/device', {
          headers: headers
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: DevicesDataState, b: DevicesDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchReady(context: any) {
      await axios
        .get(getBaseUrl() + '/device', {
          params: {
            status: [2]
          },
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setReadyList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async simRegister(context: any, payload: DeviceSimRegistData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/device/sim', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'simの登録に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status === 405) {
            const errorIdList = e.response.data.map(
              (error: any) => '\r\n' + error.sim
            );
            error(
              e.response.status,
              '次のsimの登録に失敗しました' + errorIdList
            );
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/device');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: DevicesFilterData) {
      context.commit('setFilter', payload);
    }
  }
};
