/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  HarnessesState,
  HarnessesDataState,
  HarnessReplaceData
} from '@/types/master/harness';

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser
} from 'amazon-cognito-identity-js';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

const fmUserName = import.meta.env.VITE_APP_API_FM_USER_NAME ?? '';
const fmPassword = import.meta.env.VITE_APP_API_FM_PASSWORD ?? '';
const fmUserPoolId = import.meta.env.VITE_APP_API_FM_USER_POOL_ID ?? '';
const fmClientId = import.meta.env.VITE_APP_API_FM_CLIENT_ID ?? '';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null,
    lastDate: null
  },
  mutations: {
    setList(state: HarnessesState, payload: HarnessesDataState[]) {
      state.listData = payload;
    },
    setReplace(state: HarnessesState, payload: HarnessReplaceData) {
      state.replaceData = payload;
    },
    setLastDate(state: HarnessesState, payload: number) {
      state.lastDate = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/harnesses', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceData: HarnessReplaceData = {};
          response.data.forEach((data: HarnessesDataState) => {
            replaceData[data.id] = {
              carInfo: data.carInfoId,
              itemId: data.itemId
            };
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchCarSecurity(context: any) {
      await axios
        .get(getBaseUrl() + '/master/harnesses/carsec', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceData: HarnessReplaceData = {};
          response.data.forEach((data: HarnessesDataState) => {
            replaceData[data.id] = {
              carInfo: data.carInfoId,
              itemId: data.itemId
            };
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchAll(context: any) {
      await axios
        .get(getBaseUrl() + '/master/harnesses/all', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setList', response.data);
          const replaceData: HarnessReplaceData = {};
          response.data.forEach((data: HarnessesDataState) => {
            replaceData[data.id] = {
              carInfo: data.carInfoId,
              itemId: data.itemId
            };
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchLastDate(context: any) {
      await axios
        .get(getBaseUrl() + '/master/harnesses/last-date', {
          headers: { auth: await await getAccessToken() }
        })
        .then((response) => {
          context.commit('setLastDate', response.data.lastDate);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async update() {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const authenticationData = {
        Username: fmUserName as string,
        Password: fmPassword as string
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const poolData = {
        UserPoolId: fmUserPoolId as string,
        ClientId: fmClientId as string
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: fmUserName as string,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function (result: any) {
          axios
            .post(
              getBaseUrl() + '/master/harnesses/update',
              {
                auth: result.idToken.jwtToken
              },
              {
                headers: { auth: await getAccessToken() }
              }
            )
            .then(() => {
              loadingInstance.close();
              success('Success', 'ハーネス適合表の更新に成功しました。');
            })
            .catch(async (e) => {
              loadingInstance.close();
              if (e.response.status === 401) {
                await handleAuthorizationError();
              } else if (e.response.status < 500) {
                error(
                  e.response.status,
                  'ハーネス適合表の更新に失敗しました。'
                );
              } else {
                router.push('/error/server');
              }
            });
        },
        onFailure: function () {
          loadingInstance.close();
          error('403', 'ファイルメーカーの認証に失敗しました。');
        }
      });
    },
    async csvUpload(context: any, payload: any) {
      const chunkSize = 50; // 一度に送る数
      const totalChunks = Math.ceil(payload.length / chunkSize); // 送信回数

      const loadingInstance = ElLoading.service({ fullscreen: true });
      for (let i = 0; i < totalChunks; i++) {
        const chunk = payload.slice(i * chunkSize, (i + 1) * chunkSize);
        await axios
          .post(getBaseUrl() + '/master/harnesses/csv-upload', chunk, {
            headers: { auth: await getAccessToken() }
          })
          .then(() => {
            console.log('success...');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response && e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response && e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
            return;
          });
      }
      loadingInstance.close();
      success(
        'Success',
        'CSV登録に成功しました。全てのデータがサーバーに送信されました。'
      );
    }
  }
};
