<template>
  <BaseSimpleLayout class="master-item-detail">
    <template #title>単品マスタ詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="130"
      />
      <el-button
        class="unit-master-item-detail-button-list"
        type="info"
        size="large"
        @click="router.push('/master/item')"
      >
        戻る
      </el-button>
      <el-button
        v-if="editRole"
        type="success"
        class="unit-master-item-detail-button-edit"
        size="large"
        @click="router.push('/master/item/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="deleteRole"
        type="danger"
        class="unit-master-item-detail-button-delete"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="30%">
        <span><b>この単品マスタを削除しますか。</b></span>
        <template #footer>
          <el-button size="large" type="info" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button type="danger" size="large" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData } from '@/types/common';

  const props = defineProps({
    itemMasterId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const editRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));
  const deleteRole = ref<boolean>(rolePermission(['SHIPADMIN', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('itemMaster/fetch', props.itemMasterId);
    const detailData = store.state.itemMaster.detailData;
    const subCategoryId = detailData.subCategoryId;
    await Promise.all([
      store.dispatch('categoryMaster/fetch'),
      store.dispatch('subCategoriesMaster/fetch'),
      store.dispatch('subSubCategoriesMaster/fetch', {
        subcategoryId: subCategoryId,
        inDeleted: false
      }),
      store.dispatch('suppliersMaster/fetch')
    ]);

    const categoryNameChanger = store.state.categoryMaster.replaceData;
    const subCategoryNameChanger = store.state.subCategoriesMaster.replaceData;
    const subSubCategoryNameChanger =
      store.state.subSubCategoriesMaster.replaceData;
    const supplierNameChanger = store.state.suppliersMaster.replaceData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id.toString()
      },
      {
        label: '名前',
        value: detailData.name
      },
      {
        label: '型式',
        value: detailData.model ?? '-'
      },
      {
        label: '品番',
        value: detailData.partCode
      },
      {
        label: 'カテゴリー',
        value: categoryNameChanger[detailData.categoryId]
      },
      {
        label: '分類',
        value: detailData.subCategoryId
          ? String(detailData.subCategoryId).padStart(2, '0') +
            ': ' +
            subCategoryNameChanger[detailData.subCategoryId]
          : '-'
      },
      {
        label: '小分類',
        value: detailData.subSubCategoryId
          ? String(detailData.subSubCategoryId).padStart(2, '0') +
            ': ' +
            subSubCategoryNameChanger[detailData.subSubCategoryId]
          : '-'
      },
      {
        label: '基準原価',
        value: detailData.baseCost + ' 円'
      },
      {
        label: '平均原価',
        value: detailData.averageCost + ' 円'
      },
      {
        label: 'ケース単価',
        value: detailData.caseCost + ' 円'
      },
      {
        label: '入数',
        value: detailData.lotQty
      },
      {
        label: 'リードタイム',
        value: detailData.leadTimeDay + ' 日'
      },
      {
        label: '仕入先',
        value: detailData.supplierId
          ? String(detailData.supplierId).padStart(2, '0') +
            ': ' +
            supplierNameChanger[detailData.supplierId]
          : '-'
      },
      {
        label: '備考',
        value: detailData.note ?? '-'
      },
      {
        label: '在庫管理',
        value: detailData.isStock ? '管理する' : '管理しない'
      }
    ];

    loadingInstance.close();
    loadingFlag.value = true;
  };

  const deleteItem = () => {
    store.dispatch('itemMaster/delete', props.itemMasterId);
  };
  initialize();
</script>
<style lang="scss">
  .master-item-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
