import { BulkInputData } from '@/types/bulk';

export const defaultSubmitData: BulkInputData = {
  acceptDate: null,
  financeId: null,
  productId: null,
  productQty: null,
  note: '',
  shipNote: '',
  deviceType: 1
};
