/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import { CarInfoDetailDataState, CarInfoState } from '@/types/master/carInfo';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null
  },
  mutations: {
    setDetail(state: CarInfoState, payload: CarInfoDetailDataState) {
      state.detailData = payload;
    }
  },
  actions: {
    async fetchDetail(context: any, carInfoId: string) {
      await axios
        .get(getBaseUrl() + '/master/car-info/' + carInfoId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '車両情報が存在しません。');
            return router.push('/master/carInfo');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/carInfo');
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
