import { ElNotification } from 'element-plus';

export function success(title: string, message: string) {
  ElNotification({
    title: title,
    message: message,
    type: 'success'
  });
}

export function error(
  status: string,
  message = 'エラーが発生しました。管理者に連絡してください。'
) {
  ElNotification({
    title: 'エラーコード:' + status,
    message: message,
    type: 'error',
    duration: 0
  });
}

export function authError() {
  ElNotification({
    title: 'エラーコード:401',
    message: 'ログインの有効期限が切れました。\r\n再度ログインしてください。',
    type: 'error',
    duration: 0
  });
}
