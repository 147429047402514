<template>
  <BaseSimpleLayout class="master-car-info-list">
    <template #title>車両情報一覧</template>
    <template #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" width="550" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="model" label="型式" width="150" sortable />
        <el-table-column prop="modelYear" label="年式" width="150" sortable />
        <el-table-column prop="carMakerId" label="メーカー" sortable>
          <template #default="scope">
            {{
              scope.row.carMakerId ? carMakerChanger[scope.row.carMakerId] : '-'
            }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { CarInfoListDataState } from '@/types/master/carInfo';
  import { CarMakerReplaceData } from '@/types/master/carMaker';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<CarInfoListDataState[]>([]);
  const carMakerChanger = ref<CarMakerReplaceData>({});
  const loadingFlag = ref(false);
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('carInfoListMaster/fetch'),
      store.dispatch('carMakersMaster/fetch')
    ]);
    tableData.value = store.state.carInfoListMaster.listData;
    carMakerChanger.value = store.state.carMakersMaster.replaceData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const moveDetail = (id: number) => {
    router.push('/master/carinfo/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.carInfoListMaster.listData.filter(
      (item: CarInfoListDataState) => {
        return (
          item.name.includes(searchText.value) ||
          item.model.includes(searchText.value) ||
          item.modelYear.includes(searchText.value) ||
          carMakerChanger.value[item.carMakerId].includes(searchText.value)
        );
      }
    );
  };
  initialize();
</script>

<style lang="scss">
  .master-car-info-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px 0 0;
      thead th {
        background: #f5f7fa;
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
