<template>
  <div class="base-simple-layout">
    <BaseHeader class="header" />
    <div class="main">
      <div class="header-container">
        <div class="page-title">
          <slot name="title" />
        </div>
        <div class="header-button-area">
          <slot name="button-area" />
        </div>
      </div>
      <div class="main-container">
        <slot name="main" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import BaseHeader from '@/components/BaseHeader.vue';

  export default defineComponent({
    name: 'BaseSimpleLayout',
    components: {
      BaseHeader
    }
  });
</script>

<style lang="scss">
  .base-simple-layout {
    .main {
      padding-top: 65px;
    }
    .header-container {
      display: flex;
      height: 40px;
      width: 90%;
      margin: auto;

      .header-button-area {
        width: 70%;
        text-align: right;
      }
      .page-title {
        text-align: left;
        font-weight: 600;
        width: 100%;
        font-size: 25px;
      }
    }
    .main-container {
      margin: auto;
      width: 90%;
      overflow-x: scroll;
    }
  }
</style>
