<template>
  <BaseSimpleLayout class="master-finance-detail">
    <template #title>ファイナンス詳細</template>
    <template v-if="loadingFlag" #main>
      <BaseDataTable
        :table-data="detailTableData"
        table-size="medium"
        :label-width="155"
      />
      <el-button
        type="info"
        size="large"
        @click="router.push('/master/finance')"
      >
        戻る
      </el-button>
      <el-button
        v-if="orderRole"
        type="success"
        size="large"
        @click="router.push('/master/finance/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="orderRole"
        type="danger"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog
        v-model="dialogVisible"
        custom-class="unit-master-finance-detail-delete-dialog"
        title="確認"
        width="30%"
      >
        <span><b>このファイナンスマスタを削除しますか。</b></span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { ElLoading } from 'element-plus';
  import { DetailTableData } from '@/types/common';
  import { rolePermission } from '@/libs/auth';

  const props = defineProps({
    financeMasterId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>();
  const dialogVisible = ref(false);
  const loadingFlag = ref(false);
  const orderRole = ref<boolean>(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('financeMaster/fetch', props.financeMasterId);
    const detailData = store.state.financeMaster.detailData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: 'ファイナンス名称',
        value: detailData.name
      },
      {
        label: 'BCID',
        value: detailData.bcId
      },
      {
        label: 'ファイナンスコード',
        value: detailData.financeCode
      },
      {
        label: 'バルク発注',
        value: detailData.isBulk == 1 ? '有効' : '無効'
      }
    ];
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const deleteItem = () => {
    store.dispatch('financeMaster/delete', props.financeMasterId);
  };
  initialize();
</script>
<style lang="scss">
  .master-finance-detail {
    .el-table {
      width: 500px;
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
