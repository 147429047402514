<template>
  <BaseSimpleLayout class="device-edit-confirm">
    <template #title>【AUTOBACS】端末情報編集確認</template>
    <template #main>
      <div class="device-edit-confirm-table-line">
        <BaseDataTable :table-data="confirmLeftData" :label-width="140" />
      </div>
      <div class="device-edit-confirm-table-line">
        <BaseDataTable :table-data="confirmRightData" :label-width="120" />
      </div>
      <div class="device-edit-confirm-button-area">
        <el-button
          size="large"
          type="info"
          @click="router.push('/autobacs/device/edit')"
        >
          戻る
        </el-button>
        <el-button size="large" type="primary" @click="put()">登録</el-button>
      </div>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import { deviceType, handlingType } from '@/libs/device';
  import { DetailTableData } from '@/types/common';

  const store = useStore();
  const confirmLeftData = ref<DetailTableData[]>([]);
  const confirmRightData = ref<DetailTableData[]>([]);
  const initialize = () => {
    const putData = store.state.autobacsDevice.putData;
    if (!putData) {
      return router.push('/autobacs/device');
    }
    confirmLeftData.value = [
      {
        label: 'ID',
        value: putData.id
      },
      {
        label: 'シリアル番号',
        value: putData.serialCode
      },
      {
        label: '製造番号',
        value: putData.deviceCode
      },
      {
        label: '端末タイプ',
        value: deviceType[putData.deviceType]
      },
      {
        label: 'SIM番号',
        value: putData.simNumber
      },
      {
        label: 'IMSI',
        value: putData.simImsi
      },
      {
        label: '出荷ファームVer',
        value: putData.firmVersion
      },
      {
        label: 'IBバッテリー',
        value: putData.internalBatteryVoltage
      },
      {
        label: 'Mboot',
        value: putData.mboot
      },
      {
        label: '設定ID',
        value: putData.deviceSettingUser
      },
      {
        label: '設定パスワード',
        value: putData.deviceSettingPassword
      }
    ];
    confirmRightData.value = [
      {
        label: '取扱',
        value: handlingType[putData.handlingType]
      },
      {
        label: '発注番号',
        value: putData.autobacsOrderNumber
      },
      {
        label: '備考',
        value: putData.note
      }
    ];
  };
  const put = () => {
    store.dispatch('autobacsDevice/put');
  };
  initialize();
</script>
<style lang="scss">
  .device-edit-confirm {
    .main-container {
      width: 900px;
    }
    .device-edit-confirm-table-line {
      width: 400px;
      margin: 0px 25px 20px;
      float: left;
    }
    .device-edit-confirm-button-area {
      clear: left;
      margin: 20px 0px;
      .el-button {
        font-weight: 600;
      }
    }
  }
</style>
