<template>
  <BaseSimpleLayout class="advance-order-stock-list">
    <template #title>預かり端末在庫一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-button size="large" type="info" @click="router.push('/advance')">
        先行受注一覧
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <span class="advance-order-note">
        *1 現状在庫数 = 売上数 - 出荷数 + 入荷数
      </span>
      <el-table size="small" :data="tableData" border :max-height="tableHeight">
        <el-table-column prop="orderCompanyName" label="企業名" sortable />
        <el-table-column
          prop="saleQty"
          label="売上数"
          width="130"
          align="center"
          sortable
        />
        <!-- <el-table-column
          prop="acceptQty"
          label="受注数"
          width="130"
          align="center"
          sortable
        />
        <el-table-column
          prop="sippingRequestQty"
          label="出荷準備数"
          width="150"
          align="center"
          sortable
        /> -->
        <el-table-column
          prop="shipQty"
          label="出荷数"
          width="130"
          align="center"
          sortable
        />
        <el-table-column
          prop="receiveQty"
          label="入荷数"
          width="130"
          align="center"
          sortable
        />
        <el-table-column
          prop="currentStockQty"
          label="現状在庫数*1"
          width="160"
          align="center"
          sortable
        />
        <!-- <el-table-column
          prop="theoryStockQty"
          label="理論在庫数*2"
          width="160"
          align="center"
          sortable
        /> -->
        <el-table-column
          prop="unusedStockQty"
          label="未使用在庫数"
          width="160"
          align="center"
          sortable
        />
        <el-table-column
          prop="custodyStockQty"
          label="流用在庫数"
          width="160"
          align="center"
          sortable
        />
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { OrderDevicesDataState } from '@/types/orderDevice';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const tableData = ref<OrderDevicesDataState[]>([]);
  const loadingFlag = ref(false);
  const tableHeight = ref(600);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('orderDevices/fetch');
    tableData.value = store.state.orderDevices.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .advance-order-stock-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      margin: 10px 0 0;
      font-size: 15px;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
    .advance-order-note {
      float: right;
      font-size: 13px;
      margin-top: 15px;
    }
  }
</style>
