/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error } from '@/libs/notification';
import router from '@/router';
import {
  SubSubCategoriesState,
  SubSubCategoriesDataState,
  SubSubCategoriesSubmitDataState,
  SubSubCategoryReplaceData
} from '@/types/master/subSubCategory';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    replaceData: null
  },
  mutations: {
    setSubSubCategories(
      state: SubSubCategoriesState,
      payload: SubSubCategoriesDataState[]
    ) {
      state.listData = payload;
    },
    setReplace(
      state: SubSubCategoriesState,
      payload: SubSubCategoryReplaceData
    ) {
      state.replaceData = payload;
    }
  },
  actions: {
    async fetch(context: any) {
      await axios
        .get(getBaseUrl() + '/master/subsubcategories', {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setSubSubCategories', response.data);
          const replaceData: SubSubCategoryReplaceData = {};
          response.data.forEach((data: SubSubCategoriesDataState) => {
            replaceData[data.id] = data.name;
          });
          context.commit('setReplace', replaceData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);

            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async submit(context: any, payload: SubSubCategoriesSubmitDataState) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(getBaseUrl() + '/master/subsubcategories', payload, {
          headers: { auth: await getAccessToken() }
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
