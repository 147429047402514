/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  CompanyState,
  CompanyDetailData,
  CompanyPutData,
  CompanyGroupList
} from '@/types/master/company';
import _ from 'lodash';

import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null
  },
  mutations: {
    setDetail(state: CompanyState, payload: CompanyDetailData) {
      state.detailData = payload;
    },
    setPut(state: CompanyState, payload: CompanyPutData) {
      state.putData = payload;
    }
  },
  actions: {
    async fetch(context: any, companyId: any) {
      await axios
        .get(getBaseUrl() + '/master/companies/' + companyId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const setData = _.cloneDeep(response.data);
          setData.companyGroupList = setData.companyGroupList.map(
            (data: CompanyGroupList) => {
              return {
                id: data.id
              };
            }
          );
          context.commit('setPut', setData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店企業情報が存在しません。');
            router.push('/master/company');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/master/company');
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, companyId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/master/companies/' + companyId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('success', '販売店企業情報の削除に成功しました。');
          router.push('/master/company');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店企業情報が存在しません。');
            return router.push('/master/company');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any, payload: CompanyPutData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/master/companies/' + payload.id,
          {
            name: payload.name,
            zip: payload.zip,
            prefecture: payload.prefecture,
            city: payload.city,
            address: payload.address,
            addressSub: payload.addressSub,
            tel: payload.tel,
            fax: payload.fax,
            email: payload.email,
            staffName: payload.staffName,
            isAdvanceOrder: payload.isAdvanceOrder,
            companyGroupList: payload.companyGroupList
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '販売店企業情報の変更に成功しました。');
          router.push('/master/company');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '販売店企業情報が存在しません。');
            return router.push('/master/company');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    }
  }
};
