<template>
  <BaseSimpleLayout class="accept-create-product">
    <template #title>
      受注入力-製品情報 顧客管理番号:{{ customerCode }}
    </template>
    <template v-if="loadingFlag" #main>
      <div class="product-mccs-checkbox">
        <el-form-item label="MCCSver">
          <el-radio-group v-model="createHarnessForm.deviceType">
            <el-radio :label="3" v-on:change="changeMCCS">3</el-radio>
            <el-radio :label="2" v-on:change="changeMCCS">2</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-button size="large" type="success" @click="dialogFormVisible = true">
        製品追加
      </el-button>
      <el-table
        :data="createProductForm"
        stripe
        border
        class="product-details-table"
        empty-text="製品が存在しません"
        size="small"
      >
        <el-table-column
          prop="productName"
          label="名称"
          align="center"
          class-name="unit-master-product-details-name"
        >
          <template #default="scope">
            <span>{{ productReplaceData[scope.row.productId].name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productPrice"
          label="価格"
          width="70px"
          align="center"
        />
        <el-table-column
          prop="productType"
          label="種別"
          width="120px"
          align="center"
          class-name="unit-master-product-details-qty"
        >
          <template #default="scope">
            <span>{{
              productType[productReplaceData[scope.row.productId].productType]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="productQty"
          label="個数"
          width="70px"
          align="center"
          class-name="unit-master-product-details-qty"
        />
        <el-table-column
          label="操作"
          width="80px"
          align="center"
          class-name="unit-master-product-details-ope"
        >
          <template #default="scope">
            <el-button
              size="small"
              type="danger"
              class="unit-master-product-details-button-delete"
              @click="deletePackage(scope.$index)"
            >
              削除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="productErrorVisible"
        class="accept-create-product-details-error-text"
      >
        製品登録は必須です。
      </div>
      <el-form
        v-if="acceptType === 1 && isShip"
        ref="formRef"
        size="large"
        :model="createHarnessForm"
        :rules="rules"
        label-width="150px"
      >
        <el-form-item
          label="確定端末"
          prop="orderCompanyId"
          class="accept-create-product-order-company"
        >
          <el-select
            v-model="createHarnessForm.orderCompanyId"
            clearable
            filterable
            placeholder="選択"
          >
            <el-option
              v-for="item in companyList"
              :key="item.orderCompanyId"
              :label="
                item.orderCompanyName + ' 【在庫：' + item.theoryStockQty + '】'
              "
              :value="item.orderCompanyId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="accept-create-product-radio accept-create-product-radio-harness-cut"
          label="ハーネスカット"
          prop="isHarnessCut"
        >
          <el-radio-group v-model="createHarnessForm.isHarnessCut">
            <el-radio class="unit-no" :label="false" @change="resetCarInfo">
              利用しない
            </el-radio>
            <el-radio class="unit-yes" :label="true">利用する</el-radio>
          </el-radio-group>
          <div v-if="harnessError()" class="harness-error-text">
            プッシュ式車両にハーネスカットは使用できません。
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="!createHarnessForm.isHarnessCut"
            type="success"
            size="large"
            @click="harnessDialogFormVisible = true"
          >
            ハーネス取付車両追加
          </el-button>
        </el-form-item>
        <el-form-item
          class="accept-create-product-radio"
          label="新車/中古車"
          prop="isNewCar"
        >
          <el-radio-group v-model="createHarnessForm.isNewCar">
            <el-radio class="unit-new" :label="true">新車</el-radio>
            <el-radio class="unit-old" :label="false">中古</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="メーカー"
          prop="carMakerId"
          class="accept-create-product-auto-input accept-create-product-select-maker"
        >
          <el-select
            v-model="createHarnessForm.carMakerId"
            filterable
            clearable
            placeholder="選択"
            :disabled="!createHarnessForm.isHarnessCut"
          >
            <el-option
              v-for="item in carMakerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="車種"
          prop="carType"
          class="accept-create-product-auto-input"
        >
          <el-input
            v-model="createHarnessForm.carType"
            :disabled="!createHarnessForm.isHarnessCut"
          />
        </el-form-item>
        <el-form-item
          label="型式"
          prop="carModel"
          class="accept-create-product-auto-input"
        >
          <el-input
            v-model="createHarnessForm.carModel"
            :disabled="!createHarnessForm.isHarnessCut"
            @change="changeInput(createHarnessForm, 'carModel')"
          />
        </el-form-item>
        <el-form-item label="年式" prop="firstRegistYear">
          <el-input
            v-model="createHarnessForm.firstRegistYear"
            @change="changeInput(createHarnessForm, 'firstRegistYear')"
          />
        </el-form-item>
        <el-form-item label="車台番号" prop="vin">
          <el-input
            v-model="createHarnessForm.vin"
            @change="changeInput(createHarnessForm, 'vin')"
          />
        </el-form-item>
        <el-form-item
          label="E/Gスタート方式"
          prop="ignitionType"
          class="accept-create-product-radio"
          :disabled="!createHarnessForm.isHarnessCut"
        >
          <el-radio-group
            v-model="createHarnessForm.ignitionType"
            :disabled="!createHarnessForm.isHarnessCut"
          >
            <el-radio :label="1">キー</el-radio>
            <el-radio :label="4">キー02</el-radio>
            <el-radio :label="2">プッシュ</el-radio>
            <el-radio :label="3">その他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="!createHarnessForm.isHarnessCut"
          label="ハーネス品番"
          class="accept-create-product-auto-input"
        >
          <el-input v-model="harnessCode" disabled />
        </el-form-item>
        <el-form-item
          v-if="!createHarnessForm.isHarnessCut"
          label="ハーネス名称"
          class="accept-create-product-auto-input"
        >
          <el-input v-model="harnessName" disabled />
        </el-form-item>
      </el-form>
      <div class="accept-create-product-button-area">
        <el-button
          class="unit-master-accept-create-product-button-list"
          type="info"
          size="large"
          @click="router.push('/accept/create/basic')"
        >
          戻る
        </el-button>
        <el-button
          class="unit-master-accept-create-product-button-confirm"
          type="primary"
          size="large"
          @click="confirmClick(formRef)"
        >
          次へ
        </el-button>
      </div>
      <el-dialog v-model="dialogFormVisible" title="発送製品登録" width="500px">
        <div
          v-if="createHarnessForm.deviceType === 2"
          class="accept-create-product-notice-selectMCCS2"
        >
          MCCS2を選択しています
        </div>
        <el-form
          :model="productForm"
          class="accept-create-product-details-form"
          size="large"
          label-width="80px"
        >
          <el-form-item
            label="製品名称"
            class="accept-create-product-details-form-id"
          >
            <el-select
              v-model="productForm.productId"
              filterable
              placeholder="選択"
              class="accept-create-product-details-id"
              @change="setProductInfo()"
            >
              <el-option
                v-for="option in productList"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="製品種別">
            <el-input v-model="productForm.productType" disabled />
          </el-form-item>
          <el-form-item
            label="製品価格"
            class="accept-create-product-details-price"
          >
            <el-input-number
              v-model="productForm.productPrice"
              :precision="0"
              :min="0"
              :controls="false"
            />
          </el-form-item>
          <el-form-item label="個数" class="accept-create-product-details-qty">
            <el-input-number
              v-model="productForm.productQty"
              :precision="0"
              :min="1"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button type="primary" size="large" @click="addProduct">
            追加
          </el-button>
        </template>
      </el-dialog>
      <el-dialog
        v-model="harnessDialogFormVisible"
        title="ハーネス取付車両選択"
        width="95%"
      >
        <div
          v-if="createHarnessForm.deviceType === 2"
          class="accept-create-product-notice-selectMCCS2"
        >
          MCCS2を選択しています
        </div>
        <BaseSelectHarness
          :selectMCCS="createHarnessForm.deviceType"
          @emitting="addHarnessData($event)"
        />
      </el-dialog>
      <el-dialog v-model="duplicateDialogVisible" title="確認" width="400px">
        <span>
          <b>
            総合管理のデータに入力した車台番号が存在していますが受注入力を続けますか？
          </b>
        </span>
        <template #footer>
          <el-button size="large" @click="duplicateDialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="moveNext">
            確認
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseSelectHarness from '@/components/BaseSelectHarness.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import {
    AcceptProductData,
    AcceptHarnessData,
    AddProductForm
  } from '@/types/accept';
  import {
    ProductsDataState,
    ProductReplaceData
  } from '@/types/master/product';
  import { CarMakersDataState } from '@/types/master/carMaker';
  import { ElLoading } from 'element-plus';
  import { productType } from '@/libs/product';
  import { ManagementListState } from '@/types/management';
  import { OrderDevicesDataState } from '@/types/orderDevice';
  import { halfCharacterRestriction } from '@/libs/restriction';
  import { getHarnessByDeviceType } from '@/libs/device';
  import { HarnessEmitData } from '@/types/master/harness';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const dialogFormVisible = ref(false);
  const harnessDialogFormVisible = ref(false);
  const productErrorVisible = ref(false);
  const duplicateDialogVisible = ref(false);
  const customerCode = ref('');
  const sfOrderCode = ref('');
  const acceptType = ref(1);
  const isShip = ref(true);
  const createHarnessForm = ref<AcceptHarnessData>({
    harnessId: null,
    isNewCar: true,
    carMakerId: null,
    carType: '',
    carModel: '',
    firstRegistYear: '',
    vin: '',
    ignitionType: 1,
    isHarnessCut: false,
    orderCompanyId: null,
    deviceType: 3
  });
  const createProductForm = ref<AcceptProductData>([]);
  const productForm = ref<AddProductForm>({
    productId: 0,
    productType: '',
    productQty: 1,
    productPrice: 0
  });

  const rules = ref<FormRules>({
    carMakerId: [{ required: true, message: 'メーカーは必須です。' }],
    carType: [{ required: true, message: '車種は必須です。' }],
    carModel: [{ required: true, message: '型式は必須です。' }],
    firstRegistYear: [{ required: true, message: '年式は必須です。' }],
    vin: [{ required: true, message: '車台番号は必須です。' }]
  });
  const productList = ref<ProductsDataState[]>([]);
  const productReplaceData = ref<ProductReplaceData>({});
  const harnessName = ref('');
  const harnessCode = ref('');
  const carMakerList = ref<CarMakersDataState[]>([]);
  const companyList = ref<OrderDevicesDataState[]>([]);
  const loadingFlag = ref(false);
  const changeMCCS = async () => {
    let firstProductId: number | null = null;
    productList.value = store.state.productsMaster.listData.filter(
      (data: ProductsDataState) => {
        return (
          data.productType !== 5 &&
          (!data.deviceType ||
            data.deviceType == createHarnessForm.value.deviceType)
        );
      }
    );
    firstProductId = productList.value[0]?.id ?? null;
    productForm.value.productId = Number(firstProductId);
    createHarnessForm.value.harnessId = null;
    createHarnessForm.value.carMakerId = null;
    createHarnessForm.value.carType = '';
    createHarnessForm.value.carModel = '';
    harnessName.value = '';
    harnessCode.value = '';
    setProductInfo();
  };

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.accept.submitData;
    if (!submitData) {
      return router.push('/accept');
    }
    customerCode.value = submitData.customerCode;
    sfOrderCode.value = submitData.sfOrderCode;
    acceptType.value = submitData.type;
    isShip.value = submitData.isShip;
    createProductForm.value = submitData.details;
    createHarnessForm.value = {
      harnessId: submitData.harnessId,
      isNewCar: submitData.isNewCar,
      carMakerId: submitData.carMakerId,
      carType: submitData.carType,
      carModel: submitData.carModel,
      firstRegistYear: submitData.firstRegistYear,
      vin: submitData.vin,
      ignitionType: submitData.ignitionType,
      isHarnessCut: submitData.isHarnessCut,
      orderCompanyId: submitData.orderCompanyId,
      deviceType: submitData.deviceType
    };
    await Promise.all([
      store.dispatch(
        'productsMaster/fetchSelectList',
        submitData.companyGroupId
      ),
      store.dispatch('carInfoListMaster/fetch'),
      store.dispatch('carMakersMaster/fetch'),
      store.dispatch('harnessesMaster/fetch'),
      store.dispatch('itemsMaster/fetch'),
      store.dispatch('orderDevices/fetch')
    ]);
    companyList.value = store.state.orderDevices.listData;
    productList.value = store.state.productsMaster.listData.filter(
      (data: ProductsDataState) => {
        return (
          data.productType !== 5 &&
          (!data.deviceType ||
            data.deviceType == createHarnessForm.value.deviceType)
        );
      }
    );
    carMakerList.value = store.state.carMakersMaster.listData;
    productForm.value.productId = productList?.value[0]?.id;
    productReplaceData.value = store.state.productsMaster.replaceData;
    let item;
    const harnessId = createHarnessForm.value.harnessId;
    if (!createHarnessForm.value.isHarnessCut && harnessId) {
      item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        harnessId,
        createHarnessForm.value.deviceType
      );
    }
    if (item?.itemId) {
      setHarnessInfo(item.itemId);
    }
    setProductInfo();
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const harnessError = () => {
    return (
      createHarnessForm.value.isHarnessCut &&
      createHarnessForm.value.ignitionType === 2
    );
  };

  const setProductInfo = () => {
    const productId = productForm.value.productId;
    if (!productId) return;
    const productData = productReplaceData.value[productId];
    if (!productData) return;
    productForm.value.productType = productType[productData.productType];
    productForm.value.productPrice = productData.price;
  };

  const addProduct = () => {
    createProductForm.value.push(productForm.value);
    dialogFormVisible.value = false;
    productForm.value = {
      productId: productList.value[0]['id'],
      productType: '',
      productPrice: 0,
      productQty: 1
    };
    setProductInfo();
    productErrorVisible.value = false;
  };

  const deletePackage = (index: number) => {
    createProductForm.value.splice(index, 1);
    if (createProductForm.value.length === 0) {
      productErrorVisible.value = true;
    }
  };

  const addHarnessData = async (val: HarnessEmitData) => {
    createHarnessForm.value.harnessId = val.harnessId;
    createHarnessForm.value.carMakerId = val.carMakerId;
    createHarnessForm.value.carType = val.carInfo.name;
    createHarnessForm.value.carModel = val.carInfo.model;
    createHarnessForm.value.ignitionType = Number(val.ignitionType);
    let item;
    const harnessId = createHarnessForm.value.harnessId;
    if (!createHarnessForm.value.isHarnessCut && harnessId) {
      item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        harnessId,
        createHarnessForm.value.deviceType
      );
    }
    if (!createHarnessForm.value.harnessId) {
      createHarnessForm.value.isHarnessCut = true;
    }
    harnessName.value = '';
    harnessCode.value = '';
    if (item?.itemId) {
      setHarnessInfo(item.itemId);
    }
    harnessDialogFormVisible.value = false;
  };

  const setHarnessInfo = (itemId: number) => {
    const itemReplaceData = store.state.itemsMaster.replaceData;
    harnessName.value = itemReplaceData[itemId].name;
    harnessCode.value = itemReplaceData[itemId].partCode;
  };

  const resetCarInfo = () => {
    createHarnessForm.value.harnessId = null;
    createHarnessForm.value.carMakerId = null;
    createHarnessForm.value.carType = '';
    createHarnessForm.value.carModel = '';
    createHarnessForm.value.firstRegistYear = '';
    createHarnessForm.value.vin = '';
    createHarnessForm.value.ignitionType = 1;
    harnessName.value = '';
    harnessCode.value = '';
  };

  const confirmClick = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    let selectProdut = true;
    let harnessCut = true;
    let isValid = true;
    if (!createProductForm.value.length) {
      productErrorVisible.value = true;
      selectProdut = false;
    }
    if (
      createHarnessForm.value.ignitionType === 2 &&
      createHarnessForm.value.isHarnessCut
    ) {
      harnessCut = false;
    }
    if (acceptType.value === 1 && isShip) {
      formEl.validate((valid: boolean) => {
        isValid = valid;
      });
      if (isValid && selectProdut && harnessCut) {
        const loadingInstance = ElLoading.service({ fullscreen: true });
        await store.dispatch('management/fetch');
        const isDuplicate = store.state.management.listData.findIndex(
          (data: ManagementListState) => {
            return data.vin === createHarnessForm.value.vin;
          }
        );
        loadingInstance.close();
        if (isDuplicate >= 0) duplicateDialogVisible.value = true;
        else moveNext();
      }
      return;
    }
    createHarnessForm.value.harnessId = null;
    createHarnessForm.value.carMaker = '';
    createHarnessForm.value.carType = '';
    createHarnessForm.value.carModel = '';
    createHarnessForm.value.firstRegistYear = '';
    createHarnessForm.value.vin = '';
    createHarnessForm.value.ignitionType = 1;
    if (selectProdut) {
      moveNext();
    }
  };

  const moveNext = async () => {
    if (createHarnessForm.value.isHarnessCut) {
      createHarnessForm.value.harnessId = null;
    }
    await store.dispatch('accept/setSubmitPartData', createHarnessForm.value);
    if (acceptType.value === 1) {
      store.dispatch('accept/setSubmitPartData', {
        details: createProductForm.value
      });
    }
    router.push('/accept/create/shipment');
  };

  const changeInput = (form: AcceptHarnessData, key: string) => {
    form[key] = halfCharacterRestriction(form[key] as string);
  };
  initialize();
</script>
<style lang="scss">
  .accept-create-product {
    .header-container {
      .page-title {
        width: 100%;
      }
    }
    .el-form {
      width: 450px;
      margin: 20px auto 0px auto;
    }
    .el-form-item {
      font-weight: 600;
    }

    .accept-create-basic-input {
      text-align: left;
      margin-bottom: 10px;

      .el-input {
        width: 300px;
      }
    }

    .accept-create-product-details-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      padding-top: 10px;
      margin: -30px 0px 0px 0px;
    }

    .accept-create-product-notice-selectMCCS2 {
      font-weight: 600;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      justify-content: center;
      background-color: yellow;
      font-size: 16px;
      width: 200px;
    }

    .harness-error-text {
      color: #f56c6c;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      width: 310px;
    }
    .product-mccs-checkbox {
      font-weight: 600;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      width: 30%;
    }
    .product-details-table {
      width: 800px;
      font-size: 14px;
      margin: 10px auto;
      div {
        font-size: 14px;
      }
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }

    .accept-create-product-details-form {
      width: 450px;

      .accept-create-product-details-form-id {
        .el-select {
          float: left;
          width: 320px;
        }
      }
      .accept-create-product-details-price {
        .el-input-number {
          float: left;
          width: 100px;
        }
      }
      .accept-create-product-details-qty {
        .el-input-number {
          float: left;
          width: 60px;
        }
      }
    }

    .el-input.is-disabled .el-input__inner {
      color: #606266;
    }

    .accept-create-product-radio {
      text-align: left;
      .el-form-item__label {
        margin-right: 20px;
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #606266;
      }
    }

    .accept-create-product-basic-info {
      text-align: left;
    }

    .accept-create-product-button-area {
      margin: 30px 0px;
    }
    .accept-create-product-select-maker .el-select {
      float: left;
      width: 250px;
    }
    .header-container .page-title {
      width: 100%;
    }
    .el-select.accept-create-product-details-id {
      float: left;
      width: 300px;
    }
    .accept-create-product-order-company .el-select {
      float: left;
      width: 300px;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    td {
      div {
        font-weight: 600;
      }
    }
  }
</style>
