<template>
  <BaseSimpleLayout class="shop-edit">
    <template #title>販売店編集</template>
    <template #main>
      <el-form
        ref="formRef"
        :model="editForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <div class="shop-master-edit-form">
          <div>
            <el-form-item label="ID" prop="id">
              <el-input v-model="editForm.id" disabled />
            </el-form-item>
            <el-form-item label="販売店名" prop="name">
              <el-input
                v-model="editForm.name"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="販売店コード" prop="shopCode">
              <el-input
                v-model="editForm.shopCode"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              label="販売店企業名"
              prop="companyId"
              class="shop-edit-company"
            >
              <el-select
                v-model="editForm.companyId"
                placeholder="選択"
                filterable
                :disabled="isConfirm"
              >
                <el-option
                  v-for="company in companyList"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="shop-edit-tel" label="電話番号" prop="tel">
              <el-input
                v-model="editForm.tel"
                autocomplete="off"
                maxlength="13"
                :disabled="isConfirm"
                @change="telRestrict()"
              />
            </el-form-item>
            <el-form-item class="shop-edit-fax" label="FAX" prop="fax">
              <el-input
                v-model="editForm.fax"
                autocomplete="off"
                maxlength="12"
                :disabled="isConfirm"
                @change="faxRestrict()"
              />
            </el-form-item>
            <el-form-item
              class="shop-edit-staff-name"
              label="メールアドレス"
              prop="email"
            >
              <el-input
                v-model="editForm.email"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item
              class="shop-edit-staff-name"
              label="担当者名"
              prop="staffName"
            >
              <el-input
                v-model="editForm.staffName"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item label="郵便番号" prop="zip" class="shop-edit-zip">
              <el-input
                v-model="editForm.zip"
                autocomplete="off"
                maxlength="7"
                :disabled="isConfirm"
                @change="zipRestrict"
              />
            </el-form-item>
            <el-form-item
              label="都道府県"
              prop="prefecture"
              class="shop-edit-pref"
            >
              <el-select
                v-model="editForm.prefecture"
                filterable
                :disabled="isConfirm"
                placeholder="選択"
              >
                <el-option
                  v-for="prefecture in prefectureList"
                  :key="prefecture"
                  :label="prefecture"
                  :value="prefecture"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="市区町村" prop="city" class="shop-edit-city">
              <el-input
                v-model="editForm.city"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="住所" prop="address">
              <el-input
                v-model="editForm.address"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
            <el-form-item label="住所サブ" prop="addressSub">
              <el-input
                v-model="editForm.addressSub"
                autocomplete="off"
                :disabled="isConfirm"
              />
            </el-form-item>
          </div>
        </div>
        <div v-if="isConfirm" class="button-area">
          <el-button
            class="unit-master-shop-edit-button-list"
            type="info"
            @click="isConfirm = false"
          >
            キャンセル
          </el-button>
          <el-button
            class="unit-master-shop-edit-button-confirm"
            type="primary"
            @click="update()"
          >
            更新
          </el-button>
        </div>
        <div v-else class="button-area">
          <el-button
            class="unit-master-shop-edit-button-list"
            type="info"
            @click="router.push('/master/shop/' + shopId)"
          >
            戻る
          </el-button>
          <el-button
            class="unit-master-shop-edit-button-confirm"
            type="primary"
            @click="changeConfirm(formRef)"
          >
            確認
          </el-button>
        </div>
      </el-form>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { ShopPutData } from '@/types/master/shop';
  import { CompaniesDataState } from '@/types/master/company';
  import { prefectureList } from '@/libs/prefecture';
  import { telRestriction, zipRestriction } from '@/libs/common';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const editForm = ref<ShopPutData>({
    id: 0,
    companyId: null,
    shopCode: '',
    name: '',
    zip: '',
    prefecture: '',
    city: '',
    address: '',
    addressSub: '',
    tel: '',
    fax: '',
    email: '',
    staffName: ''
  });
  const rules = ref<FormRules>({
    companyId: [{ required: true, message: '企業名は必須です。' }],
    shopCode: [{ required: true, message: '取扱店名は必須です。' }],
    name: [{ required: true, message: '取扱店名は必須です。' }],
    zip: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    prefecture: [{ required: true, message: '都道府県は必須です。' }],
    city: [{ required: true, message: '市区町村は必須です。' }],
    address: [{ required: true, message: '住所は必須です。' }],
    tel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    fax: [
      {
        min: 10,
        max: 12,
        message:
          'FAXは10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    email: [
      {
        type: 'email',
        message: 'メールアドレス形式で入力して下さい。',
        trigger: 'blur'
      }
    ],
    staffName: [{ required: true, message: '担当者は必須です。' }]
  });
  const companyList = ref<CompaniesDataState[]>([]);
  const shopId = ref(0);
  const isConfirm = ref(false);

  const initialize = () => {
    const putData = store.state.shopMaster.putData;
    if (!putData) {
      return router.push('/master/shop');
    }
    editForm.value = putData;
    shopId.value = putData.id;
    companyList.value = store.state.companiesMaster.listData;
    if (store.state.shopsMaster.submitData) {
      editForm.value = store.state.shopsMaster.submitData;
    }
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid) => {
      if (!valid) {
        return false;
      }
      isConfirm.value = true;
    });
  };
  const update = () => {
    store.dispatch('shopMaster/put', editForm.value);
  };
  const zipRestrict = () => {
    editForm.value.zip = zipRestriction(editForm.value.zip);
  };
  const telRestrict = () => {
    editForm.value.tel = telRestriction(editForm.value.tel);
  };
  const faxRestrict = () => {
    editForm.value.fax = telRestriction(editForm.value.fax);
  };
  initialize();
</script>
<style lang="scss">
  .shop-edit {
    .shop-master-edit-form {
      display: inline-flex;
    }
    form {
      width: 100%;
      display: inline-block;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;
    }

    .shop-edit-zip .el-input {
      width: 90px;
      float: left;
    }

    .shop-edit-company {
      .el-input {
        width: 300px;
        float: left;
      }
      .el-select {
        width: 300px;
        float: left;
      }
    }
    .shop-edit-zip {
      .el-input {
        width: 110px;
        float: left;
      }
    }

    .shop-edit-pref {
      .el-input {
        width: 110px;
      }
      .el-select {
        width: 110px;
        float: left;
      }
    }

    .shop-edit-city {
      .el-input {
        width: 150px;
        float: left;
      }
    }

    .shop-edit-tel {
      .el-input {
        width: 135px;
        float: left;
      }
    }

    .shop-edit-fax {
      .el-input {
        width: 135px;
        float: left;
      }
    }

    .shop-edit-staff-name {
      .el-input {
        width: 200px;
        float: left;
      }
    }

    .button-area {
      margin: 10px 0px;
    }

    .el-button {
      font-weight: 600;
    }
  }
</style>
