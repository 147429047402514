/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NumberData } from '@/types/common';
import { ManagementDetailData } from '@/types/management';
import { formatDate } from '@/libs/dateFormat';
import { shipSelectType } from '@/libs/accept';
import { DetailTableData } from '@/types/common';

export const managementStatus: NumberData = {
  0: '発送済',
  1: '発送済長期未取付',
  2: '取付中',
  3: '起動制御中',
  4: '稼働中',
  5: '取付前キャンセル',
  6: '動作不良のため交換対象',
  7: 'MCCS不良交換',
  8: '取付後返品対象',
  9: '取付後案件変更',
  10: '返品済',
  11: '取外済',
  12: 'デフォルト',
  13: '完済済',
  14: '車両回収',
  15: 'その他',
  16: '解約',
  17: 'トラブル対応中（請求停止）'
};

export const managementStatusList = [
  {
    val: 0,
    label: '発送済'
  },
  {
    val: 1,
    label: '発送済長期未取付'
  },
  {
    val: 2,
    label: '取付中'
  },
  {
    val: 3,
    label: '起動制御中'
  },
  {
    val: 4,
    label: '稼働中'
  },
  {
    val: 5,
    label: '取付前キャンセル'
  },
  {
    val: 6,
    label: '動作不良のため交換対象'
  },
  {
    val: 7,
    label: 'MCCS不良交換'
  },
  {
    val: 8,
    label: '取付後返品対象'
  },
  {
    val: 9,
    label: '取付後案件変更'
  },
  {
    val: 10,
    label: '返品済'
  },
  {
    val: 11,
    label: '取外済'
  },
  {
    val: 12,
    label: 'デフォルト'
  },
  {
    val: 13,
    label: '完済済'
  },
  {
    val: 14,
    label: '車両回収'
  },
  {
    val: 15,
    label: 'その他'
  },
  {
    val: 16,
    label: '解約'
  },
  {
    val: 17,
    label: 'トラブル対応中（請求停止）'
  }
];

export function createLeftTableData(
  data: ManagementDetailData
): DetailTableData[] {
  return [
    {
      label: 'シリアル番号',
      value: data.deviceSerialCode ?? '-'
    },
    {
      label: '顧客管理番号',
      value: data.customerCode ?? '-'
    },
    {
      label: 'ファイナンス',
      value: data.financeName ?? '-'
    },
    {
      label: 'FS管理番号',
      value: data.financeCode ?? '-'
    },
    {
      label: '販売店グループ',
      value: data.companyGroupName ?? '-'
    },
    {
      label: '販売店企業',
      value: data.companyName ?? '-'
    },
    {
      label: '販売店',
      value: data.shopName ?? '-'
    },
    {
      label: '車種',
      value: data.carType ?? '-'
    },
    {
      label: '型式',
      value: data.carModel ?? '-'
    },
    {
      label: '車台番号',
      value: data.vin ?? '-'
    },
    {
      label: 'ハーネス品番',
      value: data.harnessCode ?? '-'
    },
    {
      label: 'ハーネス名称',
      value: data.harnessName ?? '-'
    },
    {
      label: '出荷先種別',
      value: data.shipSelectType ? shipSelectType[data.shipSelectType] : '-'
    },
    {
      label: '出荷先店名',
      value: data.shipName ?? '-'
    },
    {
      label: '取付店種別',
      value: data.shipDate
        ? data.supportShopId
          ? 'サポート店'
          : '販売店'
        : '-'
    },
    {
      label: '取付店名',
      value: data.supportShopId ? data.supportShopName ?? '-' : data.shopName
    }
  ];
}

export function createRightTableData(
  data: ManagementDetailData
): DetailTableData[] {
  return [
    {
      label: 'ステータス',
      value: managementStatus[data.status]
    },
    {
      label: '解約理由',
      value: '-'
    },
    {
      label: '出荷日',
      value: data.shipDate ? formatDate(Number(data.shipDate)) : '-'
    },
    {
      label: '取付日',
      value: data.mountDate ? formatDate(Number(data.mountDate)) : '-'
    },
    {
      label: '解約日(取外日)',
      value: data.unMountDate ? formatDate(Number(data.unMountDate)) : '-'
    },
    {
      label: '動作確認日',
      value: data.operationCheckDate
        ? formatDate(Number(data.operationCheckDate))
        : '-'
    },
    {
      label: '請求開始日',
      value: data.startBillingDate
        ? formatDate(Number(data.startBillingDate))
        : '-'
    },
    {
      label: '請求終了日',
      value: data.endBillingDate ? formatDate(Number(data.endBillingDate)) : '-'
    },
    {
      label: '返品確認日',
      value: data.returnedCheckDate
        ? formatDate(Number(data.returnedCheckDate))
        : '-'
    },
    {
      label: '使用月額費',
      value: data.usedMonthlyFee
    },
    {
      label: '未使用月額費',
      value: data.unUsedMonthlyFee
    },
    {
      label: '最終請求日',
      value: data.lastRecordDate ? formatDate(Number(data.lastRecordDate)) : '-'
    }
  ];
}

export function createOtherTableData(
  data: ManagementDetailData
): DetailTableData[] {
  return [
    {
      label: '備考',
      value: data.note
    },
    {
      label: '登録者',
      value: data.registerUserName
    },
    {
      label: '登録日',
      value: data.createdAt ? formatDate(Number(data.createdAt)) : '-'
    },
    {
      label: '更新者',
      value: data.updateUserName
    },
    {
      label: '更新日',
      value: data.updatedAt ? formatDate(Number(data.updatedAt)) : '-'
    }
  ];
}

export function createLeftEditData(
  data: ManagementDetailData,
  putData: ManagementDetailData
): DetailTableData[] {
  return [
    {
      label: '端末シリアル番号',
      value: data.deviceSerialCode
    },
    {
      label: '顧客管理番号',
      value: putData.customerCode
    },
    {
      label: 'ファイナンス',
      value: ''
    },
    {
      label: 'FC管理番号',
      value: putData.financeCode
    },
    {
      label: '販売店グループ',
      value: data.companyGroupName
    },
    {
      label: '販売店企業',
      value: data.companyName
    },
    {
      label: '販売店',
      value: data.shopName
    }
  ];
}

export function createRightEditData(
  data: ManagementDetailData
): DetailTableData[] {
  return [
    {
      label: 'ステータス',
      value: managementStatus[data.status]
    },
    {
      label: '解約理由',
      value: '-'
    },
    {
      label: '車台番号',
      value: data.vin
    },
    {
      label: '使用月額費用',
      value: data.usedMonthlyFee
    },
    {
      label: '未使用月額費用',
      value: data.unUsedMonthlyFee
    },
    {
      label: '取付日',
      value: data.mountDate ? formatDate(Number(data.mountDate)) : '-'
    },
    {
      label: '解約日(取外日)',
      value: data.unMountDate ? formatDate(Number(data.unMountDate)) : '-'
    },
    {
      label: '動作確認日',
      value: data.operationCheckDate
        ? formatDate(Number(data.operationCheckDate))
        : '-'
    },
    {
      label: '請求開始日',
      value: data.startBillingDate
        ? formatDate(Number(data.startBillingDate))
        : '-'
    },
    {
      label: '請求終了日',
      value: data.endBillingDate ? formatDate(Number(data.endBillingDate)) : '-'
    },
    {
      label: '返品確認日',
      value: data.returnedCheckDate
        ? formatDate(Number(data.returnedCheckDate))
        : '-'
    },
    {
      label: '備考',
      value: data.note
    }
  ];
}
