<template>
  <BaseSimpleLayout class="accept-create-shipment">
    <template #title>
      受注入力-発送情報 顧客管理番号:{{ customerCode }}
    </template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="150px"
      >
        <el-form-item class="input-radio" label="拠点" prop="depotId">
          <el-radio-group v-model="createForm.depotId">
            <el-radio class="unit-new" :label="1">岐阜</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="input-radio" label="取付先">
          <el-radio-group
            v-model="useSupportShop"
            @change="changeInstallShopType"
          >
            <el-radio :label="false">販売店</el-radio>
            <el-radio :label="true">サポート店</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="useSupportShop"
          label="取付サポート店"
          prop="supportShopId"
        >
          <el-select
            v-model="createForm.supportShopId"
            filterable
            placeholder=""
            disabled
          >
            <el-option
              v-for="supportShop in supportShopList"
              :key="supportShop.id"
              :label="supportShop.name"
              :value="supportShop.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="input-radio"
          label="発送先種別"
          prop="shipSelectType"
        >
          <el-radio-group
            v-model="createForm.shipSelectType"
            @change="changeShipSelectType"
          >
            <el-radio :label="1">販売店</el-radio>
            <el-radio :label="2">サポート店</el-radio>
            <el-radio :label="3">その他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="発送先名" prop="shipName">
          <el-input v-model="createForm.shipName" />
        </el-form-item>
        <el-form-item label="郵便番号" prop="shipZip" class="shipment-zip">
          <el-input
            v-model="createForm.shipZip"
            maxlength="7"
            @change="zipRestrict"
          />
        </el-form-item>
        <el-form-item
          label="都道府県"
          prop="shipPrefecture"
          class="shipment-select"
        >
          <el-select
            v-model="createForm.shipPrefecture"
            filterable
            placeholder=""
          >
            <el-option
              v-for="prefecture in prefectureList"
              :key="prefecture"
              :label="prefecture"
              :value="prefecture"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="市区町村"
          prop="shipCity"
          class="accept-create-shipment-city"
        >
          <el-input v-model="createForm.shipCity" />
        </el-form-item>
        <el-form-item label="住所" prop="shipAddress">
          <el-input v-model="createForm.shipAddress" />
        </el-form-item>
        <el-form-item label="住所サブ" prop="shipAddressSub">
          <el-input v-model="createForm.shipAddressSub" />
        </el-form-item>
        <el-form-item
          label="電話番号"
          prop="shipTel"
          class="accept-create-shipment-tel"
        >
          <el-input
            v-model="createForm.shipTel"
            @change="telRestrict('shipTel')"
          />
        </el-form-item>
        <el-form-item
          label="FAX番号"
          prop="shipFax"
          class="accept-create-shipment-tel"
        >
          <el-input
            v-model="createForm.shipFax"
            @change="telRestrict('shipFax')"
          />
        </el-form-item>
        <el-form-item label="メールアドレス" prop="shipEmail">
          <el-input v-model="createForm.shipEmail" />
        </el-form-item>
        <el-form-item label="発送先担当者" prop="shipTargetName">
          <el-input v-model="createForm.shipTargetName" />
        </el-form-item>
        <el-form-item
          label="着指定日"
          prop="arrivalDate"
          class="accept-create-shipment-arrival-date"
        >
          <el-date-picker
            v-model="createForm.arrivalDate"
            type="date"
            placeholder="日付を選択"
            style="width: 140px"
            format="YYYY-MM-DD"
            value-format="x"
          />
        </el-form-item>
      </el-form>
      <div class="accept-create-product-button-area">
        <el-button
          type="info"
          size="large"
          @click="router.push('/accept/create/product')"
        >
          戻る
        </el-button>
        <el-button type="primary" size="large" @click="moveConfirm(formRef)">
          確認
        </el-button>
      </div>
      <el-dialog
        v-model="dialogVisible"
        title="サポート店選択"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="80%"
      >
        <base-select-support-shop
          @emitting="addSupportShopData($event)"
          @close="closeDialog"
        />
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import { AcceptShipmentData } from '@/types/accept';
  import BaseSelectSupportShop from '@/components/BaseSelectSupportShop.vue';
  import { prefectureList } from '@/libs/prefecture';
  import { ElLoading } from 'element-plus';
  import { telRestriction, zipRestriction } from '@/libs/common';
  import { SupportShopsDataState } from '@/types/master/supportShop';
  import { ShopDetailData } from '@/types/master/shop';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const dialogVisible = ref(false);
  const sfOrderCode = ref('');
  const shopId = ref(0);
  const createForm = ref<AcceptShipmentData>({
    arrivalDate: null,
    shipSelectType: null,
    shipName: '',
    shipZip: '',
    shipPrefecture: '',
    shipCity: '',
    shipAddress: '',
    shipAddressSub: '',
    shipTel: '',
    shipFax: '',
    shipEmail: '',
    shipTargetName: '',
    depotId: 1,
    supportShopId: null,
    orderCompanyId: null
  });
  const rules = ref<FormRules>({
    supportShopId: [
      { required: true, message: '取付サポート店名は必須です。' }
    ],
    shipName: [{ required: true, message: '発送先店名は必須です。' }],
    shipSelectType: [{ required: true, message: '発送先種別は必須です。' }],
    shipZip: [
      { required: true, message: '郵便番号は必須です。' },
      {
        min: 7,
        max: 7,
        message: '郵便番号は7桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    shipPrefecture: [{ required: true, message: '都道府県は必須です。' }],
    shipCity: [{ required: true, message: '市区町村は必須です。' }],
    shipAddress: [{ required: true, message: '住所は必須です。' }],
    shipTel: [
      { required: true, message: '電話番号は必須です。' },
      {
        min: 10,
        max: 13,
        message:
          '電話番号は10桁から13桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    shipFax: [
      {
        min: 10,
        max: 12,
        message:
          'FAXは10桁から12桁の半角数字(ハイフン含む)で入力してください。',
        trigger: 'blur'
      }
    ],
    shipTargetName: [{ required: true, message: '発送先担当者は必須です。' }]
  });
  const customerCode = ref('');
  const useSupportShop = ref(false);
  const loadingFlag = ref(false);
  const supportShopList = ref<SupportShopsDataState[]>([]);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    const submitData = store.state.accept.submitData;
    if (!submitData) {
      return router.push('/accept');
    }
    await store.dispatch('supportShopsMaster/fetch');
    supportShopList.value = store.state.supportShopsMaster.listData;
    customerCode.value = submitData.customerCode;
    sfOrderCode.value = submitData.sfOrderCode;
    shopId.value = submitData.shopId;

    createForm.value = {
      arrivalDate: submitData.arrivalDate,
      shipSelectType: submitData.shipSelectType,
      shipName: submitData.shipName,
      shipZip: submitData.shipZip,
      shipPrefecture: submitData.shipPrefecture,
      shipCity: submitData.shipCity,
      shipAddress: submitData.shipAddress,
      shipAddressSub: submitData.shipAddressSub,
      shipTel: submitData.shipTel,
      shipFax: submitData.shipFax,
      shipEmail: submitData.shipEmail,
      shipTargetName: submitData.shipTargetName,
      note: submitData.note,
      depotId: submitData.depotId,
      orderCompanyId: submitData.orderCompanyId,
      supportShopId: submitData.supportShopId
    };
    useSupportShop.value = !!createForm.value.supportShopId;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const addSupportShopData = (supportShopId: number) => {
    if (useSupportShop.value) {
      createForm.value.supportShopId = supportShopId;
    }
    if (createForm.value.shipSelectType === 2) {
      setShipmentData(supportShopId, false);
    }
    dialogVisible.value = false;
  };
  const closeDialog = () => {
    dialogVisible.value = false;
  };

  const changeShipSelectType = () => {
    if (createForm.value.shipSelectType === 1) {
      setShipmentData(shopId.value, true);
    }
    if (createForm.value.shipSelectType === 2) {
      if (createForm.value.supportShopId) {
        setShipmentData(createForm.value.supportShopId, false);
      } else {
        dialogVisible.value = true;
      }
    }
    if (createForm.value.shipSelectType === 3) {
      createForm.value.shipName = '';
      createForm.value.shipZip = '';
      createForm.value.shipPrefecture = '';
      createForm.value.shipCity = '';
      createForm.value.shipAddress = '';
      createForm.value.shipAddressSub = '';
      createForm.value.shipTel = '';
      createForm.value.shipFax = '';
      createForm.value.shipEmail = '';
    }
  };

  const changeInstallShopType = () => {
    if (useSupportShop.value) {
      dialogVisible.value = true;
    } else {
      createForm.value.supportShopId = null;
    }
  };

  const setShipmentData = async (id: number, isShop: boolean) => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    let shopData: ShopDetailData | null = null;
    if (isShop) {
      await store.dispatch('shopMaster/fetch', id);
      shopData = store.state.shopMaster.detailData;
    } else {
      await store.dispatch('supportShopMaster/fetch', id);
      shopData = store.state.supportShopMaster.detailData;
    }
    if (!shopData) return;
    createForm.value.shipName = shopData.name;
    createForm.value.shipZip = shopData.zip;
    createForm.value.shipPrefecture = shopData.prefecture;
    createForm.value.shipCity = shopData.city;
    createForm.value.shipAddress = shopData.address;
    createForm.value.shipAddressSub = shopData.addressSub;
    createForm.value.shipTel = shopData.tel;
    createForm.value.shipFax = shopData.fax;
    createForm.value.shipEmail = shopData.email;
    createForm.value.shipTargetName = shopData.staffName;
    loadingInstance.close();
  };

  const moveConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid: boolean) => {
      if (valid) {
        store.dispatch('accept/setSubmitPartData', createForm.value);
        router.push('/accept/create/confirm');
      }
    });
  };

  const zipRestrict = () => {
    createForm.value.shipZip = zipRestriction(createForm.value.shipZip);
  };
  const telRestrict = (index: string) => {
    createForm.value[index] = telRestriction(createForm.value[index] as string);
  };
  initialize();
</script>
<style lang="scss">
  .accept-create-shipment {
    .header-container {
      .page-title {
        width: 100%;
      }
    }
    form {
      width: 600px;
      margin: 20px auto 0px auto;
    }

    .el-input {
      width: 300px;
      float: left;
    }

    .el-form-item {
      font-weight: 600 !important;
    }
    .input-radio {
      text-align: left;
      .el-form-item__label {
        margin-right: 20px;
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #606266;
      }
    }
    .accept-create-product-button-area {
      margin: 30px 0px;
    }
    .shipment-select .el-input {
      width: 130px;
    }

    .shipment-zip .el-input {
      width: 130px;
    }

    .accept-create-shipment-city .el-input {
      width: 130px;
    }

    .accept-create-shipment-tel .el-input {
      width: 135px;
    }

    .accept-create-shipment-arrival-date .el-input {
      float: left;
    }
    .page-title {
      width: 1000px;
    }
    .el-button {
      font-weight: 600;
    }
    .el-select {
      float: left;
    }
    .el-input.is-disabled .el-input__inner {
      color: #606266;
    }
  }
</style>
