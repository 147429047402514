<template>
  <div class="base-company-group-list">
    <div class="table-title">
      {{ isGroup ? '販売店グループ' : '所属販売店企業' }}
    </div>
    <el-table
      :data="tableData"
      stripe
      border
      class="base-company-group-list"
      empty-text="所属販売店グループが存在しません"
    >
      <el-table-column prop="id" label="ID" width="60px" align="center">
        <template #default="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称">
        <template #default="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="種別" width="60px">
        <template #default="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { CompanyListData } from '@/types/common';
  import { groupTypeData } from '@/libs/companyGroup';

  export default defineComponent({
    name: 'BaseCompanyList',
    props: {
      tableData: {
        type: Array as PropType<CompanyListData[]>,
        default: () => []
      },
      isGroup: {
        type: Boolean,
        default: () => true
      }
    },
    setup() {
      const groupType = groupTypeData;
      return { groupType };
    }
  });
</script>
<style lang="scss">
  .base-company-group-list {
    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    .el-table {
      thead {
        th {
          background: #f5f7fa;
        }
      }
      .cell {
        white-space: pre-wrap !important;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
</style>
